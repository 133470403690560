import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { Grid, Theme, useMediaQuery } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { parse } from 'query-string'
import * as R from 'ramda'
import {
  Business,
  CreatableAutocomplete,
  FieldProp,
  LanguageUtils,
  NamedEntity,
  Nil,
  PermissionArea,
  PuiTextField,
  Text,
  useFields,
  Utils,
} from '@pbt/pbt-ui-components'

import BusinessTypeLabel from '~/components/common/BusinessTypeLabel'
import RequiredFieldsNotice from '~/components/common/inputs/RequiredFieldsNotice'
import Expander from '~/components/common/lists/Expander'
import ClinicLogo from '~/components/common/logos/ClinicLogo'
import PanelAccordion from '~/components/common/PanelAccordion'
import PuiCheckboxList from '~/components/common/PuiCheckboxList'
import { BusinessTypeName } from '~/constants/businesses'
import FeatureToggle from '~/constants/featureToggle'
import i18n from '~/locales/i18n'
import {
  fetchBusiness,
  fetchBusinessGroupTags,
  updateBusiness,
} from '~/store/actions/businesses'
import {
  getInvalidPanels,
  getSettingsHasChanged,
  updateBusinessFieldsFromSection,
  validateBusinessSections,
} from '~/store/duck/businessSettings'
import { fetchModality } from '~/store/duck/imagingOrders'
import { fetchDevices } from '~/store/duck/labOrders'
import {
  getRhapsodyPayIsFetching,
  getRhapsodyPayIsSaving,
} from '~/store/duck/rhapsodyPay'
import {
  getCRUDByArea,
  getCRUDByAreaForBusiness,
  getCurrentBusinessRootId,
} from '~/store/reducers/auth'
import {
  getBusiness,
  getBusinessIsFetching,
  getBusinessIsSaving,
} from '~/store/reducers/businesses'
import {
  getFeatureToggle,
  getFeatureToggleForBusiness,
  getPracticeTypes,
} from '~/store/reducers/constants'
import { PracticeMainFields } from '~/types'
import { useBoopEnabled } from '~/utils/boop'
import { getBusinessIsGroup } from '~/utils/businessUtils'
import { usePracticeFieldsSection } from '~/utils/usePracticeFieldsSection'

import { PracticeDetailsPanelsComponents } from './PracticeDetailsPanelsComponents'
import {
  PracticeDetailsPanels,
  PracticeDetailsPanelsLabels,
  PracticeDetailsPanelsList,
  PracticeDetailsPanelsNoPadding,
} from './practices'
import PracticeStatusSelect from './PracticeStatusSelect'
import { usePracticeDetailsFields } from './usePracticeDetailsFields'

const useStyles = makeStyles(
  (theme) => ({
    practiceLogo: {
      padding: 0,
      width: 200,
      height: 112,
      boxShadow:
        '1px 1px 2px 0 rgba(60,56,56,0.14), 0 1px 4px 0 rgba(60,56,56,0.12)',
    },
    headerRow: {
      flexWrap: 'nowrap',
      [theme.breakpoints.down('lg')]: {
        flexWrap: 'wrap',
      },
    },
    numberLabel: {
      whiteSpace: 'nowrap',
    },
    typeCheckboxList: {
      flexDirection: 'row',
      flexWrap: 'wrap',
    },
    autocompleteTag: {
      height: 30,
    },
  }),
  { name: 'PracticeDetails' },
)

const getMainFields = (
  business: Business | Nil,
  isGroup: boolean,
): FieldProp[] => [
  {
    name: 'name',
    label: isGroup
      ? i18n.t('Businesses:GROUP_NAME')
      : i18n.t('Businesses:PRACTICE_NAME'),
    validators: ['required'],
    initialValue: business?.name,
  },
  {
    name: 'internalName',
    label: i18n.t('Businesses:INTERNAL_NAME'),
    initialValue: business?.internalName || '',
  },
  {
    name: 'region',
    label: i18n.t('Businesses:REGION'),
    initialValue: business?.region || '',
  },
  {
    name: 'statusId',
    label: i18n.t('Common:STATUS'),
    type: 'select',
    initialValue: business?.statusId || '',
  },
  {
    name: 'tags',
    label: i18n.t('Common:TAG_ONE_OR_OTHER'),
    type: 'none',
    initialValue: business?.tags || [],
  },
  {
    name: 'practiceTypeIds',
    label: i18n.t('Common:TYPE_ONE_OR_OTHER'),
    type: 'none',
    initialValue: business?.practiceTypeIds || [],
  },
  {
    name: 'logo',
    type: 'none',
    initialValue: business?.logo || '',
  },
]

export interface PracticeDetailsProps {
  itemId: string
  onClose: () => void
}

const PracticeDetails = ({ itemId, onClose }: PracticeDetailsProps) => {
  const classes = useStyles()
  const { search } = useLocation()
  const { panels } = parse(search)
  const isMobile = useMediaQuery<Theme>((theme) => theme.breakpoints.down('md'))
  const dispatch = useDispatch()

  const business = useSelector(getBusiness(itemId))
  const businessGroup = useSelector(getBusiness(business?.parentBusinessId!))
  const businessRootId = useSelector(getCurrentBusinessRootId)
  const isPayConfigFetching = useSelector(getRhapsodyPayIsFetching)
  const isPayConfigSaving = useSelector(getRhapsodyPayIsSaving)
  const isBusinessSaving = useSelector(getBusinessIsSaving)
  const isBusinessFetching = useSelector(getBusinessIsFetching)
  const permissions = useSelector(
    getCRUDByAreaForBusiness(PermissionArea.BUSINESS, business),
  )
  const migrationPermissions = useSelector(getCRUDByArea(PermissionArea.IMPORT))
  const supportPermissions = useSelector(getCRUDByArea(PermissionArea.SUPPORT))
  const ssoSettingsPermissions = useSelector(
    getCRUDByArea(PermissionArea.SSO_SETTINGS),
  )
  const chatReconcilePermissions = useSelector(
    getCRUDByArea(PermissionArea.CHAT_RECONCILE),
  )
  const reminderProtocolsPermissions = useSelector(
    getCRUDByArea(PermissionArea.REMINDER_PROTOCOLS),
  )
  const kyriosClinicIdPermissions = useSelector(
    getCRUDByArea(PermissionArea.KYRIOS_CLINIC_ID),
  )
  const isDiscountGroupsEnabled = useSelector(
    getFeatureToggle(FeatureToggle.DISCOUNT_GROUPS),
  )
  const isAppointmentConfigHidden = useSelector(
    getFeatureToggle(
      FeatureToggle.HIDE_APPOINTMENT_CONFIGURATION_IN_PRACTICE_SETTINGS_TAB,
    ),
  )
  const isVetcoveSetupEnabled = useSelector(
    getFeatureToggle(FeatureToggle.VETCOVE_SETUP),
  )
  const isRcReconcilationEnabled = useSelector(
    getFeatureToggle(FeatureToggle.RC_RECONCILATION_ENABLED),
  )
  const isSsoIntegrationEnabled = useSelector(
    getFeatureToggle(FeatureToggle.SSO_INTEGRATION),
  )
  const PracticeTypes = useSelector(getPracticeTypes)
  const hasUnsavedData = useSelector(getSettingsHasChanged(itemId))
  const invalidPanels = useSelector(getInvalidPanels(itemId))

  const isAppointmentCancellationReasonEnabled = useSelector(
    getFeatureToggle(FeatureToggle.APPOINTMENT_CANCELLATION_REASON),
  )
  const isPatientSharingEnabled = useSelector(
    getFeatureToggle(FeatureToggle.PATIENT_SHARING),
  )
  const isGroupClientSharingEnabled = useSelector(
    getFeatureToggle(FeatureToggle.GROUP_CLIENT_SHARING),
  )
  const isNpsClinicLevelEnabled = useSelector(
    getFeatureToggle(FeatureToggle.NPS_CLINIC_LEVEL),
  )
  const isMigrationV2 = useSelector(
    getFeatureToggle(FeatureToggle.IMPORT_V3_PORTAL_INTEGRATION),
  )
  const isAutoReplyRhapsodyCommunicationEnabled = useSelector(
    getFeatureToggle(FeatureToggle.AUTO_REPLY_RHAPSODY_COMMUNICATION),
  )
  const isTeamAssignmentEnabled = useSelector(
    getFeatureToggle(FeatureToggle.TEAM_ASSIGNMENT),
  )
  const isWellnessplanSharingEnabled = useSelector(
    getFeatureToggle(FeatureToggle.WELLNESSPLAN_SHARING),
  )
  const isAutoRolesAssignEnabled = useSelector(
    getFeatureToggleForBusiness(
      FeatureToggle.AUTO_CWAV_VCR_ROLES_ASSIGN,
      itemId,
    ),
  )
  const isDiscountReasonEnabled = useSelector(
    getFeatureToggleForBusiness(FeatureToggle.DISCOUNT_REASON, itemId),
  )

  const isFormValid = R.isEmpty(invalidPanels)
  const isSaving = isBusinessSaving || isPayConfigSaving

  const { t } = useTranslation(['Admin', 'Businesses', 'Common'])

  const isGroup = getBusinessIsGroup(business)

  const hideBoopConfig = !useBoopEnabled(business)

  const [expandedPanels, setExpandedPanels] = useState<PracticeDetailsPanels[]>(
    [],
  )

  const { fields, validate, reset } = useFields(
    getMainFields(business, isGroup),
    false,
  )

  const {
    alertConfigurationFields,
    appointmentCommunicationFields,
    appointmentConfigurationFields,
    autoReplyCommunicationsFields,
    boopCommunicationsConfigFields,
    brandingConfigurationFields,
    chewyFields,
    countrySectionFields,
    discountConfigurationFields,
    discountReasonConfigurationFields,
    generalInformationFields,
    groupDetailsConfigFields,
    invoiceConfigurationsFields,
    kioskConfigurationsFields,
    labelConfigurationFields,
    licensesFields,
    localizationFields,
    mainFields,
    mobileFields,
    npsSystemFields,
    marketplaceManagementFields,
    savedSignatureConfigurationFields,
    sharingFields,
    soapConfigurationFields,
    socialMediaFields,
    recordSharingFields,
    remindersSettingsFields,
    taxConfigurationFields,
    telehealthIntegrationFields,
    vaccineCertificateFooterFields,
    unitsOfMeasurementFields,
    wellnessPlansFields,
  } = usePracticeDetailsFields(itemId)

  usePracticeFieldsSection<PracticeMainFields>({
    business,
    fields,
    sectionName: PracticeDetailsPanels.MAIN,
    validate,
    reset,
  })

  const { name, internalName, region, statusId, tags, practiceTypeIds } = fields

  const sectionRenderConditions = {
    [PracticeDetailsPanels.MAIN]: true,
    [PracticeDetailsPanels.GENERAL_INFORMATION]: true,
    [PracticeDetailsPanels.ALERT_CONFIGURATION]: !isGroup,
    [PracticeDetailsPanels.APPOINTMENT_COMMUNICATIONS]: !isGroup,
    [PracticeDetailsPanels.AUTO_REPLY_COMMUNICATIONS]:
      isAutoReplyRhapsodyCommunicationEnabled && !isGroup,
    [PracticeDetailsPanels.APPOINTMENT_CONFIGURATION]: !isGroup,
    [PracticeDetailsPanels.BOOP_COMMUNICATIONS]:
      !hideBoopConfig && !isAutoReplyRhapsodyCommunicationEnabled && !isGroup,
    [PracticeDetailsPanels.BRANDING_CONFIGURATION]: !isGroup,
    [PracticeDetailsPanels.CHEWY]: kyriosClinicIdPermissions.read && !isGroup,
    [PracticeDetailsPanels.COUNTRY_SECTION]:
      supportPermissions.read && !isGroup,
    [PracticeDetailsPanels.DEPARTMENT]: !isGroup,
    [PracticeDetailsPanels.DISCOUNT_CONFIGURATION]: isDiscountGroupsEnabled,
    [PracticeDetailsPanels.DISCOUNT_REASON_CONFIGURATION]:
      isDiscountReasonEnabled,
    [PracticeDetailsPanels.GROUP_ACCESS_ROLES]:
      isGroup && isAutoRolesAssignEnabled,
    [PracticeDetailsPanels.GROUP_DETAILS]: isGroup,
    [PracticeDetailsPanels.IMAGING_INTEGRATIONS]: !isGroup,
    [PracticeDetailsPanels.INTERNAL_CHAT_AND_LOGS]:
      isRcReconcilationEnabled && chatReconcilePermissions.update && !isGroup,
    [PracticeDetailsPanels.INVOICE_ESTIMATE_CONFIGURATION]: !isGroup,
    [PracticeDetailsPanels.KIOSK]: !isGroup,
    [PracticeDetailsPanels.LAB_INTEGRATIONS]:
      (!Utils.isPlayEnvironment() || !Utils.isEuropeRegion()) && !isGroup,
    [PracticeDetailsPanels.LABEL_CONFIGURATION]: !isGroup,
    [PracticeDetailsPanels.LICENSES]: !isGroup,
    [PracticeDetailsPanels.LOCALIZATIONS]: !isGroup,
    [PracticeDetailsPanels.MARKETPLACE_MANAGEMENT]:
      supportPermissions.read && !isGroup,
    [PracticeDetailsPanels.MIGRATION]:
      migrationPermissions.read && !isGroup && !isMigrationV2,
    [PracticeDetailsPanels.MIGRATION_V2]:
      migrationPermissions.read && !isGroup && isMigrationV2,
    [PracticeDetailsPanels.MOBILE]:
      !hideBoopConfig && supportPermissions.read && !isGroup,
    [PracticeDetailsPanels.NPS_SYSTEM]: isNpsClinicLevelEnabled
      ? !isGroup && itemId !== businessRootId
      : supportPermissions.update,
    [PracticeDetailsPanels.RECORD_SHARING]:
      isGroup && isGroupClientSharingEnabled && !isPatientSharingEnabled,
    [PracticeDetailsPanels.REMINDER_SET_UP]:
      reminderProtocolsPermissions.read && !isGroup,
    [PracticeDetailsPanels.RHAPSODY_PAY]: !isGroup,
    [PracticeDetailsPanels.SAVED_SIGNATURE_CONFIGURATION]: !isGroup,
    [PracticeDetailsPanels.SHARING]: isGroup && isWellnessplanSharingEnabled,
    [PracticeDetailsPanels.SOAP_CONFIGURATION]: !isGroup,
    [PracticeDetailsPanels.SOCIAL_MEDIA]: !isGroup,
    [PracticeDetailsPanels.SSO_CONFIGURATION]:
      isSsoIntegrationEnabled && !isGroup && ssoSettingsPermissions.update,
    [PracticeDetailsPanels.TAX_CONFIGURATION]:
      !isGroup && business?.taxes?.length,
    [PracticeDetailsPanels.TEAM_CONFIGURATION]:
      !isGroup && isTeamAssignmentEnabled,
    [PracticeDetailsPanels.TELEHEALTH_INTEGRATION]: !isGroup,
    [PracticeDetailsPanels.UNITS_OF_MEASUREMENT]: !isGroup,
    [PracticeDetailsPanels.VACCINE_CERTIFICATE_FOOTERS]: !isGroup,
    [PracticeDetailsPanels.VETCOVE_INTEGRATION]:
      !isGroup && isVetcoveSetupEnabled,
    [PracticeDetailsPanels.WELLNESS_PLANS]: !isGroup,
  }

  const filterBusinessTypes = (type: NamedEntity) => {
    if (type.name === BusinessTypeName.PHONE) {
      return !hideBoopConfig
    }
    if (type.name === BusinessTypeName.OMNICHANNEL) {
      return supportPermissions.update
    }
    return true
  }

  const openPanelWithInvalidField = () => {
    setExpandedPanels([...expandedPanels, ...invalidPanels])
  }

  const save = () => {
    dispatch(validateBusinessSections(itemId))

    if (isFormValid && business?.id) {
      const newBusiness = {
        ...mainFields,
        ...generalInformationFields,
        ...groupDetailsConfigFields,
        ...alertConfigurationFields,

        ...appointmentCommunicationFields,
        ...(isAppointmentConfigHidden && !isAppointmentCancellationReasonEnabled
          ? {}
          : appointmentConfigurationFields),
        ...(isAutoReplyRhapsodyCommunicationEnabled
          ? autoReplyCommunicationsFields
          : boopCommunicationsConfigFields),
        ...brandingConfigurationFields,
        ...chewyFields,
        ...countrySectionFields,
        // The discount fields was not integrated yet
        ...(isDiscountGroupsEnabled ? discountConfigurationFields : {}),
        ...(isDiscountReasonEnabled ? discountReasonConfigurationFields : {}),
        ...invoiceConfigurationsFields,
        ...kioskConfigurationsFields,
        ...labelConfigurationFields,
        ...licensesFields,
        ...localizationFields,
        ...mobileFields,
        ...npsSystemFields,
        ...marketplaceManagementFields,
        ...savedSignatureConfigurationFields,
        ...(isWellnessplanSharingEnabled ? sharingFields : {}),
        ...soapConfigurationFields,
        ...socialMediaFields,
        ...recordSharingFields,
        ...remindersSettingsFields,
        ...taxConfigurationFields,
        ...telehealthIntegrationFields,
        ...vaccineCertificateFooterFields,
        ...unitsOfMeasurementFields,
        ...wellnessPlansFields,
        id: business?.id,
      }

      dispatch(updateBusiness(newBusiness))
    } else {
      openPanelWithInvalidField()
    }
  }

  const practiceNameInput = (
    <PuiTextField
      disabled={!permissions.update}
      field={name}
      inputProps={{ maxLength: 100 }}
      label={`${name.label}*`}
    />
  )

  useEffect(() => {
    const newPanels = ((panels as PracticeDetailsPanels) || '').split(
      ',',
    ) as PracticeDetailsPanels[]
    if (newPanels.length > 0) {
      setExpandedPanels(newPanels)
    }
  }, [panels])

  useEffect(() => {
    if (itemId) {
      dispatch(fetchBusiness(itemId, false, true))
      dispatch(fetchModality(itemId))
      dispatch(fetchDevices(itemId))
    }
  }, [itemId])

  useEffect(() => {
    if (businessGroup?.id) {
      dispatch(fetchBusinessGroupTags(businessGroup.id))
    }
  }, [businessGroup?.id])

  const handleCheckBoxListChange = (value: string[]) => {
    practiceTypeIds.setValue(value)
    const omniChannelId = Utils.findConstantIdByName(
      BusinessTypeName.OMNICHANNEL,
      PracticeTypes,
    )

    if (value.includes(omniChannelId) && business && business.id) {
      dispatch(
        updateBusinessFieldsFromSection(
          business.id,
          {
            ...invoiceConfigurationsFields,
            estimateSignatureOnApproval: true,
          },
          PracticeDetailsPanels.INVOICE_ESTIMATE_CONFIGURATION,
          true,
        ),
      )
    }
  }

  return (
    <Expander
      expandedItemClass={t('Common:PRACTICE').toLowerCase()}
      hasUnsavedData={isSaving ? false : hasUnsavedData}
      isFetching={isBusinessFetching || isPayConfigFetching}
      isSaving={isSaving}
      showButtons={permissions.update}
      showOverlay={isSaving}
      onBack={onClose}
      onSaveRequested={save}
    >
      <Grid container direction="column" pt={{ xs: 3, md: 0 }}>
        {isGroup ? (
          <Grid container item direction="column">
            {practiceNameInput}
          </Grid>
        ) : (
          <>
            <Grid
              container
              item
              justifyContent={isMobile ? 'center' : 'flex-start'}
            >
              <Grid item>
                {business && (
                  <ClinicLogo
                    allowUpload={permissions.update}
                    className={classes.practiceLogo}
                    clinic={{ ...business, logo: fields.logo.value }}
                    onLogoUploaded={fields.logo.setValue}
                  />
                )}
              </Grid>
              <Grid
                container
                item
                md
                direction="column"
                pl={{ md: 4 }}
                pr={{ md: 7 }}
                pt={{ xs: 2, md: 0 }}
                px={{ xs: 0, sm: 2 }}
              >
                <Grid
                  container
                  alignItems="flex-end"
                  className={classes.headerRow}
                >
                  {practiceNameInput}
                  {supportPermissions.read && (
                    <Text
                      gutterBottom
                      className={classes.numberLabel}
                      ml={{ xs: 0, lg: 2 }}
                    >
                      {t('Admin:PRACTICE.ACCOUNT_NUMBER', { number: itemId })}
                    </Text>
                  )}
                </Grid>
                <Grid
                  container
                  alignItems="flex-end"
                  columnSpacing={2}
                  wrap="nowrap"
                >
                  <Grid item xs={12}>
                    <PuiTextField
                      disabled={!permissions.update}
                      field={internalName}
                      inputProps={{ maxLength: 100 }}
                      label={internalName.label}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <PuiTextField
                      disabled={!permissions.update}
                      field={region}
                      inputProps={{ maxLength: 100 }}
                      label={region.label}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid
              pl={{ md: 0 }}
              pr={{ md: 7 }}
              pt={{ xs: 2, md: 0 }}
              px={{ xs: 0, sm: 2 }}
            >
              <CreatableAutocomplete
                classes={{ tag: classes.autocompleteTag }}
                createLabel={`(${t('Common:NEW_TAG')})`}
                disabled={isBusinessFetching || !permissions.update}
                label={tags.label}
                options={businessGroup?.groupTags || []}
                value={tags.value}
                onValueChange={tags.setValue}
              />
              <Grid container wrap="wrap">
                <Grid container item alignItems="center" wrap="nowrap">
                  {(permissions.update ||
                    Boolean(practiceTypeIds?.value?.length)) && (
                    <Text variant="body">{`${practiceTypeIds.label}:`}</Text>
                  )}
                  {permissions.update ? (
                    <PuiCheckboxList
                      hideAllButton
                      className={classes.typeCheckboxList}
                      disabled={!supportPermissions.update}
                      initialState={practiceTypeIds.value}
                      items={PracticeTypes.filter(filterBusinessTypes)}
                      onChange={handleCheckBoxListChange}
                    />
                  ) : (
                    <Grid container columnSpacing={2} p={1} wrap="nowrap">
                      {PracticeTypes.filter((item) =>
                        practiceTypeIds.value.includes(item.id),
                      ).map((item) => (
                        <Grid item key={item.id}>
                          <BusinessTypeLabel icon={item.name}>
                            {LanguageUtils.getTranslatedFieldName(item)}
                          </BusinessTypeLabel>
                        </Grid>
                      ))}
                    </Grid>
                  )}
                </Grid>
                <Grid container item alignItems="center" wrap="nowrap">
                  <Text variant="body">{`${t('Common:STATUS')}:`}</Text>
                  <PracticeStatusSelect status={statusId} />
                </Grid>
              </Grid>
            </Grid>
          </>
        )}
        <Grid
          container
          item
          direction="column"
          mt={3}
          pl={{ md: 0 }}
          pr={{ md: 4 }}
          px={{ xs: 0, sm: 2 }}
        >
          {PracticeDetailsPanelsList.map((panel) => {
            const Component = PracticeDetailsPanelsComponents[panel]
            const shouldRender = Boolean(sectionRenderConditions[panel])
            const noPadding = PracticeDetailsPanelsNoPadding.includes(panel)

            if (!shouldRender || !Component || !business) {
              return null
            }

            const isExpanded = expandedPanels.includes(panel)

            return (
              <PanelAccordion
                expanded={isExpanded}
                key={panel}
                noPadding={noPadding}
                title={PracticeDetailsPanelsLabels[panel]}
                onChange={() =>
                  setExpandedPanels(Utils.toggleListItem(panel, expandedPanels))
                }
              >
                <Component business={business} />
              </PanelAccordion>
            )
          })}
        </Grid>
        <Grid item mt={2} pl={{ xs: 2, md: 0 }}>
          <RequiredFieldsNotice />
        </Grid>
      </Grid>
    </Expander>
  )
}

export default PracticeDetails
