import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import ThreeDotsIcon from '@mui/icons-material/MoreHoriz'
import { Grid, IconButton, Skeleton } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import classNames from 'classnames'
import * as R from 'ramda'
import { LinkButton, PuiSelect, Text, Utils } from '@pbt/pbt-ui-components'
import { Expand as ExpandIcon } from '@pbt/pbt-ui-components/src/icons'

import ActionsPopper, { PopperAction } from '~/components/common/ActionsPopper'
import { WidgetColumn } from '~/types'

const useStyles = makeStyles(
  (theme) => ({
    root: {
      border: `1px solid ${theme.colors.link}`,
      borderRadius: 2,
      width: '100%',
    },
    titleContainer: {
      borderBottom: theme.constants.tableBorder,
    },
    title: {
      color: theme.colors.title,
    },
    noItemsTitle: {
      color: theme.colors.tabLabel,
    },
    headingCell: {
      alignSelf: 'center',
    },
    heading: {
      fontSize: '1.2rem',
      fontWeight: 500,
      color: theme.colors.tabLabel,
    },
    icon: {
      width: theme.spacing(2.5),
      height: theme.spacing(2.5),
    },
    iconButton: {
      padding: theme.spacing(0.5),
      margin: theme.spacing(0.5),
    },
    launchIconContainer: {
      marginLeft: 'auto',
    },
    row: {
      minHeight: 40,
      '&:nth-of-type(even)': {
        backgroundColor: theme.colors.tableOddRowBackground,
      },
    },
    cell: {
      fontSize: '1.2rem',
      color: theme.colors.tabLabel,
    },
    nameCell: {
      fontWeight: 500,
      fontSize: '1.4rem',
      color: theme.colors.secondaryText,
    },
    actionCell: {
      width: 40,
    },
    noItemsRow: {
      height: 40,
      alignItems: 'center',
    },
    linkLabel: {
      fontSize: '1.4rem',
      color: theme.colors.link,
      '&:not(:last-child)': {
        marginRight: 0,
      },
    },
    filter: {
      margin: theme.spacing(1, 0, 0, 2),
    },
    filterText: {
      fontSize: '1.4rem',
    },
  }),
  { name: 'Snapshot' },
)

interface SnapshotProps<T extends { id: string }> {
  addActionName?: string
  addFilterActionName?: string
  columns?: WidgetColumn<T>[]
  component?: React.JSXElementConstructor<{ data: any[]; isLoading?: boolean }>
  data: T[]
  filterItems?: any[]
  filterValue?: string
  getActions?: (item: any) => PopperAction[] | false
  hideActions?: boolean
  isLoading?: boolean
  noItems?: boolean
  noRecentItems?: boolean
  onAdd?: () => void
  onChangeFilter?: () => void
  onOpenRecords?: () => void
  onViewAll?: () => void
  title: string
}

const Snapshot = <T extends { id: string }>({
  title,
  columns: columnsProp,
  data: dataProp,
  noItems,
  noRecentItems,
  addActionName,
  addFilterActionName,
  isLoading,
  hideActions = false,
  filterItems: filterItemsProp,
  filterValue,
  component: Component,
  onChangeFilter = R.F,
  getActions = R.F,
  onOpenRecords = R.F,
  onViewAll = R.F,
  onAdd = R.F,
}: SnapshotProps<T>) => {
  const columns = columnsProp || []
  const filterItems = filterItemsProp || []
  const classes = useStyles()
  const { t } = useTranslation(['Common', 'Clients'])

  const [actionsAnchorEl, setActionsAnchorEl] = useState<EventTarget>()
  const [activeActionItem, setActiveActionItem] = useState<T>()

  const handleToggleActions = (event: React.MouseEvent, item: any) => {
    setActionsAnchorEl(actionsAnchorEl ? undefined : event.target)
    setActiveActionItem(activeActionItem ? undefined : item)
  }

  const getColumnWidth = (column: WidgetColumn<T>, totalWidth: number) =>
    (column?.width || 0) / totalWidth

  const rowTotalWidth = R.reduce(
    (acc, cur: WidgetColumn<T>) => acc + (cur?.width || 0),
    0,
  )(columns)

  const noItemsToShow = noItems || noRecentItems
  const data =
    isLoading && !Component
      ? ([{ id: '1' }, { id: '2' }, { id: '3' }] as T[])
      : dataProp || []
  const activeFilter =
    filterItems && filterValue
      ? filterItems.find(({ id }) => id === filterValue)
      : undefined

  return (
    <Grid className={classes.root}>
      <Grid container className={classes.titleContainer} pl={1}>
        {columns.map((column, index) => (
          <Grid
            item
            className={classes.headingCell}
            // eslint-disable-next-line react/no-array-index-key
            key={index}
            px={1}
            style={{ flex: getColumnWidth(column, rowTotalWidth) }}
          >
            {index === 0 ? (
              <Text
                strong
                className={classNames(
                  classes.title,
                  noItemsToShow && classes.noItemsTitle,
                )}
                variant="body2"
              >
                {title}
              </Text>
            ) : noItemsToShow || isLoading ? null : (
              <Text className={classes.heading}>{column.label}</Text>
            )}
          </Grid>
        ))}
        <Grid item className={classes.launchIconContainer}>
          <IconButton
            className={classes.iconButton}
            size="large"
            onClick={onOpenRecords}
          >
            <ExpandIcon className={classes.icon} />
          </IconButton>
        </Grid>
      </Grid>
      {filterItems?.length > 0 && (
        <Grid container px={2} py={1}>
          <PuiSelect
            disableUnderline
            classes={{ select: classes.filterText }}
            items={filterItems}
            renderEmpty={false}
            value={filterValue}
            onChange={Utils.handleFormSelectInput(onChangeFilter)}
          />
        </Grid>
      )}
      <Grid container>
        {Component ? (
          <Component data={data} isLoading={isLoading} />
        ) : noItemsToShow || data?.length === 0 ? (
          <Grid container className={classes.noItemsRow} wrap="nowrap">
            <Text pl={2} pr={1} variant="lowAccent2">
              {activeFilter
                ? t('Clients:SNAPSHOTS_AND_RECORDS.NO_RECORDS_FOR_FILTER', {
                    activeFilterName: activeFilter.name.toLowerCase(),
                  })
                : noItems
                ? t('Clients:SNAPSHOTS_AND_RECORDS.NO_TITLE', {
                    title: title.toLowerCase(),
                  })
                : t('Clients:SNAPSHOTS_AND_RECORDS.NO_CURRENT_TITLE', {
                    title: title.toLowerCase(),
                  })}
            </Text>
            {activeFilter ? (
              addFilterActionName && (
                <LinkButton
                  classes={{ button: classes.linkLabel }}
                  onClick={onAdd}
                >
                  {addFilterActionName}
                </LinkButton>
              )
            ) : (
              <>
                {!noItems && (
                  <LinkButton
                    classes={{ button: classes.linkLabel }}
                    onClick={onViewAll}
                  >
                    {t('Common:VIEW_ALL')}
                  </LinkButton>
                )}
                {addActionName && (
                  <LinkButton
                    classes={{ button: classes.linkLabel }}
                    onClick={onAdd}
                  >
                    {addActionName}
                  </LinkButton>
                )}
              </>
            )}
          </Grid>
        ) : (
          data.map((row) => (
            <Grid
              container
              alignItems="center"
              className={classes.row}
              key={row.id}
              pl={1}
              wrap="nowrap"
            >
              <Grid container>
                {columns.map((column, index) => (
                  <Grid
                    item
                    className={classNames(
                      classes.cell,
                      index === 0 && classes.nameCell,
                    )}
                    key={column.id}
                    px={1}
                    style={{ flex: getColumnWidth(column, rowTotalWidth) }}
                  >
                    {isLoading ? (
                      <Skeleton height={20} variant="text" width="100%" />
                    ) : typeof column.prop === 'function' ? (
                      column.prop(row)
                    ) : (
                      column.prop && row[column.prop]
                    )}
                  </Grid>
                ))}
              </Grid>
              {!hideActions && (
                <Grid
                  container
                  item
                  className={classes.actionCell}
                  justifyContent="center"
                >
                  {isLoading ? (
                    <Skeleton height={20} variant="circular" width={20} />
                  ) : (
                    <IconButton
                      className={classes.iconButton}
                      size="large"
                      onClick={(event) => handleToggleActions(event, row)}
                    >
                      <ThreeDotsIcon className={classes.icon} />
                    </IconButton>
                  )}
                </Grid>
              )}
            </Grid>
          ))
        )}
      </Grid>
      <ActionsPopper
        actions={activeActionItem ? getActions(activeActionItem) || [] : []}
        anchorEl={actionsAnchorEl as HTMLAnchorElement}
        onClose={() => {
          setActionsAnchorEl(undefined)
          setActiveActionItem(undefined)
        }}
      />
    </Grid>
  )
}

export default Snapshot
