import React from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { FormControl, Grid, InputLabel } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import {
  BackButton,
  ButtonWithLoader,
  PuiSelect,
  Text,
  useFields,
} from '@pbt/pbt-ui-components'

import BooleanRadioGroup from '~/components/common/inputs/BooleanRadioGroup'
import QuantityInput from '~/components/common/inputs/QuantityInput'
import {
  registrationRequest,
  updateFinalQuestions,
} from '~/store/actions/registration'
import { getOnboardingPracticeType } from '~/store/reducers/constants'
import {
  getIsUserAlreadyExist,
  getRegistrationError,
  getRegistrationIsLoading,
  getRegistrationMembers,
} from '~/store/reducers/registration'
import useCloseAfterCreation from '~/utils/useCloseAfterCreation'

const useStyles = makeStyles(
  (theme) => ({
    quantityInput: {
      fontSize: '1.6rem',
    },
    submitButton: {
      minWidth: 250,
      backgroundColor: theme.colors.loginButtonBackground,
      '&&&&:hover': {
        backgroundColor: theme.colors.loginButtonBackground,
      },
    },
  }),
  { name: 'FinalQuestionsForActivationComponent' },
)

const FinalQuestionsForActivationComponent = () => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { t } = useTranslation(['Registration', 'Common'])
  const onboardingPracticeType = useSelector(getOnboardingPracticeType)
  const registrationError = useSelector(getRegistrationError)
  const isUserAlreadyExist = useSelector(getIsUserAlreadyExist)
  const isLoading = useSelector(getRegistrationIsLoading)
  const members = useSelector(getRegistrationMembers)
  const isFromMembersPage = Boolean(Object.values(members).length)

  const { fields, validate } = useFields(
    [
      {
        name: 'isAaha',
        type: 'toggle',
        initialValue: true,
        validators: ['required'],
      },
      {
        name: 'fearFree',
        type: 'toggle',
        initialValue: true,
        validators: ['required'],
      },
      {
        name: 'doctorCount',
        type: 'number',
        initialValue: 0,
        validators: ['nonNegative'],
      },
      {
        name: 'practiceType',
        type: 'select',
        initialValue: undefined,
        validators: ['required'],
      },
    ],
    false,
  )

  const { isAaha, fearFree, doctorCount, practiceType } = fields

  const onRegistrationRequest = async () => {
    if (!registrationError) {
      if (isUserAlreadyExist) {
        navigate('/register/manager')
      } else {
        navigate('/register/termsAndConditions')
      }
    }
  }

  const setOnRegistrationRequest = useCloseAfterCreation(
    onRegistrationRequest,
    getRegistrationIsLoading,
  )

  const onSubmit = () => {
    if (validate()) {
      dispatch(
        updateFinalQuestions({
          isAaha: isAaha.value,
          fearFree: fearFree.value,
          doctorCount: doctorCount.value,
          practiceType: practiceType.value,
        }),
      )
      setOnRegistrationRequest()
      dispatch(registrationRequest())
    }
  }

  const onBack = () => {
    if (isFromMembersPage) {
      navigate('/register/members')
    } else {
      navigate('/register/manager')
    }
  }

  return (
    <>
      <Grid container px={2} spacing={2}>
        <Grid item xs={12}>
          <Text mb={1} mt={2} variant="hero">
            {t(
              'Registration:FINAL_QUESTIONS_FOR_ACTIVATION.JUST_A_FEW_FINAL_QUESTIONS',
            )}
          </Text>
        </Grid>
        <Grid item xs={12}>
          <Text strong variant="body">
            {t(
              'Registration:FINAL_QUESTIONS_FOR_ACTIVATION.IS_YOUR_PRACTICE_AAHA_CERTIFIED',
            )}
          </Text>
          <BooleanRadioGroup field={isAaha} />
        </Grid>
        <Grid item xs={12}>
          <Text strong variant="body">
            {t(
              'Registration:FINAL_QUESTIONS_FOR_ACTIVATION.IS_YOUR_PRACTICE_FEAR_FREE',
            )}
          </Text>
          <BooleanRadioGroup field={fearFree} />
        </Grid>
        <Grid item xs={12}>
          <Text strong variant="body">
            {t(
              'Registration:FINAL_QUESTIONS_FOR_ACTIVATION.HOW_MANY_DOCTORS_DOES_YOUR_PRACTICE_HAVE',
            )}
          </Text>
          <Grid container item justifyContent="row" xs={3}>
            <QuantityInput
              showControls
              classes={{
                input: classes.quantityInput,
              }}
              field={doctorCount}
              min={0}
            />
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Text strong variant="body">
            {t(
              'Registration:FINAL_QUESTIONS_FOR_ACTIVATION.WHAT_TYPE_OF_PRACTICE_DO_YOU_HAVE',
            )}
          </Text>
          <Grid xs={4}>
            <FormControl fullWidth>
              <InputLabel>{t('Common:PRACTICE_TYPE')}</InputLabel>
              <PuiSelect field={practiceType} items={onboardingPracticeType} />
            </FormControl>
          </Grid>
        </Grid>
      </Grid>
      <Grid container justifyContent="space-between" pt={3} px={2}>
        <Grid container item ml={-1.5} width="initial">
          <BackButton label={t('Common:BACK_ACTION')} onClick={onBack} />
        </Grid>
        <ButtonWithLoader
          className={classes.submitButton}
          loading={isLoading}
          type="submit"
          onClick={onSubmit}
        >
          {t('Common:GET_STARTED')}
        </ButtonWithLoader>
      </Grid>
    </>
  )
}

export default FinalQuestionsForActivationComponent
