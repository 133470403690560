import * as R from 'ramda'
import { AnyAction } from 'redux'
import { createSelector } from 'reselect'
import {
  BackendRole,
  CRUD,
  PermissionArea,
  Role,
  RoleType,
  UserPermissions,
} from '@pbt/pbt-ui-components'

import RoleName from '~/constants/roleNames'
import { secondLevelMerge } from '~/utils'
import { getErrorMessage } from '~/utils/errors'

import { SILENT_LOGIN_SUCCESS } from '../actions/types/auth'
import {
  FETCH_GROUP_ROLES,
  FETCH_GROUP_ROLES_FAILURE,
  FETCH_GROUP_ROLES_SUCCESS,
  FETCH_USED_ROLES,
  FETCH_USED_ROLES_FAILURE,
  FETCH_USED_ROLES_SUCCESS,
  UPDATE_GROUP_ROLES,
  UPDATE_GROUP_ROLES_FAILURE,
  UPDATE_GROUP_ROLES_SUCCESS,
  UPDATE_ROLES,
} from '../actions/types/roles'
import type { RootState } from '../index'

export type RolesState = {
  error: string | null
  groupRoles: Record<string, string[]>
  isLoading: boolean
  map: Record<string, Role>
  usedMap: Record<string, Role>
}

export const ROLES_INITIAL_STATE: RolesState = {
  map: {},
  usedMap: {},
  isLoading: false,
  error: null,
  groupRoles: {},
}

const convertPermissions = (permissions: BackendRole['permissions']) =>
  Object.keys(permissions).reduce((acc, area) => {
    const permissionArea = area as PermissionArea
    const permission = permissions[permissionArea] || []
    acc[permissionArea] = {
      create: permission.includes(CRUD.CREATE),
      read: permission.includes(CRUD.READ),
      update: permission.includes(CRUD.UPDATE),
      delete: permission.includes(CRUD.DELETE),
      sign: permission.includes(CRUD.SIGN),
    }
    return acc
  }, {} as Record<PermissionArea, UserPermissions>)

const roles = (
  state: RolesState = ROLES_INITIAL_STATE,
  action: AnyAction,
): RolesState => {
  switch (action.type) {
    case SILENT_LOGIN_SUCCESS:
      return ROLES_INITIAL_STATE
    case UPDATE_ROLES:
      return {
        ...state,
        map: secondLevelMerge(
          state.map,
          Object.keys(action.roles).reduce((acc, key) => {
            acc[key] = {
              ...action.roles[key],
              permissions: convertPermissions(action.roles[key].permissions),
            }
            return acc
          }, {} as Record<string, Role>),
        ),
      }
    case FETCH_GROUP_ROLES:
      return {
        ...state,
        isLoading: true,
        error: null,
      }
    case FETCH_GROUP_ROLES_SUCCESS:
      return {
        ...state,
        isLoading: false,
        groupRoles: { ...state.groupRoles, [action.groupId]: action.roles },
      }
    case FETCH_GROUP_ROLES_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: getErrorMessage(action.error),
      }
    case UPDATE_GROUP_ROLES:
      return {
        ...state,
        isLoading: true,
        error: null,
      }
    case UPDATE_GROUP_ROLES_SUCCESS:
      return {
        ...state,
        isLoading: false,
        groupRoles: { ...state.groupRoles, [action.groupId]: action.roles },
      }
    case UPDATE_GROUP_ROLES_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: getErrorMessage(action.error),
      }
    case FETCH_USED_ROLES:
      return {
        ...state,
        isLoading: true,
        usedMap: {},
        error: null,
      }
    case FETCH_USED_ROLES_SUCCESS:
      return {
        ...state,
        isLoading: false,
        usedMap: Object.keys(action.roles).reduce((acc, key) => {
          acc[key] = {
            ...action.roles[key],
            permissions: convertPermissions(action.roles[key].permissions),
          }
          return acc
        }, {} as Record<string, Role>),
      }
    case FETCH_USED_ROLES_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: getErrorMessage(action.error),
      }
    default:
      return state
  }
}

export default roles
export const getRoles = (state: RootState): RolesState => state.roles
export const getRolesMap = (state: RootState) => getRoles(state).map
export const getRolesUsedMap = (state: RootState) => getRoles(state).usedMap
export const getRolesIsLoading = (state: RootState) => getRoles(state).isLoading
export const getRole = (id: string) =>
  createSelector(getRolesMap, (map) => R.prop(id, map))
export const getMultipleRoles = (ids: string[]) =>
  createSelector(getRolesMap, (map) => R.props(ids, map))
export const getRolesList = createSelector(
  getRolesMap,
  (map: RolesState['map']) =>
    Object.values(map).filter(({ hidden }) => !hidden),
)
export const getStaffRolesList = createSelector(
  getRolesMap,
  (map: RolesState['map']) =>
    Object.values(map).filter(
      ({ hidden, type }) => !hidden && type === RoleType.STAFF_ROLE,
    ),
)

export const getUsedStaffRolesList = createSelector(
  getRolesUsedMap,
  (map: RolesState['map']) =>
    Object.values(map).filter(
      ({ hidden, type }) => !hidden && type === RoleType.STAFF_ROLE,
    ),
)
export const getMainStaffRolesList = createSelector(
  getRolesMap,
  (map: RolesState['map']) =>
    Object.values(map).filter(
      ({ hidden, type, remote }) =>
        !remote && !hidden && type === RoleType.STAFF_ROLE,
    ),
)

export const getAllStaffRolesList = createSelector(
  getRolesMap,
  (map: RolesState['map']) =>
    Object.values(map).filter(({ type }) => type === RoleType.STAFF_ROLE),
)

export const getClientRolesList = createSelector(
  getRolesMap,
  (map: RolesState['map']) =>
    Object.values(map).filter(
      ({ hidden, type }) => !hidden && type === RoleType.CLIENT_ROLE,
    ),
)
export const getRoleIdsList = (state: RootState) =>
  getRolesList(state).map(R.prop('id'))
export const getStaffRoleIdsList = (state: RootState) =>
  getStaffRolesList(state).map(R.prop('id'))
export const getMainStaffRoleIdsList = (state: RootState) =>
  getMainStaffRolesList(state).map(R.prop('id'))
export const getClientRoleIdsList = (state: RootState) =>
  getClientRolesList(state).map(R.prop('id'))
export const getHasStaffRoles = (state: RootState) =>
  getStaffRolesList(state).length > 0
export const getHasMainStaffRoles = (state: RootState) =>
  getMainStaffRolesList(state).length > 0
export const getRoleIdByName = (roleName: RoleName) => (state: RootState) =>
  R.pipe(
    getRolesList,
    R.find<Role>(R.propEq('name', roleName)),
    R.defaultTo<Partial<Role>>({}),
    ({ id }) => id || undefined,
  )(state)
export const getGroupRolesMap = (state: RootState) => getRoles(state).groupRoles
export const getGroupRoles = (businessId: string) =>
  createSelector(getGroupRolesMap, (map) => R.prop(businessId, map))
