import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import { Button, Grid, Paper } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { ButtonWithLoader, Nil, Text } from '@pbt/pbt-ui-components'

import { ConversationTransport } from '~/api/graphql/generated/types'
import { CompactAlert } from '~/components/elements/CompactAlert/CompactAlert'
import { MessageType } from '~/constants/communications'
import {
  createConversation,
  fetchConversationEmailPreview,
} from '~/store/actions/conversations'
import { getContact } from '~/store/duck/contacts'
import { useCreatedConversationsInfo } from '~/store/hooks/conversations'
import { getAttachmentsToSend } from '~/store/reducers/conversationMessages'
import {
  getConversationEmailPreview,
  getConversationEmailPreviewIsReceiving,
  getConversationsError,
  getConversationsIsCreating,
} from '~/store/reducers/conversations'
import { getUser } from '~/store/reducers/users'
import { ContactSlot } from '~/types'
import useCloseAfterCreation from '~/utils/useCloseAfterCreation'
import { useNewConversationValidationForm } from '~/utils/useNewConversationValidationForm'

import ConversationMessageFormattingArea, {
  ConversationMessageFormattingAreaHandle,
  ConversationMessageFormattingAreaProps,
} from '../ConversationMessageFormattingArea'

const useStyles = makeStyles(
  (theme) => ({
    sendButton: {
      width: 150,
      margin: theme.spacing(0, 2),
    },
    draftButton: {
      color: theme.colors.title,
    },
    backButton: {
      paddingLeft: 0,
    },
    backButtonArrow: {
      color: theme.colors.searchButton,
    },
    messageFormattingArea: {
      padding: theme.spacing(2, 2, 3),
    },
  }),
  { name: 'NewMessage' },
)

enum ProcessingType {
  DRAFT = 'DRAFT',
  PLAIN = 'PLAIN',
}

interface NewMessageProps
  extends Omit<
    ConversationMessageFormattingAreaProps,
    'patientId' | 'clientId' | 'messageField'
  > {
  appointmentId: string | Nil
  contactSlot: ContactSlot
  handleBack: () => void
  handleConversationCreated: (isDraft: boolean) => void
  initialSubject?: string
}

const NewMessage = ({
  handleBack,
  handleConversationCreated,
  transport,
  contactSlot,
  initialSubject = '',
  appointmentId,
  ...rest
}: NewMessageProps) => {
  const conversationMessageFormattingAreaRef =
    useRef<ConversationMessageFormattingAreaHandle>(null)
  const dispatch = useDispatch()
  const classes = useStyles()
  const { t } = useTranslation('Common')

  const isConversationCreating = useSelector(getConversationsIsCreating)
  const conversationsError = useSelector(getConversationsError)
  const isEmailPreviewLoading = useSelector(
    getConversationEmailPreviewIsReceiving,
  )
  const emailTemplate = useSelector(getConversationEmailPreview)
  const { clientId, patientId, contactId } = contactSlot
  const client = useSelector(getUser(clientId))
  const contact = useSelector(getContact(contactId))
  const files = useSelector(getAttachmentsToSend)
  const [processingMessageType, setProcessingMessageType] =
    useState<ProcessingType>()
  const initialContacts = contact ? [contact] : []

  const isLoading = Boolean(
    isConversationCreating || isEmailPreviewLoading || processingMessageType,
  )
  const sendButtonText =
    transport === ConversationTransport.LogPhoneCall
      ? t('Common:LOG_ACTION')
      : t('Common:SEND_ACTION')

  const [includeHeaderAndFooter, setIncludeHeaderAndFooter] = useState(true)

  const toggleIncludeHeaderAndFooter = () =>
    setIncludeHeaderAndFooter(!includeHeaderAndFooter)

  const {
    fields: { subject: subjectField, message: messageField, to },
    validate,
    reset,
  } = useNewConversationValidationForm(
    { transport, client, contact },
    { initialSubject, initialMessage: '' },
  )

  useEffect(() => {
    reset()
  }, [clientId, patientId, contactId])

  const onConversationCreated = () => {
    handleConversationCreated(processingMessageType === ProcessingType.DRAFT)
    setProcessingMessageType(undefined)
  }

  const onConversationCreationFailure = () => {
    setProcessingMessageType(undefined)
  }

  const displayConversationCreationResult = useCreatedConversationsInfo({
    getIsConversationCreating: getConversationsIsCreating,
    onConversationCreationSuccess: onConversationCreated,
    onConversationCreationFailure,
    displayDefaultSuccessResult: false,
  })

  const getMessageType = (
    transportToHandle: ConversationTransport | Nil,
    processingMessageTypeToHandle: ProcessingType | Nil,
  ) =>
    transportToHandle === ConversationTransport.LogPhoneCall &&
    processingMessageTypeToHandle !== ProcessingType.DRAFT
      ? MessageType.INTERNAL_NOTE
      : MessageType.PLAIN

  const createConversationWithMessage = (
    messageBody: string,
    processingMessageTypeToCheck: ProcessingType | Nil,
  ) => {
    const recipients =
      conversationMessageFormattingAreaRef.current?.formRecipients()
    const title = subjectField.value
    const message = {
      body: messageBody,
      type: getMessageType(transport, processingMessageTypeToCheck),
      files,
    }
    const isDraft = processingMessageTypeToCheck === ProcessingType.DRAFT
    const conversation = {
      transport,
      title,
      recipients,
      isDraft,
      eventId: appointmentId,
    }

    dispatch(createConversation(conversation, message))
    displayConversationCreationResult()
  }

  const fetchEmailTemplate = useCloseAfterCreation(() => {
    createConversationWithMessage(emailTemplate || '', processingMessageType)
  }, getConversationEmailPreviewIsReceiving)

  const handleSendMessage = (messageTypeToHandle: ProcessingType) => {
    setProcessingMessageType(messageTypeToHandle)
    if (validate()) {
      if (transport === ConversationTransport.Email && !emailTemplate) {
        dispatch(
          fetchConversationEmailPreview({
            includeHeaderAndFooter,
            content: messageField.value,
          }),
        )
        fetchEmailTemplate()
      } else {
        const messageBody =
          transport === ConversationTransport.Email
            ? emailTemplate
            : messageField.value
        createConversationWithMessage(messageBody, messageTypeToHandle)
      }
    } else {
      setProcessingMessageType(undefined)
    }
  }

  return (
    <Grid container direction="column">
      <ConversationMessageFormattingArea
        isShowPreview
        showAttachment
        classes={{
          messageFormattingArea: classes.messageFormattingArea,
        }}
        clientId={clientId}
        eventId={appointmentId}
        hideTo={transport === ConversationTransport.LogPhoneCall}
        includeHeaderAndFooter={includeHeaderAndFooter}
        messageField={messageField}
        minEditorHeight={150}
        patientId={patientId}
        ref={conversationMessageFormattingAreaRef}
        subjectField={subjectField}
        to={to}
        toInputProps={{ initialContacts }}
        toggleIncludeHeaderAndFooter={toggleIncludeHeaderAndFooter}
        transport={transport}
        {...rest}
      />

      <Paper square elevation={3}>
        <Grid p={2}>
          {conversationsError && (
            <CompactAlert mb={1} message={conversationsError} />
          )}
          <Button className={classes.backButton} onClick={handleBack}>
            <ArrowBackIcon className={classes.backButtonArrow} />
            <Text
              strong
              pl={1}
              sx={{ textTransform: 'none' }}
              textTransform="none"
              variant="lowAccent2"
            >
              {t('Common:BACK_ACTION')}
            </Text>
          </Button>

          <ButtonWithLoader
            className={classes.sendButton}
            disabled={isLoading}
            loading={
              isLoading && processingMessageType === ProcessingType.PLAIN
            }
            onClick={() => handleSendMessage(ProcessingType.PLAIN)}
          >
            {sendButtonText}
          </ButtonWithLoader>
          {transport !== ConversationTransport.LogPhoneCall && (
            <ButtonWithLoader
              className={classes.draftButton}
              color="secondary"
              disabled={isLoading}
              loading={
                isLoading && processingMessageType === ProcessingType.DRAFT
              }
              onClick={() => handleSendMessage(ProcessingType.DRAFT)}
            >
              {t('Common:SAVE_AS_DRAFT')}
            </ButtonWithLoader>
          )}
        </Grid>
      </Paper>
    </Grid>
  )
}

export default NewMessage
