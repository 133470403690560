import React from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { Grid, MenuItemProps } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import * as R from 'ramda'
import {
  AtLeast,
  Countries,
  EmergencyContact,
  LanguageUtils,
  PhoneUtils,
  User,
  Utils,
} from '@pbt/pbt-ui-components'

import { partialUpdateClient } from '~/store/actions/clients'
import { updateCoparent } from '~/store/actions/coparents'
import {
  useGetContactMethodWithDisabledFlag,
  useGetIsTextMessage,
} from '~/store/hooks/clients'
import { getContactMethod, getTag } from '~/store/reducers/constants'

import ClientAndPatientDetailsRow, {
  ClientAndPatientDetailsRowProps,
} from './ClientAndPatientDetailsRow'
import { InlineEditInputType } from './InlineEditPopper'

const useStyles = makeStyles(
  (theme) => ({
    root: {
      paddingBottom: theme.spacing(1),
    },
    selectItem: {
      whiteSpace: 'pre-line',
    },
  }),
  { name: 'PetFriendDetails' },
)

interface PetFriendDetailsProps {
  clientId: string
  isEmergencyContact?: boolean
  petFriend: Partial<User>
}

const PetFriendDetails = ({
  isEmergencyContact,
  petFriend,
  clientId,
}: PetFriendDetailsProps) => {
  const dispatch = useDispatch()
  const classes = useStyles()
  const { t } = useTranslation(['Abbreviations', 'Common'])

  const ContactMethod = useSelector(getContactMethod)
  const Tag = useSelector(getTag)

  const { canSendSmsToClient, ContactMethodWithDisabledFlag } =
    useGetContactMethodWithDisabledFlag(null, false)
  const isTextMessageOption = useGetIsTextMessage(
    petFriend.preferredContactMethodId,
  )

  const updateCoPetParentContact = R.curry((fieldName, newValue) => {
    dispatch(
      updateCoparent(clientId, {
        id: petFriend.id,
        [fieldName]: newValue,
      } as AtLeast<User, 'id'>),
    )
  })

  const updateEmergencyContact = R.curry((fieldName, newValue) => {
    dispatch(
      partialUpdateClient({
        id: clientId,
        emergencyContact: {
          ...petFriend,
          [fieldName]: newValue,
        } as EmergencyContact,
      }),
    )
  })

  const emergencyContactDetails: ClientAndPatientDetailsRowProps[] = [
    {
      label: t('Common:MOBILE_NUMBER_SIGN'),
      value: PhoneUtils.formatPhoneNumber(petFriend?.mobilePhone),
      onEdit: updateEmergencyContact('mobilePhone'),
      inputType: InlineEditInputType.PHONE,
      fieldProps: {
        name: 'mobilePhone',
        label: t('Common:MOBILE_PHONE'),
        validators: ['phone'],
        initialValue: petFriend.mobilePhone,
      },
    },
    {
      label: t('Common:HOME_PHONE_NUMBER_SIGN'),
      value: PhoneUtils.formatPhoneNumber(petFriend.homePhone),
      onEdit: updateEmergencyContact('homePhone'),
      inputType: InlineEditInputType.PHONE,
      fieldProps: {
        name: 'homePhone',
        label: t('Common:HOME_PHONE'),
        validators: ['phone'],
        initialValue: petFriend.homePhone,
      },
    },
    {
      label: t('Common:WORK_PHONE_NUMBER_SIGN'),
      value: PhoneUtils.formatPhoneNumber(petFriend.workPhone),
      onEdit: updateEmergencyContact('workPhone'),
      inputType: InlineEditInputType.PHONE,
      fieldProps: {
        name: 'workPhone',
        label: t('Common:WORK_PHONE'),
        validators: ['phone'],
        initialValue: petFriend.workPhone,
      },
    },
    {
      label: t('Common:OTHER_NUMBER_SIGN'),
      value: PhoneUtils.formatPhoneNumber(petFriend.otherPhone),
      onEdit: updateEmergencyContact('otherPhone'),
      inputType: InlineEditInputType.PHONE,
      fieldProps: {
        name: 'otherPhone',
        label: t('Common:OTHER_PHONE'),
        validators: ['phone'],
        initialValue: petFriend.otherPhone,
      },
    },
  ]

  const petFriendContactDetails: ClientAndPatientDetailsRowProps[] = [
    {
      label: t('Common:NOTES'),
      value: petFriend.notes,
      onEdit: updateCoPetParentContact('notes'),
      inputType: InlineEditInputType.MULTILINE,
      fieldProps: {
        name: 'notes',
        label: t('Common:NOTES'),
        initialValue: petFriend.notes,
      },
    },
    {
      label: t('Common:EMAIL'),
      value: petFriend.email,
      onEdit: updateCoPetParentContact('email'),
      fieldProps: {
        name: 'email',
        label: t('Common:EMAIL'),
        validators: ['email'],
        initialValue: petFriend.email,
      },
    },
    {
      label: t('Common:MOBILE_NUMBER_SIGN'),
      value: PhoneUtils.formatPhoneNumber(petFriend.mobilePhone),
      onEdit: updateCoPetParentContact('mobilePhone'),
      inputType: InlineEditInputType.PHONE,
      fieldProps: {
        name: 'mobilePhone',
        label: t('Common:MOBILE_PHONE'),
        validators: ['phone'],
        initialValue: petFriend.mobilePhone,
      },
    },
    {
      label: t('Common:HOME_PHONE_NUMBER_SIGN'),
      value: PhoneUtils.formatPhoneNumber(petFriend.homePhone),
      onEdit: updateCoPetParentContact('homePhone'),
      inputType: InlineEditInputType.PHONE,
      fieldProps: {
        name: 'homePhone',
        label: t('Common:HOME_PHONE'),
        validators: ['phone'],
        initialValue: petFriend.homePhone,
      },
    },
    {
      label: t('Common:WORK_PHONE_NUMBER_SIGN'),
      value: PhoneUtils.formatPhoneNumber(petFriend.workPhone),
      onEdit: updateCoPetParentContact('workPhone'),
      inputType: InlineEditInputType.PHONE,
      fieldProps: {
        name: 'workPhone',
        label: t('Common:WORK_PHONE'),
        validators: ['phone'],
        initialValue: petFriend.workPhone,
      },
    },
    {
      label: t('Common:OTHER_NUMBER_SIGN'),
      value: PhoneUtils.formatPhoneNumber(petFriend.otherPhone),
      onEdit: updateCoPetParentContact('otherPhone'),
      inputType: InlineEditInputType.PHONE,
      fieldProps: {
        name: 'otherPhone',
        label: t('Common:OTHER_PHONE'),
        validators: ['phone'],
        initialValue: petFriend.otherPhone,
      },
    },
    {
      label: t('Common:ADDRESS'),
      value: petFriend.address,
      onEdit: updateCoPetParentContact('address'),
      fieldProps: { name: 'address', initialValue: petFriend.address },
    },
    {
      label: t('Abbreviations:COMMON.APARTMENT_NUMBER_SIGN'),
      value: petFriend.suite,
      onEdit: updateCoPetParentContact('suite'),
      fieldProps: { name: 'suite', initialValue: petFriend.suite },
    },
    {
      label: t('Common:CITY'),
      value: petFriend.city,
      onEdit: updateCoPetParentContact('city'),
      fieldProps: { name: 'city', initialValue: petFriend.city },
    },
    {
      label: t('Common:STATE'),
      value: petFriend.state,
      onEdit: updateCoPetParentContact('state'),
      inputType: InlineEditInputType.STATE,
      inputProps: { country: petFriend.country },
      fieldProps: {
        name: 'state',
        type: 'select',
        initialValue: petFriend.state,
      },
    },
    {
      label: t('Common:COUNTRY'),
      value: Utils.getConstantName(petFriend.country, Countries),
      displayValue: LanguageUtils.getConstantTranslatedName(
        petFriend.country,
        Countries,
      ),
      onEdit: updateCoPetParentContact('country'),
      inputType: InlineEditInputType.COUNTRY,
      fieldProps: {
        name: 'country',
        type: 'select',
        initialValue: petFriend.country,
      },
    },
    {
      label: t('Abbreviations:COMMON.ZIP_CODE'),
      value: petFriend.zip,
      onEdit: updateCoPetParentContact('zip'),
      inputType: InlineEditInputType.ZIP,
      inputProps: { country: petFriend.country },
      fieldProps: {
        name: 'zip',
        label: t('Common:ZIP_CODE'),
        validators: ['zip'],
        initialValue: petFriend.zip,
      },
    },
    {
      label: t('Common:CONTACT_ONE'),
      value: Utils.getConstantName(
        petFriend.preferredContactMethodId,
        ContactMethod,
      ),
      displayValue: LanguageUtils.getConstantTranslatedName(
        petFriend.preferredContactMethodId,
        ContactMethod,
      ),
      onEdit: updateCoPetParentContact('preferredContactMethod'),
      inputType: InlineEditInputType.SELECT,
      inputProps: {
        items: ContactMethodWithDisabledFlag,
        selectItemProps: {
          classes: { root: classes.selectItem },
        } as MenuItemProps,
      },
      fieldProps: {
        name: 'preferredContactMethod',
        type: 'select',
        initialValue: canSendSmsToClient
          ? petFriend.preferredContactMethodId
          : isTextMessageOption
          ? undefined
          : petFriend.preferredContactMethodId,
      },
    },
    {
      label: t('Common:TAG'),
      value: Utils.getConstantName(petFriend.tag, Tag),
      displayValue: LanguageUtils.getConstantTranslatedName(petFriend.tag, Tag),
      onEdit: updateCoPetParentContact('tag'),
      inputType: InlineEditInputType.SELECT,
      inputProps: { items: Tag },
      fieldProps: { name: 'tag', type: 'select', initialValue: petFriend.tag },
    },
  ]

  const clientDetails = isEmergencyContact
    ? emergencyContactDetails
    : petFriendContactDetails

  return (
    <Grid container className={classes.root} direction="column">
      {clientDetails.map((props) => (
        <ClientAndPatientDetailsRow
          entityType={isEmergencyContact ? 'client' : 'petFriends'}
          key={petFriend.id + (props?.label || '')}
          {...props}
        />
      ))}
    </Grid>
  )
}

export default PetFriendDetails
