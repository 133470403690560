import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { Grid } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import {
  BasePuiDialogProps,
  ButtonWithLoader,
  ClassesType,
  PuiDialog,
  Text,
  useFields,
} from '@pbt/pbt-ui-components'
import { Warning as WarningIcon } from '@pbt/pbt-ui-components/src/icons'

import RoleName from '~/constants/roleNames'
import { selectDoctorForCustomSoap } from '~/store/actions/soap'
import { getIsAnyLoading } from '~/store/duck/customLoading'
import { getSoapBusinessId } from '~/store/reducers/soap'
import useCloseAfterCreation from '~/utils/useCloseAfterCreation'

import TeamMemberSelect from '../../inputs/TeamMemberSelect'

interface LabOrderAssignmentDialogProps extends BasePuiDialogProps {
  classes?: ClassesType<typeof useStyles>
  logIdsForInvoiceWithNoProducer?: string[]
  onRetry: (producerId?: string, logIds?: string[]) => void
  soapsWithNoDoctor?: string[]
}

const useStyles = makeStyles(
  (theme) => ({
    dialogContentRoot: {
      padding: theme.spacing(2),
    },
    button: {
      width: 150,
    },
    actions: {
      padding: theme.spacing(2),
    },
    warningIcon: {
      color: theme.colors.alertWarning,
      margin: theme.spacing(2),
      width: 32,
      height: 32,
    },
  }),
  { name: 'LabOrderAssignmentDialog' },
)

const LabOrderAssignmentDialog = ({
  classes: classesProp,
  open,
  onClose,
  onRetry,
  soapsWithNoDoctor,
  logIdsForInvoiceWithNoProducer,
}: LabOrderAssignmentDialogProps) => {
  const classes = useStyles({ classes: classesProp })
  const dispatch = useDispatch()
  const { t } = useTranslation(['Common', 'Dialogs'])

  const [producerId, setProducerId] = useState<string>()

  const isEditingSoaps: boolean = useSelector(
    getIsAnyLoading(soapsWithNoDoctor || []),
  )
  const currentSoapBusinessId = useSelector(getSoapBusinessId)

  const {
    fields: { assignedDoctor },
  } = useFields([{ name: 'assignedDoctor', initialValue: null }])

  const retryAfterVetAssigned = useCloseAfterCreation(() => {
    onRetry(producerId, logIdsForInvoiceWithNoProducer)
    if (onClose) {
      onClose()
    }
  }, getIsAnyLoading(soapsWithNoDoctor || []))

  const handleAssignDoctor = () => {
    if (
      logIdsForInvoiceWithNoProducer &&
      logIdsForInvoiceWithNoProducer.length > 0
    ) {
      setProducerId(assignedDoctor.value)
    } else {
      soapsWithNoDoctor?.forEach((soapId) => {
        dispatch(
          selectDoctorForCustomSoap(
            soapId,
            currentSoapBusinessId,
            assignedDoctor.value,
          ),
        )
      })
    }
    retryAfterVetAssigned()
  }

  return (
    <PuiDialog
      fullWidth
      actions={
        <ButtonWithLoader
          className={classes.button}
          disabled={!assignedDoctor.value || isEditingSoaps}
          loading={isEditingSoaps}
          onClick={handleAssignDoctor}
        >
          {t('Common:SAVE_ACTION')}
        </ButtonWithLoader>
      }
      aria-labelledby="lab-order-assignment-dialog"
      classes={{
        dialogContentRoot: classes.dialogContentRoot,
        actions: classes.actions,
      }}
      maxWidth="sm"
      open={open}
      onClose={onClose}
    >
      <Grid container direction="column">
        <Grid container item justifyContent="center">
          <WarningIcon className={classes.warningIcon} />
        </Grid>
        <Text>{t('Dialogs:LAB_ORDER_ASSIGNMENT_DIALOG.DESCRIPTION')}</Text>
        <TeamMemberSelect
          field={assignedDoctor}
          label={`${t('Common:ASSIGNED_DOCTOR')}*`}
          renderEmpty={false}
          roles={[RoleName.Veterinarian]}
        />
      </Grid>
    </PuiDialog>
  )
}

export default LabOrderAssignmentDialog
