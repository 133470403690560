import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from 'react'
import { useTranslation } from 'react-i18next'
import { TableCell } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import * as R from 'ramda'
import {
  Amount,
  CurrencyTextField,
  CustomFieldValidatorState,
  FieldProp,
  NumberUtils,
  PuiCheckbox,
  useFields,
  ValidateHandle,
} from '@pbt/pbt-ui-components'

import PrePaidButton from '~/components/common/buttons/PrePaidButton'
import RangeButton from '~/components/common/buttons/RangeButton'
import PercentTextField from '~/components/common/inputs/PercentTextField'
import QuantityInput from '~/components/common/inputs/QuantityInput'
import DragAndDropTable, {
  DragAndDropTableProps,
} from '~/components/common/lists/DragAndDropTable'
import {
  discountAmountFieldChanged,
  discountPercFieldChanged,
  dropPrepaidState,
  formatMoneyRange,
  getDiscountedPrice,
  getDiscountPerc,
  getExtendedPrice,
  getHighValue,
  getLimitedDiscountAmount,
  getLowValue,
  isEveryItemOfSingleQuantity,
  isRange,
  prepaidFieldChanged,
  quantityFieldChanged,
  restrictUsedQuantity,
  setHighValue,
  setLowValue,
} from '~/components/dashboard/invoices/invoiceUtils'
import { Bundle, BundleItem } from '~/types'
import { getItemId } from '~/utils/orderUtils'
import { useGetFormattedPriceUnit } from '~/utils/priceUtils'

import BundleItemsTableFooter from './BundleItemsTableFooter'

const useStyles = makeStyles(
  () => ({
    discountInputPercent: {
      width: 65,
    },
    discountInputAmount: {
      width: 65,
    },
    tableContainer: {
      overflowY: 'auto',
    },
    tableMainCell: {
      width: '30%',
    },
  }),
  { name: 'BundleItemsTable' },
)

export interface BundleItemsTableHandle extends ValidateHandle {}

interface BundleItemsTableProps
  extends Omit<
    DragAndDropTableProps<BundleItem>,
    'children' | 'onOrderChange'
  > {
  bundle: Bundle
  bundlePreview?: boolean
  onBundleChange: (bundle: Bundle) => void
  originalBundle?: Bundle
}

const BundleItemsTable = forwardRef<
  BundleItemsTableHandle,
  BundleItemsTableProps
>(function BundleItemsTable(
  { bundlePreview = false, originalBundle, bundle, onBundleChange, ...rest },
  ref,
) {
  const classes = useStyles()
  const { t } = useTranslation(['Common', 'Validations'])

  const { items = [] } = bundle

  const [isRangeEnabled, setIsRangeEnabled] = useState(
    !isEveryItemOfSingleQuantity(items),
  )
  const [isPrePaidEnabled, setIsPrePaidEnabled] = useState(
    R.any(R.propEq('prepaid', true), items),
  )

  const priceUnits = useGetFormattedPriceUnit(R.pluck('price', items))

  const quantityValidator = ({ state, name }: CustomFieldValidatorState) =>
    isRangeEnabled || state[name] > 0

  const highQuantityValidator = ({ state, name }: CustomFieldValidatorState) =>
    !isRangeEnabled || state[name] > 0

  const getFields = R.pipe(
    R.addIndex<BundleItem>(R.map)((item, index) => [
      {
        name: `quantity_${index}`,
        validators: [
          { validator: quantityValidator, validatorName: 'bundleItemQuantity' },
        ],
        messages: {
          bundleItemQuantity: t('Validations:QUANTITY_GREATER_THAN_ZERO'),
        },
        initialValue: getLowValue(item.quantity),
      },
      {
        name: `high_quantity_${index}`,
        validators: [
          {
            validator: highQuantityValidator,
            validatorName: 'bundleItemHighQuantity',
          },
        ],
        messages: {
          bundleItemHighQuantity: t(
            'Validations:HIGH_QUANTITY_GREATER_THAN_ZERO',
          ),
        },
        initialValue: getHighValue(item.quantity),
      },
    ]),
    R.flatten<FieldProp[]>,
  )

  const { fields, validate, reset } = useFields(getFields(items))

  useEffect(() => {
    reset()
  }, [items])

  const onItemsChange = (newItems: BundleItem[]) => {
    onBundleChange({ ...bundle, items: newItems })
  }

  const toggleRange = () => {
    const newRangeEnabled = !isRangeEnabled

    setIsRangeEnabled(newRangeEnabled)

    if (isRangeEnabled) {
      const newItems = items.map((item) => ({
        ...item,
        quantity: getLowValue(item.quantity),
      }))
      onItemsChange(newItems)
    }
  }

  const toggleBundlePrePaid = () => {
    const toggledState = !isPrePaidEnabled
    setIsPrePaidEnabled(toggledState)
    if (!toggledState) {
      onItemsChange(dropPrepaidState(items) as BundleItem[])
    }
  }

  const onOrderChange = (oldIndex: number, newIndex: number) => {
    const result = Array.from(items)
    const [removed] = result.splice(oldIndex, 1)
    result.splice(newIndex, 0, removed)

    onItemsChange(result)
  }

  const updateFieldForItem = (
    value: any,
    field: string,
    item: BundleItem,
    updateOtherFields?: (item: BundleItem, newItem: BundleItem) => void,
  ) => {
    const itemIndex = items.indexOf(item)
    const newItem = { ...item, [field]: value } as BundleItem

    if (updateOtherFields) {
      updateOtherFields(item, newItem)
    }

    onItemsChange(R.update(itemIndex, newItem, items))
  }

  const onQuantityChange = (quantity: Amount, item: BundleItem) => {
    updateFieldForItem(quantity, 'quantity', item, quantityFieldChanged)
  }

  const onDiscountPercChange = (perc: number, item: BundleItem) => {
    updateFieldForItem(perc, 'discountPerc', item, discountPercFieldChanged)
  }

  const onDiscountAmountChange = (amount: Amount, item: BundleItem) => {
    updateFieldForItem(
      amount,
      'discountAmount',
      item,
      discountAmountFieldChanged,
    )
  }

  const onUpdateFirstVisitQuantity = (
    usedQuantity: number,
    item: BundleItem,
  ) => {
    const newQuantity = restrictUsedQuantity(usedQuantity, item)
    updateFieldForItem(newQuantity, 'usedQuantity', item)
  }

  const togglePrePaid = (prepaid: boolean, item: BundleItem) => {
    updateFieldForItem(prepaid, 'prepaid', item, prepaidFieldChanged)
  }

  useEffect(() => {
    if (!isEveryItemOfSingleQuantity(items)) {
      setIsRangeEnabled(true)
    }
    if (R.any(R.propEq('prepaid', true), items)) {
      setIsPrePaidEnabled(true)
    }
  }, [items])

  useImperativeHandle(ref, () => ({
    validate,
  }))

  const headerRow = [
    {
      label: t('Common:QUANTITY'),
      labelButton: (
        <>
          {!isRangeEnabled && !isPrePaidEnabled && (
            <RangeButton
              inline
              isSingleQuantity={!isRangeEnabled}
              toggleRange={toggleRange}
            />
          )}
          {!isRangeEnabled && (
            <PrePaidButton
              inline
              isPrePaid={isPrePaidEnabled}
              togglePrePaid={toggleBundlePrePaid}
            />
          )}
        </>
      ),
      subLabels: isRangeEnabled
        ? [
            { label: t('Common:LOW').toLowerCase() },
            { label: t('Common:HIGH').toLowerCase() },
          ]
        : isPrePaidEnabled
        ? [
            { label: t('Common:PRE-PAID') },
            { label: t('Common:FIRST_VISIT') },
            { label: t('Common:TOTAL') },
          ]
        : [],
    },
    { label: t('Common:UNITS') },
    { label: isRangeEnabled ? t('Common:EXTENDED_PRICE') : t('Common:PRICE') },
    {
      label: t('Common:DISCOUNT'),
      subLabels: [{ label: '%' }, { label: NumberUtils.getCurrencySymbol() }],
    },
    { label: t('Common:TOTAL_PRICE') },
  ].filter(Boolean)

  return (
    <DragAndDropTable
      classes={
        bundlePreview
          ? { mainCell: classes.tableMainCell }
          : { root: classes.tableContainer, mainCell: classes.tableMainCell }
      }
      data={items}
      footer={
        <BundleItemsTableFooter
          bundle={bundle}
          isPrePaidEnabled={isPrePaidEnabled}
          isRangeEnabled={isRangeEnabled}
          onBundleChange={onBundleChange}
        />
      }
      getId={getItemId}
      headerRow={headerRow}
      mainColumns={[
        {
          label: t('Common:NAME'),
          prop: 'name',
          getShowLabel: (item) => {
            const id = getItemId(item)
            return originalBundle && originalBundle.items
              ? originalBundle.items.every(
                  (originalItem) => getItemId(originalItem) !== id,
                )
              : false
          },
          labelText: t('Common:NEW'),
        },
      ]}
      onOrderChange={onOrderChange}
      {...rest}
    >
      {({ item, tableCellClassName }, index) => {
        const itemPrice = getExtendedPrice(item)
        const itemDiscountedPrice = getDiscountedPrice(item)
        const allowDecimalQuantity = item.typeName !== 'Lab test'
        const discountAllowed = item.price?.discountAllowed
        return (
          <>
            {isRangeEnabled && (
              <>
                <TableCell className={tableCellClassName}>
                  <QuantityInput
                    disableUnderline
                    allowDecimal={allowDecimalQuantity}
                    data-test-id={`${item.name}-low`}
                    max={999}
                    min={0}
                    value={getLowValue(item.quantity)}
                    onChange={(value) =>
                      onQuantityChange(setLowValue(item.quantity, value), item)
                    }
                  />
                </TableCell>
                <TableCell className={tableCellClassName}>
                  <QuantityInput
                    disableUnderline
                    minReachedTooltipEnabled
                    allowDecimal={allowDecimalQuantity}
                    data-test-id={`${item.name}-high`}
                    field={{
                      ...fields[`high_quantity_${index}`],
                      setValue: (value) => {
                        fields[`high_quantity_${index}`].setValue(value)
                        onQuantityChange(
                          setHighValue(item.quantity, value),
                          item,
                        )
                      },
                    }}
                    max={999}
                    min={allowDecimalQuantity ? 0.001 : 1}
                  />
                </TableCell>
              </>
            )}
            {!isRangeEnabled && isPrePaidEnabled && (
              <>
                <TableCell className={tableCellClassName}>
                  <PuiCheckbox
                    checked={item.prepaid}
                    onChange={() => togglePrePaid(!item.prepaid, item)}
                  />
                </TableCell>
                <TableCell className={tableCellClassName}>
                  {item.prepaid && (
                    <QuantityInput
                      disableUnderline
                      minReachedTooltipEnabled
                      allowDecimal={allowDecimalQuantity}
                      data-test-id={`${item.name}-firstVisit`}
                      max={getLowValue(item.quantity)}
                      min={0}
                      value={item.usedQuantity}
                      onChange={(value) =>
                        onUpdateFirstVisitQuantity(value, item)
                      }
                    />
                  )}
                </TableCell>
                <TableCell className={tableCellClassName}>
                  <QuantityInput
                    disableUnderline
                    allowDecimal={allowDecimalQuantity}
                    data-test-id={`${item.name}-prepaidTotal`}
                    max={999}
                    min={0}
                    minReachedTooltipEnabled={false}
                    value={getLowValue(item.quantity)}
                    onChange={(value) => onQuantityChange(value, item)}
                  />
                </TableCell>
              </>
            )}
            {!isRangeEnabled && !isPrePaidEnabled && (
              <TableCell className={tableCellClassName}>
                <QuantityInput
                  disableUnderline
                  minReachedTooltipEnabled
                  allowDecimal={allowDecimalQuantity}
                  data-test-id={`${item.name}-quantity`}
                  field={{
                    ...fields[`quantity_${index}`],
                    setValue: (value) => {
                      fields[`quantity_${index}`].setValue(value)
                      onQuantityChange(value, item)
                    },
                  }}
                  max={999}
                  min={allowDecimalQuantity ? 0.001 : 1}
                />
              </TableCell>
            )}
            <TableCell className={tableCellClassName}>
              {priceUnits[index]}
            </TableCell>
            <TableCell className={tableCellClassName}>
              {formatMoneyRange(itemPrice)}
            </TableCell>
            <TableCell className={tableCellClassName}>
              {discountAllowed ? (
                <PercentTextField
                  className={classes.discountInputPercent}
                  placeholder="&nbsp;"
                  value={
                    isRange(getDiscountPerc(item))
                      ? undefined
                      : getLowValue(getDiscountPerc(item))
                  }
                  onChange={(value) => onDiscountPercChange(value, item)}
                />
              ) : (
                '-'
              )}
            </TableCell>
            <TableCell className={tableCellClassName}>
              {discountAllowed ? (
                <CurrencyTextField
                  className={classes.discountInputAmount}
                  value={getHighValue(getLimitedDiscountAmount(item)) || 0}
                  onChange={(event) =>
                    onDiscountAmountChange(
                      Math.min(
                        Number(event.target.value),
                        getHighValue(itemPrice),
                      ),
                      item,
                    )
                  }
                />
              ) : (
                '-'
              )}
            </TableCell>
            <TableCell className={tableCellClassName}>
              {formatMoneyRange(itemDiscountedPrice)}
            </TableCell>
          </>
        )
      }}
    </DragAndDropTable>
  )
})

export default BundleItemsTable
