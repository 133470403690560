import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { Navigate, useNavigate } from 'react-router-dom'
import AddIcon from '@mui/icons-material/Add'
import { Grid, IconButton } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import isEmail from 'validator/lib/isEmail'
import { ErrorTooltip, Text } from '@pbt/pbt-ui-components'
import { notEmptyFormField } from '@pbt/pbt-ui-components/src/utils/validation'

import {
  createPersons,
  registrationRequest,
  updateMember,
} from '~/store/actions/registration'
import {
  createPerson,
  isEmptyMember,
  isNoJob,
  PersonDto,
} from '~/store/dto/Person'
import {
  getIsActivationKeyFlow,
  getIsUserAlreadyExist,
  getRegistrationBusinesses,
  getRegistrationError,
  getRegistrationIsLoading,
  getRegistrationMembers,
  getRegistrationPerson,
} from '~/store/reducers/registration'
import useCloseAfterCreation from '~/utils/useCloseAfterCreation'
import useIsAuthenticated from '~/utils/useIsAuthenticated'

import MemberInfo from './MemberInfo'
import RegistrationPage from './RegistrationPage'

const useStyles = makeStyles(
  (theme) => ({
    list: {
      overflowX: 'hidden',
      overflow: 'auto',
      height: 354,
      [theme.breakpoints.down('md')]: {
        height: 467,
        marginTop: 10,
        paddingLeft: 17,
        paddingRight: 17,
      },
    },
    plusButton: {
      margin: 15,
      backgroundColor: theme.colors.sideText,
      '&&&&:hover': {
        backgroundColor: theme.colors.sideText,
      },
      width: 20,
      height: 20,
      padding: 0,
    },
    plusIcon: {
      color: theme.colors.profileText,
      width: 16,
      height: 16,
    },
    addPracticeText: {
      color: theme.colors.sideText,
      fontSize: 14,
      lineHeight: '17px',
    },
    addNewButton: {
      cursor: 'pointer',
    },
  }),
  { name: 'MembersList' },
)

const AMOUNT_OF_DEFAULT_MEMBERS_TO_INVITE = 3

const MembersList = () => {
  const navigate = useNavigate()
  const classes = useStyles()
  const dispatch = useDispatch()
  const { t } = useTranslation(['Common', 'Registration', 'Tooltips'])

  const businesses = useSelector(getRegistrationBusinesses)
  const members = useSelector(getRegistrationMembers)
  const person = useSelector(getRegistrationPerson)
  const isLoading = useSelector(getRegistrationIsLoading)
  const registrationError = useSelector(getRegistrationError)
  const isActivationFlow = useSelector(getIsActivationKeyFlow)
  const isUserAlreadyExist = useSelector(getIsUserAlreadyExist)

  const { isAuthenticated } = useIsAuthenticated()

  const [focused, setFocused] = useState<string | null>(null)
  const [highlightErrors, setHighlightErrors] = useState(false)
  const [showErrorFlags, setShowErrorFlags] = useState(false)
  const [submitted, setSubmitted] = useState(false)
  const membersArr = Object.values(members)

  const onRegistrationRequest = async () => {
    if (!registrationError) {
      if (isUserAlreadyExist) {
        navigate('/register/manager')
      } else {
        navigate('/register/termsAndConditions')
      }
    }
  }

  const setOnRegistrationRequest = useCloseAfterCreation(
    onRegistrationRequest,
    getRegistrationIsLoading,
  )

  useEffect(() => {
    if (!membersArr.length) {
      dispatch(createPersons(AMOUNT_OF_DEFAULT_MEMBERS_TO_INVITE))
    }
  }, [])

  useEffect(() => {
    if (registrationError && submitted) {
      navigate('/register/manager')
    }
  }, [registrationError, submitted])

  const businessesAmount = () => Object.values(businesses).length

  if (isAuthenticated) {
    return <Navigate replace to="/" />
  }

  if (!businesses || !businessesAmount()) {
    return <Navigate replace to="/register" />
  }

  if (!person?.id) {
    return <Navigate replace to="/register/manager" />
  }

  const addNew = () => {
    const newPerson = createPerson()
    dispatch(updateMember(newPerson))
    setFocused(newPerson.id)
  }

  const onSubmit = () => {
    const isValid = !membersArr.some((member) => {
      if (!isEmptyMember(member)) {
        if (!isEmail(member.email ?? '') || !notEmptyFormField(member.jobId)) {
          setHighlightErrors(true)
          setFocused(member.id)
          return true
        }
        if (
          (businessesAmount() > 1 && !member.businessRoles?.length) ||
          isNoJob(member)
        ) {
          setHighlightErrors(true)
          setFocused(member.id)
          setShowErrorFlags(true)
          return true
        }
      }
      return false
    })
    if (isValid) {
      if (!isActivationFlow) {
        setOnRegistrationRequest()
        dispatch(registrationRequest())
      } else {
        navigate('/register/activation/questions')
      }

      setSubmitted(true)
    }
  }

  return (
    <RegistrationPage
      disabled={isLoading}
      header={t('Registration:MEMBERS_LIST.HEADER')}
      subheader={t('Registration:MEMBERS_LIST.SUB_HEADER')}
      submitLabel={t('Common:GET_STARTED')}
      onSubmit={onSubmit}
    >
      <ErrorTooltip
        message={t('Tooltips:PLEASE_FILL_REQUIRED_FIELDS')}
        open={showErrorFlags}
      >
        <>
          <div className={classes.list}>
            <Grid container direction="column" spacing={1}>
              {membersArr.map((member) => (
                <MemberInfo
                  businesses={businesses}
                  expanded={Boolean(businesses && businessesAmount() > 1)}
                  focused={focused === member.id}
                  highlightErrors={highlightErrors && !isEmptyMember(member)}
                  key={member.id}
                  member={member}
                  onChange={(newMember: PersonDto) => {
                    dispatch(updateMember(newMember))
                    setShowErrorFlags(false)
                    setFocused(null)
                  }}
                />
              ))}
            </Grid>
          </div>
          <Grid
            container
            alignItems="center"
            className={classes.addNewButton}
            justifyContent="flex-end"
            onClick={addNew}
          >
            <Text className={classes.addPracticeText}>
              {t('Common:ADD_ANOTHER_TEAM_MEMBER')}
            </Text>
            <IconButton
              disableRipple
              className={classes.plusButton}
              size="large"
            >
              <AddIcon className={classes.plusIcon} />
            </IconButton>
          </Grid>
        </>
      </ErrorTooltip>
    </RegistrationPage>
  )
}

export default MembersList
