import React, { useEffect, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { Grid, LinearProgress } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import classNames from 'classnames'
import * as R from 'ramda'
import {
  AlertIconType,
  BackButton,
  BillingAddress,
  ButtonWithLoader,
  ClassesType,
  NumberUtils,
  PaymentIframe,
  StripePaymentForm,
  Text,
} from '@pbt/pbt-ui-components'
import { StripePaymentFormHandle } from '@pbt/pbt-ui-components/src/components/payments/stripe/StripePaymentForm'

import DialogNames from '~/constants/DialogNames'
import FeatureToggle from '~/constants/featureToggle'
import PaymentProcessors from '~/constants/PaymentProcessors'
import PaymentType from '~/constants/paymentTypes'
import { RhapsodyGoPaymentMethod } from '~/constants/RhapsodyGoPaymentMethod'
import { clearRhapsodyGoError } from '~/store/actions/payments'
import { getRhapsodyPayConfig } from '~/store/duck/rhapsodyPay'
import { getCurrentBusinessId } from '~/store/reducers/auth'
import { getFeatureToggle } from '~/store/reducers/constants'
import {
  getPayment,
  getPaymentsIsLoading,
  getProcessedBillingAddress,
  getRhapsodyGoError,
  getRhapsodyGoProcessedPayment,
  getStripeClientSecret,
} from '~/store/reducers/payments'
import { getUser } from '~/store/reducers/users'
import { ExtendPayment, RhapsodyGoPreference } from '~/types'
import { getErrorMessage } from '~/utils/errors'
import useDialog from '~/utils/useDialog'

const useStyles = makeStyles(
  (theme) => ({
    content: {
      padding: theme.spacing(2, 3),
    },
    footer: {
      padding: theme.spacing(2, 3),
      borderTop: theme.constants.tabBorder,
    },
    button: {
      height: 40,
      width: 220,
    },
    labelRoot: {
      marginRight: theme.spacing(2),
    },
    radioLabel: {
      fontSize: '1.6rem',
      color: theme.colors.secondaryText,
    },
    progress: {
      height: theme.constants.progressBarHeight,
    },
    hidden: {
      visibility: 'hidden',
    },
  }),
  { name: 'PaymentCardDetails' },
)

const paymentMethodsMap = {
  [RhapsodyGoPaymentMethod.CREDIT_CARD]: 'Sale',
  [RhapsodyGoPaymentMethod.ELECTRONIC_CHECK]: 'AchDebit',
}

export interface PaymentCardDetailsProps {
  buttonLabel?: string
  classes?: ClassesType<typeof useStyles>
  clientId: string
  isAchRefund: boolean
  isAuth: boolean
  onBack: () => void
  onOk: () => void
  onProceed: (
    cryptogram: string,
    paymentType: PaymentType,
    billingAddress: BillingAddress,
  ) => void
  paymentAmount: number
}

const PaymentCardDetails = ({
  classes: classesProp,
  clientId,
  onProceed,
  paymentAmount,
  isAchRefund,
  isAuth,
  onBack,
  onOk,
  buttonLabel,
}: PaymentCardDetailsProps) => {
  const classes = useStyles({ classes: classesProp })
  const dispatch = useDispatch()
  const { t } = useTranslation(['Common', 'Errors', 'Invoices'])

  const [openAlert, closeAlert] = useDialog(DialogNames.DISMISSIBLE_ALERT)

  const isIpoM0EstimatesEnabled = useSelector(
    getFeatureToggle(FeatureToggle.IPO_M0_ESTIMATES),
  )

  const currentBusinessId = useSelector(getCurrentBusinessId)
  const rhapsodyPayConfig = useSelector(getRhapsodyPayConfig(currentBusinessId))
  const { goPreferences = {} as RhapsodyGoPreference } = rhapsodyPayConfig || {}
  const financeIsLoading = useSelector(getPaymentsIsLoading)
  const rhapsodyGoProcessedPayment = useSelector(getRhapsodyGoProcessedPayment)
  // GoPayment doesnt have unappliedAmount + other fields needed. Need to retreive payment to display on modal if we have a goPayment

  const nonGoPayment = useSelector(
    getPayment((rhapsodyGoProcessedPayment as any)?.payment),
  )

  const rhapsodyGoError = useSelector(getRhapsodyGoError)
  const client = useSelector(getUser(clientId))
  const stripeClientSecret = useSelector(getStripeClientSecret)
  const billingAddress = useSelector(getProcessedBillingAddress)

  const paymentMethod = useMemo(
    () =>
      isAchRefund
        ? RhapsodyGoPaymentMethod.ELECTRONIC_CHECK
        : RhapsodyGoPaymentMethod.CREDIT_CARD,
    [],
  )
  const isStripePayment =
    rhapsodyPayConfig?.goProcessor === PaymentProcessors.STRIPE

  const [isPaymentSystemBusy, setIsPaymentSystemBusy] = useState(
    !isStripePayment,
  )
  const [cryptogram, setCryptogram] = useState('')
  const [isStripeReadyToPay, setIsStripeReadyToPay] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const [openPaymentDetails] = useDialog(DialogNames.PAYMENT_DETAILS)
  const [processedBillingAddress, setProcessedBillingAddress] = useState({})

  const stripeRef = useRef<StripePaymentFormHandle>(null)

  const transactionTypeForCharge =
    paymentMethod === RhapsodyGoPaymentMethod.CREDIT_CARD
      ? PaymentType.SALE
      : PaymentType.ACH_SALE

  const isPayButtonDisabled =
    Boolean(errorMessage) ||
    isPaymentSystemBusy ||
    (isStripePayment ? !isStripeReadyToPay : !cryptogram)

  const reset = () => {
    setErrorMessage('')
  }

  const handleClose = () => {
    reset()
    dispatch(clearRhapsodyGoError())
    onOk()
  }

  const handleBack = () => {
    reset()
    dispatch(clearRhapsodyGoError())
    onBack()
  }

  const moveToCurrentTransaction = (payment: ExtendPayment | null) => {
    openPaymentDetails({ clientId, payment })
    handleClose()
  }

  const createStripePayment = async () => {
    const paymentDetails = await stripeRef.current?.createPayment({
      client,
      clientSecret: stripeClientSecret,
      billingAddress,
      method: 'confirmCardPayment',
    })
    setIsPaymentSystemBusy(false)
    if (paymentDetails?.error) {
      openAlert({
        iconType: AlertIconType.WARN,
        message: getErrorMessage(
          paymentDetails.error as string,
          t('Errors:UNKNOWN_ERROR'),
        ),
        okButtonText: t('Common:OK'),
        onOk: closeAlert,
      })
    } else {
      openAlert({
        iconType: AlertIconType.SUCCESS,
        message: t('Invoices:PAYMENTS.PAYMENT_CARD_DETAILS.PAYMENT_APPROVED'),
        okButtonText: t('Common:OK'),
        onOk: () => {
          closeAlert()
          onOk()
        },
      })
    }
  }

  useEffect(() => {
    if (!R.isEmpty(billingAddress)) {
      setProcessedBillingAddress(billingAddress)
    }
  }, [billingAddress])

  useEffect(() => {
    if (rhapsodyGoError) {
      setErrorMessage(rhapsodyGoError)
    }
  }, [rhapsodyGoError])

  const hasRhapsodyGoProcessedPayment = Boolean(rhapsodyGoProcessedPayment)

  useEffect(() => {
    if (hasRhapsodyGoProcessedPayment) {
      moveToCurrentTransaction(
        (isIpoM0EstimatesEnabled && nonGoPayment !== undefined
          ? nonGoPayment
          : rhapsodyGoProcessedPayment) as ExtendPayment,
      )
    }
  }, [hasRhapsodyGoProcessedPayment])

  useEffect(() => {
    if (stripeClientSecret && stripeRef?.current && isStripePayment) {
      setIsPaymentSystemBusy(true)
      createStripePayment()
    }
  }, [stripeClientSecret, stripeRef, client])

  const chargePayment = () => {
    const transactionType: PaymentType = isAuth
      ? PaymentType.AUTH
      : isAchRefund
      ? PaymentType.ACH_REFUND
      : transactionTypeForCharge
    onProceed(cryptogram, transactionType, processedBillingAddress)
  }

  const onGenerateCryptoSuccess = (crypto: string) => {
    dispatch(clearRhapsodyGoError())
    setErrorMessage('')
    setIsPaymentSystemBusy(false)
    setCryptogram(crypto)
  }

  const payButtonLabel = isAuth
    ? t('Common:AUTHORIZE_ACTION')
    : isAchRefund
    ? t('Common:PAYMENTS.REFUND_ACTION')
    : t('Common:CHARGE_ACTION')

  const getFirstAmericanTransactionType = () => {
    if (isAuth) {
      return 'Auth'
    }
    if (isAchRefund) {
      return 'AchCredit'
    }

    return paymentMethodsMap[paymentMethod]
  }

  const isCreditCard = paymentMethod === RhapsodyGoPaymentMethod.CREDIT_CARD
  const iframeSrc =
    (isCreditCard ? goPreferences.cardUrl : goPreferences.achUrl) || ''
  const processorId = isCreditCard
    ? goPreferences.cardProcessorId
    : goPreferences.achProcessorId || ''

  return (
    <>
      <LinearProgress
        className={classNames(classes.progress, {
          [classes.hidden]: !isPaymentSystemBusy,
        })}
      />
      <Grid container className={classes.content}>
        {isAchRefund && (
          <Text variant="lowAccent2">
            {t('Invoices:PAYMENTS.PAYMENT_CARD_DETAILS.RE-ENTER_ACCOUNT')}
          </Text>
        )}
        {isStripePayment ? (
          rhapsodyPayConfig?.stripePreferences?.publicKey && (
            <StripePaymentForm
              publicKey={rhapsodyPayConfig?.stripePreferences?.publicKey}
              ref={stripeRef}
              stripeAccount={rhapsodyPayConfig?.stripePreferences?.accountId}
              onCompleteStateChange={(isValid) =>
                setIsStripeReadyToPay(isValid)
              }
            />
          )
        ) : (
          <PaymentIframe
            processorId={processorId}
            src={iframeSrc}
            transactionType={getFirstAmericanTransactionType()}
            transcenterId={goPreferences.transcenterId}
            onGenerateCrypto={() => {
              setIsPaymentSystemBusy(true)
              setCryptogram('')
            }}
            onGenerateCryptoFailure={setErrorMessage}
            onGenerateCryptoSuccess={onGenerateCryptoSuccess}
            onLoad={() => setIsPaymentSystemBusy(false)}
          />
        )}
      </Grid>
      <Grid container className={classes.footer}>
        <Grid item mr={3}>
          <BackButton label={t('Common:BACK_ACTION')} onClick={handleBack} />
        </Grid>
        <Grid item>
          <ButtonWithLoader
            className={classes.button}
            disabled={isPayButtonDisabled}
            loading={isPaymentSystemBusy || financeIsLoading}
            type="submit"
            onClick={chargePayment}
          >
            {buttonLabel ||
              `${payButtonLabel} ${NumberUtils.formatMoney(paymentAmount)}`}
          </ButtonWithLoader>
        </Grid>
      </Grid>
    </>
  )
}

export default PaymentCardDetails
