import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { Grid } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import {
  ButtonWithLoader,
  DateUtils,
  Defaults,
  moment,
  Text,
} from '@pbt/pbt-ui-components'

import PuiButtonGroup, {
  PuiButtonGroupItem,
} from '~/components/common/buttons/PuiButtonGroup'
import { LabeledSection } from '~/components/elements/LabeledSection/LabeledSection'
import DialogNames from '~/constants/DialogNames'
import FeatureToggle from '~/constants/featureToggle'
import i18n from '~/locales/i18n'
import {
  patientMembershipsActions,
  patientMembershipsSelectors,
  // @ts-ignore
} from '~/store/duck/patientMemberships'
import {
  fetchPausedPatientMemberships,
  getPausedPatientMemberships,
} from '~/store/duck/pausedPatientMemberships'
import { getFeatureToggle } from '~/store/reducers/constants'
import { getPatientsMap } from '~/store/reducers/patients'
import { Membership, MembershipPlan, PausedMembership } from '~/types'
import useDialog from '~/utils/useDialog'

import ClientMembershipSavingsBanner from './ClientMembershipSavingsBanner'
// @ts-ignore
import MembershipTableLoyaltyPointsView from './loyalty-points/MembershipTableLoyaltyPointsView'
import MembershipTablePatientsView from './patients/MembershipTablePatientsView'
import MembershipTablePaymentsView from './payments/MembershipTablePaymentsView'

const useStyles = makeStyles(
  (theme) => ({
    buttonGroup: {
      marginBottom: theme.spacing(2),
    },
    tableContainer: {
      [theme.breakpoints.up('md')]: {
        minWidth: 1000,
      },
    },
  }),
  { name: 'MembershipTableComponent' },
)

enum MembershipTableRoutes {
  PATIENTS = '',
  LOYALTY_POINTS = 'loyalty-points',
  PAYMENTS = 'payments',
}

const MembershipTableLabels = {
  [MembershipTableRoutes.PATIENTS]: i18n.t('Common:PATIENTS'),
  [MembershipTableRoutes.LOYALTY_POINTS]: i18n.t('Common:LOYALTY_POINTS'),
  [MembershipTableRoutes.PAYMENTS]: i18n.t('Common:PAYMENTS.PAYMENTS'),
}

const MembershipTableComponents = {
  [MembershipTableRoutes.PATIENTS]: MembershipTablePatientsView,
  [MembershipTableRoutes.LOYALTY_POINTS]: MembershipTableLoyaltyPointsView,
  [MembershipTableRoutes.PAYMENTS]: MembershipTablePaymentsView,
}

const MembershipPaymentsTab = {
  route: MembershipTableRoutes.PAYMENTS,
  name: MembershipTableLabels[MembershipTableRoutes.PAYMENTS],
}

const MembershipTableTabItems = [
  {
    route: MembershipTableRoutes.PATIENTS,
    name: MembershipTableLabels[MembershipTableRoutes.PATIENTS],
  },
  // {
  //   route: MembershipTableRoutes.LOYALTY_POINTS,
  //   name: MembershipTableViews[MembershipTableRoutes.LOYALTY_POINTS],
  // },
  MembershipPaymentsTab,
]

interface MembershipTableComponentProps {
  clientId: string
}

const MembershipTableComponent = ({
  clientId,
}: MembershipTableComponentProps) => {
  const dispatch = useDispatch()
  const classes = useStyles()
  const navigate = useNavigate()
  const { currentTable = MembershipTableRoutes.PATIENTS } = useParams<{
    currentTable: MembershipTableRoutes
  }>()
  const { t } = useTranslation(['Common', 'Memberships'])
  const isM2bBraintreeSignupEnabled = useSelector(
    getFeatureToggle(FeatureToggle.IPO_M2B_BRAINTREE_SIGNUP),
  )

  const rootMembershipRoute = `/membership/${clientId}/`

  const onViewChange = ({ route }: PuiButtonGroupItem) =>
    navigate(route ? `${rootMembershipRoute}${route}` : rootMembershipRoute)

  const TableComponent = MembershipTableComponents[currentTable]

  const patients = useSelector(getPatientsMap)
  const membershipList: string[] = useSelector(
    patientMembershipsSelectors.getList,
  )
  const patientMemberships: Membership[] = useSelector(
    patientMembershipsSelectors.getMultipleItems(membershipList),
  )

  const patientMembershipsByPatientId = patientMemberships.reduce<{
    [key: string]: Membership
  }>(
    (membershipMap, membership) => ({
      ...membershipMap,
      [membership.patient]: membership,
    }),
    {},
  )

  const pausedMemberships: PausedMembership[] = useSelector(
    getPausedPatientMemberships,
  )

  useEffect(() => {
    if (clientId) {
      dispatch(
        patientMembershipsActions.fetchList(
          0,
          Defaults.INFINITE_LIST_BATCH_LOAD_COUNT,
          clientId,
        ),
      )
      if (isM2bBraintreeSignupEnabled) {
        dispatch(fetchPausedPatientMemberships({ clientId }))
      }
    }
  }, [clientId, isM2bBraintreeSignupEnabled])

  const [openPatientMembershipPaymentLinkDialog] = useDialog(
    DialogNames.PATIENT_MEMBERSHIP_PAYMENT_LINK,
  )

  const handleSendUpdateLink = (patientId: string, plan: MembershipPlan) =>
    openPatientMembershipPaymentLinkDialog({
      clientId,
      plan,
      patientId,
    })

  const getPausedPlanDetails = (patientId: string) =>
    patientMembershipsByPatientId[patientId]?.plans?.[0] || {}

  return (
    <>
      <ClientMembershipSavingsBanner clientId={clientId} mb={2} />
      <Text mb={0.5} variant="h1">
        {t('Common:MEMBERSHIP')}
      </Text>
      <PuiButtonGroup
        className={classes.buttonGroup}
        items={MembershipTableTabItems}
        selectedItem={MembershipTableLabels[currentTable]}
        onItemSelected={onViewChange}
      />
      {isM2bBraintreeSignupEnabled &&
        pausedMemberships.map((pausedPlanInfo: PausedMembership) => (
          <LabeledSection
            key={pausedPlanInfo.patientId}
            labelText={t('Memberships:PAUSED_ALERT.LABEL', {
              name: patients[pausedPlanInfo.patientId]?.name,
              planName: getPausedPlanDetails(pausedPlanInfo.patientId)
                ?.planName,
              date: DateUtils.formatDate(pausedPlanInfo.overdueSinceTime),
            })}
            mb={2}
            variant="warning"
          >
            <Grid
              container
              alignItems="center"
              flexDirection="row"
              justifyContent="space-between"
            >
              <Grid container item width="auto">
                <Text mr={1}>
                  {t('Memberships:PAUSED_ALERT.LAST_PAYMENT', {
                    date: DateUtils.formatDate(
                      pausedPlanInfo.lastPaymentRetryTime,
                    ),
                  })}
                  {' • '}
                  {t('Memberships:PAUSED_ALERT.TOTAL_DUE', {
                    amount: pausedPlanInfo.amountDue,
                  })}
                </Text>
                <Text
                  variant="lowAccent2"
                  onClick={() => onViewChange(MembershipPaymentsTab)}
                >
                  {t('Memberships:PAUSED_ALERT.MONTHS_DECLINED', {
                    months: moment().diff(
                      new Date(pausedPlanInfo.overdueSinceTime),
                      'months',
                      false,
                    ),
                  })}
                </Text>
              </Grid>
              <Grid container item width="auto">
                <ButtonWithLoader
                  color="important"
                  onClick={() =>
                    handleSendUpdateLink(
                      pausedPlanInfo.patientId,
                      getPausedPlanDetails(pausedPlanInfo.patientId),
                    )
                  }
                >
                  {t('Memberships:PAUSED_ALERT.SEND_UPDATE_LINK')}
                </ButtonWithLoader>
              </Grid>{' '}
            </Grid>
          </LabeledSection>
        ))}
      <Grid container item className={classes.tableContainer} flex={1}>
        <TableComponent clientId={clientId} />
      </Grid>
    </>
  )
}

export default MembershipTableComponent
