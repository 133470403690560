import React from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { Box, Stack } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import classNames from 'classnames'
import * as R from 'ramda'
import { Defaults, NumberUtils, Text } from '@pbt/pbt-ui-components'

import { SummaryPartWidget } from '~/components/dashboard/clients/balance/widgets/SummaryPartWidget'
import {
  fetchInvoicePage,
  fetchPaymentPage,
  getBillingActivitySummary,
} from '~/store/duck/clientBillingActivityData'
import {
  getInvoiceStates,
  getRefundInvoiceState,
} from '~/store/reducers/constants'
import { BillingActivitySectionName } from '~/types/entities/clients'

const useStyles = makeStyles(
  (theme) => ({
    root: {},
    balanceSubLabelWarning: {
      color: theme.colors.important,
      backgroundColor: theme.colors.actionNeededBackground,
    },
    balanceSubLabelNegative: {
      color: theme.colors.success,
      backgroundColor: theme.colors.successBackground,
    },
    balanceSubLabelNormal: {
      color: theme.colors.lowAccentText,
      backgroundColor: theme.colors.tableOddRowBackground,
    },
    control: {
      border: theme.constants.tabBorder,
    },
    topElement: {
      borderBottom: theme.constants.tabBorder,
    },
    colorSuccess: {
      color: theme.colors.success,
      backgroundColor: theme.colors.successBackground,
    },
  }),
  { name: 'SummaryWidget' },
)

const FilteredInvoiceNames = ['Open', 'Partially Paid']

const FilteredRefundInvoiceNames = ['Pending', 'Partially Refunded']

interface SummaryWidgetProps {
  clientId: string
  onExpand: (sectionKey: BillingActivitySectionName, filters?: any) => void
}

export const SummaryWidget = ({ clientId, onExpand }: SummaryWidgetProps) => {
  const dispatch = useDispatch()
  const classes = useStyles()
  const { t } = useTranslation('Common')

  const clientBillingActivitySummary = useSelector(getBillingActivitySummary)
  const RefundInvoiceState = useSelector(getRefundInvoiceState)
  const InvoiceStates = useSelector(getInvoiceStates)

  const refundStateIds = RefundInvoiceState.filter((state) =>
    FilteredRefundInvoiceNames.includes(state.name),
  ).map((state) => state.id)
  const invoiceStateIds = InvoiceStates.filter((state) =>
    FilteredInvoiceNames.includes(state.name),
  ).map((state) => state.id)

  const { deposits, unpaidInvoices, unappliedPayments, pendingRefunds } =
    clientBillingActivitySummary
  const depositAmount = deposits?.amount || 0
  const unpaidInvoicesAmount = unpaidInvoices?.amount || 0
  const pendingRefundsAmount = pendingRefunds?.amount || 0
  const unappliedPaymentsAmount = unappliedPayments?.amount || 0
  const depositCount = deposits?.count || 0
  const unpaidInvoicesCount = unpaidInvoices?.count || 0
  const unappliedPaymentsCount = unappliedPayments?.count || 0
  const pendingRefundsCount = pendingRefunds?.count || 0
  const unpaidInvoicesAmountFormatted =
    NumberUtils.formatMoney(unpaidInvoicesAmount)
  const pendingRefundsAmountFormatted =
    NumberUtils.formatMoney(pendingRefundsAmount)
  const unappliedPaymentsAmountFormatted = NumberUtils.formatMoney(
    unappliedPaymentsAmount,
  )

  const clientBalance = clientBillingActivitySummary.balance

  const areAnyUnappliedPaymentsOrUnpaidInvoices = () =>
    (!R.isNil(clientBillingActivitySummary.unappliedPayments) &&
      unappliedPaymentsCount > 0) ||
    (!R.isNil(clientBillingActivitySummary.unpaidInvoices) &&
      unpaidInvoicesCount > 0)

  const subBalance = {
    labelStyle:
      clientBalance < 0
        ? classes.balanceSubLabelNegative
        : clientBalance > 0 || areAnyUnappliedPaymentsOrUnpaidInvoices()
        ? classes.balanceSubLabelWarning
        : classes.balanceSubLabelNormal,

    labelTitle: `${t('Common:CLIENT_BALANCE')}: ${NumberUtils.formatMoney(
      clientBalance,
    )}`,
  }

  const unpaidInvoicesSectionTitle = `${t(
    'Soap:SOAP_CLIENT_INVOICE_LIST.UNPAID_INVOICES',
  )} (${unpaidInvoicesCount})`
  const pendingRefundsTitle = `${t(
    'Common:PENDING_REFUNDS',
  )} (${pendingRefundsCount})`
  const showUnappliedSection =
    depositAmount !== 0 || unappliedPaymentsAmount !== 0
  const unappliedSectionTitle = `${t(
    'Common:UNAPPLIED_PAYMENTS',
  )} (${unappliedPaymentsCount}${
    depositCount > 0
      ? ` ${t('Common:TOTAL').toLowerCase()}, ${depositCount} ${
          depositCount > 1
            ? t('Payments:PAYMENT_TYPES.DEPOSIT_OTHER').toLowerCase()
            : t('Payments:PAYMENT_TYPES.DEPOSIT').toLowerCase()
        }`
      : ''
  })`

  return (
    <Box
      className={classes.control}
      display="flex"
      flexDirection="column"
      flexWrap="nowrap"
    >
      <Box
        className={classNames(subBalance.labelStyle, classes.topElement)}
        display="flex"
        px={2}
        py={1}
      >
        <Text className={subBalance.labelStyle} variant="h4">
          {subBalance.labelTitle}{' '}
          {clientBalance === 0 && (
            <span style={{ fontWeight: 'normal' }}>
              {t('Common:NOTHING_TO_DO')}
            </span>
          )}
        </Text>
      </Box>
      <Stack alignItems="center" direction="row" p={2} spacing={2}>
        {unpaidInvoicesAmount !== 0 && (
          <SummaryPartWidget
            amount={unpaidInvoicesAmountFormatted}
            title={unpaidInvoicesSectionTitle}
            onClick={() => {
              onExpand(BillingActivitySectionName.INVOICES, {
                unpaid: true,
                invoiceStateIds,
                refundStateIds,
              })
              dispatch(
                fetchInvoicePage({
                  clientId,
                  unpaid: true,
                  limit: Defaults.INFINITE_LIST_BATCH_LOAD_COUNT,
                  offset: 0,
                  refundStateIds: [],
                  stateIds: [],
                }),
              )
            }}
          />
        )}
        {pendingRefundsAmount !== 0 && (
          <SummaryPartWidget
            amount={pendingRefundsAmountFormatted}
            title={pendingRefundsTitle}
            onClick={() => {
              onExpand(BillingActivitySectionName.INVOICES, {
                invoiceStateIds: [],
                refundStateIds,
              })
              dispatch(
                fetchInvoicePage({
                  clientId,
                  limit: Defaults.INFINITE_LIST_BATCH_LOAD_COUNT,
                  offset: 0,
                  refundStateIds,
                  stateIds: [],
                }),
              )
            }}
          />
        )}
        {showUnappliedSection && (
          <SummaryPartWidget
            amount={unappliedPaymentsAmountFormatted}
            title={unappliedSectionTitle}
            onClick={() => {
              onExpand(BillingActivitySectionName.PAYMENTS, {
                unapplied: true,
                paymentLabelAndAmount: true,
              })
              dispatch(
                fetchPaymentPage({
                  unapplied: true,
                  clientId,
                  limit: Defaults.INFINITE_LIST_BATCH_LOAD_COUNT,
                  offset: 0,
                }),
              )
            }}
          />
        )}
      </Stack>
    </Box>
  )
}
