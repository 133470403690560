import React, { ChangeEvent, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material'
import {
  Button,
  Collapse,
  Divider,
  FormControl,
  Grid,
  Hidden,
  Input,
  InputLabel,
  Theme,
  useMediaQuery,
} from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import * as R from 'ramda'
import {
  AddButton,
  CityTextField,
  CountrySelect,
  Field,
  FieldProp,
  NumberUtils,
  PermissionArea,
  PhoneUtils,
  PuiSelect,
  PuiTextArea,
  PuiTextField,
  RegionUtils,
  StateSelect,
  Text,
  TextInteractive,
  useFields,
  User,
  ZipInput,
} from '@pbt/pbt-ui-components'

import PhoneInput from '~/components/common/form-inputs/PhoneInput'
import ClientEmailTextField from '~/components/common/inputs/ClientEmailTextField'
import PuiSwitch from '~/components/common/PuiSwitch'
import {
  AlertColorLevel,
  DEF_BUSINESS_COLOR_ID,
  useGetAlertColorsWithBusinessDefault,
} from '~/constants/alertColors'
import DialogNames from '~/constants/DialogNames'
import {
  fetchClient,
  updateClient,
  updateClientActivityState,
} from '~/store/actions/clients'
import { deleteCoparent } from '~/store/actions/coparents'
import { useGetContactMethodWithDisabledFlag } from '~/store/hooks/clients'
import { useCreatePatient } from '~/store/hooks/patient'
import {
  getCRUDByArea,
  getCurrentBusinessSendRemindersEnabled,
  getCurrentBusinessWellnessPlansEnabled,
} from '~/store/reducers/auth'
import { getClientIsLoading } from '~/store/reducers/clients'
import { getTag } from '~/store/reducers/constants'
import { getPatientsList } from '~/store/reducers/patients'
import { getMultipleUsers, getUser } from '~/store/reducers/users'
import { AtLeast } from '~/types'
import useDialog from '~/utils/useDialog'

import ChangePetParentDialog from '../change-patient-parent/ChangePetParentDialog'
import CoPetParentDialog from '../pet-friend/CoPetParentDialog'
import EmergencyContactDialog from '../pet-friend/EmergencyContactDialog'
import ReferredBySelect, {
  joinReferredByIds,
  parseReferredByIds,
} from '../ReferredBySelect'
import ClientDetailsLink from './ClientDetailsLink'
import ClientMainDetails from './ClientMainDetails'
import PatientItem from './PatientItem'
import PetFriendDetails from './PetFriendDetails'
import PetFriendItem from './PetFriendItem'

const useStyles = makeStyles(
  (theme) => ({
    clientDetailsContainer: {
      [theme.breakpoints.up('md')]: {
        minHeight: 224,
      },
      [theme.breakpoints.down('md')]: {
        paddingBottom: theme.spacing(1),
        backgroundColor: theme.colors.contentBackground,
      },
      '& > *': {
        padding: theme.spacing(2),
      },
      backgroundColor: theme.colors.profileTopBackground,
      flexShrink: 0,
    },
    notesLabelText: {
      color: theme.colors.profileText,
    },
    clientNotesContainer: {
      paddingTop: 0,
      paddingBottom: 0,
    },
    notesTextContainer: {
      [theme.breakpoints.up('md')]: {
        marginLeft: theme.spacing(6),
      },
    },
    expandDetailsButton: {
      textTransform: 'none',
      marginLeft: 102,
      fontSize: '1.4rem',
      color: theme.colors.profileText,
    },
    primaryText: {
      color: theme.colors.primaryText,
      fontSize: '1.6rem',
      fontWeight: 500,
    },
    collapse: {
      padding: 0,
    },
    clientInfoContainer: {
      minHeight: 'calc(100vh - 100% - 32px)',
      flex: 1,
      padding: theme.spacing(1, 2),
      backgroundColor: '#EFF3F4',
      [theme.breakpoints.down('md')]: {
        backgroundColor: theme.colors.contentBackground,
      },
    },
    detailsContainer: {
      marginTop: 'auto',
      padding: 2.5,
    },
    balanceText: {
      fontSize: '3.6rem',
    },
    balanceContainer: {
      paddingBottom: theme.spacing(1),
    },
    clientPatientsContainer: {
      paddingTop: 30,
    },
    patientDivider: {
      marginTop: 5,
    },
    additionalInfoContainer: {
      backgroundColor: theme.colors.tableLeftColumnBackground,
      padding: theme.spacing(3, 2),
      [theme.breakpoints.down('md')]: {
        paddingTop: 0,
        backgroundColor: theme.colors.contentBackground,
      },
    },
    addText: {
      color: theme.colors.primaryText,
    },
    plusButton: {
      backgroundColor: theme.colors.markerHighlighted,
    },
    addItem: {
      borderRadius: 4,
    },
    balanceLink: {
      textDecoration: 'none',
    },
    textAreaInputMultiline: {
      marginTop: 0,
    },
    textAreaInput: {
      color: theme.colors.tableBackground,
      [theme.breakpoints.down('md')]: {
        color: theme.colors.secondaryText,
      },
    },
    textAreaInputOutline: {
      borderColor: `${theme.colors.searchPicker} !important`,
    },
    textAreaProgress: {
      color: theme.colors.tableBackground,
    },
    additionalClientActionsButton: {
      color: theme.colors.secondaryText,
      fontWeight: 400,
      textTransform: 'none',
      textDecoration: 'underline',
      '&:hover': {
        textDecoration: 'underline',
      },
    },
    wellnessPromotionToggle: {
      margin: theme.spacing(2, 0),
    },
  }),
  { name: 'ClientDetails' },
)

const EMERGENCY_CONTACT_ID = 'EMERGENCY_CONTACT_ID'

interface ClientDetailsProps {
  disablePatientNavigation?: boolean
  hideAddPatient?: boolean
  hideAddPetFriend?: boolean
  hideBalance?: boolean
  hidePatients?: boolean
  patientId?: string
  showAllPatients?: boolean
}

const ClientDetails = ({
  patientId: patientIdProp = '',
  disablePatientNavigation,
  hideAddPetFriend,
  hideAddPatient,
  hideBalance,
  hidePatients,
  showAllPatients,
}: ClientDetailsProps) => {
  const navigate = useNavigate()
  const { clientId = '' } = useParams()
  const classes = useStyles()
  const isSm = useMediaQuery<Theme>((theme) => theme.breakpoints.down('md'))
  const isXs = useMediaQuery<Theme>((theme) => theme.breakpoints.down('sm'))
  const { t } = useTranslation(['Common', 'Clients', 'Tooltips'])

  const dispatch = useDispatch()
  const { createPatient } = useCreatePatient()
  const isLoading = useSelector(getClientIsLoading)
  const clientFromState = useSelector(getUser(clientId))
  const patients = useSelector(getPatientsList(clientFromState?.patients))
  const coparents = useSelector(getMultipleUsers(clientFromState?.coparents!))
  const { create: patientCreatePermissions, update: patientUpdatePermissions } =
    useSelector(getCRUDByArea(PermissionArea.PATIENT))
  const { read: communicationReadPermissions } = useSelector(
    getCRUDByArea(PermissionArea.COMMUNICATION),
  )
  const { read: paymentReadPermissions } = useSelector(
    getCRUDByArea(PermissionArea.PAYMENT),
  )

  const Tag = useSelector(getTag)
  const wellnessPlansEnabled = useSelector(
    getCurrentBusinessWellnessPlansEnabled,
  )
  const showSendRemindersFlag = useSelector(
    getCurrentBusinessSendRemindersEnabled,
  )

  const [petFriendDetailsExpanded, setPetFriendDetailsExpanded] = useState<
    Record<string, boolean>
  >({})
  const [coPetParentDialogOpen, setCoPetParentDialogOpen] = useState(false)
  const [emergencyContactDialogOpen, setEmergencyContactDialogOpen] =
    useState(false)
  const [clientDetailsExpanded, setClientDetailsExpanded] = useState(false)
  const [currentCoparentId, setCurrentCoparentId] = useState<string | null>(
    null,
  )
  const [editedField, setEditedField] = useState<string | null>(null)
  const [editedClient, setEditedClient] = useState<User | null>(null)
  const [changePetParentDialogOpen, setChangePetParentDialogOpen] =
    useState(false)

  const [openClientDetailsEditDialog] = useDialog(
    DialogNames.CLIENT_DETAILS_EDIT,
  )
  const [openPatientDialog] = useDialog(DialogNames.PATIENT)
  const [openPetFriendDialog] = useDialog(DialogNames.PET_FRIEND)

  const client = editedClient || clientFromState || ({} as User)
  const noBalance = R.isNil(client.balance)

  const { fields, validate, touched, reset } = useFields(
    [
      {
        name: 'active',
        label: t('Common:ACTIVE_ONE'),
        type: 'toggle',
        initialValue: client.active || false,
      },
      {
        name: 'notes',
        label: isSm ? t('Common:NOTES') : '',
        initialValue: client.notes || '',
      },
      {
        name: 'email',
        label: t('Common:EMAIL'),
        validators: ['email'],
        initialValue: client.email || '',
      },
      {
        name: 'mobilePhone',
        label: t('Common:MOBILE_PHONE'),
        validators: ['phone'],
        initialValue: client.mobilePhone || '',
      },
      {
        name: 'homePhone',
        label: t('Common:HOME_PHONE'),
        validators: ['phone'],
        initialValue: client.homePhone || '',
      },
      {
        name: 'workPhone',
        label: t('Common:WORK_PHONE'),
        validators: ['phone'],
        initialValue: client.workPhone || '',
      },
      {
        name: 'otherPhone',
        label: t('Common:OTHER_PHONE'),
        validators: ['phone'],
        initialValue: client.otherPhone || '',
      },
      {
        name: 'address',
        label: t('Common:ADDRESS'),
        initialValue: client.address || '',
      },
      {
        name: 'suite',
        label: t('Common:APARTMENT_NUMBER_SIGN'),
        initialValue: client.suite || '',
      },
      {
        name: 'county',
        label: t('Common:COUNTY'),
        initialValue: client.county || '',
      },
      {
        name: 'country',
        label: t('Common:COUNTRY'),
        initialValue: RegionUtils.getAvailableCountry(client.country),
      },
      {
        name: 'city',
        label: t('Common:CITY'),
        initialValue: client.city || '',
      },
      {
        name: 'state',
        label: t('Common:STATE'),
        type: 'select',
        initialValue: client.state || '',
      },
      {
        name: 'zip',
        label: t('Common:ZIP_CODE'),
        validators: ['zip'],
        initialValue: client.zip?.trim() || '',
      },
      {
        name: 'preferredContactMethodId',
        label: t('Common:PREFERRED_CONTACT_METHOD'),
        type: 'select',
        initialValue: client.preferredContactMethodId || '',
      },
      {
        name: 'tag',
        label: t('Common:TAG'),
        type: 'select',
        initialValue: client.tag || '',
      },
      {
        name: 'referredBy',
        label: t('Common:REFERRED_BY'),
        type: 'select',
        initialValue:
          joinReferredByIds(
            client.referralSourceId,
            client.referralSourceTypeId,
          ) || '',
      },
      {
        name: 'sendReminders',
        label: t('Common:SEND_REMINDERS'),
        type: 'toggle',
        initialValue: client.sendReminders || false,
      },
      {
        name: 'shouldNotPromoteWellnessPlans',
        label: t('Common:SHOULD_NOT_PROMOTE_WELLNESS_PLANS'),
        type: 'toggle',
        initialValue: !(client.promoteWellnessPlans ?? true),
      },
      {
        name: 'alertText',
        label: t('Common:CLIENT_ALERT'),
        initialValue: client.alertText,
      },
      {
        name: 'alertColorId',
        label: t('Common:COLOR'),
        initialValue: client.alertColorId || DEF_BUSINESS_COLOR_ID,
      },
    ],
    false,
  )

  const {
    active,
    notes,
    email,
    mobilePhone,
    homePhone,
    workPhone,
    otherPhone,
    address,
    suite,
    county,
    country,
    city,
    state,
    zip,
    preferredContactMethodId,
    tag,
    referredBy,
    sendReminders,
    shouldNotPromoteWellnessPlans,
    alertText,
    alertColorId,
  } = fields

  useEffect(() => {
    if (clientFromState && R.isNil(clientFromState.balance)) {
      dispatch(fetchClient({ clientId: clientFromState.id }))
    }
    if (!touched) {
      reset()
    }
  }, [clientFromState])

  useEffect(() => {
    reset()
    setEditedClient(null)
  }, [clientId])

  useEffect(() => {
    if (editedClient && validate()) {
      const [referredById, referredByType] =
        parseReferredByIds(referredBy.value) || []

      const details = {
        id: editedClient.id!,
        mobilePhone: editedClient.mobilePhone,
        homePhone: editedClient.homePhone,
        workPhone: editedClient.workPhone,
        otherPhone: editedClient.otherPhone,
        email: editedClient.email,
        preferredContactMethodId: editedClient.preferredContactMethodId,
        address: editedClient.address,
        suite: editedClient.suite,
        county: editedClient.county,
        country: editedClient.country,
        city: editedClient.city,
        state: editedClient.state,
        zip: editedClient.zip,
        notes: editedClient.notes,
        emergencyContact: editedClient.emergencyContact,
        tag: tag.value,
        referralSourceId: referredById,
        referralSourceTypeId: referredByType,
        sendReminders: sendReminders.value,
        promoteWellnessPlans: !shouldNotPromoteWellnessPlans.value,
        alertText: alertText.value,
        alertColorId:
          alertColorId.value === DEF_BUSINESS_COLOR_ID
            ? null
            : alertColorId.value,
      }

      dispatch(updateClient(details))
    }
  }, [editedClient])

  const { ContactMethodWithDisabledFlag } =
    useGetContactMethodWithDisabledFlag(clientId)
  const alertColors = useGetAlertColorsWithBusinessDefault(
    AlertColorLevel.CLIENT,
  )

  const patientsArr = showAllPatients
    ? patients
    : patients.filter((patient) => patient && patient.id !== patientIdProp)

  const emergencyContacts = client.emergencyContact
    ? [
        {
          ...client.emergencyContact,
          id: EMERGENCY_CONTACT_ID,
        },
      ]
    : []

  const mapToPetFriendItem =
    (role: string) => (petFriend: AtLeast<User, 'id'>) => ({
      ...petFriend,
      role,
    })

  const petFriends = [
    ...emergencyContacts.map(mapToPetFriendItem(t('Common:EMERGENCY_CONTACT'))),
    ...(coparents || []).map(mapToPetFriendItem(t('Common:CO-PET_PARENT'))),
  ]

  const hasEmergencyContact = emergencyContacts.length > 0

  const onUpdate = (
    event: React.ChangeEvent<HTMLInputElement>,
    field: Field,
    isBool?: boolean,
    changedFields?: Partial<User>,
  ) => {
    field.set(event)
    const value =
      field === mobilePhone ||
      field === homePhone ||
      field === workPhone ||
      field === otherPhone
        ? PhoneUtils.parsePhoneNumber(event.target.value)
        : isBool
        ? event.target.checked
        : event.target.value

    const newClient = {
      ...client,
      [field.name]: value,
      patients: null,
      ...changedFields,
    }

    setEditedField(field.name)

    if (field === active) {
      dispatch(updateClientActivityState(clientId, Boolean(value)))
    } else if (field === referredBy) {
      const [referredById, referredByType] =
        parseReferredByIds(value as string) || []
      setEditedClient({
        ...newClient,
        referralSourceId: referredById,
        referralSourceTypeId: referredByType,
      })
    } else {
      setEditedClient(newClient)
    }
  }

  const onPetFriendDetailsClick = (petFriendId: string) => {
    if (isSm) {
      if (petFriendId === EMERGENCY_CONTACT_ID) {
        navigate(`/client/${clientId}/emergencycontact`)
      } else {
        navigate(`/client/${clientId}/coparent/${petFriendId}`)
      }
    } else {
      setPetFriendDetailsExpanded({
        ...petFriendDetailsExpanded,
        [petFriendId]: !(petFriendId && petFriendDetailsExpanded[petFriendId]),
      })
    }
  }

  const onPetFriendEdit = (petFriendId: string) => {
    if (petFriendId === EMERGENCY_CONTACT_ID) {
      setCurrentCoparentId(null)
      setEmergencyContactDialogOpen(true)
    } else {
      setCurrentCoparentId(petFriendId)
      setCoPetParentDialogOpen(true)
    }
  }

  const onPetFriendDelete = (petFriendId: string) => {
    if (petFriendId === EMERGENCY_CONTACT_ID) {
      dispatch(
        updateClient({
          id: clientId,
          emergencyContact: null,
        }),
      )
    } else {
      dispatch(deleteCoparent(clientId, petFriendId))
    }
  }

  const onPatientDetailsClick = (patientId?: string) => {
    navigate(`/client/${clientId}/patient/${patientId}`)
  }

  const onEditClientDetails = () => {
    if (isXs) {
      navigate(`/client/${clientId}/edit`, {
        state: {
          title: t('Common:CLIENT_DETAILS'),
          renderCoparents: false,
        },
      })
    } else {
      openClientDetailsEditDialog({
        clientId,
      })
    }
  }

  const addPatient = () => {
    if (isXs) {
      navigate(`/client/${clientId}/patient/new`)
    } else {
      openPatientDialog({
        clientId,
        createPatient,
      })
    }
  }

  const handleAddPetFriend = () => {
    if (hasEmergencyContact) {
      if (isXs) {
        navigate(`/client/${clientId}/coparent/new`)
      } else {
        setCoPetParentDialogOpen(true)
      }
    } else if (isXs) {
      navigate(`/client/${clientId}/petfriend`)
    } else {
      openPetFriendDialog({ clientId })
    }
  }

  const getFieldIsLoading = (field: FieldProp) =>
    isLoading && editedField === field.name

  const onSetCountry = (event: ChangeEvent<HTMLInputElement>) => {
    zip.setValue('')
    state.setValue('')
    county.setValue('')
    onUpdate(event, country, false, {
      state: '',
      zip: '',
    })
  }

  const handleChangePetParent = () => {
    setChangePetParentDialogOpen(true)
  }

  const onChangePetParentDialogClose = () => {
    setChangePetParentDialogOpen(false)
  }

  return (
    <>
      <Grid
        container
        item
        className={classes.clientDetailsContainer}
        direction="column"
      >
        <Grid container item wrap="nowrap">
          <ClientMainDetails clientId={clientId} onEdit={onEditClientDetails} />
        </Grid>
        <Grid container item className={classes.clientNotesContainer}>
          <Hidden mdDown>
            <Grid item>
              <Text strong className={classes.notesLabelText}>
                {t('Common:NOTES')}:
              </Text>
            </Grid>
          </Hidden>
          <Grid item xs className={classes.notesTextContainer}>
            <PuiTextArea
              InputProps={{
                classes: {
                  notchedOutline: classes.textAreaInputOutline,
                  input: classes.textAreaInput,
                  multiline: classes.textAreaInputMultiline,
                },
              }}
              classes={{
                progress: classes.textAreaProgress,
              }}
              disabled={!patientUpdatePermissions}
              field={{
                ...notes,
                set: (event) => onUpdate(event, notes),
              }}
              isLoading={getFieldIsLoading(notes)}
              label={notes.label}
              margin="none"
            />
          </Grid>
        </Grid>
        <Hidden mdDown>
          <Grid container item className={classes.detailsContainer}>
            <Button
              className={classes.expandDetailsButton}
              onClick={() => setClientDetailsExpanded(!clientDetailsExpanded)}
            >
              {clientDetailsExpanded
                ? t('Common:LESS_CLIENT_DETAILS')
                : t('Common:MORE_CLIENT_DETAILS')}
              {clientDetailsExpanded ? (
                <KeyboardArrowUp />
              ) : (
                <KeyboardArrowDown />
              )}
            </Button>
          </Grid>
        </Hidden>
      </Grid>
      <Collapse className={classes.collapse} in={clientDetailsExpanded}>
        <Grid
          container
          item
          alignItems="center"
          className={classes.additionalInfoContainer}
          columnSpacing={3}
        >
          <Grid item xs={5}>
            <PuiSwitch
              disabled={!patientUpdatePermissions || getFieldIsLoading(active)}
              field={{
                ...active,
                set: (event) => onUpdate(event, active, true),
              }}
              label={
                active.value ? t('Common:ACTIVE_ONE') : t('Common:INACTIVE_ONE')
              }
            />
          </Grid>
          {showSendRemindersFlag && (
            <Grid item xs={7}>
              <PuiSwitch
                disabled={
                  !patientUpdatePermissions || getFieldIsLoading(sendReminders)
                }
                field={{
                  ...sendReminders,
                  set: (event) => onUpdate(event, sendReminders, true),
                }}
                label={sendReminders.label}
              />
            </Grid>
          )}
          <Grid item xs={12}>
            <ClientEmailTextField
              client={client}
              disabled={!patientUpdatePermissions}
              field={{
                ...email,
                set: (event) => onUpdate(event, email),
              }}
              isLoading={getFieldIsLoading(email)}
              label={email.label}
            />
          </Grid>
          <Grid item xs={6}>
            <PhoneInput
              disabled={!patientUpdatePermissions}
              field={{
                ...mobilePhone,
                set: (event) => onUpdate(event, mobilePhone),
              }}
            />
          </Grid>
          <Grid item xs={6}>
            <PhoneInput
              disabled={!patientUpdatePermissions}
              field={{
                ...homePhone,
                set: (event) => onUpdate(event, homePhone),
              }}
            />
          </Grid>
          <Grid item xs={6}>
            <PhoneInput
              disabled={!patientUpdatePermissions}
              field={{
                ...workPhone,
                set: (event) => onUpdate(event, workPhone),
              }}
            />
          </Grid>
          <Grid item xs={6}>
            <PhoneInput
              disabled={!patientUpdatePermissions}
              field={{
                ...otherPhone,
                set: (event) => onUpdate(event, otherPhone),
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <PuiTextField
              disabled={!patientUpdatePermissions}
              field={{
                ...address,
                set: (event) => onUpdate(event, address),
              }}
              inputProps={{ maxLength: 100 }}
              isLoading={getFieldIsLoading(address)}
              label={address.label}
            />
          </Grid>
          <Grid item xs={12}>
            <PuiTextField
              disabled={!patientUpdatePermissions}
              field={{
                ...suite,
                set: (event) => onUpdate(event, suite),
              }}
              inputProps={{ maxLength: 100 }}
              isLoading={getFieldIsLoading(suite)}
              label={suite.label}
            />
          </Grid>
          <Grid item xs={12}>
            <PuiTextField
              disabled={!patientUpdatePermissions}
              field={{
                ...county,
                set: (event) => onUpdate(event, county),
              }}
              inputProps={{ maxLength: 100 }}
              isLoading={getFieldIsLoading(county)}
              label={county.label}
            />
          </Grid>
          <Grid item xs={6}>
            <CityTextField
              country={country.value}
              disabled={!patientUpdatePermissions}
              field={{
                ...city,
                set: (event) => onUpdate(event, city),
              }}
              isLoading={getFieldIsLoading(city)}
            />
          </Grid>
          <Grid item xs={6}>
            <CountrySelect
              disabled={!patientUpdatePermissions}
              field={{
                ...country,
                set: onSetCountry,
              }}
              isLoading={getFieldIsLoading(country)}
            />
          </Grid>
          <Grid item xs={6}>
            <StateSelect
              country={country.value}
              disabled={!patientUpdatePermissions}
              field={{
                ...state,
                set: (event) => onUpdate(event, state),
              }}
              isLoading={getFieldIsLoading(state)}
            />
          </Grid>
          <Grid item xs={6}>
            <PuiTextField
              InputProps={{
                inputComponent: ZipInput,
                inputProps: { country: country.value },
              }}
              disabled={!patientUpdatePermissions}
              field={{
                ...zip,
                set: (event) => onUpdate(event, zip),
              }}
              isLoading={getFieldIsLoading(zip)}
              label={zip.label}
            />
          </Grid>
          <Grid item xs={12}>
            <FormControl fullWidth margin="normal">
              <InputLabel htmlFor="contact-method-select">
                {preferredContactMethodId.label}
              </InputLabel>
              <PuiSelect
                disabled={!patientUpdatePermissions}
                field={{
                  ...preferredContactMethodId,
                  set: (event) => onUpdate(event, preferredContactMethodId),
                }}
                items={ContactMethodWithDisabledFlag}
              />
            </FormControl>
          </Grid>
          <Grid item md={6} xs={6}>
            <FormControl fullWidth margin="normal">
              <InputLabel htmlFor="tags-select">{tag.label}</InputLabel>
              <PuiSelect
                disabled={!patientUpdatePermissions}
                field={{
                  ...tag,
                  set: (event) => onUpdate(event, tag),
                }}
                input={<Input id="tags-select" />}
                items={Tag}
              />
            </FormControl>
          </Grid>
          <Grid item md={6} xs={6}>
            <FormControl fullWidth margin="normal">
              <InputLabel htmlFor="referred-by-select">
                {referredBy.label}
              </InputLabel>
              <ReferredBySelect
                disabled={!patientUpdatePermissions}
                field={{
                  ...referredBy,
                  set: (event) => onUpdate(event, referredBy),
                }}
                input={<Input id="referred-by-select" />}
              />
            </FormControl>
          </Grid>
          <Grid item md={6} xs={6}>
            <PuiTextField
              field={{
                ...alertText,
                set: (event) => onUpdate(event, alertText),
              }}
              label={alertText.label}
              margin="none"
            />
          </Grid>
          <Grid item md={6} xs={6}>
            <FormControl fullWidth margin="normal">
              <InputLabel htmlFor="alert-color-select">
                {t('Common:ALERT_COLOR')}
              </InputLabel>
              <PuiSelect
                field={{
                  ...alertColorId,
                  set: (event) => onUpdate(event, alertColorId),
                }}
                input={<Input id="alert-color-select" />}
                items={alertColors}
                label={alertColorId.label}
                renderEmpty={false}
              />
            </FormControl>
          </Grid>
          {client.externalPersonId && (
            <Grid item xs={12}>
              <PuiTextField
                disabled
                InputProps={{
                  disableUnderline: true,
                }}
                label={t('Clients:CLIENT_DETAILS.IMPORTED_CLIENT_ID')}
                margin="none"
                value={client.externalPersonId}
              />
            </Grid>
          )}
        </Grid>
      </Collapse>
      <Grid
        container
        item
        className={classes.clientInfoContainer}
        direction="column"
        wrap="nowrap"
      >
        {!hideBalance && (
          <>
            <Grid item className={classes.balanceContainer}>
              {paymentReadPermissions && (
                <ClientDetailsLink
                  actionComponent={
                    <TextInteractive
                      align="left"
                      className={classes.balanceText}
                      isLoading={noBalance}
                      loaderHeight={54}
                      loaderWidth={100}
                      variant="hero"
                    >
                      {NumberUtils.formatMoney(client.balance)}
                    </TextInteractive>
                  }
                  label={t('Common:BALANCE')}
                  to={`/balance/${clientId}/`}
                />
              )}
              {communicationReadPermissions && (
                <ClientDetailsLink
                  label={t('Common:COMMUNICATIONS')}
                  to={`/communications/client/${clientId}/`}
                />
              )}
              {wellnessPlansEnabled && (
                <ClientDetailsLink
                  label={t('Common:WELLNESS_PLANS')}
                  to={`/membership/${clientId}/`}
                />
              )}
            </Grid>
            <Divider />
          </>
        )}
        {wellnessPlansEnabled && (
          <Grid>
            <PuiSwitch
              className={classes.wellnessPromotionToggle}
              disabled={
                !patientUpdatePermissions ||
                getFieldIsLoading(shouldNotPromoteWellnessPlans)
              }
              field={{
                ...shouldNotPromoteWellnessPlans,
                set: (event) =>
                  onUpdate(event, shouldNotPromoteWellnessPlans, true),
              }}
              label={shouldNotPromoteWellnessPlans.label}
            />
            <Divider />
          </Grid>
        )}
        <Grid>
          <Grid container item justifyContent="center" spacing={2}>
            {!hideAddPatient && patientCreatePermissions && (
              <Grid container item my={2} xs={6}>
                <AddButton
                  addText={t('Common:ADD_PATIENT')}
                  classes={{
                    addText: classes.addText,
                    plusButton: classes.plusButton,
                    addItem: classes.addItem,
                  }}
                  variant="dashed"
                  onAdd={addPatient}
                />
              </Grid>
            )}
            {!hideAddPetFriend && patientUpdatePermissions && (
              <Grid container item my={2} xs={6}>
                <AddButton
                  addText={t('Common:ADD_PET_FRIEND')}
                  classes={{
                    addText: classes.addText,
                    plusButton: classes.plusButton,
                    addItem: classes.addItem,
                  }}
                  variant="dashed"
                  onAdd={handleAddPetFriend}
                />
              </Grid>
            )}
          </Grid>
          {(!hideAddPatient || !hideAddPetFriend) && <Divider />}
        </Grid>
        {!hidePatients && patientsArr.length > 0 && (
          <Grid item className={classes.clientPatientsContainer}>
            <Text strong align="left" mb={5} variant="subheading2">
              {showAllPatients
                ? t('Common:PATIENTS')
                : t('Common:OTHER_PATIENTS')}
            </Text>
            {patientsArr.map((patient) => (
              <React.Fragment key={patient.id}>
                <PatientItem
                  disableNavigation={disablePatientNavigation}
                  patientId={patient.id!}
                  onClick={onPatientDetailsClick}
                />
                <Divider className={classes.patientDivider} />
              </React.Fragment>
            ))}
          </Grid>
        )}
        {petFriends.length > 0 && (
          <Grid item className={classes.clientPatientsContainer}>
            <Text strong align="left" mb={5} variant="subheading2">
              {t('Common:PET_FRIENDS')}:
            </Text>
            {petFriends.map((petFriend) => (
              <React.Fragment key={petFriend.id}>
                <PetFriendItem
                  {...petFriend}
                  onDelete={onPetFriendDelete}
                  onEdit={onPetFriendEdit}
                  onToggle={onPetFriendDetailsClick}
                />
                {!isXs && (
                  <Collapse
                    className={classes.collapse}
                    in={petFriendDetailsExpanded[petFriend.id]}
                  >
                    <PetFriendDetails
                      isEmergencyContact={petFriend.id === EMERGENCY_CONTACT_ID}
                      petFriend={petFriend}
                    />
                  </Collapse>
                )}
                <Divider className={classes.patientDivider} />
              </React.Fragment>
            ))}
          </Grid>
        )}
        {patientIdProp && (
          <Grid container item>
            <Button
              className={classes.additionalClientActionsButton}
              onClick={handleChangePetParent}
            >
              {t('Common:CHANGE_PET_PARENT')}
            </Button>
          </Grid>
        )}
      </Grid>
      {clientId && (
        <CoPetParentDialog
          clientId={clientId}
          coparentId={currentCoparentId}
          open={coPetParentDialogOpen}
          onClose={() => {
            setCoPetParentDialogOpen(false)
            setCurrentCoparentId(null)
          }}
        />
      )}
      {clientId && (
        <EmergencyContactDialog
          clientId={clientId}
          open={emergencyContactDialogOpen}
          onClose={() => setEmergencyContactDialogOpen(false)}
        />
      )}
      <ChangePetParentDialog
        clientId={clientId}
        open={changePetParentDialogOpen}
        patientId={patientIdProp}
        onAddClientDetails={onEditClientDetails}
        onClose={onChangePetParentDialogClose}
      />
    </>
  )
}

export default ClientDetails
