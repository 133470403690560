import * as R from 'ramda'
import { Constant, Nil, Utils } from '@pbt/pbt-ui-components'

import { OrderType, ReadyToOrderLabStatuses } from '~/constants/SOAPStates'
import { ChargeSheetItemSection, InvoiceLineItem, Order } from '~/types'
import { convertInvoiceLineItemToSoapLog } from '~/utils/orderUtils'

export enum LabTestGroupKeys {
  READY_FOR_ORDER = 'READY_FOR_ORDER',
  NOT_READY_FOR_ORDER = 'NOT_READY_FOR_ORDER',
}

const getDefaultStructure = () => ({
  [LabTestGroupKeys.READY_FOR_ORDER]: [],
  [LabTestGroupKeys.NOT_READY_FOR_ORDER]: [],
})

const getOrderSatisfactoryStatesIds = (LabTestsStates: Constant[]) =>
  ReadyToOrderLabStatuses.map((name) =>
    Utils.findConstantIdByName(name, LabTestsStates),
  )

const isReadyForOrderState = (LabTestsStates: Constant[], stateId?: string) =>
  getOrderSatisfactoryStatesIds(LabTestsStates).includes(stateId)

export const getGroupKey = (LabTestsStates: Constant[], item: Order) => {
  const { labTest, stateId } = item

  const isReadyForOrder = isReadyForOrderState(LabTestsStates, stateId)
  return (
    labTest?.labOrderId ||
    (isReadyForOrder
      ? LabTestGroupKeys.READY_FOR_ORDER
      : LabTestGroupKeys.NOT_READY_FOR_ORDER)
  )
}

export const getGroupKeyPatient = (LabTestsStates: Constant[], item: Order) =>
  `${item.soapId}_${item.patientId}`

export const groupLabTests = (LabTestsStates: Constant[], items: Order[]) => {
  const labTests = items.filter(
    ({ type, labTest }) => type === OrderType.LAB_TEST && Boolean(labTest),
  )

  return labTests.reduce((acc, item) => {
    const vendorId = item.labTest?.vendorId as string
    acc[vendorId] = acc[vendorId] || getDefaultStructure()
    const groupKey = getGroupKey(LabTestsStates, item)
    acc[vendorId][groupKey] = [...(acc[vendorId][groupKey] || []), item]
    return acc
  }, {} as Record<string, Record<LabTestGroupKeys | string, Order[]>>)
}

const isLabTestNotDeclinedNotUsed = (item: InvoiceLineItem) =>
  item.logType === OrderType.LAB_TEST &&
  !item.declined &&
  ((item.prepaid && !R.isNil(item.usedQuantity) && item.usedQuantity > 0) ||
    !item.prepaid)

export const groupLabTestsByPatients = (
  LabTestsStates: Constant[],
  sections: ChargeSheetItemSection[],
) => {
  const itemsByVendorSectionItemMap: Record<
    string,
    Record<string, Record<LabTestGroupKeys | string, Order[]>>
  > = sections.reduce((acc, section) => {
    section.groupedItems
      .flatMap(
        (groupedItems) => R.prop('items', groupedItems) || [groupedItems],
      )
      .filter((item) => isLabTestNotDeclinedNotUsed(item))
      .map((item) => convertInvoiceLineItemToSoapLog(item))
      .forEach((item) => {
        const vendorId = item.labTest?.vendorId as string
        acc[vendorId] = acc[vendorId] || {
          [section.id]: {},
        }
        acc[vendorId][section.id] =
          acc[vendorId][section.id] || getDefaultStructure()

        const groupKey = getGroupKey(LabTestsStates, item)
        acc[vendorId][section.id][groupKey] = [
          ...(acc[vendorId][section.id][groupKey] || []),
          item,
        ]
        return acc
      })
    return acc
  }, {} as Record<string, Record<string, Record<LabTestGroupKeys | string, Order[]>>>)
  return itemsByVendorSectionItemMap
}

export const hasNotOrderedLabTests = (
  labTestsObj: Record<string, object[]> | Nil,
) => labTestsObj && labTestsObj[LabTestGroupKeys.READY_FOR_ORDER]?.length > 0
