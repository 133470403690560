import * as API from '~/api'

import createDuck from './duck-generators/createDuck'
import listDuck from './duck-generators/list'

const patientMembershipsDuck = createDuck({
  name: 'patientMemberships',
  duck: listDuck,
  apiEndpoints: (types) => ({
    [types.FETCH_LIST]: API.fetchPatientMembershipsList,
    [types.FETCH_MORE_ITEMS_FOR_LIST]: API.fetchPatientMembershipsList,
    [types.REFRESH_LIST]: API.fetchPatientMembershipsList,
    [types.FETCH_ITEM]: API.fetchPatientMembership,
    [types.FETCH_PAUSED_LIST]: API.fetchPausedPatientMembershipsList,
  }),
})

export const patientMembershipsReducer = patientMembershipsDuck.reducer
export const patientMembershipsSaga = patientMembershipsDuck.saga
export const patientMembershipsSelectors = patientMembershipsDuck.selectors
export const patientMembershipsActions = patientMembershipsDuck.actions
