import React from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { Checkbox, Grid, IconButton } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import classNames from 'classnames'
import { DateUtils, HtmlNotesPreview, Text } from '@pbt/pbt-ui-components'
import { AddNote, Edit as EditIcon } from '@pbt/pbt-ui-components/src/icons'

import Link from '~/components/common/link/Link'
import DialogNames from '~/constants/DialogNames'
import FeatureToggle from '~/constants/featureToggle'
import { TaskContext } from '~/constants/taskConstants'
import { partialEditOrder } from '~/store/actions/orders'
import { fetchTask, partialEditTask } from '~/store/actions/tasks'
import { editChargeSheetOrder } from '~/store/duck/clientFinanceData'
import { useIsOrderDeclined } from '~/store/hooks/orders'
import { useGetInvoicePosted } from '~/store/hooks/soap'
import { getFeatureToggle } from '~/store/reducers/constants'
import { getOrdersIsSendingOrReceiving } from '~/store/reducers/orders'
import { getIsCurrentContextSoap, getIsFinalized } from '~/store/reducers/soap'
import { getTask, getTasksIsLoading } from '~/store/reducers/tasks'
import { Order, Task } from '~/types'
import { getIsCreatedPrescriptionChewyActiveRx } from '~/utils/prescription'
import useDialog from '~/utils/useDialog'

import TaskStateButtonMesh from '../../tasks-dashboard/mesh/TaskStateButtonMesh'
import TaskStatusSelect from '../../tasks-dashboard/TaskStatusSelect'
import OrderStatusSelect from './OrderStatusSelect'

const useStyles = makeStyles(
  (theme) => ({
    row: {
      borderBottom: theme.constants.tabBorder,
      maxWidth: `calc(100vw - ${theme.spacing(15)})`,
    },
    dueDateContainer: {
      minWidth: 100,
    },
    link: {
      color: theme.colors.secondaryText,
    },
    iconButton: {
      padding: 0,
    },
    divider: {
      width: 1,
      height: 16,
      backgroundColor: theme.colors.sideText,
      margin: '0 2px',
    },
    editIcon: {
      color: theme.colors.editIconColor,
    },
    checkbox: {
      marginRight: theme.spacing(0.5),
      padding: theme.spacing(0.5),
    },
    hidden: {
      visibility: 'hidden',
    },
    statusSelect: {
      marginRight: theme.spacing(1.5),
    },
  }),
  { name: 'ToDoTasksListItemRow' },
)

export type ToDoTasksListItemRowProps = {
  checkedOrderIds: string[]
  checkedTaskIds: string[]
  disabled?: boolean
  hasRecurringTasks?: boolean
  isOrderItem?: boolean
  item: Task | Order
  itemId: string
  itemIndex: number
  onCheckOrder: (order: Order) => void
  onCheckTask: (task: Task) => void
  rowRef: React.RefObject<HTMLDivElement>[]
  scrollOffset: number
  timeColumns: string[]
}

const ToDoTasksListItemRow = ({
  item,
  itemId,
  disabled,
  isOrderItem,
  checkedOrderIds,
  checkedTaskIds,
  hasRecurringTasks,
  timeColumns,
  scrollOffset,
  itemIndex,
  rowRef,
  onCheckTask,
  onCheckOrder,
}: ToDoTasksListItemRowProps) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const { t } = useTranslation('Common')

  const isTaskOptimizationEnabled = useSelector(
    getFeatureToggle(FeatureToggle.TASK_OPTIMIZATION),
  )
  const isEditPostedChargesEnabled = useSelector(
    getFeatureToggle(FeatureToggle.EDIT_POSTED_CHARGES),
  )

  const { children } = item as Task

  const childTaskId = isOrderItem ? undefined : children && children[0]

  const childTask = useSelector(getTask(childTaskId))
  const isSoapFinalized = useSelector(getIsFinalized)
  const isInvoicePosted = useGetInvoicePosted()
  const isCurrentContextSoap = useSelector(getIsCurrentContextSoap)

  const [openTaskDialog] = useDialog(DialogNames.TASK)
  const [openNotesDialog] = useDialog(DialogNames.NOTES)
  const isOrderDeclined = useIsOrderDeclined(
    isOrderItem ? (item as Order) : null,
  )

  const handleNoteUpdate = () => {
    openNotesDialog({
      name: item.name,
      notes: item.notes,
      soapId: item.soapId,
      onUpdateNotes: (notes: string) => {
        if (isOrderItem) {
          if (isEditPostedChargesEnabled && isInvoicePosted) {
            dispatch(
              editChargeSheetOrder({
                id: item.id,
                type: (item as Order).type,
                order: {
                  notes,
                },
                soapLogModificationDate: item.modificationDate || '',
              }),
            )
          } else {
            dispatch(
              partialEditOrder({
                id: item.id,
                type: (item as Order).type,
                notes,
              }),
            )
          }
        } else {
          dispatch(partialEditTask({ ...(item as Task), notes }))
        }
      },
      isLoadingSelector: isOrderItem
        ? getOrdersIsSendingOrReceiving
        : getTasksIsLoading,
      hidePlusButtonBlock: false,
    })
  }

  const openEditDialog = () => {
    if (isTaskOptimizationEnabled) {
      dispatch(fetchTask(item.id))
    }

    openTaskDialog({
      taskId: item.id,
      shortTaskFlow: true,
      soapId: item.soapId || undefined,
    })
  }

  const checkedItems = isOrderItem ? checkedOrderIds : checkedTaskIds
  const isChewyActiveRx =
    isOrderItem &&
    getIsCreatedPrescriptionChewyActiveRx(
      (item as Order).prescriptionType,
      (item as Order).origin,
    )
  const isReadOnly = isSoapFinalized || !isCurrentContextSoap || isChewyActiveRx
  const itemName = isOrderDeclined
    ? `${item.name} (${t('Common:DECLINED').toLowerCase()})`
    : item.name

  return (
    <Grid container alignItems="flex-start" className={classes.row}>
      <Grid container item xs direction="column" pl={0.5} wrap="nowrap">
        <Grid container item alignItems="center" wrap="nowrap">
          <Checkbox
            checked={checkedItems.includes(itemId)}
            className={classes.checkbox}
            disabled={disabled || isChewyActiveRx}
            onClick={() =>
              isOrderItem
                ? onCheckOrder(item as Order)
                : onCheckTask(item as Task)
            }
          />
          {isOrderItem ? (
            <Text disabled={isOrderDeclined} variant="body">
              {isChewyActiveRx
                ? `${t('Common:CHEWY_BRAND_NAME')}—${itemName}`
                : itemName}
            </Text>
          ) : (
            <Link className={classes.link} to={`/tasks-dashboard/${item.id}`}>
              {item.name}
            </Link>
          )}
        </Grid>
        {item.notes && (
          <Grid item pl={4.5}>
            <HtmlNotesPreview
              includeLabel
              fontSize="1.4rem"
              notes={item.notes}
            />
          </Grid>
        )}
      </Grid>
      <Grid item mt={0.25} xs={2}>
        {isOrderItem ? (
          <OrderStatusSelect
            className={classes.statusSelect}
            disabled={disabled || isReadOnly}
            order={item as Order}
          />
        ) : (
          <TaskStatusSelect
            className={classes.statusSelect}
            disabled={disabled || isReadOnly}
            task={item as Task}
          />
        )}
      </Grid>
      <Grid
        container
        item
        alignItems="center"
        className={classes.dueDateContainer}
        minHeight={28}
        mt={0.25}
        xs={hasRecurringTasks ? 1 : 3}
      >
        <Text variant="body">
          {isOrderItem
            ? ' '
            : hasRecurringTasks
            ? DateUtils.formatDate(
                childTask ? childTask.dueDate : (item as Task).dueDate,
              )
            : DateUtils.formatDateWithHours((item as Task).dueDate, true)}
        </Text>
      </Grid>
      <Grid
        container
        item
        alignItems="center"
        justifyContent="flex-end"
        minHeight={28}
        mt={0.25}
        pr={0.5}
        xs={1}
      >
        {!isReadOnly && (
          <>
            <IconButton
              className={classNames(classes.iconButton, classes.editIcon, {
                [classes.hidden]: isOrderItem,
              })}
              disabled={disabled}
              size="large"
              onClick={openEditDialog}
            >
              <EditIcon />
            </IconButton>
            {!isOrderItem && <div className={classes.divider} />}
            <IconButton
              className={classes.iconButton}
              disabled={disabled}
              size="large"
              onClick={handleNoteUpdate}
            >
              <AddNote filled={Boolean(item.notes)} />
            </IconButton>
          </>
        )}
      </Grid>
      {hasRecurringTasks && (
        <TaskStateButtonMesh
          columns={timeColumns}
          context={TaskContext.SOAP}
          ref={rowRef[itemIndex]}
          scrollOffset={scrollOffset}
          taskId={itemId}
        />
      )}
    </Grid>
  )
}

export default ToDoTasksListItemRow
