import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
} from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import {
  FormControl,
  FormControlLabel,
  Grid,
  Input,
  InputLabel,
  Radio,
  RadioGroup,
} from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import {
  Nil,
  PuiSelect,
  PuiTextArea,
  PuiTextField,
  TaskTemplateTeamOption,
  Text,
  useFields,
  Utils,
} from '@pbt/pbt-ui-components'

import RequiredFieldsNotice from '~/components/common/inputs/RequiredFieldsNotice'
import FeatureToggle from '~/constants/featureToggle'
import { fetchTeamsList } from '~/store/actions/members'
import { useCreatableTaskTypes } from '~/store/hooks/tasks'
import {
  getCurrentBusiness,
  getCurrentBusinessWellnessPlansEnabled,
} from '~/store/reducers/auth'
import {
  getFeatureToggle,
  getTaskTemplateOptions,
} from '~/store/reducers/constants'
import { getTeamsList } from '~/store/reducers/members'
import { getMultipleUsers } from '~/store/reducers/users'
import { DataHandleWithUnsavedChanges, Task } from '~/types'
import { isFieldValuesChanged } from '~/utils'
import useIsCurrentContextItem from '~/utils/useIsCurrentContextItem'

import BundleTaskRecurrenceSettings, {
  BundleTaskRecurrenceSettingsHandle,
} from './BundleTaskRecurrenceSettings'

const useStyles = makeStyles(
  (theme) => ({
    root: {},
    radioLabel: {
      fontSize: '1.6rem',
      color: theme.colors.secondaryText,
    },
  }),
  { name: 'Task' },
)

export interface BundleTaskHandle extends DataHandleWithUnsavedChanges<Task> {}

interface BundleTaskProps {
  soapId?: string
  task: Task | Nil
  view?: boolean
}

const BundleTask = forwardRef<BundleTaskHandle, BundleTaskProps>(
  function BundleTask({ soapId: soapIdProp, task, view }, ref) {
    const classes = useStyles()
    const dispatch = useDispatch()
    const { t } = useTranslation(['Admin', 'Common'])

    const TaskTemplateOptions = useSelector(getTaskTemplateOptions)
    const teamsList = useSelector(getTeamsList)
    const teams = useSelector(getMultipleUsers(teamsList))
    const teamOption = Utils.findConstantIdByName(
      TaskTemplateTeamOption.SelectedTeam,
      TaskTemplateOptions.team || [],
    )
    const isTeamsPhase2Enabled = useSelector(
      getFeatureToggle(FeatureToggle.TEAMS_PHASE_2),
    )

    const taskTemplateTeamOptions = [
      ...(TaskTemplateOptions.teamMember?.slice(0, -1) || []),
      ...(TaskTemplateOptions.teamMember
        ? [
            {
              ...TaskTemplateOptions.teamMember[
                TaskTemplateOptions.teamMember.length - 1
              ],
              divider: true,
            },
          ]
        : []),
      {
        name: teams.length > 0 ? t('Common:TEAMS') : t('Common:LOADING_TEAMS'),
        subheader: true,
        id: 'teamsSubheader',
      },
      ...teams.map((team) => ({
        name: team.firstName || '',
        nameTranslation: team.firstName,
        id: team.id || '',
        enabled: true,
      })),
    ]

    const business = useSelector(getCurrentBusiness)
    const wellnessPlansEnabled = useSelector(
      getCurrentBusinessWellnessPlansEnabled,
    )
    const isBoopDisablementEnabled = useSelector(
      getFeatureToggle(FeatureToggle.BOOP_DISABLEMENT),
    )

    const isContextItem = useIsCurrentContextItem(task)
    const CreatableTaskTypes = useCreatableTaskTypes()

    const taskRecurrenceSettingsRef =
      useRef<BundleTaskRecurrenceSettingsHandle>(null)

    const OtherType = Utils.findConstantIdByName('Other', CreatableTaskTypes)
    const soapId = soapIdProp || task?.soapId

    useEffect(() => {
      if (business?.id && teams.length === 0) {
        dispatch(
          fetchTeamsList({ businessId: business?.id, includeInactive: true }),
        )
      }
    }, [business?.id, teams.length])

    const { fields, validate, reset } = useFields(
      [
        { name: 'forClient', type: 'toggle', initialValue: task?.forClient },
        {
          name: 'name',
          label: t('Common:NAME'),
          validators: ['required'],
          initialValue: task?.name || '',
        },
        {
          name: 'typeId',
          label: t('Common:TYPE_ONE'),
          initialValue: task?.typeId || OtherType,
        },
        {
          name: 'teamMemberOptionId',
          type: 'select',
          label: t('Common:ASSIGN_TEAM_MEMBER'),
          initialValue: task?.teamMemberOptionId || task?.teamId || null,
        },
        {
          name: 'notes',
          label: t('Common:NOTES'),
          initialValue: task?.notes || '',
        },
        {
          name: 'instructions',
          label: t('Common:INSTRUCTIONS_FOR_PET_PARENT'),
          initialValue: task?.instructions || '',
        },
      ],
      false,
    )

    const { forClient, name, typeId, teamMemberOptionId, notes, instructions } =
      fields

    useEffect(() => {
      reset()
    }, [task])

    const createTask = () =>
      ({
        ...(task || {}),
        forClient: forClient.value,
        name: name.value,
        typeId: typeId.value,
        notes: notes.value,
        ...(soapId ? { soapId } : {}),
        ...(forClient.value
          ? { instructions: instructions.value }
          : teamsList.includes(teamMemberOptionId.value)
          ? { teamId: teamMemberOptionId.value, teamOptionId: teamOption }
          : { teamMemberOptionId: teamMemberOptionId.value }),
        ...(taskRecurrenceSettingsRef?.current?.get() || {}),
      } as Task)

    const validateTaskRecurrenceSettings = () =>
      taskRecurrenceSettingsRef.current
        ? taskRecurrenceSettingsRef.current.validate()
        : true

    useImperativeHandle(ref, () => ({
      validate: () => validate() && validateTaskRecurrenceSettings(),
      get: createTask,
      hasUnsavedChanges: () => {
        const fieldsChanged = isFieldValuesChanged(fields)
        const recurrenceSettingsChanged =
          taskRecurrenceSettingsRef.current?.hasUnsavedChanges() ?? false

        return fieldsChanged || recurrenceSettingsChanged
      },
    }))

    return (
      <Grid
        container
        item
        alignItems="flex-end"
        className={classes.root}
        columnSpacing={3}
        pb={view ? 0 : 3}
        px={3}
      >
        {!view && wellnessPlansEnabled && (
          <Grid container item>
            <Text strong mt={1} variant="body2">
              {t('Common:WORKFLOW_ONE')}
            </Text>
            <Grid container>
              <RadioGroup
                row
                aria-label="workflow-radio-group"
                name="workflow-radio-group"
                value={Boolean(forClient.value)}
                onChange={() => forClient.setValue(!forClient.value)}
              >
                <FormControlLabel
                  classes={{ label: classes.radioLabel }}
                  control={<Radio />}
                  disabled={!isContextItem}
                  label={t('Common:INTERNAL')}
                  value={false}
                />
                <FormControlLabel
                  value
                  classes={{ label: classes.radioLabel }}
                  control={<Radio />}
                  disabled={!isContextItem}
                  label={
                    isBoopDisablementEnabled
                      ? t('Common:CLIENT')
                      : t('Common:BOOP_SYSTEM_NAME_PET_PARENT')
                  }
                />
              </RadioGroup>
            </Grid>
          </Grid>
        )}
        <Grid item xs={6}>
          <PuiTextField
            disabled={!isContextItem}
            field={name}
            inputProps={{ maxLength: 100 }}
            label={`${name.label}*`}
          />
        </Grid>
        <Grid item xs={6}>
          <FormControl fullWidth margin="normal">
            <InputLabel htmlFor="bundle-task-type-select">
              {typeId.label}
            </InputLabel>
            <PuiSelect
              disabled={!isContextItem}
              field={typeId}
              input={<Input id="bundle-task-type-select" />}
              items={CreatableTaskTypes}
              renderEmpty={false}
            />
          </FormControl>
        </Grid>
        <BundleTaskRecurrenceSettings
          ref={taskRecurrenceSettingsRef}
          task={task}
        />
        {forClient.value ? (
          <Grid item>
            <Text mt={2} variant="body">
              {t('Admin:CATALOG.BUNDLE_TASK.NOTE')}
            </Text>
          </Grid>
        ) : (
          <Grid item xs={6}>
            <FormControl fullWidth margin="normal">
              <InputLabel htmlFor="bundle-task-team-member-select">
                {teamMemberOptionId.label}
              </InputLabel>
              <PuiSelect
                disabled={!isContextItem}
                field={teamMemberOptionId}
                input={<Input id="bundle-task-team-member-select" />}
                items={
                  isTeamsPhase2Enabled
                    ? taskTemplateTeamOptions
                    : TaskTemplateOptions.teamMember
                }
                renderEmpty={false}
              />
            </FormControl>
          </Grid>
        )}
        <Grid container item mt={2}>
          <PuiTextArea
            disabled={!isContextItem}
            field={notes}
            label={
              forClient.value ? t('Common:INTERNAL_NOTES') : t('Common:NOTES')
            }
            margin="none"
            showColon={false}
          />
        </Grid>
        {forClient.value && (
          <Grid container item mt={2}>
            <PuiTextArea
              disabled={!isContextItem}
              field={instructions}
              label={instructions.label}
              margin="none"
              rows={2}
              showColon={false}
            />
          </Grid>
        )}
        <Grid container item direction="column" mt={1}>
          <RequiredFieldsNotice />
        </Grid>
      </Grid>
    )
  },
)

export default BundleTask
