import React, { Suspense, useState } from 'react'
import { ErrorBoundary } from 'react-error-boundary'
import { useTranslation } from 'react-i18next'
import { Grid, Input, LinearProgress } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import {
  ButtonWithLoader,
  Constant,
  CopyToClipboard,
  LinkButton,
  Nil,
  PuiSelect,
  Utils,
} from '@pbt/pbt-ui-components'

import DialogNames from '~/constants/DialogNames'
import { DymoLabelType } from '~/constants/dymo'
import useDialog from '~/utils/useDialog'
import useIsDymoConnectEnabled from '~/utils/useIsDymoEnabled'

import { sendGoogleAnalyticsEvent } from '../GoogleAnalytics'
import DymoLabelPrint from './DymoLabelPrint'
import LabelSelect from './LabelSelect'

const useStyles = makeStyles(
  (theme) => ({
    buttons: {
      marginTop: theme.spacing(3),
    },
    button: {
      height: 40,
      minWidth: 124,
      marginRight: theme.spacing(2),
    },
    printerInfo: {
      marginTop: theme.spacing(2),
    },
    spinner: {
      width: '100%',
      height: 3,
      margin: theme.spacing(2, 0),
    },
  }),
  { name: 'PrintLabelSection' },
)

interface PrintLabelSectionProps {
  activeLabel?: Constant
  copyToClipBoardText: string
  customPrintSize?: boolean
  isFormattedLabel: boolean
  isHtmlLabel: boolean
  isLoading: boolean
  labelType: DymoLabelType
  labelXML: string | Nil
  labels: Constant[]
  onHtmlPrint: () => void
  onLabelChange: (value: any) => void
  onPrintPdf: () => void
  showCopyToClipboard: boolean
  showFormattedLabels: boolean
  showHtmlLabels: boolean
  showPdfPrint: boolean
  showPrintLabelContent: boolean
}

const PrintLabelSection = ({
  activeLabel,
  customPrintSize,
  isFormattedLabel,
  isHtmlLabel,
  isLoading,
  copyToClipBoardText,
  labelType,
  labelXML,
  labels,
  showFormattedLabels,
  showHtmlLabels,
  showPdfPrint,
  showPrintLabelContent,
  showCopyToClipboard,
  onLabelChange,
  onPrintPdf,
  onHtmlPrint,
}: PrintLabelSectionProps) => {
  const classes = useStyles()
  const { t } = useTranslation(['Common', 'Dialogs'])

  const isDymoConnectEnabled = useIsDymoConnectEnabled()

  const [printTriggered, setPrintTriggered] = useState(false)
  const [printers, setPrinters] = useState([])
  const [activePrinterName, setActivePrinterName] = useState('')

  const isPrinterDetected = Boolean(printers.length)

  const [openDymoErrorDialog] = useDialog(DialogNames.DYMO_ERROR)

  const handleDymoError = (error: Error) => {
    openDymoErrorDialog({ error })
  }

  const handlePdfPrint = () => {
    sendGoogleAnalyticsEvent({
      category: 'label_print',
      action: `pdf_print_${labelType}`,
    })

    onPrintPdf()
  }

  const handleHtmlPrint = () => {
    if (isHtmlLabel) {
      sendGoogleAnalyticsEvent({
        category: 'label_print',
        action: `html_label_${
          isFormattedLabel ? 'formatted_' : ''
        }print_${labelType}`,
        label: activeLabel?.name || activeLabel?.id,
      })
    } else {
      sendGoogleAnalyticsEvent({
        category: 'label_print',
        action: `html_content_print_${labelType}`,
        label: activeLabel?.name || activeLabel?.id,
      })
    }

    onHtmlPrint()
  }

  const handleDymoPrint = () => {
    sendGoogleAnalyticsEvent({
      category: 'label_print',
      action: `dymo_label_print_${labelType}`,
      label: activeLabel?.name || activeLabel?.id,
    })

    setPrintTriggered(true)
  }

  const handlePrintButtonClick = isHtmlLabel ? handleHtmlPrint : handleDymoPrint

  return (
    <Suspense fallback={<LinearProgress className={classes.spinner} />}>
      {isDymoConnectEnabled && (
        <ErrorBoundary FallbackComponent={() => null} onError={handleDymoError}>
          <DymoLabelPrint
            activeLabel={activeLabel}
            activePrinterName={activePrinterName}
            labelXML={labelXML}
            labels={labels}
            printTriggered={printTriggered}
            setActivePrinterName={setActivePrinterName}
            setPrintTriggered={setPrintTriggered}
            setPrinters={setPrinters}
          />
        </ErrorBoundary>
      )}
      <Grid container item className={classes.printerInfo} spacing={2}>
        <Grid item xs={isHtmlLabel ? 8 : 6}>
          <LabelSelect
            fullWidth
            renderEmpty
            labelType={labelType}
            placeholder={t('Dialogs:PRESCRIPTION_DIALOG.LABEL_SIZE')}
            showFormattedLabels={isDymoConnectEnabled && showFormattedLabels}
            showHtmlLabels={showHtmlLabels}
            showSimpleTextLabels={
              isDymoConnectEnabled && !(showFormattedLabels || showHtmlLabels)
            }
            value={activeLabel?.id || ''}
            onChange={Utils.handleFormSelectInput(onLabelChange)}
          />
        </Grid>
        {isDymoConnectEnabled && !isHtmlLabel && (
          <Grid item>
            <PuiSelect
              fullWidth
              disabled={!isPrinterDetected}
              input={<Input id="printer-select" />}
              items={printers.map(({ name }) => ({ id: name, name }))}
              placeholder={t(
                'Dialogs:PRINT_LABEL_DIALOG.NO_DYMO_PRINTERS_DETECTED',
              )}
              renderEmpty={!isPrinterDetected}
              value={activePrinterName}
              onChange={Utils.handleFormSelectInput(setActivePrinterName)}
            />
          </Grid>
        )}
      </Grid>
      <Grid container item className={classes.buttons} wrap="wrap">
        <ButtonWithLoader
          className={classes.button}
          disabled={
            !activeLabel?.id ||
            (!activePrinterName && !isHtmlLabel) ||
            (isLoading && printTriggered)
          }
          loading={isLoading && printTriggered}
          onClick={handlePrintButtonClick}
        >
          {t('Common:PRINT_LABEL')}
        </ButtonWithLoader>
        {showPdfPrint && customPrintSize && (
          <LinkButton onClick={handlePdfPrint}>
            {t('Dialogs:PRINT_LABEL_DIALOG.PRINT_LABEL_PDF')}
          </LinkButton>
        )}
        {showPrintLabelContent && (
          <LinkButton onClick={handleHtmlPrint}>
            {t('Dialogs:PRINT_LABEL_DIALOG.PRINT_LABEL_CONTENT')}
          </LinkButton>
        )}
        {showCopyToClipboard && <CopyToClipboard text={copyToClipBoardText} />}
      </Grid>
    </Suspense>
  )
}

export default PrintLabelSection
