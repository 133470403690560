/* eslint-disable max-lines */
import React, {
  forwardRef,
  memo,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react'
import { useTranslation } from 'react-i18next'
import InfiniteScroll from 'react-infinite-scroll-component'
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft'
import {
  CheckboxProps,
  CircularProgress,
  Fab,
  Grid,
  IconButton,
} from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import classNames from 'classnames'
import * as R from 'ramda'
import useDeepCompareEffect from 'use-deep-compare-effect'
import {
  ClassesType,
  DebouncedInlineSearch,
  Defaults,
  InlineSearchHandle,
  LanguageUtils,
  Text,
  Utils,
  ValidateHandle,
} from '@pbt/pbt-ui-components'
import { Filter as FilterIcon } from '@pbt/pbt-ui-components/src/icons'

import PuiSwitch from '~/components/common/PuiSwitch'
import {
  ListType,
  Order,
  OrderCallbackWithTypeCheck,
  OrderListType,
} from '~/types'
import { addIdToList } from '~/utils'
import {
  getOrdersEqual,
  isBundle,
  isWellnessPlanItem,
} from '~/utils/orderUtils'

import ListItemWrapper from '../order/ListItemWrapper'
import EmptyListPlaceholder from './EmptyListPlaceholder'
import ListFilter, { ListFilterSubItem, ListFilterType } from './ListFilter'
import ListWithFiltersNoSearchResult from './ListWithFiltersNoSearchResult'

const FILTER_TYPES_TO_SWITCH_IF_EMPTY = ['BUNDLE', 'REMINDER']

const useStyles = makeStyles(
  (theme) => ({
    headerContainer: {},
    container: {
      height: 294,
      minHeight: 294,
    },
    filtersContainer: {
      minWidth: 48,
      maxHeight: '100%',
    },
    filtersHeaderContainer: {
      backgroundColor: 'rgba(198,192,192,0.3)',
      borderRight: 'none',
      overflow: 'hidden',
      border: theme.constants.tabBorder,
      height: 48,
    },
    filtersContainerExpanded: {
      transition: theme.transitions.create('max-width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    filtersContainerCollapsed: {
      maxWidth: 48,
      transition: theme.transitions.create('max-width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
    },
    filtersExpanded: {
      opacity: 1,
      transition: theme.transitions.create('opacity', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    filtersCollapsed: {
      opacity: 0,
      transition: theme.transitions.create('opacity', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
    },
    collapseButton: {
      marginRight: theme.spacing(0.5),
      padding: theme.spacing(0.5),
    },
    filterIconButton: {
      padding: theme.spacing(1),
      margin: theme.spacing(0.5),
    },
    contentContainer: {
      height: '100%',
      transition: theme.transitions.create('max-width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
      position: 'relative',
    },
    contentContainerExpanded: {
      maxWidth: 'calc(100% - 48px)',
      transition: theme.transitions.create('max-width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
    },
    listHeaderContainer: {
      backgroundColor: theme.colors.tableBackground,
      border: theme.constants.tabBorder,
      borderLeft: 'none',
      borderBottom: 'none',
      height: 48,
      padding: theme.spacing(0, 0.5),
    },
    filters: {
      backgroundColor: theme.colors.tableBackground,
      border: theme.constants.tabBorder,
      borderTop: 'none',
      borderRight: 'none',
      flex: 1,
      overflowY: 'auto',
      overflowX: 'hidden',
    },
    listContainer: {
      backgroundColor: theme.colors.tableBackground,
      border: theme.constants.tabBorder,
      flex: 1,
      overflowY: 'auto',
      padding: theme.spacing(1, 0),
    },
    fabButton: {
      height: 34,
      minWidth: 100,
      padding: theme.spacing(0, 1),
      margin: theme.spacing(0, 0.5),
      boxShadow: 'none',
      '&&&&:hover': {
        boxShadow: 'none',
      },
      lineHeight: '2rem',
      fontWeight: 500,
    },
    fabButtonSelected: {
      backgroundColor: theme.colors.tabSelected,
      color: 'white',
      '&&&&:hover': {
        backgroundColor: theme.colors.tabSelected,
        boxShadow: 'none',
      },
    },
    progress: {
      position: 'absolute',
      left: '50%',
      top: '50%',
      transform: 'translateY(-50%)',
      zIndex: theme.zIndex.progress,
    },
    loader: {
      display: 'block',
      marginLeft: 'auto',
      marginRight: 'auto',
    },
    searchRoot: {
      marginBottom: theme.spacing(2),
    },
    buttonContainer: {},
    switchRoot: {
      marginLeft: 0,
    },
    switch: {
      whiteSpace: 'nowrap',
    },
  }),
  { name: 'ListWithFilters' },
)

export type ListWithFiltersHandle = ValidateHandle & {
  handleItemChange: (filter: ListFilterType) => void
}

export interface ListWithFiltersProps<T = any> {
  DetailsComponent?: React.JSXElementConstructor<any>
  ItemComponent?: React.JSXElementConstructor<any>
  ItemTooltipComponent?: React.JSXElementConstructor<any>
  ListItemProps?: any
  SearchComponent?: React.JSXElementConstructor<any>
  classes?: ClassesType<typeof useStyles>
  clearItems?: () => void
  clientId?: string
  disabledWeakMap?: WeakMap<any, boolean>
  editDisabled?: boolean
  enableBackendFiltering?: boolean
  filters: ListFilterType[]
  filtersToggleLabel?: string | React.ReactElement
  getItemColor?: (item: T) => CheckboxProps['color']
  hasMore?: boolean
  hasMoreItems?: boolean
  hasMoreSearchResults?: boolean
  headerAddon?: React.ReactElement
  hideFilters?: boolean
  hideSearchBar?: boolean
  importantFiltersNumber?: number
  includePrice?: boolean
  includeSubItemsPrices?: boolean
  infiniteScrollClassName?: string
  isCheckedItem: (item: T) => boolean
  isLoading: boolean
  isSelectableItem?: (item: T) => boolean
  items: T[]
  label?: string
  listTypes?: ListType[]
  loadMore?: (...args: any[]) => void
  loadNewItems?: (...args: any[]) => void
  onCheckAll?: (item: T, checked: boolean) => void
  onCheckItem: OrderCallbackWithTypeCheck
  onEditItem?: OrderCallbackWithTypeCheck
  onResetFilter?: () => void
  onSearchChange?: () => void
  onUncheckItem: OrderCallbackWithTypeCheck
  onUpdateItem?: (item: T) => void
  patientId?: string
  searchComponentProps?: any
  searchItems?: (...args: any[]) => void
  searchPlaceholder?: string
  searchResults?: any[]
  searchResultsTotalCount?: number
  setEnableBackendFiltering: (value: boolean) => void
  showFiltersToggle?: boolean
  showItemFilterPath?: boolean
  showLabelState?: boolean
  showResetButton?: boolean
  showTooltip?: boolean
  useChargesWidgetView?: boolean
}

const ListWithFilters = forwardRef<ListWithFiltersHandle, ListWithFiltersProps>(
  function ListWithFilters(
    {
      headerAddon,
      ItemComponent,
      searchItems,
      items: itemsProp = [],
      hideFilters,
      hideSearchBar,
      filters = [],
      isCheckedItem,
      disabledWeakMap,
      importantFiltersNumber = 2,
      listTypes = [],
      isLoading,
      ItemTooltipComponent,
      label,
      loadNewItems,
      clearItems,
      ListItemProps,
      editDisabled = false,
      onCheckItem,
      onCheckAll,
      onResetFilter,
      onUncheckItem,
      onUpdateItem,
      onEditItem,
      searchPlaceholder,
      isSelectableItem,
      searchComponentProps = {},
      showResetButton = false,
      showTooltip,
      showLabelState,
      DetailsComponent,
      SearchComponent,
      searchResults: searchResultsProp = [],
      searchResultsTotalCount,
      hasMore = false,
      hasMoreSearchResults,
      hasMoreItems,
      loadMore = () => {},
      filtersToggleLabel,
      showFiltersToggle = false,
      showItemFilterPath = false,
      enableBackendFiltering = true,
      setEnableBackendFiltering,
      patientId,
      clientId,
      getItemColor,
      onSearchChange,
      includeSubItemsPrices,
      includePrice,
      classes: classesProp,
      infiniteScrollClassName,
      useChargesWidgetView,
    },
    ref,
  ) {
    const classes = useStyles({ classes: classesProp })

    const { t } = useTranslation(['Common'])
    const firstFilter = R.head(filters)

    const [expandedFilters, setExpandedFilters] = useState(
      {} as Record<string, boolean>,
    )
    const [expanded, setExpanded] = useState(true)
    const [filterSelectedState, setFilterSelectedState] = useState(
      firstFilter && filters.length === 1
        ? {
            [firstFilter.type]: {
              categories: [],
              subcategories: [],
            },
          }
        : ({} as Record<string, any>),
    )
    const [listType, setListType] = useState<OrderListType>()
    const [selectedItems, setSelectedItems] = useState<Order[]>([])
    const [selectedSubItem, setSelectedSubItem] = useState<Order>()
    const [searchTerm, setSearchTerm] = useState('')
    const [currentCategoryId, setCurrentCategoryId] = useState<string>()
    const [itemChangedOnce, setItemChangedOnce] = useState(false)
    const [itemIsChanging, setItemIsChanging] = useState(false)
    const [listOfItemsUniqId, setListOfItemsUniqId] = useState('')
    const [searchDisabled, setSearchDisabled] = useState(false)

    const useBackendSearch = Boolean(searchItems)
    const isSearchMode =
      useBackendSearch && searchTerm.length >= Defaults.MIN_SEARCH_LENGTH
    const searchResults = searchTerm
      ? isSearchMode
        ? searchResultsProp.map((result) => result.item)
        : itemsProp.filter(({ name }) =>
            Utils.matchSubstring(searchTerm, name || ''),
          )
      : []

    const isSearchOn = searchTerm && (isSearchMode || !useBackendSearch)
    const items = isSearchOn ? searchResults : itemsProp

    const hasMoreItemsToLoad = isSearchOn
      ? typeof hasMoreSearchResults === 'boolean'
        ? hasMoreSearchResults
        : Boolean(searchResultsTotalCount)
      : typeof hasMoreItems === 'boolean'
      ? hasMoreItems
      : Boolean(hasMore)

    const infiniteScrollRef = useRef<HTMLDivElement>(null)
    const searchRef = useRef<InlineSearchHandle>(null)
    const itemsRefs = Array.from({ length: items.length }, () =>
      React.createRef<HTMLDivElement & ValidateHandle>(),
    )

    const filterType = Object.keys(filterSelectedState)[0]
    const filter = filterType ? filterSelectedState[filterType] : {}

    const categories = filter?.categories || []
    const subcategories = filter?.subcategories || []

    const hasFullListType = listTypes.some(({ id }) => id === 'FULL')

    useEffect(() => {
      setListOfItemsUniqId(`${Math.random()}`)
      return () => {
        if (onSearchChange) {
          onSearchChange()
        }
      }
    }, [])

    const performSearch = () => {
      searchItems?.({
        searchTerm,
        filters: R.pluck('type', filters),
        listType,
        entityType: filterType,
      })
    }

    useEffect(() => {
      if (isSearchMode) {
        performSearch()
      }
    }, [searchTerm, listType, enableBackendFiltering])

    useDeepCompareEffect(() => {
      if (isSearchMode) {
        return
      }

      if (filterType || hideFilters) {
        loadNewItems?.({
          categories,
          subcategories,
          entityType: filterType,
          listType,
        })
      } else if (clearItems) {
        clearItems()
      }
    }, [listType, filterSelectedState, isSearchMode, enableBackendFiltering])

    const isFilterSelected = (
      type: string,
      categoryId?: string,
      subcategoryId?: string,
    ) => {
      const category = filterSelectedState[type]
      const isSelected = subcategoryId
        ? category?.subcategories?.includes(subcategoryId)
        : categoryId
        ? category?.categories?.includes(categoryId)
        : category

      return Boolean(isSelected)
    }

    const handleLoadMore = () => {
      loadMore({
        categories,
        subcategories,
        entityType: filterType,
        listType,
        filters: R.pluck('type', filters),
        searchTerm: isSearchMode ? searchTerm : '',
        from: items.length,
        to: items.length + Defaults.INFINITE_LIST_BATCH_LOAD_COUNT,
      })
    }

    const validate = () =>
      itemsRefs.every((itemRef) =>
        itemRef.current?.validate ? itemRef.current.validate() : true,
      )

    const handleItemChange = ({ type, categoryId, id }: ListFilterType) => {
      if (!validate()) {
        return
      }

      if (infiniteScrollRef.current) {
        infiniteScrollRef.current.scrollTop = 0
      }

      searchRef.current?.clearSearch?.()
      setItemIsChanging(true)

      if (!R.isEmpty(filterSelectedState)) {
        setItemChangedOnce(true)
      }

      setCurrentCategoryId(categoryId)

      const currentItemCategoryId = categoryId || id

      setFilterSelectedState({
        [type]: {
          categories: currentItemCategoryId ? [currentItemCategoryId] : [],
          subcategories: [],
        },
      })
    }

    useImperativeHandle(ref, () => ({
      validate,
      handleItemChange,
    }))

    const handleSubItemChange = ({
      type,
      categoryId,
      subcategoryId,
      selected,
    }: ListFilterSubItem) => {
      if (!validate()) {
        return
      }

      if (infiniteScrollRef.current) {
        infiniteScrollRef.current.scrollTop = 0
      }

      const isChangeInTheCurrentCategory = subcategoryId
        ? filterSelectedState[type] &&
          filterSelectedState[type].categories.includes(categoryId)
        : filterSelectedState[type]

      if (isChangeInTheCurrentCategory) {
        if (selected) {
          // add
          const selectedCategories = categoryId
            ? filterSelectedState[type].categories.includes(categoryId)
              ? // if was already checked, remove everything else
                [categoryId]
              : addIdToList(categoryId, filterSelectedState[type].categories)
            : filterSelectedState[type].categories

          const selectedSubcategories = subcategoryId
            ? filterSelectedState[type].subcategories.includes(subcategoryId)
              ? // if was already checked, remove everything else
                [subcategoryId]
              : addIdToList(
                  subcategoryId,
                  filterSelectedState[type].subcategories,
                )
            : filterSelectedState[type].subcategories

          const typeUpdate = {
            ...filterSelectedState[type],
            categories: selectedCategories,
            subcategories: selectedSubcategories,
          }

          setFilterSelectedState({
            ...filterSelectedState,
            [type]: typeUpdate,
          })
        } else {
          // remove

          // eslint-disable-next-line no-lonely-if
          if (subcategoryId) {
            // top item is defined by categoryId
            const newSubcategories = R.without(
              [subcategoryId],
              filterSelectedState[type].subcategories,
            )
            setFilterSelectedState({
              ...filterSelectedState,
              [type]: {
                ...filterSelectedState[type],
                subcategories: newSubcategories,
              },
            })
          } else {
            // top item is defined by type only
            const newCategories = R.without(
              [categoryId],
              filterSelectedState[type].categories,
            )
            setFilterSelectedState({
              ...filterSelectedState,
              [type]: {
                ...filterSelectedState[type],
                categories: newCategories,
              },
            })
          }
        }
      } else {
        // change in different category, reset current
        setFilterSelectedState({
          [type]: {
            categories: categoryId ? [categoryId] : [],
            subcategories: subcategoryId ? [subcategoryId] : [],
          },
        })
      }
    }

    const clearFilters = (type: string) => {
      if (filterSelectedState[type].subcategories?.length > 0) {
        setFilterSelectedState({
          [type]: {
            ...filterSelectedState[type],
            subcategories: [],
          },
        })
      } else {
        setFilterSelectedState({
          [type]: {
            categories: filterSelectedState[type].categories.filter(
              (id: string) => id === type,
            ),
          },
        })
      }
    }

    const onSearchResultFilterClick = (resultFilters: any[]) => {
      if (!validate()) {
        return
      }
      const mainFilter = resultFilters[0]
      const filterIdentifier = mainFilter.type ? 'type' : 'id'

      searchRef.current?.clearSearch?.()
      setExpandedFilters({
        [`${mainFilter[filterIdentifier]}_${mainFilter.categoryId}`]: true,
      })

      if (resultFilters.length === 1) {
        const foundFilter = filters.find(
          (f) =>
            f.type === mainFilter[filterIdentifier] &&
            f.categoryId === mainFilter.categoryId,
        )
        if (foundFilter) {
          handleItemChange(foundFilter)
        }
      } else {
        const args = resultFilters.reduce(
          (acc, resultFilter) => {
            const isMainItem = R.equals(resultFilter, mainFilter)
            return isMainItem
              ? {
                  ...acc,
                  type: resultFilter[filterIdentifier],
                  categoryId: resultFilter.categoryId,
                }
              : {
                  ...acc,
                  categoryId:
                    acc.categoryId ||
                    resultFilter.categoryId ||
                    resultFilter.id,
                  subcategoryId: acc.categoryId && resultFilter.categoryId,
                }
          },
          { selected: true },
        )

        handleSubItemChange(args)
      }
    }

    const handleReset = () => {
      setFilterSelectedState({})
      if (onResetFilter) {
        onResetFilter()
      }
    }

    useEffect(() => {
      if (!isSearchMode) {
        if (R.isEmpty(filterSelectedState) && firstFilter) {
          handleItemChange(firstFilter)
        }
        if (onSearchChange) {
          onSearchChange()
        }
      }
    }, [searchTerm])

    // select first Filter type on filters change
    useEffect(() => {
      if (R.isEmpty(filterSelectedState) && firstFilter) {
        handleItemChange(firstFilter)
      }
    }, [Boolean(firstFilter)])

    // select default filter type on first load
    useEffect(() => {
      if (!listType && listTypes && listTypes.length > 0) {
        const defaultListType = listTypes.find(({ isDefault }) => isDefault)
        setListType(defaultListType ? defaultListType.id : undefined)
      }
    }, [listTypes])

    useEffect(() => {
      if (
        !searchTerm &&
        !isLoading &&
        items.length === 0 &&
        FILTER_TYPES_TO_SWITCH_IF_EMPTY.includes(filterType) &&
        !itemChangedOnce
      ) {
        const currentItemIndex = filters.findIndex(R.propEq('type', filterType))
        const currentFilter =
          currentItemIndex === -1
            ? filters[0]
            : filters[currentItemIndex + 1] || filters[0]
        if (currentFilter) {
          handleItemChange(currentFilter)
        }
      } else if (!isLoading) {
        setItemIsChanging(false)
      }
    }, [isLoading])

    const getSelectedItemsHaveOrder = (order: Order) =>
      // ignore items field since it may have nested orders which might have changed but we don't care about that
      selectedItems.some((item) => getOrdersEqual(item, order))

    const isSelectedItem = (order: Order) => {
      if (isBundle(order)) {
        return selectedItems.some((item) => item.bundleId === order.bundleId)
      }

      if (isWellnessPlanItem(order)) {
        // for wellness plan items it's reversed since items are expanded by default - selected means collapsed
        return selectedItems.every((item) => item.id !== order.id)
      }

      return (
        R.equals(selectedSubItem, order) || getSelectedItemsHaveOrder(order)
      )
    }

    const getNewSelectedItems = (order: Order) => {
      // for benefits it's reversed since benefits are expanded by default - selected means collapsed
      const wasSelected = isWellnessPlanItem(order)
        ? !isSelectedItem(order)
        : isSelectedItem(order)

      if (isBundle(order) && wasSelected) {
        return selectedItems.filter((item) => item.bundleId !== order.bundleId)
      }

      return wasSelected
        ? selectedItems.filter((item) => !getOrdersEqual(item, order))
        : selectedItems.concat(order)
    }

    const updateSelectedItem = (order: Order) => {
      setSelectedItems(getNewSelectedItems(order))
      setSelectedSubItem(undefined)
    }

    const onSearchFocus = () => {
      setSearchDisabled(!validate())
    }

    const onSearchBlur = () => {
      setSearchDisabled(false)
    }

    const setFullListType = () => {
      setListType(OrderListType.FULL)
    }

    const setDisableFiltering = () => {
      setEnableBackendFiltering(false)
    }

    const selectedFilter = currentCategoryId
      ? filters.find(
          (currentCategoryFilter) =>
            currentCategoryFilter.categoryId === currentCategoryId,
        )
      : filters.find((fil) => fil.type === filterType)

    const categoryName =
      filters && !R.isEmpty(filterSelectedState) && selectedFilter
        ? LanguageUtils.getTranslatedFieldName(selectedFilter).toLowerCase()
        : ''

    const categoryType =
      filters && !R.isEmpty(filterSelectedState) && selectedFilter
        ? selectedFilter.name?.toLowerCase()
        : ''

    return (
      <>
        <Grid container item alignItems="baseline" wrap="nowrap">
          {!hideSearchBar && (
            <>
              {SearchComponent ? (
                <SearchComponent {...searchComponentProps} />
              ) : (
                <DebouncedInlineSearch
                  classes={{ root: classes.searchRoot }}
                  disabled={searchDisabled}
                  placeholder={searchPlaceholder}
                  ref={searchRef}
                  onBlur={onSearchBlur}
                  onChange={setSearchTerm}
                  onFocus={onSearchFocus}
                />
              )}
            </>
          )}
          {showFiltersToggle && (
            <PuiSwitch
              checked={enableBackendFiltering}
              classes={{
                root: classes.switchRoot,
                switchLabel: classes.switch,
              }}
              label={filtersToggleLabel}
              onChange={() =>
                setEnableBackendFiltering(!enableBackendFiltering)
              }
            />
          )}
        </Grid>
        <Grid container item className={classes.container} wrap="nowrap">
          {!hideFilters && (
            <Grid
              container
              item
              className={classNames(
                classes.filtersContainer,
                expanded
                  ? classes.filtersContainerExpanded
                  : classes.filtersContainerCollapsed,
              )}
              direction="column"
              wrap="nowrap"
              xs={expanded ? 4 : undefined}
            >
              <Grid container className={classes.headerContainer}>
                <Grid
                  container
                  item
                  alignItems="center"
                  className={classes.filtersHeaderContainer}
                  justifyContent="space-between"
                  wrap="nowrap"
                >
                  {expanded && (
                    <Grid item>
                      <Text strong ml={1.5} variant="body">
                        {t('Common:FILTERS')}
                      </Text>
                    </Grid>
                  )}
                  <Grid container item alignItems="center" width="auto">
                    {showResetButton && (
                      <Text link variant="body2" onClick={handleReset}>
                        {t('Common:RESET')}
                      </Text>
                    )}
                    <IconButton
                      className={classNames({
                        [classes.collapseButton]: expanded,
                        [classes.filterIconButton]: !expanded,
                      })}
                      size="large"
                      onClick={() => setExpanded(!expanded)}
                    >
                      {expanded ? <KeyboardArrowLeft /> : <FilterIcon />}
                    </IconButton>
                  </Grid>
                </Grid>
              </Grid>
              <Grid container className={classes.filters}>
                <Grid
                  container
                  className={
                    expanded
                      ? classes.filtersExpanded
                      : classes.filtersCollapsed
                  }
                  direction="column"
                  wrap="nowrap"
                >
                  {filters.map((filterItem) => {
                    const { type, categoryId, id, children = [] } = filterItem
                    const currentId = categoryId || id
                    const key = `${type}_${currentId}`
                    return (
                      <ListFilter
                        editDisabled={editDisabled}
                        expanded={expandedFilters[key]}
                        filter={filterItem}
                        importantSubItemsNumber={importantFiltersNumber}
                        key={key}
                        label={label}
                        selected={isFilterSelected(type, currentId)}
                        subItems={children.map((subItem) => ({
                          ...subItem,
                          selected: isFilterSelected(
                            type,
                            categoryId || subItem.categoryId || subItem.id,
                            subItem.subcategoryId,
                          ),
                        }))}
                        onChange={handleItemChange}
                        onClearFilters={clearFilters}
                        onExpandChange={(isExpanded) => {
                          setExpandedFilters({
                            [key]: isExpanded,
                          })
                        }}
                        onSubItemChange={handleSubItemChange}
                      />
                    )
                  })}
                </Grid>
              </Grid>
            </Grid>
          )}
          <Grid
            container
            item
            className={classNames(classes.contentContainer, {
              [classes.contentContainerExpanded]: !expanded,
            })}
            direction="column"
            xs={expanded ? (hideFilters ? 12 : 8) : true}
          >
            {listTypes.length > 0 && (
              <Grid
                container
                item
                alignItems="center"
                className={classes.listHeaderContainer}
              >
                {listTypes.map(({ id, name, Icon }) => (
                  <Fab
                    className={classNames(
                      classes.fabButton,
                      listType === id && classes.fabButtonSelected,
                    )}
                    color="primary"
                    key={id}
                    type="button"
                    variant="extended"
                    onClick={() => setListType(id)}
                  >
                    <Icon />
                    {name}
                  </Fab>
                ))}
                {headerAddon && (
                  <Grid container item xs justifyContent="flex-end">
                    {headerAddon}
                  </Grid>
                )}
              </Grid>
            )}
            <Grid
              container
              item
              className={classes.listContainer}
              direction="column"
              id={`list-with-filters-container_${listOfItemsUniqId}`}
              ref={infiniteScrollRef}
              wrap="nowrap"
            >
              {isLoading && (
                <Grid className={classes.progress}>
                  <CircularProgress color="secondary" />
                </Grid>
              )}
              {!searchTerm &&
                !R.isEmpty(filterSelectedState) &&
                items.length === 0 &&
                !isLoading &&
                itemChangedOnce &&
                !itemIsChanging && (
                  <EmptyListPlaceholder
                    categoryName={categoryName}
                    categoryType={categoryType}
                    onFullList={setFullListType}
                  />
                )}
              {((!useBackendSearch && searchTerm) || isSearchMode) &&
                searchResults.length === 0 &&
                !isLoading && (
                  <ListWithFiltersNoSearchResult
                    query={searchTerm}
                    showFiltersToggle={showFiltersToggle}
                    onDisableFiltering={setDisableFiltering}
                    onFullList={hasFullListType ? setFullListType : undefined}
                  />
                )}
              {/* @ts-ignore */}
              <InfiniteScroll
                className={infiniteScrollClassName}
                dataLength={items.length}
                hasMore={hasMoreItemsToLoad}
                // loader={<CircularProgress className={classes.loader} color="secondary" size={16} />}
                next={handleLoadMore}
                scrollableTarget={`list-with-filters-container_${listOfItemsUniqId}`}
              >
                {items.map((order, index) => {
                  const searchResultFilter =
                    isSearchMode &&
                    searchResultsProp.find((result) => result.item === order)
                      ?.filter
                  const itemFilterPath =
                    showItemFilterPath && order?.filter && order.filter

                  return (
                    <ListItemWrapper
                      DetailsComponent={DetailsComponent}
                      ItemComponent={ItemComponent}
                      ItemTooltipComponent={ItemTooltipComponent}
                      ListItemProps={ListItemProps}
                      clientId={clientId}
                      disabledWeakMap={disabledWeakMap}
                      editDisabled={editDisabled}
                      getItemColor={getItemColor}
                      includePrice={includePrice}
                      includeSubItemsPrices={includeSubItemsPrices}
                      isCheckedItem={isCheckedItem}
                      isDisabled={
                        editDisabled ||
                        (disabledWeakMap && disabledWeakMap.get(order))
                      }
                      isLoading={isLoading}
                      isSelectableItem={
                        isSelectableItem ? isSelectableItem(order) : true
                      }
                      isSelected={isSelectedItem(order)}
                      /* eslint-disable-next-line react/no-array-index-key */
                      key={index}
                      order={order}
                      patientId={patientId}
                      ref={itemsRefs[index]}
                      searchResultFilter={searchResultFilter || itemFilterPath}
                      searchTerm={searchTerm}
                      selectedSubItem={selectedSubItem}
                      showLabelState={showLabelState}
                      showTooltip={showTooltip}
                      updateSelectedItem={updateSelectedItem}
                      updateSelectedSubItem={(item) => {
                        setSelectedSubItem(item)
                      }}
                      useChargesWidgetView={useChargesWidgetView}
                      onCheckAll={onCheckAll}
                      onCheckItem={onCheckItem}
                      onEditItem={onEditItem}
                      onSearchResultFilterClick={
                        itemFilterPath ? undefined : onSearchResultFilterClick
                      }
                      onUncheckItem={onUncheckItem}
                      onUpdateItem={onUpdateItem}
                    />
                  )
                })}
              </InfiniteScroll>
            </Grid>
          </Grid>
        </Grid>
      </>
    )
  },
)

export default memo(ListWithFilters)
