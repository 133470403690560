import React from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { Divider, Grid } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { Text } from '@pbt/pbt-ui-components'

import ListItemWrapper from '~/components/dashboard/soap/order/ListItemWrapper'
import ReadonlyOrderTooltip from '~/components/dashboard/soap/order/ReadonlyOrderTooltip'
import OrderTooltip from '~/components/dashboard/soap/OrderTooltip'
import FeatureToggle from '~/constants/featureToggle'
import { editOrder } from '~/store/actions/orders'
import {
  useGetIsCheckedItem,
  useOrderCheckAllListItems,
  useOrderCheckItem,
  useOrderSelectedItems,
  useOrderUncheckItem,
} from '~/store/hooks/orders'
import { getFeatureToggle } from '~/store/reducers/constants'
import { getAppointmentLineItemsMap } from '~/store/reducers/finance'
import { getSelectedOrders } from '~/store/reducers/orders'
import {
  BaseChargeTabProps,
  Order,
  OrderCallbackWithTypeCheck,
  OrderSearchFilter,
} from '~/types'
import {
  findOrderItemByItem,
  getItemColor,
  hasPriceOrAggregatedPrice,
  isObsoleteGlobalItem,
} from '~/utils/orderUtils'
import useGetIsCatalogSelectableItem from '~/utils/useGetIsCatalogSelectableItem'

const useStyles = makeStyles(
  (theme) => ({
    lineItem: {
      '&:hover': {
        backgroundColor: theme.colors.soapListItemHover,
      },
    },
  }),
  { name: 'ChargeSearchResultItem' },
)

export interface ChargeSearchResultItemProps
  extends Omit<BaseChargeTabProps, 'enableBackendFiltering'> {
  clientId: string
  contentRef: React.RefObject<any>
  disabledWeakMap: WeakMap<Order, boolean>
  isFirstItem: boolean
  isFirstOccurrenceWithoutPrice: boolean
  isReceivingOrders: boolean
  order: Order
  patientId: string
  searchResultFilter?: OrderSearchFilter[]
  searchTerm: string
}

const ChargeSearchResultItem = ({
  clientId,
  contentRef,
  disabled,
  disabledWeakMap,
  handleEditListItem,
  handleRefill,
  handleSavePrescription,
  isFirstItem,
  isFirstOccurrenceWithoutPrice,
  isReceivingOrders,
  logItemStateGetter,
  openPrescriptionDialog,
  order,
  patientId,
  searchResultFilter,
  searchTerm,
}: ChargeSearchResultItemProps) => {
  const dispatch = useDispatch()
  const classes = useStyles()
  const { t } = useTranslation(['Search', 'Common'])

  const appointmentLineItemsMap = useSelector(getAppointmentLineItemsMap)
  const selectedOrders = useSelector(getSelectedOrders)

  const isCheckedItem = useGetIsCheckedItem()
  const {
    isSelectedItem,
    selectedSubItem,
    setSelectedSubItem,
    updateSelectedItem,
  } = useOrderSelectedItems()
  const onCheckItem = useOrderCheckItem({
    handleSavePrescription,
    logItemStateGetter,
    openPrescriptionDialog,
  })
  const handleCheckAllListItems = useOrderCheckAllListItems()
  const handleListItemUncheck = useOrderUncheckItem()

  const isObsoleteEnabled = useSelector(
    getFeatureToggle(FeatureToggle.OBSOLETE_GLOBAL_INVENTORY_ITEM_VARIATION),
  )
  const isFoodCatalogEnabled = useSelector(
    getFeatureToggle(FeatureToggle.FOOD_CATALOG),
  )

  const isNotObsolete = isObsoleteEnabled
    ? !isObsoleteGlobalItem(order, isFoodCatalogEnabled)
    : true
  const toolTipText = isNotObsolete ? (
    <ReadonlyOrderTooltip order={order} />
  ) : (
    t('Common:OBSOLETE')
  )

  const getIsCatalogSelectableItem = useGetIsCatalogSelectableItem()

  const isSelectableItem = getIsCatalogSelectableItem(order)

  const isFirstItemAndHasPrice = isFirstItem && hasPriceOrAggregatedPrice(order)
  const title = isFirstItemAndHasPrice
    ? t('Soap:SOAP_ORDERS_LOCKED.MY_LIST')
    : isFirstOccurrenceWithoutPrice
    ? t('Soap:SOAP_ORDERS_LOCKED.FULL_LIST')
    : undefined

  const onUncheckItem: OrderCallbackWithTypeCheck = (
    currentOrder,
    checkOptions,
  ) => {
    handleListItemUncheck(currentOrder, checkOptions)
  }

  const onCheckAll = (currentOrder: Order, checked: boolean) => {
    handleCheckAllListItems(currentOrder, checked)
  }

  const onUpdateItem = (newOrder: Order) => {
    const fullOrder = findOrderItemByItem(newOrder, selectedOrders)
    dispatch(editOrder({ ...fullOrder, ...newOrder }))
  }

  return (
    <Grid>
      {title && (
        <>
          <Text strong mb={0.5} ml={2} variant="body2">
            {title}
          </Text>
          <Grid mb={0.5}>
            <Divider />
          </Grid>
        </>
      )}
      <Grid className={classes.lineItem}>
        <ListItemWrapper
          includePrice
          showTooltip
          useChargesWidgetView
          ItemTooltipComponent={OrderTooltip}
          ListItemProps={{
            showListItemActions: true,
            onRefillSelect: handleRefill,
          }}
          clientId={clientId}
          disabledWeakMap={disabledWeakMap}
          editDisabled={disabled}
          getItemColor={getItemColor(
            appointmentLineItemsMap || {},
            selectedOrders,
          )}
          isCheckedItem={isCheckedItem}
          isDisabled={
            disabled || (disabledWeakMap && disabledWeakMap.get(order))
          }
          isLoading={isReceivingOrders}
          isSelectableItem={isSelectableItem}
          isSelected={isSelectedItem(order)}
          order={order}
          patientId={patientId}
          ref={contentRef}
          searchResultFilter={searchResultFilter}
          searchTerm={searchTerm}
          selectedSubItem={selectedSubItem}
          toolTipText={toolTipText}
          updateSelectedItem={updateSelectedItem}
          updateSelectedSubItem={setSelectedSubItem}
          onCheckAll={onCheckAll}
          onCheckItem={onCheckItem}
          onEditItem={handleEditListItem}
          onUncheckItem={onUncheckItem}
          onUpdateItem={onUpdateItem}
        />
      </Grid>
    </Grid>
  )
}

export default ChargeSearchResultItem
