import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Grid } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { sanitize } from 'dompurify'
import { HtmlNotesPreview, LanguageUtils, Text } from '@pbt/pbt-ui-components'

import { getProblemBodySystems } from '~/store/reducers/constants'
import { ProblemDiagnosisLog } from '~/types'

import ProblemsDiagnosesTableDiagnosis from './ProblemsDiagnosesTableDiagnosis'

const useStyles = makeStyles(
  (theme) => ({
    bodySystemWrapper: {
      borderTop: theme.constants.tableBorder,
    },
    bodySystemName: {
      borderRight: theme.constants.tableBorder,
    },
  }),
  { name: 'ProblemsDiagnosesTableBodySystem' },
)

interface ProblemsDiagnosesTableBodySystemProps {
  bodySystemId: string
  logs: ProblemDiagnosisLog[]
}


/**
 * Important info - the "notes" field actually refers to the diagnosis
 * And the "diagnosisNotes" field refers to the body system.
 * We believe these got swapped at some point during initial implementation.
 */
const ProblemsDiagnosesTableBodySystem = ({
  logs,
  bodySystemId,
}: ProblemsDiagnosesTableBodySystemProps) => {
  const classes = useStyles()
  const { t } = useTranslation(['Soap', 'Common'])
  const ProblemsBodySystems = useSelector(getProblemBodySystems)

  const bodySystemName = LanguageUtils.getConstantTranslatedName(
    bodySystemId,
    ProblemsBodySystems,
  )

  return (
    <Grid
      container
      item
      className={classes.bodySystemWrapper}
      direction="row"
      display="flex"
      id="bodySystemWrapper"
      xs={12}
    >
      <Grid
        item
        className={classes.bodySystemName}
        id="bodySystemName"
        p={1}
        xs={2}
      >
        <Text>{bodySystemName}</Text>
      </Grid>
      <Grid
        container
        item
        direction="column"
        display="flex"
        id="notesWrapper"
        p={1}
        xs={10}
      >
        {Boolean(logs[0].diagnosisNotes) && (
          <HtmlNotesPreview
            fontSize="1.4rem"
            notes={sanitize(`${t('Common:NOTES')}: ${logs[0].diagnosisNotes}`)}
          />
        )}
        {logs.map((diagnosisLog: ProblemDiagnosisLog) => (
          <ProblemsDiagnosesTableDiagnosis
            key={diagnosisLog.description + diagnosisLog.date}
            log={diagnosisLog}
          />
        ))}
      </Grid>
    </Grid>
  )
}
export default ProblemsDiagnosesTableBodySystem
