import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { Grid } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import * as R from 'ramda'
import {
  BasePuiDialogProps,
  ButtonWithLoader,
  ClassesType,
  ErrorTooltip,
  PuiCheckbox,
  PuiDialog,
  Utils,
} from '@pbt/pbt-ui-components'

import { ImagingVendorLabel } from '~/constants/imaging'
import { OrderType } from '~/constants/SOAPStates'
import { removeOrder } from '~/store/actions/orders'
import {
  batchCreateOrder,
  getImagingIsLoading as getIsLoadingImaging,
  getOutstandingOrders,
} from '~/store/duck/imagingOrders'
import { useGetIdexxImagingId } from '~/store/hooks/constants'
import {
  useGetInvoiceLogsByLogType,
  useOutstandingImagingOrders,
} from '~/store/hooks/finance'
import { getImagingVendors } from '~/store/reducers/constants'
import { getFinanceInvoice } from '~/store/reducers/finance'
import { getOrdersIsSending } from '~/store/reducers/orders'
import { getDoctorId } from '~/store/reducers/soap'
import { ImagingOrder as ImagingOrderType, Order } from '~/types'
import useCloseAfterCreation from '~/utils/useCloseAfterCreation'

import ImagingOrder, { ImagingOrderHandle } from './ImagingOrder'

type ImagingOrderDraftMap = Record<string, ImagingOrderDraft>
type ImagingOrderHandleMap = Record<string, ImagingOrderHandle>

const useStyles = makeStyles(
  (theme) => ({
    actionButton: {
      marginLeft: theme.spacing(1),
      minWidth: 120,
    },
    content: {},
    orderItem: {
      flex: 1,
      border: theme.constants.tableBorder,
    },
    checkboxContainer: {
      border: theme.constants.tableBorder,
      borderRightWidth: 0,
    },
    checkbox: {
      margin: 0,
    },
    paper: {
      overflow: 'visible',
    },
  }),
  { name: 'BatchImagingOrderManagementDialog' },
)

type ImagingOrderDraft = {
  draft: ImagingOrderType
  selected: boolean
}

const buildOrderKey = (order: ImagingOrderType) =>
  [order?.name, order?.logId].join('-')

const buildOrderDraft = (
  soapLog: Order | ImagingOrderType,
  assignedVetId: string,
) =>
  ({
    name: soapLog.name,
    notes: soapLog.notes,
    logId: soapLog.id,
    assignedVetId,
    procedureId: soapLog.procedure?.id,
    procedureCode: soapLog.procedure?.procedureCode,
    modalityId: soapLog.procedure?.modalityId,
    soapId: soapLog.soapId,
    soapLog,
  } as ImagingOrderType)

const registerRef =
  (refHolder: React.RefObject<ImagingOrderHandleMap>, key: string) =>
  (element: ImagingOrderHandle) => {
    if (refHolder.current) {
      refHolder.current[key] = element
    }
  }

const getSelectedOrders = R.curry(
  (selectedMap: ImagingOrderDraftMap, readyToSendRefs: ImagingOrderHandleMap) =>
    R.toPairs(readyToSendRefs)
      .filter(([key]) => selectedMap[key]?.selected)
      .map(([, value]) => value),
)

const validateRefs = (
  selectedMap: ImagingOrderDraftMap,
  readyToSendRefs: ImagingOrderHandleMap,
) => {
  const selectedOrders = getSelectedOrders(selectedMap)(readyToSendRefs)
  const validateValues = R.pluck('validate', selectedOrders).map((func) =>
    func(),
  )
  return R.all(Boolean, validateValues)
}

const getOrdersOfRefs = (
  selectedMap: ImagingOrderDraftMap,
  readyToSendRefs: ImagingOrderHandleMap,
) => {
  const selectedOrders = getSelectedOrders(selectedMap)(readyToSendRefs)
  const getValues = R.pluck('get', selectedOrders).map(R.call)

  return getValues
}

const noOrdersSelected = (orderDrafts: ImagingOrderDraftMap) => {
  const orderDraftsValues = R.values(orderDrafts)
  const selectedValues = R.pluck('selected', orderDraftsValues)
  return !R.any(R.identity, selectedValues)
}

const draftSelectedStateReducer = (
  acc: ImagingOrderDraftMap,
  draft: ImagingOrderType,
) => {
  const key = buildOrderKey(draft)
  acc[key] = { selected: true, draft }
  return acc
}

interface BatchImagingOrderManagementDialogProps extends BasePuiDialogProps {
  classes?: ClassesType<typeof useStyles>
  hideOrderManagement?: boolean
  invoiceId?: string
}

const BatchImagingOrderManagementDialog = ({
  classes: classesProp,
  hideOrderManagement,
  invoiceId,
  open,
  onClose,
}: BatchImagingOrderManagementDialogProps) => {
  const classes = useStyles({ classes: classesProp })
  const dispatch = useDispatch()
  const { t } = useTranslation(['Common', 'Dialogs', 'Tooltips'])

  const invoice = useSelector(getFinanceInvoice(invoiceId))
  const ImagingVendors = useSelector(getImagingVendors)
  const soapDoctorId = useSelector(getDoctorId)
  const isLoadingImaging = useSelector(getIsLoadingImaging)
  const isRemovingOrder = useSelector(getOrdersIsSending)
  const outstandingOrders = useSelector(getOutstandingOrders) || []

  const procedureLogs = useGetInvoiceLogsByLogType(OrderType.PROCEDURE, invoice)
  const outstandingOrdersFromProcedureLogs =
    useOutstandingImagingOrders(procedureLogs)

  const outstandingImagingOrders =
    procedureLogs.length > 0
      ? outstandingOrdersFromProcedureLogs
      : outstandingOrders

  const imagingOrdersRef = useRef<ImagingOrderHandleMap>({})

  const [orderDrafts, setOrderDrafts] = useState<ImagingOrderDraftMap>()

  const isLoading = isLoadingImaging || isRemovingOrder
  const vendorId = useGetIdexxImagingId()
  const vendorName = Utils.getConstantName(vendorId, ImagingVendors)
  const vendorLabel = ImagingVendorLabel[vendorName]

  useEffect(() => {
    const selectedMap = outstandingImagingOrders
      .map((soapLog) => buildOrderDraft(soapLog, soapDoctorId))
      .reduce(draftSelectedStateReducer, {} as ImagingOrderDraftMap)

    setOrderDrafts(selectedMap)
  }, [outstandingImagingOrders?.length, soapDoctorId])

  const closeAfterCreate = useCloseAfterCreation(onClose, getIsLoadingImaging)

  const handleSendOrders = () => {
    const readyToSendRefs = imagingOrdersRef.current

    if (
      orderDrafts &&
      readyToSendRefs &&
      validateRefs(orderDrafts, readyToSendRefs)
    ) {
      const orders = getOrdersOfRefs(orderDrafts, readyToSendRefs)
      if (orders.length > 0) {
        dispatch(
          batchCreateOrder({
            orders,
            vendorLabel,
            invoiceId,
            clientId: undefined,
          }),
        )
        closeAfterCreate()
      }
    }
  }

  const handleDeleteOrderDraft = (key: string) => {
    const soapLog = orderDrafts?.[key]?.draft?.soapLog
    if (soapLog?.id) {
      dispatch(removeOrder(soapLog))
    }
    setOrderDrafts(R.omit([key], orderDrafts))
  }

  const handleCheck = (key: string) => {
    const target = orderDrafts && orderDrafts[key]
    const isChecked = target?.selected
    if (isChecked) {
      imagingOrdersRef.current?.[key]?.reset()
    }

    if (target) {
      setOrderDrafts({
        ...orderDrafts,
        [key]: {
          ...target,
          selected: !isChecked,
        },
      })
    }
  }

  const allOrdersNotSelected = orderDrafts && noOrdersSelected(orderDrafts)

  return (
    <PuiDialog
      fullWidth
      actions={
        <ButtonWithLoader
          className={classes.actionButton}
          disabled={isLoading || allOrdersNotSelected}
          loading={isLoading}
          onClick={handleSendOrders}
        >
          {t('Common:SEND_ACTION')}
        </ButtonWithLoader>
      }
      classes={{ paper: classes.paper }}
      maxWidth="xl"
      open={open}
      title={t('Dialogs:BATCH_IMAGING_ORDER_MANAGEMENT_DIALOG.TITLE', {
        vendorLabel,
      })}
      onClose={onClose}
    >
      <Grid container className={classes.content} direction="column" p={2}>
        {orderDrafts &&
          Object.keys(orderDrafts).map((key, index) => {
            const { draft, selected } = orderDrafts[key] || {}

            return (
              <Grid container item direction="row" key={key}>
                <Grid item className={classes.checkboxContainer}>
                  <ErrorTooltip
                    message={t('Tooltips:CHOOSE_AT_LEAST_ONE_IMAGE')}
                    open={index === 0 && allOrdersNotSelected}
                  >
                    <PuiCheckbox
                      checked={selected || false}
                      classes={{ labelRoot: classes.checkbox }}
                      onChange={() => handleCheck(key)}
                    />
                  </ErrorTooltip>
                </Grid>
                <Grid item className={classes.orderItem}>
                  <ImagingOrder
                    inline
                    order={draft}
                    ref={registerRef(imagingOrdersRef, key)}
                    vendorId={vendorId}
                    onDelete={
                      hideOrderManagement
                        ? undefined
                        : () => {
                            handleDeleteOrderDraft(key)
                          }
                    }
                  />
                </Grid>
              </Grid>
            )
          })}
        {/* @TODO: implement Add Order button */}
      </Grid>
    </PuiDialog>
  )
}

export default BatchImagingOrderManagementDialog
