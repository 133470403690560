import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { TableCell } from '@mui/material'
import { makeStyles } from '@mui/styles'
import classNames from 'classnames'
import { Nil, PermissionArea, useFields } from '@pbt/pbt-ui-components'

import UserSelect from '~/components/common/inputs/UserSelect'
import FeatureToggle from '~/constants/featureToggle'
import { FINANCE_CELL_WIDTH } from '~/constants/financeTable'
import { editInvoiceLineItem } from '~/store/actions/finance'
import { editChargeSheetProducerItemBatch } from '~/store/duck/clientFinanceData'
import { getCRUDByArea } from '~/store/reducers/auth'
import { getFeatureToggle } from '~/store/reducers/constants'
import { updateInvoiceLineItemProducerBatch } from '~/store/reducers/invoiceV3'
import { InvoiceLineItem } from '~/types'

const { PRODUCER } = FINANCE_CELL_WIDTH

const useStyles = makeStyles(
  (theme) => ({
    producerCell: {
      minWidth: PRODUCER,
      width: PRODUCER,
    },
    fullyRefunded: {
      color: theme.colors.lowAccentText,
    },
  }),
  { name: 'FinanceItemProducerCellBody' },
)

export interface FinanceItemProducerCellBodyProps {
  allowModifyForInvoiceDoctorOnly: boolean
  invoiceId?: string
  isFullyRefunded?: boolean
  item: InvoiceLineItem
  tableCellClassName: string
  tableCellWithBorder: string
}

const FinanceItemProducerCellBody = ({
  item,
  isFullyRefunded = false,
  tableCellClassName,
  tableCellWithBorder,
  allowModifyForInvoiceDoctorOnly,
  invoiceId,
}: FinanceItemProducerCellBodyProps) => {
  const classes = useStyles()

  const dispatch = useDispatch()
  const invoiceDoctorPermission = useSelector(
    getCRUDByArea(PermissionArea.INVOICE_DOCTOR),
  )
  const isChargeSheetEnabled = useSelector(
    getFeatureToggle(FeatureToggle.CHARGE_SHEET),
  )

  const dispatchChargeSheetProducerItemCharges = (value: string | Nil) =>
    dispatch(
      editChargeSheetProducerItemBatch({
        clientId: item.clientId,
        updateItemProducerInput: [
          {
            producerId: value as string,
            id: item.id,
            expectedModification: item.modificationDate,
          },
        ],
      }),
    )

  const dispatchInvoiceProducerItemChanges = (value: string | Nil) =>
    dispatch(
      updateInvoiceLineItemProducerBatch([
        {
          id: item.id,
          expectedModification: item.modificationDate,
          producerId: value as string,
        },
      ]),
    )

  const setValueFun = isChargeSheetEnabled
    ? (value: string | Nil) =>
        invoiceId
          ? dispatchInvoiceProducerItemChanges(value)
          : dispatchChargeSheetProducerItemCharges(value)
    : (value: string | Nil) =>
        dispatch(
          editInvoiceLineItem(item.id, { producerId: value }, item.invoiceId),
        )

  const { producerId } = item || ''

  const editable =
    !isFullyRefunded &&
    (!allowModifyForInvoiceDoctorOnly || invoiceDoctorPermission.update)

  const { fields, updateState } = useFields(
    [
      {
        name: 'producer',
        initialValue: producerId,
        type: 'select',
      },
    ],
    false,
  )

  useEffect(() => {
    if (producerId) {
      updateState({ producer: producerId })
    }
  }, [producerId])

  return (
    <TableCell
      className={classNames(
        tableCellClassName,
        tableCellWithBorder,
        classes.producerCell,
        {
          [classes.fullyRefunded]: isFullyRefunded,
        },
      )}
      onClick={(e) => e.stopPropagation()}
    >
      {!item.declined && (
        <UserSelect
          plain
          disabled={!editable}
          field={{
            ...fields.producer,
            setValue: setValueFun,
          }}
        />
      )}
    </TableCell>
  )
}
export default FinanceItemProducerCellBody
