import { useSelector } from 'react-redux'
import { Nil } from '@pbt/pbt-ui-components'

import FeatureToggle from '~/constants/featureToggle'
import { getCurrentBusinessId } from '~/store/reducers/auth'
import { getBusiness } from '~/store/reducers/businesses'
import { getFeatureToggle } from '~/store/reducers/constants'
import { getBusinessIsGroup } from '~/utils/businessUtils'

export const useDocumentDetailsDisabled = (
  documentBusinessId: string | Nil,
) => {
  const documentBusiness = useSelector(getBusiness(documentBusinessId))
  const currentBusinessId = useSelector(getCurrentBusinessId)

  const isDocumentBusinessGroup = getBusinessIsGroup(documentBusiness)

  const isGroupDocumentsSharingEnabled = useSelector(
    getFeatureToggle(FeatureToggle.GROUP_DOCUMENTS_SHARING),
  )

  const isCurrentBusinessChild = currentBusinessId !== documentBusinessId
  return (
    isGroupDocumentsSharingEnabled &&
    isDocumentBusinessGroup &&
    isCurrentBusinessChild
  )
}
