import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { Grid } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import {
  ButtonWithLoader,
  PermissionArea,
  useFields,
  Utils,
} from '@pbt/pbt-ui-components'

import FeatureToggle from '~/constants/featureToggle'
import { patchAppointment } from '~/store/actions/timetable'
import { getCRUDByArea } from '~/store/reducers/auth'
import { getEventState, getFeatureToggle } from '~/store/reducers/constants'
import {
  getAppointmentId,
  getIsCurrentContextSoap,
  getIsFinalized,
} from '~/store/reducers/soap'
import {
  getTimetableEvent,
  getTimetableIsLoading,
} from '~/store/reducers/timetable'

import AttachmentsWidget from '../../soapV2/attachments/AttachmentsWidget'
import AppointmentStatusSelect from '../appointment/AppointmentStatusSelect'
import ChargeSheetSection from './chargeSheetSection/ChargeSheetSection'
import SoapClientInvoiceList from './SoapClientInvoiceList'

const useStyles = makeStyles(
  (theme) => ({
    appointmentContainer: {
      border: theme.constants.tableBorder,
      backgroundColor: theme.colors.tableBackground,
    },
    appointmentStatusContainer: {
      borderBottom: theme.constants.tableBorder,
    },
    paper: {
      width: 650,
      maxWidth: 650,
    },
    button: {
      height: 42,
      minWidth: 172,
    },
    hidden: {
      display: 'none',
    },
    smallPaper: {
      width: 650,
      height: 270,
      maxWidth: 650,
    },
  }),
  { name: 'CheckOutPage' },
)

const CheckOutPage = () => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const { t } = useTranslation(['Common', 'Soap'])

  const EventState = useSelector(getEventState)
  const appointmentId = useSelector(getAppointmentId)
  const isSoapFinalized = useSelector(getIsFinalized)
  const isCurrentContextSoap = useSelector(getIsCurrentContextSoap)
  const isLoading = useSelector(getTimetableIsLoading)
  const appointment = useSelector(getTimetableEvent(appointmentId))
  const appointmentPermissions = useSelector(
    getCRUDByArea(PermissionArea.EVENT_APPOINTMENT),
  )
  const isChargeSheetEnabled = useSelector(
    getFeatureToggle(FeatureToggle.CHARGE_SHEET),
  )

  const dischargedId = Utils.findConstantIdByName('Discharged', EventState)

  const appointmentUpdateDisabled = !appointmentPermissions.update

  const isReadOnly = isSoapFinalized || !isCurrentContextSoap

  const {
    fields: { appointmentStatus },
    reset,
  } = useFields(
    [
      {
        name: 'appointmentStatus',
        label: t('Common:APPOINTMENT_STATUS'),
        initialValue: appointment?.state?.id || '',
      },
    ],
    false,
  )

  useEffect(() => {
    reset()
  }, [appointment])

  const onHandleStatusChange = (state: string) => {
    if (appointment?.id) {
      dispatch(
        patchAppointment({
          id: appointment?.id,
          state,
        }),
      )
    }
  }

  useEffect(() => {
    if (appointmentStatus.value !== appointmentStatus.initialValue) {
      onHandleStatusChange(appointmentStatus.value)
    }
  }, [appointmentStatus.value])

  return (
    <>
      {isChargeSheetEnabled ? null : <SoapClientInvoiceList />}
      <Grid container item mt={2}>
        <AttachmentsWidget />
      </Grid>
      {appointment && (
        <Grid
          container
          item
          className={classes.appointmentContainer}
          direction="column"
          mt={2}
        >
          {!isReadOnly && (
            <Grid
              container
              item
              className={classes.appointmentStatusContainer}
              p={1}
            >
              <Grid container item alignItems="center">
                <AppointmentStatusSelect
                  disabled={appointmentUpdateDisabled}
                  field={appointmentStatus}
                  label={appointmentStatus.label}
                />
              </Grid>
            </Grid>
          )}
          {!isReadOnly && appointment.state?.id !== dischargedId && (
            <Grid container item px={2} py={1}>
              <ButtonWithLoader
                className={classes.button}
                disabled={isLoading || appointmentUpdateDisabled}
                loading={isLoading}
                type="submit"
                onClick={() => onHandleStatusChange(dischargedId)}
              >
                {t('Soap:CHECKOUT_PAGE.DISCHARGE_PATIENT')}
              </ButtonWithLoader>
            </Grid>
          )}
        </Grid>
      )}
      {isChargeSheetEnabled && <ChargeSheetSection />}
    </>
  )
}

export default CheckOutPage
