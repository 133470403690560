import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material'
import { Collapse, Grid, IconButton, Theme, useMediaQuery } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import * as R from 'ramda'
import {
  Defaults,
  NumberUtils,
  PermissionArea,
  Text,
  useInterval,
  Utils,
} from '@pbt/pbt-ui-components'
import { Edit as EditIcon } from '@pbt/pbt-ui-components/src/icons'

import BusinessShareIcon from '~/components/common/icons/BusinessShareIcon'
import AlertLabel from '~/components/common/labels/AlertLabel'
import LinkedChewyAccountContainer, {
  KyriosAccountType,
} from '~/components/dashboard/link-chewy-account/LinkedChewyAccountContainer'
import { AlertColorLevel } from '~/constants/alertColors'
import DialogNames from '~/constants/DialogNames'
import FeatureToggle from '~/constants/featureToggle'
import { partialUpdateClient } from '~/store/actions/clients'
import {
  fetchClientBillingActivity,
  getBillingActivitySummary,
  getShouldRefreshData,
} from '~/store/duck/clientBillingActivityData'
import {
  fetchClientChargeSheetItemsCount,
  getChargeSheetItemsCount,
} from '~/store/duck/clientFinanceData'
import {
  getCRUDByArea,
  getCurrentBusinessIsOmniChannel,
  getCurrentBusinessSendRemindersEnabled,
  getCurrentBusinessWellnessPlansEnabled,
  getGroupCRUDByArea,
} from '~/store/reducers/auth'
import { getFeatureToggle } from '~/store/reducers/constants'
import { getPatientsForUser, getUser } from '~/store/reducers/users'
import useDialog from '~/utils/useDialog'
import { useNavigatePrevState } from '~/utils/useNavigatePrevState'

import { ClientPreferencesChip } from '../../preferences/ClientPreferencesChip'
import ClientAndPatientDetailsRow from './ClientAndPatientDetailsRow'
import ClientDetailsSection from './ClientDetailsSection'
import ClientInfoLinkItem from './ClientInfoLinkItem'
import ClientInfoLinkType from './ClientInfoLinkType'
import ClientMembershipLinkItem from './ClientMembershipLinkItem'
import { InlineEditInputType } from './InlineEditPopper'
import PreferredContactMethod from './PreferredContactMethod'

const useStyles = makeStyles(
  (theme) => ({
    root: {
      backgroundColor: theme.colors.tableBackground,
      border: theme.constants.tableBorder,
      margin: theme.spacing(1),
    },
    alertLabelRoot: {
      borderRadius: 0,
    },
    alertLabelText: {
      lineHeight: '1.7rem',
      padding: theme.spacing(0.5, 0),
    },
    alertLabelBadge: {
      justifyContent: 'flex-start',
      marginBottom: theme.spacing(1),
    },
    editIcon: {
      color: theme.colors.editIconColor,
    },
    expandDetailsButton: {
      color: theme.colors.title,
      cursor: 'pointer',
      border: `1px solid ${theme.colors.title}`,
    },
    balanceLabel: {
      fontWeight: 500,
      color: theme.colors.secondaryText,
    },
    balanceSubLabelWarning: {
      color: theme.colors.important,
      backgroundColor: theme.colors.actionNeededBackground,
    },
    balanceSubLabelNegative: {
      color: theme.colors.success,
      backgroundColor: theme.colors.successBackground,
    },
    balanceSubLabelNormal: {
      color: theme.colors.lowAccentText,
      backgroundColor: theme.colors.tableOddRowBackground,
    },
    iconButton: {
      marginLeft: 'auto',
      padding: theme.spacing(0.5),
      alignSelf: 'flex-start',
    },
    chip: {
      width: 16,
      height: 16,
      marginRight: theme.spacing(0.5),
    },
    chipMedium: {
      width: 24,
      height: 24,
      marginRight: theme.spacing(1),
    },
  }),
  { name: 'ClientSection' },
)

interface ClientSectionPropsProps {
  clientId: string
  highlight?: ClientInfoLinkType
  patientId?: string
}

const ClientSection = ({
  clientId,
  patientId,
  highlight,
}: ClientSectionPropsProps) => {
  const classes = useStyles()
  const navigate = useNavigate()
  const navigatePrevState = useNavigatePrevState()
  const isOmnichannel = useSelector(getCurrentBusinessIsOmniChannel)
  const isWPSurfaceClientStatusEnabled = useSelector(
    getFeatureToggle(FeatureToggle.WP_SURFACE_CLIENT_STATUS),
  )

  const MembershipLinkComponent =
    isOmnichannel && isWPSurfaceClientStatusEnabled
      ? ClientMembershipLinkItem
      : ClientInfoLinkItem

  const dispatch = useDispatch()
  const { t } = useTranslation(['Common', 'Clients', 'Payments'])

  const isXs = useMediaQuery<Theme>((theme) => theme.breakpoints.down('sm'))

  const [openClientDetailsEditDialog] = useDialog(
    DialogNames.CLIENT_DETAILS_EDIT,
  )

  const { read: invoiceReadPermissions } = useSelector(
    getCRUDByArea(PermissionArea.INVOICE),
  )
  const { read: paymentReadPermissions } = useSelector(
    getCRUDByArea(PermissionArea.PAYMENT),
  )
  const { read: communicationReadPermissions } = useSelector(
    getCRUDByArea(PermissionArea.COMMUNICATION),
  )
  const { read: patientGroupReadPermissions } = useSelector(
    getGroupCRUDByArea(PermissionArea.PATIENT),
  )
  const { update: patientUpdatePermissions } = useSelector(
    getCRUDByArea(PermissionArea.PATIENT),
  )
  const { read: wellnessPlanReadPermissions } = useSelector(
    getCRUDByArea(PermissionArea.WELLNESS_PLAN),
  )

  const wellnessPlansEnabled = useSelector(
    getCurrentBusinessWellnessPlansEnabled,
  )
  const patientsIds = useSelector(getPatientsForUser(clientId), R.equals)
  const showSendRemindersBusinessLevel = useSelector(
    getCurrentBusinessSendRemindersEnabled,
  )
  const isPatientSharingEnabled = useSelector(
    getFeatureToggle(FeatureToggle.PATIENT_SHARING),
  )
  const isChargeSheetEnabled = useSelector(
    getFeatureToggle(FeatureToggle.CHARGE_SHEET),
  )
  const isBoopDisablementEnabled = useSelector(
    getFeatureToggle(FeatureToggle.BOOP_DISABLEMENT),
  )
  const isIpoM0RevampClientBillingEnabled = useSelector(
    getFeatureToggle(FeatureToggle.IPO_M0_REVAMP_CLIENT_BILLING_PAGE),
  )
  const client = useSelector(getUser(clientId))
  const chargeSheetItemsCount = useSelector(getChargeSheetItemsCount)
  const clientBillingActivitySummary = useSelector(getBillingActivitySummary)
  const refresh = useSelector(getShouldRefreshData)

  const clientBalance = isIpoM0RevampClientBillingEnabled
    ? clientBillingActivitySummary.balance
    : client?.balance || 0

  const areAnyUnappliedPaymentsOrUnpaidInvoices = () =>
    isIpoM0RevampClientBillingEnabled &&
    ((!R.isNil(clientBillingActivitySummary.unappliedPayments) &&
      clientBillingActivitySummary.unappliedPayments?.count > 0) ||
      (!R.isNil(clientBillingActivitySummary.unpaidInvoices) &&
        clientBillingActivitySummary.unpaidInvoices?.count > 0))

  const subBalanceLabelClass =
    clientBalance < 0
      ? classes.balanceSubLabelNegative
      : clientBalance > 0 || areAnyUnappliedPaymentsOrUnpaidInvoices()
      ? classes.balanceSubLabelWarning
      : classes.balanceSubLabelNormal

  const [clientDetailsExpanded, setClientDetailsExpanded] = useState(false)

  useEffect(() => {
    setClientDetailsExpanded(!patientId)
  }, [patientId])

  useEffect(() => {
    if (isChargeSheetEnabled) {
      dispatch(fetchClientChargeSheetItemsCount({ clientId }))
    }
  }, [clientId, isChargeSheetEnabled])

  useEffect(() => {
    if (isIpoM0RevampClientBillingEnabled || refresh) {
      dispatch(fetchClientBillingActivity(clientId))
    }
  }, [clientId, refresh])

  useInterval(() => {
    if (isIpoM0RevampClientBillingEnabled) {
      dispatch(fetchClientBillingActivity(clientId))
    }
  }, Defaults.BALANCE_PAGE_UPDATE_INTERVAL)

  const name = Utils.getPersonString(client)

  const chipClassName = isBoopDisablementEnabled
    ? classes.chipMedium
    : classes.chip

  const handleEditClientDetails = () => {
    if (isXs) {
      navigate(`/client/${clientId}/edit`, {
        state: {
          title: t('Common:CLIENT_DETAILS'),
          renderCoparents: false,
        },
      })
    } else {
      openClientDetailsEditDialog({
        clientId,
      })
    }
  }

  const handleNavigateToCommunication = () => {
    navigate(`/communications/client/${clientId}?showNewConversation=true`)
  }

  const updateNotes = (newValue: string) => {
    dispatch(partialUpdateClient({ id: clientId, notes: newValue }))
  }

  const clientMainDetails = [
    {
      label: t('Common:NOTES'),
      value: client?.notes!,
      onEdit: updateNotes,
      inputType: InlineEditInputType.MULTILINE,
      fieldProps: { name: 'notes', initialValue: client?.notes || '' },
      inputProps: { minRows: 5 },
    },
  ]

  const showExternalGroupBalanceAlert =
    isPatientSharingEnabled &&
    patientGroupReadPermissions &&
    client?.hasBalanceInOtherGroupBusinesses

  return (
    <Grid container className={classes.root} direction="column">
      {client?.active === false && (
        <AlertLabel
          classes={{
            root: classes.alertLabelRoot,
            text: classes.alertLabelText,
          }}
          message={t('Common:INACTIVE_ONE')}
          variant="normal"
        />
      )}
      {client?.alertText && (
        <AlertLabel
          alertColorId={client?.alertColorId}
          alertColorLevel={AlertColorLevel.CLIENT}
          aria-label="client-alert"
          clamp={Number.MAX_SAFE_INTEGER}
          classes={{
            root: classes.alertLabelRoot,
            text: classes.alertLabelText,
          }}
          message={client?.alertText}
          variant="attention"
        />
      )}
      <Grid container direction="column" pt={0.5} px={1}>
        <Grid container item alignItems="center" mb={1} wrap="nowrap">
          <Grid container item>
            <Text variant="h1">{name}</Text>
            <Grid container>
              <ClientPreferencesChip
                className={chipClassName}
                clientId={clientId}
              />
              <LinkedChewyAccountContainer
                accountType={KyriosAccountType.CLIENT}
                className={chipClassName}
                clientId={clientId}
              />
              {patientGroupReadPermissions && (
                <BusinessShareIcon
                  noMargin
                  businessIds={client?.clientInContextBusinesses}
                  variant={isBoopDisablementEnabled ? 'big' : 'default'}
                />
              )}
            </Grid>
          </Grid>
          {patientUpdatePermissions && (
            <IconButton
              className={classes.iconButton}
              size="large"
              type="button"
              onClick={handleEditClientDetails}
            >
              <EditIcon className={classes.editIcon} />
            </IconButton>
          )}
        </Grid>
        {client?.promoteWellnessPlans === false && (
          <AlertLabel
            clamp={2}
            classes={{
              root: classes.alertLabelBadge,
              text: classes.alertLabelText,
            }}
            message={t(
              'Clients:CLIENT_SECTION.ALERT_LABEL.DO_NOT_PROMOTE_WELLNESS',
            )}
            variant="normal"
          />
        )}
        {client?.sendReminders === false && showSendRemindersBusinessLevel && (
          <AlertLabel
            classes={{
              root: classes.alertLabelBadge,
              text: classes.alertLabelText,
            }}
            message={t(
              'Clients:CLIENT_SECTION.ALERT_LABEL.NO_REMINDER_COMMUNICATIONS',
            )}
            variant="normal"
          />
        )}
        <PreferredContactMethod
          clientId={clientId}
          onClick={handleNavigateToCommunication}
        />
        {paymentReadPermissions && !isIpoM0RevampClientBillingEnabled && (
          <ClientInfoLinkItem
            alertText={
              showExternalGroupBalanceAlert
                ? t('Clients:CLIENT_HAS_BALANCE_AT_ANOTHER_LOCATION')
                : null
            }
            classes={{ label: classes.balanceLabel }}
            highlighted={highlight === ClientInfoLinkType.BALANCE}
            label={`${t('Common:BALANCE')}: ${NumberUtils.formatMoney(
              client?.balance,
            )}`}
            onClick={() =>
              navigate(`/balance/${clientId}/`, { state: { patientId } })
            }
          />
        )}
        {paymentReadPermissions && isIpoM0RevampClientBillingEnabled && (
          <ClientInfoLinkItem
            alertText={
              showExternalGroupBalanceAlert
                ? t('Clients:CLIENT_HAS_BALANCE_AT_ANOTHER_LOCATION')
                : null
            }
            classes={{
              label: classes.balanceLabel,
              subLabel: subBalanceLabelClass,
            }}
            label={t('Common:CLIENT_BALANCE_ACTIVITY')}
            subLabel={`${t('Common:CLIENT_BALANCE')}: ${NumberUtils.formatMoney(
              clientBalance,
            )}`}
            onClick={() =>
              navigate(`/balance/${clientId}/`, { state: { patientId } })
            }
          />
        )}
        {isChargeSheetEnabled && invoiceReadPermissions && (
          <ClientInfoLinkItem
            showDivider
            label={`${t('Payments:CHARGES')}: (${
              chargeSheetItemsCount ?? '...'
            })`}
            onClick={() => navigatePrevState(`/chargesheet/${clientId}`)}
          />
        )}
        {communicationReadPermissions && (
          <ClientInfoLinkItem
            showDivider
            highlighted={highlight === ClientInfoLinkType.COMMUNICATION}
            label={t('Common:COMMUNICATION')}
            onClick={() =>
              navigate(`/communications/client/${clientId}/`, {
                state: { patientId },
              })
            }
          />
        )}
        {wellnessPlansEnabled && wellnessPlanReadPermissions && (
          <MembershipLinkComponent
            showDivider
            clientId={clientId}
            highlighted={highlight === ClientInfoLinkType.MEMBERSHIP}
            label={t('Common:MEMBERSHIP')}
            onClick={() =>
              navigate(`/membership/${clientId}/`, { state: { patientId } })
            }
          />
        )}
        {clientMainDetails.filter(Boolean).map((props) => (
          <ClientAndPatientDetailsRow
            entityType="client"
            key={clientId + props.label}
            {...props}
          />
        ))}
      </Grid>
      <Grid
        container
        alignItems="center"
        className={classes.expandDetailsButton}
        px={1}
        py={0.5}
        onClick={() => setClientDetailsExpanded(!clientDetailsExpanded)}
      >
        <Text strong mr={0.5} variant="body2">
          {clientDetailsExpanded ? t('Common:HIDE_ACTION') : t('Common:MORE')}{' '}
          {t('Common:CLIENT_DETAILS').toLowerCase()}
        </Text>
        {clientDetailsExpanded ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
      </Grid>
      <Grid px={1}>
        <Collapse in={clientDetailsExpanded}>
          <ClientDetailsSection
            clientId={clientId}
            mainPatientId={patientId}
            patientsIds={patientsIds}
          />
        </Collapse>
      </Grid>
    </Grid>
  )
}

export default ClientSection
