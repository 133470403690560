import React from 'react'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import {
  CircularProgress,
  Grid,
  Table,
  TableCell,
  TableHead,
  TableRow,
} from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import classNames from 'classnames'
import * as R from 'ramda'
import { Text } from '@pbt/pbt-ui-components'

import { FINANCE_TABLE_PADDING_X_SPACING_VALUE } from '~/constants/financeTable'
import { useGetInvoiceV3Items } from '~/store/hooks/invoiceV3'
import {
  getInvoiceV3,
  getInvoiceV3Loading,
  useIsAnyPrepaid,
} from '~/store/reducers/invoiceV3'

import { LineItemComponentsActions } from '../../soap/rail/summary/orders/orderSummaryUtils'
import {
  INVOICE_COLUMNS,
  INVOICE_COLUMNS_WITH_REFUND,
} from '../../soapV2/charges/table/Columns'
import ChargesPanel from '../components/ChargesPanel'
import InvoiceTableFooter from './InvoiceTableFooter'
import InvoiceTableSubHeader from './InvoiceTableSubHeader'

const useStyles = makeStyles(
  (theme) => ({
    tableTitle: {
      color: theme.colors.tabLabel,
      fontWeight: theme.typography.fontWeightBold,
      fontSize: '1.4rem',
      height: theme.spacing(4),
      padding: theme.spacing(0, 1),
      borderBottom: 'none',
      '&:first-of-type': {
        paddingLeft: theme.spacing(FINANCE_TABLE_PADDING_X_SPACING_VALUE + 1.5),
      },
      '&:last-of-type': {
        textAlign: 'right',
        paddingRight: theme.spacing(FINANCE_TABLE_PADDING_X_SPACING_VALUE),
      },
    },
    chargeInformationPanel: {
      top: theme.mixins.toolbar.minHeight,
      maxHeight: `calc(100vh - ${theme.mixins.toolbar.minHeight}px)`,
    },
    tableSubTitle: {
      padding: theme.spacing(0.5, 1),
      borderBottom: 'none',
      '&:first-of-type': {
        paddingLeft: theme.spacing(FINANCE_TABLE_PADDING_X_SPACING_VALUE + 1.5),
      },
      '&:last-of-type': {
        textAlign: 'right',
        paddingRight: theme.spacing(FINANCE_TABLE_PADDING_X_SPACING_VALUE),
      },
    },
  }),
  { name: 'ViewInvoice' },
)

export const InvoiceDetails = () => {
  const classes = useStyles()

  const isInvoiceLoading = useSelector(getInvoiceV3Loading)

  const { invoiceId } = useParams()
  const invoice = useSelector(getInvoiceV3(invoiceId))
  const items = useGetInvoiceV3Items(invoiceId)

  const hasRefunds = !R.isEmpty(invoice?.refunds || [])

  const showPaidUsedRemainingLabel = useIsAnyPrepaid()

  const columns = hasRefunds ? INVOICE_COLUMNS_WITH_REFUND : INVOICE_COLUMNS

  return (
    <>
      <Table>
        <TableHead>
          <TableRow>
            {columns.map(({ label, width, id }) => (
              <TableCell
                className={classNames(classes.tableTitle)}
                key={id}
                width={width}
              >
                <Text inline strong variant="lowAccent2">
                  {label}
                </Text>
              </TableCell>
            ))}
          </TableRow>
          <TableRow>
            {columns.map(({ subLabels, width, id }) => (
              <TableCell
                className={classes.tableSubTitle}
                key={id}
                width={width}
              >
                {showPaidUsedRemainingLabel && subLabels && (
                  <Text variant="lowAccent5">
                    {subLabels.map((subLabel) => subLabel.label).join(' | ')}
                  </Text>
                )}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
      </Table>
      <Grid container item>
        <Grid container item alignItems="center" justifyContent="center">
          {isInvoiceLoading ? (
            <CircularProgress color="primary" size={32} />
          ) : (
            <ChargesPanel
              isInvoice
              ComponentsActions={LineItemComponentsActions}
              Footer={InvoiceTableFooter}
              SubHeader={InvoiceTableSubHeader}
              chargesGroupedItems={items}
              chargesId={invoiceId}
              classes={{
                chargeInformationPanel: classes.chargeInformationPanel,
              }}
              hasHeader={false}
              invoiceId={invoiceId}
            />
          )}
        </Grid>
      </Grid>
    </>
  )
}
