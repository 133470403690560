/* eslint-disable max-lines */
import React, { memo, useEffect, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import {
  Fab,
  FormControlLabel,
  Grid,
  Link,
  Radio,
  RadioGroup,
  Table,
  TableBody,
  TableCell,
  TableRow,
} from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import classNames from 'classnames'
import * as R from 'ramda'
import {
  LanguageUtils,
  NumberUtils,
  PermissionArea,
  PuiTextField,
  StateLabel,
  Text,
  TextWithTooltip,
  useFields,
  Utils,
} from '@pbt/pbt-ui-components'

import PosPayTerminalFrontView from '~/components/common/images/PosPayTerminalFrontView.png'
import RhapsodyPassBackground from '~/components/common/images/RhapsodyPassBackground.svg'
import EnumSelect from '~/components/common/inputs/EnumSelect'
import Spacer from '~/components/common/Spacer'
import FeatureToggle from '~/constants/featureToggle'
import PaymentProcessors from '~/constants/PaymentProcessors'
import {
  RhapsodyGoConfigurationLabels,
  RhapsodyPassStates,
  TranslatedRhapsodyGoConfigurationLabels,
  TranslatedRhapsodyPassStates,
} from '~/constants/RhapsodyPassStates'
import i18n from '~/locales/i18n'
import {
  fetchRhapsodyPayConfig,
  getRhapsodyPayConfig,
  updateRhapsodyPayConfigManually,
} from '~/store/duck/rhapsodyPay'
import { getCRUDByArea } from '~/store/reducers/auth'
import {
  getFeatureToggle,
  getPayGoConfigList,
  getPayStatusList,
} from '~/store/reducers/constants'
import {
  BasePracticeDetailsSectionProps,
  PracticeRhapsodyPayFields,
} from '~/types'
import { usePracticeFieldsSection } from '~/utils/usePracticeFieldsSection'

import { PracticeDetailsPanels } from '../../practices'
import RhapsodyPaySetupDialog, { Steps } from './RhapsodyPaySetupDialog'

const useStyles = makeStyles(
  (theme) => ({
    root: {
      padding: theme.spacing(1, 2, 2, 2),
      height: '100%',
    },
    startPage: {
      backgroundImage: `url(${RhapsodyPassBackground})`,
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'bottom center',
      backgroundSize: '100%, auto',
      [theme.breakpoints.down('lg')]: {
        backgroundSize: 'auto auto',
      },
      [theme.breakpoints.down('sm')]: {
        overflow: 'hidden',
      },
    },
    welcomePageText: {
      [theme.breakpoints.down('lg')]: {
        fontSize: '1.4rem',
      },
      lineHeight: '2.2rem',
    },
    button: {
      width: 150,
      height: 40,
      marginTop: theme.spacing(2),
    },
    link: {
      display: 'inline-flex',
      fontSize: '1.6rem',
      textDecoration: 'underline',
      color: theme.colors.link,
      cursor: 'pointer',
    },
    tableViewport: {
      border: theme.constants.tableBorder,
      margin: theme.spacing(1, 0, 2),
    },
    tableCell: {
      padding: theme.spacing(1),
      border: 'none',
      borderRight: theme.constants.tableBorder,
      fontSize: '1.4rem',
      color: `${theme.colors.secondaryText} !important`,
    },
    tableRow: {
      '&:nth-of-type(even)': {
        backgroundColor: theme.colors.tableEvenItem,
      },
    },
    dataCell: {
      padding: theme.spacing(2, 9, 2, 4),
    },
    terminalImgFrontView: {
      height: 322,
      [theme.breakpoints.down('lg')]: {
        height: 250,
      },
      position: 'relative',
      top: theme.spacing(-4),
      left: theme.spacing(1),
    },
    icon: {
      padding: 0,
    },
    infoIcon: {
      position: 'inherit',
      cursor: 'pointer',
      margin: 0,
    },
    readOnly: {
      pointerEvents: 'none',
    },
    sectionWithBottomBorder: {
      height: 45,
      borderBottom: theme.constants.tabBorder,
    },
  }),
  { name: 'RhapsodyPaySection' },
)

const RHAPSODY_PAY_DEFINITION = i18n.t(
  'Businesses:RHAPSODY_PAY.RHAPSODY_PAY_DEFINITION',
)
const RHAPSODY_PASS_DEFINITION = i18n.t(
  'Businesses:RHAPSODY_PAY.RHAPSODY_PASS_DEFINITION',
)
const RHAPSODY_GO_DEFINITION = i18n.t(
  'Businesses:RHAPSODY_PAY.RHAPSODY_GO_DEFINITION',
)

const RhapsodyPaySection = ({ business }: BasePracticeDetailsSectionProps) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const { t } = useTranslation(['Common', 'Businesses'])

  const permissions = useSelector(getCRUDByArea(PermissionArea.SUPPORT))
  const rhapsodyPayInitialConfig = useSelector(
    getRhapsodyPayConfig(business.id),
  )
  const PayStatusList = useSelector(getPayStatusList)
  const PayGoConfigList = useSelector(getPayGoConfigList)
  const isStripeFeatureEnabled = useSelector(
    getFeatureToggle(FeatureToggle.RHAPSODY_GO_STRIPE_INTEGRATION),
  )

  const isBusinessOmniChannel = business.omniChannel
  const isBraintreeFeatureEnabled = useSelector(
    getFeatureToggle(FeatureToggle.RHAPSODY_PAY_BRAINTREE_INTEGRATION),
  )

  const receivedPayStatus = PayStatusList.find(
    ({ id }: { id: string }) => id === rhapsodyPayInitialConfig?.payStatus,
  )

  const [rhapsodyPayDialogOpen, setRhapsodyPayDialogOpen] = useState(false)

  const isFAActive =
    Utils.findById(rhapsodyPayInitialConfig?.payStatus, PayStatusList)?.name ===
    RhapsodyPassStates.ACTIVE

  const rhapsodyGoStatus = Utils.findById(
    rhapsodyPayInitialConfig?.goOption,
    PayGoConfigList,
  )
  const isRhapsodyGoIncluded =
    rhapsodyGoStatus?.name === RhapsodyGoConfigurationLabels.INCLUDED

  const { fields, validate, reset } = useFields(
    [
      {
        name: 'merchantId',
        label: t('Businesses:RHAPSODY_PAY.MERCHANT_ID'),
        validators: isFAActive && permissions.update ? ['required'] : undefined,
        initialValue: rhapsodyPayInitialConfig?.merchantId || '',
      },
      {
        name: 'merchantGoId',
        label: t('Businesses:RHAPSODY_PAY.MERCHANT_GO_ID'),
        validators: [],
        initialValue:
          rhapsodyPayInitialConfig?.goPreferences?.merchantGoId || '',
      },
      {
        name: 'achProcessorId',
        label: t('Businesses:RHAPSODY_PAY.ACH_PROCESSOR_ID'),
        validators: [],
        initialValue:
          rhapsodyPayInitialConfig?.goPreferences?.achProcessorId || '',
      },
      {
        name: 'cardProcessorId',
        label: t('Businesses:RHAPSODY_PAY.CARD_PROCESSOR_ID'),
        validators: [],
        initialValue:
          rhapsodyPayInitialConfig?.goPreferences?.cardProcessorId || '',
      },
      {
        name: 'transcenterId',
        label: t('Businesses:RHAPSODY_PAY.TRANSCENTER_ID'),
        validators: [],
        initialValue:
          rhapsodyPayInitialConfig?.goPreferences?.transcenterId || '',
      },
      {
        name: 'spinAuthKey',
        label: 'SPIN AuthKey',
        validators: [],
        initialValue: rhapsodyPayInitialConfig?.spinAuthKey || '',
      },
      {
        name: 'key',
        label: t('Businesses:RHAPSODY_PAY.MERCHANT_KEY'),
        validators: [],
        initialValue: rhapsodyPayInitialConfig?.goPreferences?.key || '',
      },
      {
        name: 'stripeAccountId',
        label: t('Businesses:RHAPSODY_PAY.ACCOUNT_ID'),
        validators: [],
        initialValue:
          rhapsodyPayInitialConfig?.stripePreferences?.accountId || '',
      },
      {
        // cnp = card not present - For POS systems
        name: 'braintreeCnpMerchantAccountId',
        label: t('Businesses:RHAPSODY_PAY.MERCHANT_ACCOUNT_ID'),
        validators: [],
        initialValue:
          rhapsodyPayInitialConfig?.payBraintreePreferences
            ?.cnpMerchantAccountId || '',
      },
      {
        // cp = card present - for non-POS systems
        name: 'braintreeCpMerchantAccountId',
        label: t('Businesses:RHAPSODY_PAY.MERCHANT_ACCOUNT_ID'),
        validators: [],
        initialValue:
          rhapsodyPayInitialConfig?.payBraintreePreferences
            ?.cpMerchantAccountId || '',
      },
      {
        name: 'goProcessor',
        validators: [],
        initialValue: rhapsodyPayInitialConfig?.goProcessor || '',
      },
      {
        name: 'posProcessor',
        validators: [],
        initialValue:
          rhapsodyPayInitialConfig?.posProcessor ||
          PaymentProcessors.FIRST_AMERICAN,
      },
      {
        name: 'firstAmericanStatus',
        validators: [],
        initialValue: rhapsodyPayInitialConfig?.payStatus || '',
      },
    ],
    false,
  )

  const {
    merchantId,
    merchantGoId,
    achProcessorId,
    cardProcessorId,
    transcenterId,
    key,
    spinAuthKey,
    stripeAccountId,
    braintreeCnpMerchantAccountId,
    // TODO: POS/cp section will be enabled with CVC m2a
    // braintreeCpMerchantAccountId,
    goProcessor,
    posProcessor,
    firstAmericanStatus,
  } = fields

  const onSave = () => {
    dispatch(
      updateRhapsodyPayConfigManually({
        ...rhapsodyPayInitialConfig,
        goProcessor: goProcessor.value,
        spinAuthKey: spinAuthKey.value,
        stripePreferences: {
          ...rhapsodyPayInitialConfig?.stripePreferences,
          accountId: stripeAccountId.value,
        },
        posProcessor: posProcessor.value,
        goPreferences: {
          ...rhapsodyPayInitialConfig?.goPreferences,
          merchantGoId: merchantGoId.value,
          key: key.value,
          cardProcessorId: cardProcessorId.value,
          achProcessorId: achProcessorId.value,
          transcenterId: transcenterId.value,
        },
        merchantId: merchantId.value,
        payStatus: firstAmericanStatus.value,
        payBraintreePreferences: {
          cnpMerchantAccountId: braintreeCnpMerchantAccountId.value,
          // TODO: POS/cp section will be enabled with CVC m2a
          // cpMerchantAccountId: braintreeCpMerchantAccountId.value,
        },
      }),
    )
  }

  usePracticeFieldsSection<PracticeRhapsodyPayFields>({
    business,
    fields,
    onSave,
    sectionName: PracticeDetailsPanels.RHAPSODY_PAY,
    validate,
    reset,
    resetDependencies: !R.isEmpty(rhapsodyPayInitialConfig)
      ? [business, rhapsodyPayInitialConfig]
      : [business],
  })

  useEffect(() => {
    if (receivedPayStatus) {
      setRhapsodyPayDialogOpen(false)
    }
  }, [receivedPayStatus])

  useEffect(() => {
    if (business.id) {
      dispatch(fetchRhapsodyPayConfig(business.id, true))
    }
  }, [business.id])

  const displayedStatusName =
    firstAmericanStatus.value && !R.isEmpty(PayStatusList)
      ? LanguageUtils.getTranslatedFieldName(
          PayStatusList.find((item) => item.id === firstAmericanStatus.value),
        )
      : TranslatedRhapsodyPassStates[RhapsodyPassStates.NOT_STARTED]

  const receivedPayStatusName =
    receivedPayStatus?.id && !R.isEmpty(PayStatusList)
      ? PayStatusList.find((item) => item.id === receivedPayStatus.id)?.name
      : RhapsodyPassStates.NOT_STARTED

  const mapBraintreeStatus = (status?: string) => {
    switch (status) {
      case 'ACTIVE':
        return t('Common:ACTIVE')
      case 'PENDING':
        return t('Common:PENDING')
      case 'ERROR':
        return t('Common:ERROR')
      case 'Sync.Error':
        return t('Common:SYNC_ERROR')
      default:
        return ''
    }
  }

  return (
    <>
      {receivedPayStatusName === RhapsodyPassStates.NOT_STARTED && (
        <Grid
          container
          className={classNames(classes.root, classes.startPage)}
          direction="column"
        >
          <Grid container alignContent="center">
            <Grid container item direction="column" sm={7}>
              <Text align="left" className={classes.welcomePageText}>
                <Trans
                  components={{
                    boldText: (
                      <Text inline strong component="span" variant="body2" />
                    ),
                  }}
                  i18nKey="Businesses:RHAPSODY_PAY.RHAPSODY_PAY_IS_OUR_INNOVATIVE_SECURE_PLATFORM"
                />
              </Text>
              <Text align="left" className={classes.welcomePageText} mt={8}>
                <Trans
                  components={{
                    boldText: (
                      <Text inline strong component="span" variant="body2" />
                    ),
                  }}
                  i18nKey="Businesses:RHAPSODY_PAY.WITH_RHAPSODY_PAY_YOUR_CLIENTS_CAN_CHECK_OUT_AND_PAY_IN_EXAM_ROOM"
                />
              </Text>
              <Fab
                className={classes.button}
                color="inherit"
                variant="extended"
                onClick={() => setRhapsodyPayDialogOpen(true)}
              >
                {t('Businesses:RHAPSODY_PAY.START_NOW')}
              </Fab>
            </Grid>
            <Grid container item sm justifyContent="center">
              <img
                alt="TerminalFrontView"
                className={classes.terminalImgFrontView}
                src={PosPayTerminalFrontView}
              />
            </Grid>
          </Grid>
          <RhapsodyPaySetupDialog
            businessId={business.id}
            open={rhapsodyPayDialogOpen}
            rhapsodyPayConfig={rhapsodyPayInitialConfig}
            step={Steps.RATES}
            onClose={() => setRhapsodyPayDialogOpen(false)}
          />
        </Grid>
      )}
      {receivedPayStatusName !== RhapsodyPassStates.NOT_STARTED && (
        <Grid container item className={classes.root} direction="column">
          <Text strong mr={1} variant="subheading3">
            {t('Businesses:RHAPSODY_PAY.FIRST_AMERICAN')}
          </Text>
          <Grid container item direction="column" pb={2} pl={2}>
            <Grid
              container
              item
              alignItems="center"
              className={classes.sectionWithBottomBorder}
              justifyContent="flex-start"
            >
              <Grid item xs={3}>
                <Text strong variant="body2">
                  {t('Common:STATUS')}
                </Text>
              </Grid>
              <Grid item>
                {permissions.update && (
                  <EnumSelect
                    accent
                    Constant={PayStatusList}
                    field={firstAmericanStatus}
                  />
                )}
                {!permissions.update && (
                  <StateLabel warning>{displayedStatusName}</StateLabel>
                )}
              </Grid>
            </Grid>
            <Grid
              container
              item
              alignItems="center"
              className={classes.sectionWithBottomBorder}
              justifyContent="flex-start"
            >
              <Grid item xs={3}>
                <Text strong variant="body2">
                  {t('Businesses:RHAPSODY_PAY.MERCHANT_PORTAL')}:
                </Text>
              </Grid>
              <Grid item xs={9}>
                <Link
                  className={classes.link}
                  href="https://FirstView.net"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  FirstView.net
                </Link>
              </Grid>
            </Grid>
            <Grid
              item
              className={classNames({
                [classes.readOnly]: !permissions.update,
              })}
              md={3}
              pr={2}
            >
              <PuiTextField field={merchantId} label={merchantId.label} />
            </Grid>
            <Grid
              item
              className={classNames({
                [classes.readOnly]: !permissions.update,
              })}
              md={3}
              pr={2}
            >
              <PuiTextField field={spinAuthKey} label={spinAuthKey.label} />
            </Grid>
            {isRhapsodyGoIncluded && (
              <>
                <Spacer />
                <Text strong mr={1} variant="subheading3">
                  {t('Businesses:RHAPSODY_PAY.RHAPSODY_GO_SETTINGS')}:
                </Text>
                <Grid container>
                  <Grid
                    item
                    className={classNames({
                      [classes.readOnly]: !permissions.update,
                    })}
                    md={4}
                    pr={2}
                  >
                    <PuiTextField
                      field={merchantGoId}
                      label={merchantGoId.label}
                    />
                  </Grid>
                  <Grid
                    item
                    className={classNames({
                      [classes.readOnly]: !permissions.update,
                    })}
                    md={4}
                    pr={2}
                  >
                    <PuiTextField
                      autoComplete="new-password"
                      field={key}
                      label={key.label}
                      type="password"
                    />
                  </Grid>
                  <Grid
                    item
                    className={classNames({
                      [classes.readOnly]: !permissions.update,
                    })}
                    md={4}
                    pr={2}
                  >
                    <PuiTextField
                      field={achProcessorId}
                      label={achProcessorId.label}
                    />
                  </Grid>
                  <Grid
                    item
                    className={classNames({
                      [classes.readOnly]: !permissions.update,
                    })}
                    md={4}
                    pr={2}
                  >
                    <PuiTextField
                      field={cardProcessorId}
                      label={cardProcessorId.label}
                    />
                  </Grid>
                  <Grid
                    item
                    className={classNames({
                      [classes.readOnly]: !permissions.update,
                    })}
                    md={4}
                    pr={2}
                  >
                    <PuiTextField
                      field={transcenterId}
                      label={transcenterId.label}
                    />
                  </Grid>
                </Grid>
              </>
            )}
          </Grid>
          {isStripeFeatureEnabled && (
            <>
              <Text strong mr={1} variant="subheading3">
                {t('Businesses:RHAPSODY_PAY.STRIPE')}
              </Text>
              <Grid container item direction="column" pb={2} pl={2}>
                <Grid
                  container
                  item
                  alignItems="center"
                  className={classes.sectionWithBottomBorder}
                  justifyContent="flex-start"
                >
                  <Grid item xs={3}>
                    <Text strong variant="body2">
                      {t('Common:STATUS')}
                    </Text>
                  </Grid>
                  <Grid item>
                    <StateLabel
                      success={
                        rhapsodyPayInitialConfig?.stripePreferences?.active
                      }
                      warning={
                        !rhapsodyPayInitialConfig?.stripePreferences?.active
                      }
                    >
                      {displayedStatusName}
                    </StateLabel>
                  </Grid>
                  {rhapsodyPayInitialConfig?.stripePreferences?.onboardURL && (
                    <Grid item>
                      <Link
                        className={classes.link}
                        href={
                          rhapsodyPayInitialConfig.stripePreferences.onboardURL
                        }
                        pl={4}
                        rel="noopener noreferrer"
                        target="_blank"
                      >
                        {t('Businesses:RHAPSODY_PAY.GO_TO_APPLICATION')}
                      </Link>
                    </Grid>
                  )}
                </Grid>
                {rhapsodyPayInitialConfig?.stripePreferences?.dashboardURL && (
                  <Grid
                    container
                    item
                    alignItems="center"
                    className={classes.sectionWithBottomBorder}
                    justifyContent="flex-start"
                  >
                    <Grid item xs={3}>
                      <Text strong variant="body2">
                        {t('Businesses:RHAPSODY_PAY.MERCHANT_PORTAL')}:
                      </Text>
                    </Grid>
                    <Grid item xs={9}>
                      <Link
                        className={classes.link}
                        href={
                          rhapsodyPayInitialConfig.stripePreferences
                            .dashboardURL
                        }
                        rel="noopener noreferrer"
                        target="_blank"
                      >
                        {t('Businesses:RHAPSODY_PAY.YOUR_STRIPE_DASHBOARD')}
                      </Link>
                    </Grid>
                  </Grid>
                )}
                <Grid
                  item
                  className={classNames({
                    [classes.readOnly]: !permissions.update,
                  })}
                  md={3}
                  pr={2}
                >
                  <PuiTextField
                    field={stripeAccountId}
                    label={stripeAccountId.label}
                  />
                </Grid>
              </Grid>
              {!isBraintreeFeatureEnabled && (
                <>
                  <Text strong mr={1} variant="subheading3">
                    {t('Businesses:RHAPSODY_PAY.PAYMENT_PROCESSOR')}
                  </Text>
                  <Text>
                    {t('Businesses:RHAPSODY_PAY.RHAPSODY_GO_PROCESSOR')}
                  </Text>
                  <RadioGroup
                    name="items"
                    value={goProcessor.value}
                    onChange={(_, value) => goProcessor.setValue(value)}
                  >
                    <Grid container>
                      <Grid item>
                        <FormControlLabel
                          control={
                            <Radio value={PaymentProcessors.FIRST_AMERICAN} />
                          }
                          disabled={!permissions.update || !isFAActive}
                          label={t('Businesses:RHAPSODY_PAY.FIRST_AMERICAN')}
                        />
                      </Grid>
                      <Grid item>
                        <FormControlLabel
                          control={<Radio value={PaymentProcessors.STRIPE} />}
                          disabled={
                            !permissions.update ||
                            !rhapsodyPayInitialConfig?.stripePreferences?.active
                          }
                          label={t('Businesses:RHAPSODY_PAY.STRIPE')}
                        />
                      </Grid>
                    </Grid>
                  </RadioGroup>
                  <Text>{t('Businesses:RHAPSODY_PAY.POS_PROCESSOR')}</Text>
                  <RadioGroup
                    name="items"
                    value={posProcessor.value}
                    onChange={(_, value) => posProcessor.setValue(value)}
                  >
                    <Grid container>
                      <Grid item>
                        <FormControlLabel
                          disabled
                          control={
                            <Radio value={PaymentProcessors.FIRST_AMERICAN} />
                          }
                          // disabled={!permissions.update || !isFAActive}
                          label={t('Businesses:RHAPSODY_PAY.FIRST_AMERICAN')}
                        />
                      </Grid>
                      <Grid item>
                        <FormControlLabel
                          disabled
                          control={<Radio value={PaymentProcessors.STRIPE} />}
                          // disabled={!permissions.update || !rhapsodyPayInitialConfig.stripePreferences.active}
                          label={t('Businesses:RHAPSODY_PAY.STRIPE')}
                        />
                      </Grid>
                    </Grid>
                  </RadioGroup>
                </>
              )}
            </>
          )}
          {isBusinessOmniChannel && isBraintreeFeatureEnabled ? (
            <>
              <Text strong mr={1} variant="subheading3">
                {t('Businesses:RHAPSODY_PAY.BRAINTREE')}
              </Text>
              <Grid container item direction="column" pb={2} pl={2}>
                <Grid
                  container
                  item
                  alignItems="center"
                  className={classes.sectionWithBottomBorder}
                  justifyContent="flex-start"
                >
                  <Grid item xs={3}>
                    <Text strong variant="body2">
                      {t('Businesses:RHAPSODY_PAY.MERCHANT_ID')}:
                    </Text>
                  </Grid>
                  <Grid item xs={9}>
                    {rhapsodyPayInitialConfig?.payBraintreePreferences
                      ?.merchantId || ''}
                  </Grid>
                </Grid>
              </Grid>
              <Grid container item direction="column" pl={1}>
                <Text strong variant="body2">
                  {t('Businesses:RHAPSODY_PAY.RHAPSODY_GO_SUBSCRIPTIONS')}
                </Text>
              </Grid>
              <Grid container item direction="column" pb={2} pl={2}>
                <Text strong variant="body2" />
                <Grid
                  container
                  item
                  alignItems="center"
                  className={classes.sectionWithBottomBorder}
                  justifyContent="flex-start"
                >
                  <Grid item xs={3}>
                    <Text strong variant="body2">
                      {t('Common:STATUS')}
                    </Text>
                  </Grid>
                  <Grid item>
                    <StateLabel
                      deleted={
                        rhapsodyPayInitialConfig?.payBraintreePreferences
                          ?.cnpMerchantAccountIdStatus === 'SUSPENDED'
                      }
                      error={
                        rhapsodyPayInitialConfig?.payBraintreePreferences
                          ?.cnpMerchantAccountIdStatus === 'Sync.Error'
                      }
                      success={
                        rhapsodyPayInitialConfig?.payBraintreePreferences
                          ?.cnpMerchantAccountIdStatus === 'ACTIVE'
                      }
                      warning={
                        rhapsodyPayInitialConfig?.payBraintreePreferences
                          ?.cnpMerchantAccountIdStatus !== 'PENDING'
                      }
                    >
                      {mapBraintreeStatus(
                        rhapsodyPayInitialConfig?.payBraintreePreferences
                          ?.cnpMerchantAccountIdStatus,
                      )}
                    </StateLabel>
                  </Grid>
                </Grid>
                <Grid
                  item
                  className={classNames({
                    [classes.readOnly]: !permissions.update,
                  })}
                  md={3}
                  pr={2}
                >
                  <PuiTextField
                    field={braintreeCnpMerchantAccountId}
                    label={braintreeCnpMerchantAccountId.label}
                  />
                </Grid>
              </Grid>
              {
                // TODO: POS section will be enabled with m2a: CVC-1333
              }
              {/* <>
                <Grid container item direction="column" pl={1}>
                  <Text strong variant="body2">
                    {t('Businesses:RHAPSODY_PAY.POS')}
                  </Text>
                </Grid>
                <Grid container item direction="column" pb={2} pl={2}>
                  <Grid
                    container
                    item
                    alignItems="center"
                    className={classes.sectionWithBottomBorder}
                    justifyContent="flex-start"
                  >
                    <Grid item xs={3}>
                      <Text strong variant="body2">
                        {t('Common:STATUS')}
                      </Text>
                    </Grid>
                    <Grid item>
                      <StateLabel
                        success={
                          rhapsodyPayInitialConfig?.payBraintreePreferences
                            ?.cnpMerchantAccountIdStatus === 'active'
                        }
                        warning={
                          rhapsodyPayInitialConfig?.payBraintreePreferences
                            ?.cnpMerchantAccountIdStatus !== 'active'
                        }
                      >
                        {
                          rhapsodyPayInitialConfig?.payBraintreePreferences
                            ?.cnpMerchantAccountIdStatus
                        }
                      </StateLabel>
                    </Grid>
                  </Grid>
                  <Grid
                    item
                    className={classNames({
                      [classes.readOnly]: !permissions.update,
                    })}
                    md={3}
                    pr={2}
                  >
                    <PuiTextField
                      field={cpMerchantAccountId}
                      label={cpMerchantAccountId.label}
                    />
                  </Grid>
                </Grid>
              </> */}
            </>
          ) : (
            <>
              <Grid item mt={4}>
                <Text strong mr={1} variant="subheading3">
                  {t('Businesses:RHAPSODY_PAY.YOUR_PLAN')}
                </Text>
              </Grid>
              <Grid container item>
                <Table className={classes.tableViewport}>
                  <TableBody>
                    <TableRow className={classes.tableRow}>
                      <TableCell className={classes.tableCell}>
                        <Grid container item alignItems="flex-end">
                          <TextWithTooltip
                            allowWrap
                            tooltipText={RHAPSODY_PAY_DEFINITION}
                            variant="body2"
                          >
                            {t('Businesses:RHAPSODY_PAY.RHAPSODY_PAY')}
                          </TextWithTooltip>
                        </Grid>
                      </TableCell>
                      <TableCell
                        className={classNames(
                          classes.tableCell,
                          classes.dataCell,
                        )}
                      >
                        <Grid container>
                          <Text variant="body2">
                            {t(
                              'Businesses:RHAPSODY_PAY.INCLUDED_TO_SEE_YOUR_MERCHANT_RATES',
                            )}
                          </Text>
                        </Grid>
                      </TableCell>
                    </TableRow>
                    <TableRow className={classes.tableRow}>
                      <TableCell className={classes.tableCell}>
                        <Grid container item alignItems="flex-end">
                          <TextWithTooltip
                            allowWrap
                            tooltipText={RHAPSODY_PASS_DEFINITION}
                            variant="body2"
                          >
                            {t(
                              'Businesses:RHAPSODY_PAY.RHAPSODY_PASS_SERVICE_FEE',
                            )}
                          </TextWithTooltip>
                        </Grid>
                      </TableCell>
                      <TableCell
                        className={classNames(
                          classes.tableCell,
                          classes.dataCell,
                        )}
                      >
                        {NumberUtils.toPercentFormat(
                          rhapsodyPayInitialConfig?.serviceFee ?? 0,
                          3,
                        )}
                      </TableCell>
                    </TableRow>
                    <TableRow className={classes.tableRow}>
                      <TableCell className={classes.tableCell}>
                        <Grid container item alignItems="flex-end">
                          <TextWithTooltip
                            allowWrap
                            tooltipText={RHAPSODY_GO_DEFINITION}
                            variant="body2"
                          >
                            {t('Businesses:RHAPSODY_PAY.RHAPSODY_GO')}
                          </TextWithTooltip>
                        </Grid>
                      </TableCell>
                      <TableCell
                        className={classNames(
                          classes.tableCell,
                          classes.dataCell,
                        )}
                      >
                        {!R.isEmpty(PayGoConfigList) &&
                        rhapsodyPayInitialConfig?.goOption
                          ? LanguageUtils.getTranslatedFieldName(
                              PayGoConfigList.find(
                                R.propEq(
                                  'id',
                                  rhapsodyPayInitialConfig?.goOption,
                                ),
                              ),
                            )
                          : TranslatedRhapsodyGoConfigurationLabels[
                              RhapsodyGoConfigurationLabels.NOT_INCLUDED
                            ]}
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </Grid>
            </>
          )}
        </Grid>
      )}
    </>
  )
}

export default memo(RhapsodyPaySection, (prevProps, nextProps) =>
  R.equals(prevProps.business, nextProps.business),
)
