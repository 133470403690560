import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { Grid } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import * as R from 'ramda'
import {
  ClassesType,
  ErrorTooltip,
  PuiCheckbox,
  Text,
} from '@pbt/pbt-ui-components'

import { OrderType } from '~/constants/SOAPStates'
import { removeOrder } from '~/store/actions/orders'
import { getChargeSheetSubItemById } from '~/store/duck/clientFinanceData'
import { useGetChargeSheetItemHeader } from '~/store/hooks/chargeSheet'
import { useGetIdexxImagingId } from '~/store/hooks/constants'
import {
  getChargesLogsByLogType,
  useOutstandingImagingOrders,
} from '~/store/hooks/finance'
import { useGetInvoiceV3ItemHeader } from '~/store/hooks/invoiceV3'
import { getInvoiceV3SubItemById } from '~/store/reducers/invoiceV3'
import { DataHandle, ImagingOrder as ImagingOrderType, Order } from '~/types'

import ImagingOrder, { ImagingOrderHandle } from './ImagingOrder'

type ImagingOrderDraft = {
  draft: ImagingOrderType
  selected: boolean
}

type ImagingOrderDraftMap = Record<string, ImagingOrderDraft>
type ImagingOrderHandleMap = Record<string, ImagingOrderHandle>

const getSelectedOrders = R.curry(
  (selectedMap: ImagingOrderDraftMap, readyToSendRefs: ImagingOrderHandleMap) =>
    R.toPairs(readyToSendRefs)
      .filter(([key]) => selectedMap[key]?.selected)
      .map(([, value]) => value),
)

const validateRefs = (
  selectedMap: ImagingOrderDraftMap,
  readyToSendRefs: ImagingOrderHandleMap,
) => {
  const selectedOrders = getSelectedOrders(selectedMap)(readyToSendRefs)
  const validateValues = R.pluck('validate', selectedOrders).map((func) =>
    func(),
  )
  return R.all(Boolean, validateValues)
}

const getOrdersOfRefs = (
  selectedMap: ImagingOrderDraftMap,
  readyToSendRefs: ImagingOrderHandleMap,
) => {
  const selectedOrders = getSelectedOrders(selectedMap)(readyToSendRefs)
  const getValues = R.pluck('get', selectedOrders).map(R.call)

  return getValues
}

const buildOrderKey = (order: ImagingOrderType) =>
  [order?.name, order?.logId].join('-')

const buildOrderDraft = (
  soapLog: Order | ImagingOrderType,
  assignedVetId?: string,
) =>
  ({
    name: soapLog.name,
    notes: soapLog.notes,
    logId: soapLog.id,
    assignedVetId,
    procedureId: soapLog.procedure?.id,
    procedureCode: soapLog.procedure?.procedureCode,
    modalityId: soapLog.procedure?.modalityId,
    soapId: soapLog.soapId,
    soapLog,
  } as ImagingOrderType)

const registerRef =
  (refHolder: React.RefObject<ImagingOrderHandleMap>, key: string) =>
  (element: ImagingOrderHandle) => {
    if (refHolder.current) {
      refHolder.current[key] = element
    }
  }

const noOrdersSelected = (orderDrafts: ImagingOrderDraftMap) => {
  const orderDraftsValues = R.values(orderDrafts)
  const selectedValues = R.pluck('selected', orderDraftsValues)
  return !R.any(R.identity, selectedValues)
}

const draftSelectedStateReducer = (
  acc: ImagingOrderDraftMap,
  draft: ImagingOrderType,
) => {
  const key = buildOrderKey(draft)
  acc[key] = { selected: true, draft }
  return acc
}

const useStyles = makeStyles(
  (theme) => ({
    orderItem: {
      flex: 1,
      border: theme.constants.tableBorder,
    },
    checkboxContainer: {
      border: theme.constants.tableBorder,
      borderRightWidth: 0,
    },
    checkbox: {
      margin: 0,
    },
  }),
  { name: 'ImagingOrderSection' },
)

export interface ImagingOrderSectionHandle
  extends DataHandle<Partial<ImagingOrderType>[]> {}

interface ImagingOrderSectionProps {
  classes?: ClassesType<typeof useStyles>
  hideOrderManagement?: boolean
  isChargeSheet?: boolean
  sectionItemId: string
}

const ImagingOrderSection = forwardRef<
  ImagingOrderSectionHandle,
  ImagingOrderSectionProps
>(
  (
    { isChargeSheet, sectionItemId, hideOrderManagement, classes: classesProp },
    ref,
  ) => {
    const classes = useStyles({ classes: classesProp })
    const { t } = useTranslation('Tooltips')
    const dispatch = useDispatch()

    const [orderDrafts, setOrderDrafts] = useState<ImagingOrderDraftMap>()

    const imagingOrdersRef = useRef<ImagingOrderHandleMap>({})

    const itemInvoice = useSelector(getInvoiceV3SubItemById(sectionItemId))
    const itemChargeSheet = useSelector(
      getChargeSheetSubItemById(sectionItemId),
    )
    const item = isChargeSheet ? itemChargeSheet : itemInvoice
    const vendorId = useGetIdexxImagingId()
    const invoiceItemTitle = useGetInvoiceV3ItemHeader(sectionItemId)
    const chargeSheetItemTitle = useGetChargeSheetItemHeader(sectionItemId)
    const title = isChargeSheet ? chargeSheetItemTitle : invoiceItemTitle

    const procedureLogs = getChargesLogsByLogType(
      OrderType.PROCEDURE,
      item ? [item] : [],
    )
    const outstandingImagingOrders = useOutstandingImagingOrders(procedureLogs)

    const allOrdersNotSelected = orderDrafts && noOrdersSelected(orderDrafts)

    const handleDeleteOrderDraft = (key: string) => {
      const soapLog = orderDrafts?.[key]?.draft?.soapLog
      if (soapLog?.id) {
        dispatch(removeOrder(soapLog))
      }
      setOrderDrafts(R.omit([key], orderDrafts))
    }

    const handleCheck = (key: string) => {
      const target = orderDrafts && orderDrafts[key]
      const isChecked = target?.selected
      if (isChecked) {
        imagingOrdersRef.current?.[key]?.reset()
      }

      if (target) {
        setOrderDrafts({
          ...orderDrafts,
          [key]: {
            ...target,
            selected: !isChecked,
          },
        })
      }
    }

    useEffect(() => {
      const selectedMap = outstandingImagingOrders
        .map((soapLog) => buildOrderDraft(soapLog, item?.soap?.assignedVetId))
        .reduce(draftSelectedStateReducer, {} as ImagingOrderDraftMap)

      setOrderDrafts(selectedMap)
    }, [outstandingImagingOrders?.length])

    const validateOrders = () => {
      const readyToSendRefs = imagingOrdersRef.current

      if (
        orderDrafts &&
        readyToSendRefs &&
        validateRefs(orderDrafts, readyToSendRefs) &&
        getOrdersOfRefs(orderDrafts, readyToSendRefs).length
      ) {
        return true
      }
      return false
    }

    const getOrders = () => {
      const readyToSendRefs = imagingOrdersRef.current

      return getOrdersOfRefs(orderDrafts || {}, readyToSendRefs)
    }

    useImperativeHandle(ref, () => ({
      validate: validateOrders,
      get: getOrders,
    }))

    if (!orderDrafts) {
      return null
    }

    return (
      <Grid container item mb={3}>
        <Text strong mb={1} variant="subheading2">
          {title}
        </Text>
        {Object.keys(orderDrafts).map((key, index) => {
          const { draft, selected } = orderDrafts[key] || {}

          return (
            <Grid container item direction="row" key={key}>
              <Grid item className={classes.checkboxContainer}>
                <ErrorTooltip
                  message={t('Tooltips:CHOOSE_AT_LEAST_ONE_IMAGE')}
                  open={index === 0 && allOrdersNotSelected}
                >
                  <PuiCheckbox
                    checked={selected || false}
                    classes={{ labelRoot: classes.checkbox }}
                    onChange={() => handleCheck(key)}
                  />
                </ErrorTooltip>
              </Grid>
              <Grid item className={classes.orderItem}>
                <ImagingOrder
                  inline
                  order={draft}
                  ref={registerRef(imagingOrdersRef, key)}
                  vendorId={vendorId}
                  onDelete={
                    hideOrderManagement
                      ? undefined
                      : () => {
                          handleDeleteOrderDraft(key)
                        }
                  }
                />
              </Grid>
            </Grid>
          )
        })}
      </Grid>
    )
  },
)

export default ImagingOrderSection
