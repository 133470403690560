import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import {
  CircularProgress,
  Grid,
  Table,
  TableCell,
  TableHead,
  TableRow,
} from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import classNames from 'classnames'
import * as R from 'ramda'
import { Nil, PuiTextArea, Text, TextWithTooltip } from '@pbt/pbt-ui-components'
import { formatMoney } from '@pbt/pbt-ui-components/src/utils/numberUtils'

import { Payment as GraphQlPayment } from '~/api/graphql/generated/types'
import {
  FINANCE_TABLE_PADDING_X_SPACING_VALUE,
  REFUND_TABLE_MIN_WIDTH,
} from '~/constants/financeTable'
import {
  InvoiceViewStates,
  REFUND_LANDING_COLUMNS,
  REFUND_SUMMARY_COLUMNS,
} from '~/constants/refund'
import { getChargeSheetLineItemLoading } from '~/store/duck/clientFinanceData'
import { getRefundsLoading } from '~/store/duck/refunds'
import { getFinanceIsFetching } from '~/store/reducers/finance'
import { getInvoiceV3Loading } from '~/store/reducers/invoiceV3'
import { InvoiceLineItem, Payment } from '~/types'

import ChargeInformationPanel from '../../soapV2/charges/information-panel/ChargeInformationPanel'
import { ChargeItemRefundCandidateProps } from '../../soapV2/charges/information-panel/ChargeItemRefundCandidate'
import { RefundContext } from './RefundContext'
import { RefundInvoiceTable } from './RefundInvoiceTable'
import { RefundLandingTable } from './RefundLandingTable'

const useStyles = makeStyles(
  (theme) => ({
    tableTitleRow: {
      borderBottom: theme.constants.tabBorder,
    },
    tableTitle: {
      color: theme.colors.tabLabel,
      fontWeight: theme.typography.fontWeightBold,
      fontSize: '1.4rem',
      height: theme.spacing(4),
      padding: theme.spacing(0, 1),
      borderBottom: 'none',
      '&:first-of-type': {
        paddingLeft: theme.spacing(1),
      },
      '&:last-of-type': {
        textAlign: 'right',
        paddingRight: theme.spacing(1),
      },
    },
    chargeInformationPanel: {
      top: theme.mixins.toolbar.minHeight,
      maxHeight: `calc(100vh - ${theme.mixins.toolbar.minHeight}px)`,
    },
    tableSubTitle: {
      padding: theme.spacing(0.5, 1),
      borderBottom: 'none',
      '&:first-of-type': {
        paddingLeft: theme.spacing(FINANCE_TABLE_PADDING_X_SPACING_VALUE + 1.5),
      },
      '&:last-of-type': {
        textAlign: 'right',
        paddingRight: theme.spacing(FINANCE_TABLE_PADDING_X_SPACING_VALUE),
      },
    },
    containerWithInfoPanel: {
      width: ({ isInfoPanelOpen }: { isInfoPanelOpen: boolean }) =>
        isInfoPanelOpen ? 'calc(100% - 400px)' : 'auto',
      minWidth: REFUND_TABLE_MIN_WIDTH,
    },
  }),
  { name: 'InvoiceDetailsForRefund' },
)

const getPropFromLineItem = (prop: string, item: InvoiceLineItem) =>
  R.has('items', item)
    ? R.prop(prop, R.pipe(R.prop('items'), R.head)(item))
    : R.prop(prop, item)

export const InvoiceDetailsForRefund = () => {
  const { t } = useTranslation('Invoices')
  const navigate = useNavigate()
  const {
    closeInfoPanel,
    invoice,
    internalNoteField,
    isInfoPanelOpen,
    groups,
    onSelectItem,
    refundInvoice,
    selectedItem,
    refundCandidate,
    viewState,
  } = useContext(RefundContext)
  const classes = useStyles({ isInfoPanelOpen })

  const isInvoiceLoading = useSelector(getInvoiceV3Loading)
  const isRefundInvoiceLoading = useSelector(getRefundsLoading)
  const summaryView = viewState === InvoiceViewStates.REFUND_SUMMARY
  const invoiceView = viewState === InvoiceViewStates.REFUND_INVOICE
  const landingView = viewState === InvoiceViewStates.REFUND_LANDING
  const isRefundFlow = summaryView || invoiceView || landingView

  const isFetching = useSelector(getFinanceIsFetching)
  const chargeSheetLineItemLoading = useSelector(getChargeSheetLineItemLoading)

  const isLoading =
    isFetching || chargeSheetLineItemLoading || isRefundInvoiceLoading

  const columns =
    summaryView || invoiceView ? REFUND_SUMMARY_COLUMNS : REFUND_LANDING_COLUMNS

  const payments: (GraphQlPayment | Payment)[] =
    invoice?.payments || refundInvoice?.originalInvoice?.payments || []

  const goToAssociatedInvoice = (id: string | Nil) => {
    if (id) {
      navigate(`/invoice/${id}`)
    }
  }

  return (
    <>
      {landingView && (
        <Grid ml={3}>
          <Text variant="h1">
            {t('Invoices:SELECT_ITEMS_TO_REFUND_AND_RESTOCK')}
          </Text>
        </Grid>
      )}
      <Grid className={classes.containerWithInfoPanel} pl={2}>
        <Table>
          <TableHead>
            <TableRow className={classNames(classes.tableTitleRow)}>
              {columns.map(({ label, width, id }) => (
                <TableCell
                  className={classNames(classes.tableTitle)}
                  key={id}
                  width={width}
                >
                  <Text inline strong variant="lowAccent2">
                    {label}
                  </Text>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
        </Table>
      </Grid>
      <Grid container item className={classes.containerWithInfoPanel}>
        <Grid container item alignItems="center" justifyContent="center">
          {isInvoiceLoading ? (
            <CircularProgress color="primary" size={32} />
          ) : (
            <>
              {selectedItem && (
                <ChargeInformationPanel
                  editDisabled
                  classes={{
                    chargeInformationPanel: classes.chargeInformationPanel,
                  }}
                  invoice={invoice}
                  isInvoice={isRefundFlow}
                  isLoading={isLoading}
                  isOpened={isInfoPanelOpen}
                  isSoap={false}
                  item={selectedItem}
                  refundCandidate={
                    R.omit(
                      ['id'],
                      refundCandidate,
                    ) as ChargeItemRefundCandidateProps
                  }
                  onClose={closeInfoPanel}
                  onSelectItem={onSelectItem}
                />
              )}
              {invoiceView && (
                <>
                  <Grid container item alignItems="left" ml={2} pl={1}>
                    <Text
                      link
                      component="span"
                      onClick={() =>
                        goToAssociatedInvoice(refundInvoice?.originalInvoice.id)
                      }
                    >
                      {t('Invoices:FROM_INVOICE', {
                        invoiceNumber: refundInvoice?.originalInvoice.invoiceNo,
                      })}
                    </Text>
                  </Grid>
                  {refundInvoice?.sections.map((section) => (
                    <RefundInvoiceTable
                      items={section.groupedItems}
                      key={`refund-landing-table-${section.id}`}
                      sectionId={section.id!}
                    />
                  ))}
                </>
              )}
              {!invoiceView &&
                Object.keys(groups).map((id: string) => (
                  <RefundLandingTable
                    groupId={id}
                    items={R.map(
                      (item: InvoiceLineItem) => ({
                        ...item,
                        soapId: getPropFromLineItem('soapId', item),
                        patientId: getPropFromLineItem(
                          'patientId',
                          item,
                        ) as unknown as string,
                        chargeSheetSubItemId: id,
                      }),
                      R.prop(id, groups),
                    )}
                    key={`refund-landing-table-${id}`}
                  />
                ))}
            </>
          )}
        </Grid>
      </Grid>
      <Grid className={classes.containerWithInfoPanel} pl={3} pr={3} pt={3}>
        {summaryView || invoiceView ? (
          <>
            <Grid>
              <TextWithTooltip strong variant="subheading3">
                {t('Invoices:REFUND_INTERNAL_NOTE')}
              </TextWithTooltip>
              <Text>
                {invoiceView
                  ? refundInvoice?.internalNote
                  : internalNoteField.value}
              </Text>
            </Grid>
            <Grid mt={3}>
              <TextWithTooltip strong variant="subheading3">
                {t('Invoices:PAYMENT_METHOD_USED')}
              </TextWithTooltip>
              {payments
                .filter((p) => (p.amount || -1) > 0)
                .map((payment) => {
                  const paymentName =
                    'description' in payment
                      ? payment.description
                      : payment.method?.name
                  return (
                    <Grid key={payment.id} mt={0.5}>
                      <Text variant="body2">{paymentName}</Text>
                      <Text variant="body3">
                        {t('Invoices:MAXIMUM_REFUNDABLE_AMOUNT', {
                          amount: formatMoney(payment.refundableAmount),
                        })}
                      </Text>
                    </Grid>
                  )
                })}
            </Grid>
          </>
        ) : (
          <PuiTextArea
            InputProps={{
              inputProps: { maxLength: 2000 },
            }}
            field={internalNoteField}
            label={internalNoteField.label}
            showColon={false}
          />
        )}
      </Grid>
    </>
  )
}
