import React, { useState } from 'react'
import { I18nextProvider } from 'react-i18next'
import { useSelector } from 'react-redux'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import { CssBaseline } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { PuiMomentUtils } from '@pbt/pbt-ui-components'

import FeatureToggle from '~/constants/featureToggle'
import ConditionalAuth0Provider from '~/providers/ConditionalAuth0Provider'
import { getFeatureToggle } from '~/store/reducers/constants'
import { auth0Enabled } from '~/utils'
import { useChangeLanguage } from '~/utils/useChangeLanguage'

import Auth from './auth/Auth'
import LogoutComponent from './auth/LogoutComponent'
import SignInComponent from './auth/SignInComponent'
import GoogleAnalytics from './common/GoogleAnalytics'
import MonitoringWidget from './dashboard/MonitoringWidget'
import RootPage from './dashboard/RootPage'
import AuthWatcher from './dashboard/watchers/AuthWatcher'
import EasterEggWatcher from './dashboard/watchers/EasterEggWatcher'
import MonitorWatcher from './dashboard/watchers/MonitorWatcher'
import NotificationWatcher from './dashboard/watchers/NotificationWatcher'
import PageTitleWatcher from './dashboard/watchers/PageTitleWatcher'
import DialogsManager from './dialogs-manager/DialogsManager'
import ErrorWatcher from './error/ErrorWatcher'
import LastGateErrorsBoundary from './error/LastGateErrorsBoundary'
import Favicon from './Favicon'
import GlobalHistory from './GlobalHistory'
import Registration from './registration/Registration'
import ScrollToTop from './ScrollToTop'
import TermsAndConditionsPage from './terms-and-conditions/TermsAndConditionsPage'
import WindowVariableInjector from './WindowVariableInjector'

const useStyles = makeStyles(
  (theme) => ({
    page: {
      [theme.breakpoints.up('md')]: {
        display: 'flex',
      },
    },
  }),
  { name: 'App' },
)

const App = () => {
  const classes = useStyles()

  const [passwordExpirationError, setPasswordExpirationError] = useState(false)

  const isEasterEggEnabled = useSelector(
    getFeatureToggle(FeatureToggle.EASTER_EGG),
  )

  const { i18n } = useChangeLanguage()

  return (
    <BrowserRouter>
      <ConditionalAuth0Provider>
        <I18nextProvider i18n={i18n}>
          <LastGateErrorsBoundary>
            <Favicon />
            <LocalizationProvider dateAdapter={PuiMomentUtils}>
              <CssBaseline />
              <WindowVariableInjector />
              <MonitoringWidget />
              <GlobalHistory />
              <ErrorWatcher />
              <NotificationWatcher />
              <PageTitleWatcher />
              <MonitorWatcher />
              <GoogleAnalytics>
                <ScrollToTop>
                  <>
                    <DialogsManager />
                    {isEasterEggEnabled && <EasterEggWatcher />}
                    <div className={classes.page}>
                      <Routes>
                        {auth0Enabled ? (
                          <>
                            <Route
                              element={<LogoutComponent />}
                              path="auth/logout"
                            />
                            <Route
                              element={<SignInComponent />}
                              path="auth/signin"
                            />
                          </>
                        ) : (
                          <>
                            <Route element={<Auth />} path="auth/*" />
                          </>
                        )}
                        <Route element={<Registration />} path="register/*" />
                        <Route
                          element={<TermsAndConditionsPage />}
                          path="terms-and-conditions"
                        />
                        {!passwordExpirationError && (
                          <Route element={<RootPage />} path="/*" />
                        )}
                      </Routes>
                    </div>
                    <AuthWatcher
                      setPasswordExpirationError={setPasswordExpirationError}
                    />
                  </>
                </ScrollToTop>
              </GoogleAnalytics>
            </LocalizationProvider>
          </LastGateErrorsBoundary>
        </I18nextProvider>
      </ConditionalAuth0Provider>
    </BrowserRouter>
  )
}

export default App
