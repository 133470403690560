import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Grid } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import * as R from 'ramda'
import {
  AddButton,
  Business,
  BusinessRole,
  FieldObject,
  FieldProp,
  InlineSearch,
  PermissionArea,
  Role,
  useFields,
  User,
  Utils,
  ValidateHandle,
} from '@pbt/pbt-ui-components'

import DialogNames from '~/constants/DialogNames'
import FeatureToggle from '~/constants/featureToggle'
import {
  getCRUDByArea,
  getCurrentBusiness,
  getGroupCRUDByArea,
} from '~/store/reducers/auth'
import { getMultipleBusinesses } from '~/store/reducers/businesses'
import { getFeatureToggle } from '~/store/reducers/constants'
import { getRolesMap } from '~/store/reducers/roles'
import { BusinessRoleItem } from '~/types'
import { isAnalyticsRole } from '~/utils'
import { isStaffRole } from '~/utils/roleUtils'
import useDialog from '~/utils/useDialog'
import useGetAssignedRoles from '~/utils/useGetAssignedRoles'

import MemberRolesSection from './MemberRolesSection'

const useStyles = makeStyles(
  (theme) => ({
    addButton: {
      marginLeft: theme.spacing(2),
    },
    addGroupButton: {
      marginLeft: theme.spacing(2),
      marginTop: theme.spacing(1),
    },
    rolesTable: {
      marginTop: theme.spacing(2),
    },
  }),
  { name: 'MemberRolesSection' },
)

const getFields = R.pipe(
  R.addIndex<{ businessId: string; roleId: string }>(R.map)(
    ({ businessId, roleId }, index) => [
      {
        name: `role_${index}`,
        validators: ['required'],
        type: 'select',
        initialValue: roleId || '',
      },
      {
        name: `business_${index}`,
        validators: ['required'],
        type: 'select',
        initialValue: businessId || '',
        defaultValue: businessId,
      },
    ],
  ),
  // @ts-ignore
  R.flatten,
)

interface MemberRolesSectionContainerProps {
  ignoreRolesInSearch?: boolean
  includeHiddenRoles?: boolean
  includeNotAssignedRoles?: boolean
  isRhapsodyAnalytics?: boolean
  onRoleListChange?: () => any
  onlyAlowedRoles?: boolean
  teamMember: User
}

export interface MemberRolesSectionContainerDataHandle extends ValidateHandle {
  getBusinessRoleList: () => void
}

const MemberRolesSectionContainer = forwardRef<
  MemberRolesSectionContainerDataHandle,
  MemberRolesSectionContainerProps
>(function MemberRolesSectionContainer(
  {
    teamMember,
    isRhapsodyAnalytics,
    onRoleListChange = R.F,
    onlyAlowedRoles = false,
    includeHiddenRoles = false,
    includeNotAssignedRoles: includeNotAssignedRolesProp = false,
    ignoreRolesInSearch,
  },
  ref,
) {
  const classes = useStyles()
  const { t } = useTranslation(['Admin', 'Common'])

  const rolesMap = useSelector(getRolesMap)
  const personPermissions = useSelector(getCRUDByArea(PermissionArea.PERSON))
  const rolePermissions = useSelector(
    getCRUDByArea(PermissionArea.PERSON_ASSIGNED_ROLES),
  )
  const roleGroupPermissions = useSelector(
    getGroupCRUDByArea(PermissionArea.PERSON_ASSIGNED_ROLES),
  )
  const currentBusiness = useSelector(getCurrentBusiness)
  const isPatientSharingEnabled = useSelector(
    getFeatureToggle(FeatureToggle.PATIENT_SHARING),
  )

  const updatePermissions = personPermissions.update && rolePermissions.update
  const updateGroupPermissions =
    isPatientSharingEnabled &&
    personPermissions.update &&
    roleGroupPermissions.update

  const [search, setSearch] = useState('')

  const [openRolesDialog] = useDialog(DialogNames.ROLES)

  const rolesFilter = isRhapsodyAnalytics ? isAnalyticsRole : R.T

  const isNotHiddenRole = (role: Role) =>
    includeHiddenRoles ? true : !role?.hidden
  const disabled = !updatePermissions && !updateGroupPermissions
  const disabledGroup = !updateGroupPermissions
  const businessToRoleList = teamMember?.businessToRoleList ?? []

  const getAssignedRoles = useGetAssignedRoles()
  const includeNotAssignedRoles =
    includeNotAssignedRolesProp || includeHiddenRoles
  const assignedRoles = includeNotAssignedRoles
    ? businessToRoleList
    : getAssignedRoles(businessToRoleList)

  const [filteredOutRoles, filteredBusinessRoles] = R.partition(
    ({ role }) =>
      !isNotHiddenRole(rolesMap[role]) || !rolesFilter(rolesMap[role]),
    assignedRoles,
  )

  const initialBusinessRoleList = filteredBusinessRoles
    .filter((item) => item?.business && item?.role)
    .map(({ business: businessId, role: roleId, isGroup }) => ({
      businessId,
      roleId,
      isGroup,
    }))

  const visibleRoleList = Object.values(rolesMap).filter(
    R.allPass([isNotHiddenRole, rolesFilter, isStaffRole]),
  )

  const [selectedRoleList, setSelectedRoleList] = useState(
    initialBusinessRoleList,
  )

  const businessRolesMap = R.groupBy(R.prop('businessId'), selectedRoleList)

  const businesses = useSelector(
    getMultipleBusinesses(R.keys(businessRolesMap)),
  )

  const filteredBusinessIds = businesses
    .filter((item: Business) => Utils.matchSubstring(search, item.name))
    .map(({ id }) => id)

  const businessRoleListFiltered = selectedRoleList.filter(({ businessId }) =>
    filteredBusinessIds.includes(businessId),
  )

  const [groupToRoleList, practiceToRoleList] = R.partition(
    (item) => Boolean(item?.isGroup),
    businessRoleListFiltered,
  )

  const {
    fields: rolesFields,
    validate,
    reset,
  } = useFields(getFields(selectedRoleList) as FieldProp[], false)

  useEffect(() => {
    setSelectedRoleList(initialBusinessRoleList)
    reset(getFields(initialBusinessRoleList) as FieldProp[])
  }, [businessToRoleList])

  useEffect(() => {
    onRoleListChange()
  }, [selectedRoleList])

  const updateBusinessRoleListState = (fields: FieldObject) => {
    const updatedBusinessRoleList = selectedRoleList.map(
      ({ isGroup }, index) => ({
        businessId: fields[`business_${index}`]?.value,
        roleId: fields[`role_${index}`]?.value,
        isGroup,
      }),
    )

    if (!R.equals(selectedRoleList, updatedBusinessRoleList)) {
      setSelectedRoleList(updatedBusinessRoleList)
    }
  }

  useEffect(() => {
    if (!R.isEmpty(rolesFields)) {
      updateBusinessRoleListState(rolesFields)
    }
  }, [rolesFields])

  const handleUpdateRoles = (
    businessId: string,
    roles: string[],
    isGroup?: boolean,
  ) => {
    const newBusinessRoleList = [
      ...R.filter((item) => item.businessId !== businessId, selectedRoleList),
      ...roles.map((roleId) => ({ businessId, roleId, isGroup })),
    ]

    setSelectedRoleList(newBusinessRoleList)
    reset(getFields(newBusinessRoleList) as FieldProp[])
  }

  const handleAddRoles = (isGroup?: boolean) => {
    openRolesDialog({
      isNewBusiness: true,
      isGroup,
      userId: teamMember.id,
      businessId: isGroup
        ? currentBusiness?.parentBusinessId
        : currentBusiness?.id,
      selectedRoleList,
      onSave: handleUpdateRoles,
      isRhapsodyAnalytics,
      onlyAlowedRoles,
      includeHiddenRoles,
      ignoreRolesInSearch,
    })
  }

  const handleDeleteBusinessRole = ({
    businessId,
    roleId,
  }: BusinessRoleItem) => {
    const index = R.findIndex(
      (item) => item.businessId === businessId && item.roleId === roleId,
      selectedRoleList,
    )

    const newBusinessRoleList = [
      ...selectedRoleList.slice(0, index),
      ...selectedRoleList.slice(index + 1),
    ]

    setSelectedRoleList(newBusinessRoleList)
    reset(getFields(newBusinessRoleList) as FieldProp[])
  }

  const getHandleEditBusinessRoles =
    (isGroup?: boolean) => (businessId: string) => {
      openRolesDialog({
        isGroup,
        userId: teamMember.id,
        businessId,
        selectedRoleList,
        onSave: handleUpdateRoles,
        isRhapsodyAnalytics,
        onlyAlowedRoles,
        includeHiddenRoles,
      })
    }

  useImperativeHandle(ref, () => ({
    validate,
    getBusinessRoleList: () =>
      selectedRoleList
        .map(
          (_, index) =>
            ({
              business: rolesFields[`business_${index}`].value,
              role: rolesFields[`role_${index}`].value,
            } as BusinessRole),
        )
        .concat(filteredOutRoles),
  }))

  return (
    <Grid container flexDirection="column" height="100%">
      <Grid container item alignItems="center">
        <Grid item xs>
          <InlineSearch
            placeholder={t('Admin:MEMBER.ROLE.SEARCH_PRACTICES')}
            search={search}
            onChange={setSearch}
          />
        </Grid>
        <Grid item>
          {!disabled && (
            <AddButton
              addText={t('Admin:MEMBER.ROLE.ADD_PRACTICE_AND_ROLES')}
              classes={{
                addItem: classes.addButton,
              }}
              onAdd={() => handleAddRoles(false)}
            />
          )}
          {isPatientSharingEnabled && !disabledGroup && (
            <AddButton
              addText={t('Admin:MEMBER.ROLE.ADD_GROUP_ROLES')}
              classes={{
                addItem: classes.addGroupButton,
              }}
              onAdd={() => handleAddRoles(true)}
            />
          )}
        </Grid>
      </Grid>
      <Grid container item flexGrow={1}>
        {!R.isEmpty(practiceToRoleList) && (
          <MemberRolesSection
            businessRoleList={practiceToRoleList}
            classes={{ root: classes.rolesTable }}
            disabled={disabled}
            roleList={visibleRoleList}
            title={t('Admin:MEMBER.ROLE.PRACTICE_ROLES')}
            onDeleteBusinessRole={handleDeleteBusinessRole}
            onEditBusinessRoles={getHandleEditBusinessRoles(false)}
          />
        )}
        {!R.isEmpty(groupToRoleList) && (
          <MemberRolesSection
            isGroup
            businessRoleList={groupToRoleList}
            classes={{ root: classes.rolesTable }}
            disabled={disabledGroup}
            roleList={visibleRoleList}
            title={t('Admin:MEMBER.ROLE.GROUP_ROLES')}
            onDeleteBusinessRole={handleDeleteBusinessRole}
            onEditBusinessRoles={getHandleEditBusinessRoles(true)}
          />
        )}
      </Grid>
    </Grid>
  )
})

export default MemberRolesSectionContainer
