import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Grid } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import classNames from 'classnames'
import {
  ClassesType,
  Constant,
  LanguageUtils,
  PuiCheckbox,
  Text,
} from '@pbt/pbt-ui-components'
import { Check } from '@pbt/pbt-ui-components/src/icons'

import PreviewButton from '~/components/common/buttons/PreviewButton'
import { EnabledState } from '~/constants/notifications'
import { getNotificationType } from '~/store/reducers/constants'
import { NotificationSetting } from '~/types'
import {
  anyEnabled,
  areAllEnabled,
  mapSubListWithSettingsOfType,
  sameSetting,
  updateSettingEnabledState,
  updateSettingStateInList,
} from '~/utils/notifications'

const useStyles = makeStyles(
  (theme) => ({
    borderColumn: {
      borderRight: theme.constants.tabBorder,
    },
    columnHeader: {
      height: 33,
    },
    columnSubHeader: {
      height: 20,
      borderBottom: theme.constants.tabBorder,
    },
    combinationContentCell: {
      paddingLeft: theme.spacing(1),
      '&:not(:last-of-type)': {
        borderBottom: theme.constants.tabBorder,
      },
    },
    notifyAboutColumnSelectHeader: {
      borderBottom: theme.constants.tabBorder,
      height: 40,
    },
    tableRow: {
      height: 40,
      '&:not(:last-of-type)': {
        borderBottom: theme.constants.tabBorder,
      },
      '&:nth-of-type(even)': {
        backgroundColor: theme.colors.tableOddRowBackground,
      },
    },
    checkIcon: {
      color: theme.colors.title,
    },
    column: {
      minWidth: 250,
    },
  }),
  { name: 'NotificationSettingsCombinationTable' },
)

const CombinationCellTypes = {
  READ_ONLY: 'READ_ONLY',
  CHECKBOX: 'CHECKBOX',
  NOT_APPLICANT: 'NOT_APPLICANT',
}

export interface NotificationSettingsCombinationTableProps {
  classes?: ClassesType<typeof useStyles>
  isLoading?: boolean
  onPreview?: () => void
  onSettingListChange: (settingList: NotificationSetting[]) => void
  readOnly?: boolean
  settingList: NotificationSetting[]
  settingTypeList: Constant[]
  title: string
}

const NotificationSettingsCombinationTable = ({
  title,
  settingTypeList: settingTypeListProp,
  settingList: settingListProp,
  onSettingListChange,
  onPreview,
  classes: classesProp,
  isLoading = false,
  readOnly = false,
}: NotificationSettingsCombinationTableProps) => {
  const classes = useStyles({ classes: classesProp })
  const settingTypeList = settingTypeListProp || []
  const settingList = settingListProp || []
  const notificationTypes: Constant[] = useSelector(getNotificationType) || []
  const { t } = useTranslation(['Abbreviations', 'Common'])

  const onSelectAllChecked = (
    settingTypeId: string,
    settingsList: NotificationSetting[],
  ) => {
    const allEnabled = areAllEnabled(settingTypeId, settingList)

    const updatedSettingList = mapSubListWithSettingsOfType(
      settingTypeId,
      settingsList,
      (it) => updateSettingEnabledState(it, !allEnabled),
    )

    onSettingListChange(updatedSettingList)
  }

  const onChecked = (
    settingTypeId: string,
    notificationTypeId: string,
    settings: NotificationSetting[],
    checked: boolean,
  ) => {
    const newSettings = updateSettingStateInList(
      settings,
      settingTypeId,
      notificationTypeId,
      checked,
    )
    onSettingListChange(newSettings)
  }

  return (
    <Grid
      container
      item
      className={classNames(classes.column, classes.borderColumn)}
      direction="column"
      wrap="nowrap"
    >
      <Grid container item className={classes.columnHeader} p={1} wrap="nowrap">
        <Grid container item alignItems="center" wrap="nowrap">
          <Grid item>
            <Text strong variant="lowAccent2" whiteSpace="nowrap">
              {title}
            </Text>
          </Grid>
          {onPreview && (
            <Grid item ml={1}>
              <PreviewButton
                fontSize="1.4rem"
                whiteSpace="nowrap"
                onClick={onPreview}
              />
            </Grid>
          )}
        </Grid>
      </Grid>

      <Grid container item wrap="nowrap">
        {settingTypeList.map((settingType, idx) => {
          const isLastElement = idx + 1 === settingTypeList.length
          const rightBorderStyle = classNames({
            [classes.borderColumn]: !isLastElement,
          })

          const allEnabledForSettingType = areAllEnabled(
            settingType.id,
            settingList,
          )
          const anyEnabledForSettingType = anyEnabled(
            settingType.id,
            settingList,
          )
          const selectAllIndeterminateState =
            anyEnabledForSettingType && !allEnabledForSettingType

          return (
            <Grid container item direction="column" key={settingType.id}>
              <Grid container item className={classes.columnSubHeader} pl={1}>
                <Grid container item>
                  <Text strong variant="lowAccent4" whiteSpace="nowrap">
                    {LanguageUtils.getTranslatedFieldName(settingType)}
                  </Text>
                </Grid>
              </Grid>

              {!isLoading && (
                <>
                  {!readOnly && (
                    <Grid
                      container
                      item
                      className={classNames(
                        classes.combinationContentCell,
                        classes.notifyAboutColumnSelectHeader,
                        rightBorderStyle,
                      )}
                      wrap="nowrap"
                    >
                      <PuiCheckbox
                        checked={
                          allEnabledForSettingType ||
                          selectAllIndeterminateState
                        }
                        indeterminate={selectAllIndeterminateState}
                        onChange={() =>
                          onSelectAllChecked(settingType.id, settingList)
                        }
                      />
                    </Grid>
                  )}

                  <Grid container item>
                    {notificationTypes.map((notificationType) => {
                      const currentCombinationSetting = settingList.find((it) =>
                        sameSetting(it, settingType.id, notificationType.id),
                      )

                      const isNotApplicant =
                        currentCombinationSetting?.enabledState ===
                        EnabledState.NOT_APPLICANT
                      const isEnabled =
                        currentCombinationSetting?.enabledState ===
                        EnabledState.ENABLED
                      const combinationCellType =
                        !currentCombinationSetting || isNotApplicant
                          ? CombinationCellTypes.NOT_APPLICANT
                          : readOnly
                          ? CombinationCellTypes.READ_ONLY
                          : CombinationCellTypes.CHECKBOX

                      return (
                        <Grid
                          container
                          item
                          alignItems="center"
                          className={classNames(
                            classes.combinationContentCell,
                            classes.tableRow,
                            rightBorderStyle,
                          )}
                          key={notificationType.id + settingType.id}
                          wrap="nowrap"
                        >
                          {combinationCellType ===
                            CombinationCellTypes.NOT_APPLICANT && (
                            <Text variant="lowAccent">
                              {t('Abbreviations:COMMON.NOT_AVAILABLE')}
                            </Text>
                          )}
                          {combinationCellType ===
                            CombinationCellTypes.READ_ONLY &&
                            (isEnabled ? (
                              <Check className={classes.checkIcon} />
                            ) : null)}
                          {combinationCellType ===
                            CombinationCellTypes.CHECKBOX && (
                            <PuiCheckbox
                              checked={isEnabled}
                              onChange={(_, checked) =>
                                onChecked(
                                  settingType.id,
                                  notificationType.id,
                                  settingList,
                                  checked,
                                )
                              }
                            />
                          )}
                        </Grid>
                      )
                    })}
                  </Grid>
                </>
              )}
            </Grid>
          )
        })}
      </Grid>
    </Grid>
  )
}

export default NotificationSettingsCombinationTable
