import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { CountryCode, moment } from '@pbt/pbt-ui-components'

import { getRhapsodyDomain } from '~/utils'
import { useCurrentBusinessTimezone } from '~/utils/useCurrentBusinessTimezone'

import {
  getCurrentBusiness,
  getCurrentBusinessCountryCatalogCode,
} from '../reducers/auth'
import { getBusinessCountryCatalogCode } from '../reducers/businesses'

export function useBusinessLocalization(id?: string) {
  const currentBusinessCountryCatalogCode = useSelector(
    getCurrentBusinessCountryCatalogCode,
  )
  const businessCountryCatalogCode = useSelector(
    getBusinessCountryCatalogCode(id),
  )

  const businessCountryCode = id
    ? businessCountryCatalogCode
    : currentBusinessCountryCatalogCode

  return useMemo(
    () => ({
      isUSBusiness: businessCountryCode === CountryCode.US,
      isCanadianBusiness: businessCountryCode === CountryCode.CA,
      isPortugueseBusiness: businessCountryCode === CountryCode.PT,
      isSpanishBusiness: businessCountryCode === CountryCode.ES,
    }),
    [id, businessCountryCode],
  )
}

export const useGetAnalyticsFilterParams = () => {
  const currentBusiness = useSelector(getCurrentBusiness)
  const businessTimezone = useCurrentBusinessTimezone()
  const rhapsodyDomain = getRhapsodyDomain()

  // These date formats should not use i18n format as it will be passed to metabase
  const metabaseFormatDate = 'YYYY-MM-DD'
  const todayDate = moment.tz(businessTimezone).format(metabaseFormatDate)
  const last30DaysDate = moment
    .tz(businessTimezone)
    .subtract(30, 'days')
    .format(metabaseFormatDate)
  const last120DaysDate = moment
    .tz(businessTimezone)
    .subtract(120, 'days')
    .format(metabaseFormatDate)

  const todayRange = `${todayDate}~${todayDate}`
  const last30DaysRange = `${last30DaysDate}~${todayDate}`
  const last120DaysRange = `${last120DaysDate}~${todayDate}`

  const range = {
    todayRange,
    last30DaysRange,
    last120DaysRange,
  }

  const date = {
    todayDate,
    last30DaysDate,
    last120DaysDate,
  }

  return {
    businessName: currentBusiness?.name ?? '',
    date,
    range,
    rhapsodyDomain,
  }
}
