import React from 'react'
import { Trans } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { KeyboardArrowRight as KeyboardArrowRightIcon } from '@mui/icons-material'
import { Box, TableCell } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { Text, TextWithTooltip } from '@pbt/pbt-ui-components'

import {
  Employee,
  Estimate as GraphqlEstimate,
  GoPayment,
  Payment,
} from '~/api/graphql/generated/types'
import { PuiDataTableColumn } from '~/components/common/lists/pui-data-table/puiDataTableType'
import { StaticTableColumn } from '~/components/common/lists/table/StaticTable'
import { TableTextCell } from '~/components/dashboard/clients/balance/table/common/TableTextCell'
import { TableTextWithClickCell } from '~/components/dashboard/clients/balance/table/common/TableTextWithClickCell'
import { PaymentTableLabelCell } from '~/components/dashboard/clients/balance/table/payments/PaymentTableLabelCell'
import { PaymentTablePatientNameCell } from '~/components/dashboard/clients/balance/table/payments/PaymentTablePatientNameCell'
import { TableInvoiceEstimateCell } from '~/components/dashboard/clients/balance/table/payments/TableInvoiceEstimateCell'
import { formatMoneyRange } from '~/components/dashboard/invoices/invoiceUtils'
import DialogNames from '~/constants/DialogNames'
import PaymentType from '~/constants/paymentTypes'
import i18nPortal from '~/locales/i18n'
import { fetchGoPayment } from '~/store/actions/payments'
import { refreshClientBillingData } from '~/store/duck/clientBillingActivityData'
import { getPaymentsIsLoading } from '~/store/reducers/payments'
import {
  getPaymentMethodLabel,
  getPaymentWidgetTypeLabel,
  hasUnappliedPayment,
  isCredit,
} from '~/utils/paymentUtils'
import useCloseAfterCreation from '~/utils/useCloseAfterCreation'
import useDialog from '~/utils/useDialog'

import { TableDateCell } from '../common/TableDateCell'

const useStyles = makeStyles(
  (theme) => ({
    alignRight: {
      justifyContent: 'flex-end',
      textAlign: 'right',
    },
    alignWithMultipleLines: {
      display: 'flex',
      height: '40px',
      alignSelf: 'baseline',
      alignItems: 'center',
    },
    checkbox: {
      padding: theme.spacing(1),
      marginLeft: theme.spacing(-0.75),
      marginRight: theme.spacing(-1),
    },
    titleCell: {
      color: 'inherit',
      fontSize: '1.4rem',
    },
  }),
  { name: 'UseGetInvoiceBillingActivityTableColumns' },
)

const FallbackComponent = ({ item }: { item: Payment }) => {
  if (item.links) {
    const totalEvents = (item.links || []).length
    return (
      <TableCell colSpan={7} sx={{ py: 1 }}>
        <Box
          alignItems="center"
          display="flex"
          sx={{ cursor: 'pointer' }}
          width="fit-content"
          // @ts-ignore
          onClick={item.onExpand}
        >
          <KeyboardArrowRightIcon />
          <Text fontSize="1.4rem" variant="h3">
            <Trans
              components={{
                span: <Text component="span" variant="body2" />,
              }}
              i18nKey="Payments:MORE_LINKED_INVOICES"
              values={{ totalEvents }}
            />
          </Text>
        </Box>
      </TableCell>
    )
  }

  return null
}

const getHasMultipleLines = (payment: Payment) =>
  payment.links && payment.links.length > 1

function getFullName(employee?: Employee) {
  return employee
    ? `${employee.firstName || ''} ${employee.lastName || ''}`.trim()
    : ''
}

export const useGetPaymentBillingActivityColumns = ({
  key,
  checkbox,
  onClickEstimate,
  multipleRowsLimit,
  showPaymentLabelAndAmount,
  showUnappliedAmount,
}: {
  checkbox?: {
    getIsChecked: (id: string) => boolean
    hasError: boolean
    onCheck: (id: string) => void
  }
  key: 'prop' | 'value'
  multipleRowsLimit?: number
  onClickEstimate: (data: GraphqlEstimate) => void
  showPaymentLabelAndAmount?: boolean
  showUnappliedAmount?: boolean
}): (PuiDataTableColumn[] | StaticTableColumn<Payment>)[] => {
  const classes = useStyles()
  const dispatch = useDispatch()

  const isFetchingGoPayment = useSelector(getPaymentsIsLoading)

  const [openPaymentDetailsDialog] = useDialog(DialogNames.PAYMENT_DETAILS)

  const onRefresh = () => {
    dispatch(refreshClientBillingData())
  }

  const setOpenGoPaymentDialogAfterFetching = useCloseAfterCreation(
    (payment: GoPayment) => {
      openPaymentDetailsDialog({
        clientId: payment.clientId,
        paymentId: payment.id,
        onRefresh,
        type: PaymentType.GO_PAYMENT,
      })
    },
    getPaymentsIsLoading,
  )

  const handleOpenPaymentDetailsDialog = (payment: Payment | GoPayment) => {
    if (payment.__typename === 'GoPayment') {
      setOpenGoPaymentDialogAfterFetching(payment)
      dispatch(fetchGoPayment(payment.id))
      return
    }

    openPaymentDetailsDialog({
      clientId: payment.clientId,
      paymentId: payment.id,
      onRefresh,
    })
  }

  const displayExpandButton = (payment: Payment) =>
    Boolean(multipleRowsLimit) &&
    (payment?.links || []).length > 0 &&
    payment.id.startsWith('skip')

  const linksToShow = (payment: Payment) =>
    multipleRowsLimit
      ? payment.links?.slice(0, multipleRowsLimit) ?? []
      : payment.links!

  return [
    {
      id: 'date',
      label: i18nPortal.t<string>('Common:DATE_TIME'),
      [key]: (row: Payment) => (
        <TableDateCell
          classes={{
            container: getHasMultipleLines(row)
              ? classes.alignWithMultipleLines
              : undefined,
            checkbox: classes.checkbox,
          }}
          date={row.createdAt || row.creationDate}
          hasError={checkbox?.hasError}
          id={row.id}
          isChecked={checkbox?.getIsChecked(row.id)}
          onCheck={checkbox?.onCheck ?? undefined}
        />
      ),
      getTableCellProps: (payment) =>
        Boolean(multipleRowsLimit) &&
        (payment?.links || []).length > multipleRowsLimit!
          ? {
              rowSpan: multipleRowsLimit,
              sx: { verticalAlign: 'baseline' },
            }
          : {},
      skip: displayExpandButton,
    },
    {
      id: 'invoice/estimate',
      label: `${i18nPortal.t('Common:INVOICE')}/${i18nPortal
        .t('Common:ESTIMATE_BUDGET')
        .toLowerCase()}`,
      [key]: (row: Payment) => (
        <TableInvoiceEstimateCell
          estimateInvoices={linksToShow(row)}
          onEstimateNameClick={onClickEstimate}
        />
      ),
      FallbackComponent,
      skip: displayExpandButton,
    },
    {
      id: 'recordedBy',
      label: i18nPortal.t<string>('Common:PAYMENTS.RECORDED_BY'),
      [key]: (row: Payment) => (
        <TableTextCell value={getFullName(row.employee)} />
      ),
      skip: displayExpandButton,
    },
    {
      id: 'patient',
      label: i18nPortal.t<string>('Common:PATIENT'),
      [key]: (row: Payment) => (
        <PaymentTablePatientNameCell links={linksToShow(row)} />
      ),
      skip: displayExpandButton,
    },
    {
      id: 'paidBy',
      label: i18nPortal.t<string>('Common:PAYMENTS.PAID_BY'),
      [key]: (row: Payment) => (
        <TableTextCell value={getFullName(row.paidByPerson!)} />
      ),
      skip: displayExpandButton,
    },
    {
      id: 'method',
      label: i18nPortal.t<string>('Common:METHOD'),
      [key]: (row: Payment) => (
        <TableTextCell value={getPaymentMethodLabel(row as Payment) || ''} />
      ),
      skip: displayExpandButton,
    },
    {
      id: 'payment',
      label: (
        <TextWithTooltip
          ContainerProps={{ component: 'span', className: classes.alignRight }}
          TooltipProps={{
            placement: 'top-start',
          }}
          className={classes.titleCell}
          component="span"
          tooltipText={i18nPortal.t<string>('Payments:CLICK_TO_SEE_DETAILS')}
          variant="h3"
        >
          {showPaymentLabelAndAmount || !showUnappliedAmount
            ? i18nPortal.t<string>('Common:PAYMENTS.PAYMENT')
            : i18nPortal.t<string>('Common:PAYMENTS.UNAPPLIED_AMOUNT')}
        </TextWithTooltip>
      ),
      [key]: (row: Payment | GoPayment) => {
        const isGoPayment = row.__typename === 'GoPayment'
        return (
          <TableTextWithClickCell
            classes={{ text: classes.alignRight }}
            isLoading={isGoPayment ? isFetchingGoPayment : false}
            value={formatMoneyRange(
              Math.abs(
                hasUnappliedPayment(row)
                  ? showPaymentLabelAndAmount || !showUnappliedAmount
                    ? (row as Payment).amount
                    : (row as Payment).unappliedAmount
                  : row.amount,
              ),
              !isCredit(row.amount, row.type?.name || ''),
            )}
            onClick={() => handleOpenPaymentDetailsDialog(row)}
          />
        )
      },
      skip: displayExpandButton,
    },
    {
      id: 'type',
      label: i18nPortal.t<string>('Common:TYPE_ONE'),
      [key]: (row: Payment) => (
        <TableTextCell value={getPaymentWidgetTypeLabel(row.type?.name)} />
      ),
      skip: displayExpandButton,
    },
    {
      id: 'status',
      label: i18nPortal.t<string>('Common:STATUS'),
      [key]: (row: Payment) => <PaymentTableLabelCell value={row} />,
      skip: displayExpandButton,
    },
  ]
}
