import React, { forwardRef, useImperativeHandle, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Grid } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import * as R from 'ramda'
import {
  AddButton,
  Calendar,
  CustomFieldValidatorState,
  FieldProp,
  PermissionArea,
  Text,
  useFields,
} from '@pbt/pbt-ui-components'

import NumericInput from '~/components/common/inputs/NumericInput'
import ManualInputSelect from '~/components/common/ManualInputSelect'
// @ts-ignore
import { getCommonPackagingLabel } from '~/components/dashboard/admin/catalog/inventory/inventoryUtils'
import { getHighValue } from '~/components/dashboard/invoices/invoiceUtils'
import FeatureToggle from '~/constants/featureToggle'
import { getChargesLoading } from '~/store/duck/charges'
import { useIsDrug, useIsFood, useIsVetDiet } from '~/store/hooks/orders'
import { getCRUDByArea } from '~/store/reducers/auth'
import {
  getFeatureToggle,
  getInventoryProductSizeUnit,
  getPackageType,
  getPrescriptionProductSizeUnit,
} from '~/store/reducers/constants'
import { DataHandle, Prescription } from '~/types'
import { isOtherUnitSelected } from '~/utils'
import { getCreateDefaultQuantityDrugExpDate } from '~/utils/orderUtils'
import useEffectExceptOnMount from '~/utils/useEffectExceptOnMount'

import DispensedFromSection, {
  DispensedFromSectionHandle,
} from './DispensedFromSection'
import DispensedFromSectionNew, {
  DispensedFromSectionHandleNew,
} from './DispensedFromSectionNew'
import { useChewyActiveQuantityUnitField } from './hooks/useChewyActiveQuantityUnitField'

const useStyles = makeStyles(
  (theme) => ({
    inputLabel: {
      width: '100%',
      fontWeight: 400,
    },
    quantity: {
      maxWidth: 100,
      paddingRight: theme.spacing(3),
    },
    unit: {
      width: 148,
      paddingRight: theme.spacing(3),
    },
    unitOther: {
      paddingLeft: 0,
      width: 154,
      paddingRight: theme.spacing(3),
    },
    refills: {
      maxWidth: 84,
      marginRight: theme.spacing(3),
    },
    drugExpiration: {
      maxWidth: 136,
      marginRight: theme.spacing(3),
    },
    addButton: {
      padding: theme.spacing(2, 0, 0.5),
      width: 'auto',
    },
    wideUnit: {
      width: 380,
    },
  }),
  { name: 'QuantitySection' },
)

export interface QuantitySectionProps {
  dispensedFromSectionRef:
    | React.RefObject<DispensedFromSectionHandleNew>
    | React.RefObject<DispensedFromSectionHandle>
  isChewyActiveRx?: boolean
  isChewyReactiveRx?: boolean
  isCustomCompound?: boolean
  isEdit?: boolean
  isOutsidePharmacy?: boolean
  onRefChange?: () => void
  prescription: Prescription
  selectedChewyItem?: string
  showTotalQuantitySection?: boolean
}

export interface QuantitySectionHandle extends DataHandle {}

const QuantitySection = forwardRef<QuantitySectionHandle, QuantitySectionProps>(
  function QuantitySection(
    {
      dispensedFromSectionRef,
      isChewyActiveRx,
      isChewyReactiveRx,
      isCustomCompound,
      isEdit,
      isOutsidePharmacy,
      onRefChange,
      prescription,
      selectedChewyItem,
      showTotalQuantitySection = true,
    },
    ref,
  ) {
    const classes = useStyles()
    const { t } = useTranslation(['Common', 'Dialogs'])

    const isEditPostedChargesEnabled = useSelector(
      getFeatureToggle(FeatureToggle.EDIT_POSTED_CHARGES),
    )
    const isMultiBottleLevelFunctionalityToPrescriptionsEnabled = useSelector(
      getFeatureToggle(
        FeatureToggle.MULTI_BOTTLE_LEVEL_FUNCTIONALITY_TO_PRESCRIPTIONS,
      ),
    )

    const chargesLoading = useSelector(getChargesLoading)
    const PackageType = useSelector(getPackageType)
    const InventoryProductSizeUnit = useSelector(getInventoryProductSizeUnit)

    const isDrug = useIsDrug(prescription)
    const isVetDiet = useIsVetDiet(prescription)
    const isFood = useIsFood(prescription)

    const expirationLabel = R.cond([
      [R.always(isDrug), R.always(t('Common:DRUG_EXPIRATION'))],
      [R.always(isFood), R.always(t('Common:FOOD_EXPIRATION'))],
      [R.T, R.always(t('Common:EXPIRATION_DATE'))],
    ])()

    const [chooseFromInventoryVisible, setChooseFromInventoryVisible] =
      useState(true)

    const [isQuantityDisabled, setIsQuantityDisabled] = useState(false)

    const permissions = useSelector(getCRUDByArea(PermissionArea.PRESCRIPTION))
    const PrescriptionProductSizeUnit = useSelector(
      getPrescriptionProductSizeUnit,
    )

    const validateTotalQuantityCustomUnit = ({
      state,
      value,
    }: CustomFieldValidatorState) => {
      const isOtherUnit = isOtherUnitSelected(
        { value: state.totalQuantityUnitId },
        PrescriptionProductSizeUnit,
      )

      return !isOtherUnit || value.length > 0
    }

    const defaultQuantityDrugExpDate = isEdit
      ? ''
      : getCreateDefaultQuantityDrugExpDate()

    const getFields = (obj: Prescription): FieldProp[] => [
      {
        name: 'totalQuantity',
        label: t('Common:QUANTITY'),
        validators: showTotalQuantitySection
          ? ['required', 'greaterThanZero']
          : [],
        initialValue: R.isNil(obj.totalQuantity)
          ? getHighValue(obj.quantity) || undefined
          : obj.totalQuantity,
      },
      {
        name: 'totalQuantityUnitId',
        label: t('Common:UNIT'),
        validators: showTotalQuantitySection ? ['required'] : [],
        type: 'select',
        initialValue: obj.totalQuantityUnitId,
      },
      {
        name: 'totalQuantityCustomUnit',
        initialValue: obj.totalQuantityCustomUnit || '',
        validators: [
          {
            validator: validateTotalQuantityCustomUnit,
            validatorName: 'validateTotalQuantityCustomUnit',
          },
        ],
        messages: {
          validateTotalQuantityCustomUnit: t('Validations:ENTER_OTHER_UNIT'),
        },
      },
      {
        name: 'quantityDrugExpirationDate',
        label: expirationLabel,
        validators: ['timestamp'],
        initialValue:
          obj.quantityDrugExpirationDate || defaultQuantityDrugExpDate,
      },
    ]

    const {
      fields: {
        totalQuantity,
        totalQuantityUnitId,
        totalQuantityCustomUnit,
        quantityDrugExpirationDate,
      },
      validate,
      reset,
    } = useFields(getFields(prescription), false)

    const getPackaging = getCommonPackagingLabel(
      InventoryProductSizeUnit,
      PackageType,
    )

    const fallbackPackageUnitFields = {
      perPackageUnitsId: prescription?.drugInfo?.perPackageUnitsId,
      perPackageAmount: prescription.drugInfo?.perPackageAmount,
      ...(isVetDiet
        ? {
            saleUnitOfMeasure: prescription.drugInfo?.saleUnitOfMeasure,
          }
        : {}),
    }

    const { ModifiedPrescriptionProductSizeUnit } =
      useChewyActiveQuantityUnitField({
        fallbackUnitLabel: getPackaging(fallbackPackageUnitFields),
        isChewyActiveRx,
        selectedChewyItemPartNumber: selectedChewyItem,
        totalQuantityUnitIdField: totalQuantityUnitId,
      })

    useEffectExceptOnMount(() => {
      onRefChange?.()
    }, [
      totalQuantity,
      totalQuantityUnitId,
      totalQuantityCustomUnit,
      quantityDrugExpirationDate,
    ])

    useEffectExceptOnMount(() => {
      reset(getFields(prescription))
    }, [prescription])

    useImperativeHandle(ref, () => ({
      validate,
      get: () => ({
        quantityDrugExpirationDate: quantityDrugExpirationDate.value,
        ...(showTotalQuantitySection
          ? {
              totalQuantity: totalQuantity.value && Number(totalQuantity.value),
              totalQuantityUnitId: totalQuantityUnitId.value,
              totalQuantityCustomUnit: totalQuantityCustomUnit.value,
            }
          : {}),
      }),
    }))

    const handleDispensedFieldsChange = (addButtonVisible?: boolean) => {
      const data = dispensedFromSectionRef.current?.get()
      if (Array.isArray(data)) {
        if (isMultiBottleLevelFunctionalityToPrescriptionsEnabled) {
          const minExpirationDate = data?.reduce((minDate, item) => {
            const itemExpirationDate = item.expirationDate
              ? new Date(item.expirationDate)
              : null
            return itemExpirationDate &&
              (!minDate || itemExpirationDate < minDate)
              ? itemExpirationDate
              : minDate
          }, null as Date | null)

          const calculatedQuantity = data.reduce(
            (acc, item) => acc + (Number(item.lineItemQuantity) || 0),
            0,
          )

          if (minExpirationDate) {
            quantityDrugExpirationDate.setValue(minExpirationDate.toISOString())
          }
          if (calculatedQuantity) {
            totalQuantity.setValue(calculatedQuantity)
          }
          setIsQuantityDisabled(Boolean(calculatedQuantity))
        }
      } else {
        if (data?.expirationDate) {
          quantityDrugExpirationDate.setValue(data.expirationDate)
        }
        if (data?.lineItemQuantity) {
          const calculatedQuantity = Number(data.lineItemQuantity) || 0
          totalQuantity.setValue(calculatedQuantity)

          setIsQuantityDisabled(Boolean(calculatedQuantity))
        }
        setChooseFromInventoryVisible(addButtonVisible || false)
        onRefChange?.()
      }
    }

    return (
      <>
        <Grid container direction="column" pt={2}>
          <Grid container alignItems="center" wrap="wrap">
            {showTotalQuantitySection && (
              <>
                <NumericInput
                  allowDecimal
                  InputLabelProps={{
                    className: classes.inputLabel,
                    shrink: true,
                  }}
                  className={classes.quantity}
                  disabled={
                    isQuantityDisabled ||
                    !permissions.update ||
                    isChewyReactiveRx ||
                    (isEditPostedChargesEnabled && chargesLoading)
                  }
                  field={{
                    ...totalQuantity,
                    setValue: totalQuantity.setValue,
                  }}
                  inputProps={{ maxLength: 10 }}
                  isLoading={isEditPostedChargesEnabled && chargesLoading}
                  label={`${totalQuantity.label}*`}
                  margin="none"
                  min={0}
                />
                <ManualInputSelect
                  classes={{
                    unit: isChewyActiveRx ? classes.wideUnit : classes.unit,
                    other: classes.unitOther,
                  }}
                  disabled={
                    !permissions.update ||
                    isChewyReactiveRx ||
                    isChewyActiveRx ||
                    (isEditPostedChargesEnabled && chargesLoading)
                  }
                  fields={{
                    unitId: totalQuantityUnitId,
                    customUnit: totalQuantityCustomUnit,
                  }}
                  grid={false}
                  isLoading={isEditPostedChargesEnabled && chargesLoading}
                  label={`${totalQuantityUnitId.label}*`}
                  options={
                    isChewyActiveRx
                      ? ModifiedPrescriptionProductSizeUnit
                      : PrescriptionProductSizeUnit
                  }
                />
                {!isChewyActiveRx && (
                  <Grid item>
                    <Calendar
                      fullWidth
                      className={classes.drugExpiration}
                      disabled={!permissions.update || isChewyReactiveRx}
                      field={quantityDrugExpirationDate}
                      label={quantityDrugExpirationDate.label}
                    />
                  </Grid>
                )}
              </>
            )}
            {(chooseFromInventoryVisible ||
              isMultiBottleLevelFunctionalityToPrescriptionsEnabled) &&
              !isOutsidePharmacy &&
              !isChewyActiveRx &&
              !isChewyReactiveRx && (
                <AddButton
                  addText={t('Common:CHOOSE_FROM_INVENTORY')}
                  classes={{ addItem: classes.addButton }}
                  onAdd={() => {
                    dispensedFromSectionRef.current?.chooseFromInventory()
                  }}
                />
              )}
          </Grid>
          {!isCustomCompound &&
            !isOutsidePharmacy &&
            (isMultiBottleLevelFunctionalityToPrescriptionsEnabled ? (
              <Grid>
                <Text strong mb={1} mt={2} variant="subheading3">
                  {t('Common:DISPENSED_FROM_INVENTORY')}
                </Text>
                <DispensedFromSectionNew
                  prescription={prescription}
                  ref={
                    dispensedFromSectionRef as React.RefObject<DispensedFromSectionHandleNew>
                  }
                  onFieldsChange={handleDispensedFieldsChange}
                />
              </Grid>
            ) : (
              <DispensedFromSection
                prescription={prescription}
                ref={
                  dispensedFromSectionRef as React.RefObject<DispensedFromSectionHandle>
                }
                onFieldsChange={handleDispensedFieldsChange}
                onRefChange={onRefChange}
              />
            ))}
        </Grid>
      </>
    )
  },
)

export default QuantitySection
