import React from 'react'
import Dotdotdot from 'react-dotdotdot'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import {
  Fab,
  FormControl,
  Grid,
  Grow,
  Hidden,
  Input,
  InputLabel,
  Paper,
} from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import {
  ClassesType,
  LanguageUtils,
  PermissionArea,
  PhoneUtils,
  PuiAutocomplete,
  PuiSelect,
  PuiTextField,
  Text,
  useFields,
  Utils,
  ZipInput,
} from '@pbt/pbt-ui-components'

import PhoneInput from '~/components/common/form-inputs/PhoneInput'
import MembershipSelect from '~/components/common/inputs/MembershipSelect'
import PuiSwitch from '~/components/common/PuiSwitch'
import { useGetAcquiredTypeList } from '~/constants/acquiredTypes'
import FeatureToggle from '~/constants/featureToggle'
import { MembershipFiltersList } from '~/constants/wellnessPlansConstants'
import { getGroupCRUDByArea } from '~/store/reducers/auth'
import {
  getBreed,
  getFeatureToggle,
  getGender,
  getServiceAnimal,
  getSpecies,
  getTag,
} from '~/store/reducers/constants'
import { FieldsQueryPair } from '~/types'
import {
  getFieldsQueryMergeObject,
  getStringifyFieldsQueryPairs,
  getUrlSearchParam,
} from '~/utils'

const useStyles = makeStyles(
  (theme) => ({
    dropDown: {
      position: 'absolute',
      top: theme.spacing(3),
      width: '100%',
      zIndex: theme.utils.modifyZIndex(theme.zIndex.base, 'above'),
      boxShadow: theme.constants.blockShadow,
      padding: theme.spacing(4, 1, 3),
      [theme.breakpoints.down('md')]: {
        top: theme.spacing(8),
      },
      overflowX: 'hidden',
      overflowY: 'auto',
      maxHeight: 'calc(100vh - 35px)',
    },
    searchButton: {
      width: 150,
      height: 40,
    },
  }),
  { name: 'AdvancedClientSearch' },
)

export interface AdvancedClientSearchProps {
  classes?: ClassesType<typeof useStyles>
  initialFieldsQuery?: string
  onSearch: (search: string, params: [string, string][]) => void
  open: boolean
}

const AdvancedClientSearch = ({
  open,
  initialFieldsQuery = '',
  onSearch,
  classes: classesProp,
}: AdvancedClientSearchProps) => {
  const classes = useStyles({ classes: classesProp })
  const Species = useSelector(getSpecies)
  const Gender = useSelector(getGender)
  const Breed = useSelector(getBreed)
  const Tags = useSelector(getTag)
  const ServiceAnimal = useSelector(getServiceAnimal)
  const isPatientSharingEnabled = useSelector(
    getFeatureToggle(FeatureToggle.PATIENT_SHARING),
  )
  const groupPermissions = useSelector(
    getGroupCRUDByArea(PermissionArea.PATIENT),
  )

  const { t } = useTranslation('Common')

  const { AcquiredTypeItemsList } = useGetAcquiredTypeList()
  const location = useLocation()

  const fieldsQueryParam =
    getUrlSearchParam('fieldsQuery', location.search) || initialFieldsQuery
  const searchFieldsObject = getFieldsQueryMergeObject(fieldsQueryParam)
  const initialSpeciesId = Utils.findConstantIdByName(
    searchFieldsObject['patients.species'],
    Species,
  )
  const initialBreeds = initialSpeciesId
    ? searchFieldsObject['patients.breeds']
        ?.split(',')
        .map((breedName) =>
          Utils.findConstantIdByName(breedName, Breed[initialSpeciesId]),
        )
    : []

  const activeMembershipFilter = MembershipFiltersList.find(
    (name) => searchFieldsObject[name],
  ) as string

  const {
    fields: {
      firstName,
      lastName,
      phone,
      email,
      city,
      zip,
      tag,
      name,
      species,
      gender,
      breed,
      microchipNumber,
      acquiredType,
      serviceAnimalType,
      rabiesTag,
      license,
      wplanFilterName,
      wplanFilterValue,
      externalPersonId,
      externalPatientId,
      groupSearchRequire,
    },
  } = useFields([
    {
      name: 'firstName',
      label: t('Common:FIRST_NAME'),
      initialValue: searchFieldsObject.firstName || '',
    },
    {
      name: 'lastName',
      label: t('Common:LAST_NAME'),
      initialValue: searchFieldsObject.lastName || '',
    },
    {
      name: 'phone',
      label: t('Common:PHONE_NUMBER'),
      initialValue: searchFieldsObject.phone || '',
    },
    {
      name: 'email',
      label: t('Common:EMAIL'),
      initialValue: searchFieldsObject.email || '',
    },
    {
      name: 'city',
      label: t('Common:CITY'),
      initialValue: searchFieldsObject.city || '',
    },
    {
      name: 'zip',
      label: t('Common:ZIP_CODE'),
      initialValue: searchFieldsObject.zip || '',
    },
    {
      name: 'tag',
      label: t('Search:CLIENT_FILTERS.TAG'),
      type: 'select',
      initialValue:
        Utils.findConstantIdByName(searchFieldsObject.tag, Tags) || '' || '',
    },
    {
      name: 'name',
      label: t('Common:NAME'),
      initialValue: searchFieldsObject['patients.name'] || '',
    },
    {
      name: 'species',
      label: t('Common:SPECIES'),
      type: 'select',
      initialValue: initialSpeciesId || '',
    },
    {
      name: 'gender',
      label: t('Common:GENDER'),
      type: 'select',
      initialValue:
        Utils.findConstantIdByName(
          searchFieldsObject['patients.gender'],
          Gender,
        ) || '',
    },
    {
      name: 'breed',
      label: t('Common:BREED'),
      type: 'select',
      initialValue: initialBreeds || [],
    },
    {
      name: 'microchipNumber',
      label: t('Common:MICROCHIP_NUMBER'),
      initialValue: searchFieldsObject['patients.microchipNumber'] || '',
    },
    {
      name: 'acquiredType',
      label: t('Search:CLIENT_FILTERS.WHERE_DID_PATIENT_COME_FROM'),
      initialValue: searchFieldsObject['patients.acquiredType'] || '',
    },
    {
      name: 'serviceAnimalType',
      label: t('Common:SERVICE_DESIGNATION'),
      initialValue: searchFieldsObject['patients.serviceAnimalType'] || '',
    },
    {
      name: 'rabiesTag',
      label: t('Common:RABIES_TAG'),
      initialValue: searchFieldsObject['patients.rabiesTag'] || '',
    },
    {
      name: 'license',
      label: t('Common:LICENSE_NUMBER'),
      initialValue: searchFieldsObject['patients.license'] || '',
    },
    {
      name: 'wplanFilterName',
      initialValue: activeMembershipFilter || '',
    },
    {
      name: 'wplanFilterValue',
      initialValue: searchFieldsObject[activeMembershipFilter] || '',
    },
    {
      name: 'externalPersonId',
      label: t('Search:CLIENT_FILTERS.EXTERNAL_PERSON_ID'),
      initialValue: searchFieldsObject.externalPersonId || '',
    },
    {
      name: 'externalPatientId',
      label: t('Search:CLIENT_FILTERS.PATIENTS.EXTERNAL_PATIENT_ID'),
      initialValue: searchFieldsObject['patients.externalPatientId'] || '',
    },
    {
      name: 'groupSearchRequire',
      label: t('Search:INCLUDE_GROUP_RECORDS_IN_SEARCH'),
      initialValue: false,
      type: 'toggle',
    },
  ])

  const isNoBreeds =
    !species.value || !Breed[species.value] || Breed[species.value].length === 0
  const breedsLabel = !isNoBreeds
    ? t('Common:BREED')
    : species.value
    ? t('Common:INPUTS.BREED_AUTO_COMPLETE.HAS_SPECIES', {
        specie: LanguageUtils.getConstantTranslatedName(species.value, Species),
      })
    : t('Common:INPUTS.BREED_AUTO_COMPLETE.NO_SPECIES')

  const onSearchClick = () => {
    const speciesBreeds = Breed[species.value]
    const breedsString = (breed.value as string[])
      .map((oneBreed) => Utils.getConstantName(oneBreed, speciesBreeds, ''))
      .join(',')
    const fieldsQueryPairs: FieldsQueryPair[] = [
      ['patients.name', name.value],
      ['patients.species', Utils.getConstantName(species.value, Species, '')],
      ['patients.gender', Utils.getConstantName(gender.value, Gender, '')],
      ['patients.breeds', breedsString],
      ['patients.microchipNumber', microchipNumber.value],
      ['patients.acquiredType', acquiredType.value],
      [
        'patients.serviceAnimalType',
        Utils.getConstantName(serviceAnimalType.value, ServiceAnimal, ''),
      ],
      ['patients.rabiesTag', rabiesTag.value],
      ['patients.license', license.value],
      [wplanFilterName.value, wplanFilterValue.value],
      ['firstName', firstName.value],
      ['lastName', lastName.value],
      ['phone', PhoneUtils.parsePhoneNumber(phone.value) || phone.value],
      ['email', email.value],
      ['city', city.value],
      ['zip', zip.value],
      ['tag', Utils.getConstantName(tag.value, Tags, '')],
      ['externalPersonId', externalPersonId.value],
      ['patients.externalPatientId', externalPatientId.value],
    ]

    const params: [string, string][] =
      isPatientSharingEnabled && groupSearchRequire.value
        ? [['groupSearchRequire', String(groupSearchRequire.value)]]
        : []

    onSearch(getStringifyFieldsQueryPairs(fieldsQueryPairs), params)
  }

  return (
    <Grow in={open}>
      <Paper className={classes.dropDown}>
        <Grid
          container
          columnSpacing={2}
          direction={{ sm: 'column', md: 'row' }}
          onKeyPress={(event) => {
            if (event.key === 'Enter') {
              onSearchClick()
              const target = event.target as HTMLElement
              target.blur()
            }
          }}
        >
          <Grid item md={4} sm={12}>
            <Text strong variant="body2">
              {t('Common:CLIENT')}
            </Text>
            <Grid container direction="column">
              <Grid item>
                <PuiTextField
                  field={firstName}
                  inputProps={{ maxLength: 100 }}
                  label={firstName.label}
                />
              </Grid>
              <Grid item>
                <PuiTextField
                  field={lastName}
                  inputProps={{ maxLength: 100 }}
                  label={lastName.label}
                />
              </Grid>
              <Grid item>
                <PhoneInput field={phone} />
              </Grid>
              <Grid item>
                <PuiTextField field={email} label={email.label} type="email" />
              </Grid>
              <Grid item>
                <PuiTextField
                  field={city}
                  inputProps={{ maxLength: 100 }}
                  label={city.label}
                />
              </Grid>
              <Grid item>
                <PuiTextField
                  InputProps={{
                    inputComponent: ZipInput,
                    inputProps: { country: 'any' },
                  }}
                  field={zip}
                  label={zip.label}
                />
              </Grid>
              <Grid item>
                <FormControl fullWidth margin="normal">
                  <InputLabel htmlFor="tag-select">{tag.label}</InputLabel>
                  <PuiSelect
                    field={tag}
                    id="tag-puiselect"
                    input={<Input id="tag-select" />}
                    items={Tags}
                  />
                </FormControl>
              </Grid>
              <Grid item>
                <PuiTextField
                  field={externalPersonId}
                  inputProps={{ maxLength: 100 }}
                  label={externalPersonId.label}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item md={4} sm={12}>
            <Text strong variant="body2">
              {t('Common:PATIENT')}
            </Text>
            <Grid item>
              <PuiTextField
                field={name}
                inputProps={{ maxLength: 100 }}
                label={name.label}
              />
            </Grid>
            <Grid item>
              <FormControl fullWidth margin="normal">
                <InputLabel htmlFor="species-select">
                  {species.label}
                </InputLabel>
                <PuiSelect
                  field={species}
                  id="species-puiselect"
                  input={<Input id="species-select" />}
                  items={Species}
                />
              </FormControl>
            </Grid>
            <Grid item>
              <FormControl fullWidth margin="normal">
                <InputLabel htmlFor="gender-select">{gender.label}</InputLabel>
                <PuiSelect
                  field={gender}
                  input={<Input id="gender-select" />}
                  items={Gender}
                />
              </FormControl>
            </Grid>
            <Grid item>
              <PuiAutocomplete
                fullWidth
                multiple
                translateOptions
                disabled={isNoBreeds}
                field={breed}
                label={breedsLabel}
                minSearchLength={1}
                options={Breed[species.value]}
              />
            </Grid>
            <Grid item>
              <PuiTextField
                field={microchipNumber}
                inputProps={{ maxLength: 100 }}
                label={microchipNumber.label}
              />
            </Grid>
            <Grid item>
              <PuiTextField
                field={rabiesTag}
                inputProps={{ maxLength: 100 }}
                label={rabiesTag.label}
              />
            </Grid>
            <Grid item>
              <MembershipSelect
                nameField={wplanFilterName}
                valueField={wplanFilterValue}
              />
            </Grid>
          </Grid>
          <Grid item md={4} sm={12}>
            <Hidden mdDown>
              <Text strong variant="body2">
                &nbsp;
              </Text>
            </Hidden>
            <Grid item>
              <PuiTextField
                field={license}
                inputProps={{ maxLength: 100 }}
                label={license.label}
              />
            </Grid>
            <Grid item>
              <FormControl fullWidth margin="normal">
                <InputLabel htmlFor="species-select">
                  {serviceAnimalType.label}
                </InputLabel>
                <PuiSelect
                  field={serviceAnimalType}
                  id="serviceAnimalType-puiselect"
                  input={<Input id="service-animal-select" />}
                  items={ServiceAnimal}
                />
              </FormControl>
            </Grid>
            <Grid item>
              <FormControl fullWidth margin="normal">
                <InputLabel htmlFor="species-select">
                  {acquiredType.label}
                </InputLabel>
                <PuiSelect
                  field={acquiredType}
                  id="acquiredType-puiselect"
                  input={<Input id="acquired-type-select" />}
                  items={AcquiredTypeItemsList}
                />
              </FormControl>
            </Grid>
            <Grid item>
              <PuiTextField
                field={externalPatientId}
                inputProps={{ maxLength: 100 }}
                label={externalPatientId.label}
              />
            </Grid>
            {isPatientSharingEnabled && groupPermissions.read && (
              <Grid item mt={2}>
                <PuiSwitch
                  field={groupSearchRequire}
                  label={
                    <Dotdotdot clamp={1}>{groupSearchRequire.label}</Dotdotdot>
                  }
                />
              </Grid>
            )}
          </Grid>
          <Grid container item justifyContent="center" xs={12}>
            <Fab
              className={classes.searchButton}
              color="inherit"
              variant="extended"
              onClick={onSearchClick}
            >
              {t('Common:SEARCH_ACTION')}
            </Fab>
          </Grid>
        </Grid>
      </Paper>
    </Grow>
  )
}

export default AdvancedClientSearch
