import React, {
  ForwardedRef,
  forwardRef,
  useEffect,
  useImperativeHandle,
} from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { FormControl, Grid, Input, InputLabel } from '@mui/material'
import {
  Calendar,
  Nil,
  PuiSelect,
  PuiTextField,
  TextWithTooltip,
  useFields,
} from '@pbt/pbt-ui-components'

import ManualInputSelect from '~/components/common/ManualInputSelect'
import FeatureToggle from '~/constants/featureToggle'
import {
  getFeatureToggle,
  getRabiesTagDefaultExpiration,
  getVaccineDefaultAmount,
  getVaccineDefaultDeliveryLocation,
  getVaccineDefaultDoseType,
  getVaccineDefaultDurationOfImmunity,
  getVaccineDeliveryMethod,
  getVaccineType,
} from '~/store/reducers/constants'
import { DataHandle, Order } from '~/types'
import { getIsRabiesVaccine } from '~/utils/orderUtils'
import useManualTimeDurationValue from '~/utils/useManualTimeDurationValue'

import RabiesVaccineDetails from './RabiesVaccineDetails'

export interface AdditionalVaccineDetailsProps {
  className?: string
  defaultDueDateOverride?: string
  patientId: string | Nil
  vaccine: Order
}

export interface AdditionalVaccineDetailsHandle
  extends DataHandle<Order['vaccinationDetails']> {}

const AdditionalVaccineDetails = forwardRef(function AdditionalVaccineDetails(
  {
    className,
    defaultDueDateOverride,
    vaccine,
    patientId,
  }: AdditionalVaccineDetailsProps,
  ref: ForwardedRef<AdditionalVaccineDetailsHandle>,
) {
  const { t } = useTranslation(['Common', 'Soap'])

  const VaccineDefaultDoseType = useSelector(getVaccineDefaultDoseType)
  const VaccineDeliveryMethod = useSelector(getVaccineDeliveryMethod)
  const VaccineDefaultAmount = useSelector(getVaccineDefaultAmount)
  const VaccineDefaultDeliveryLocation = useSelector(
    getVaccineDefaultDeliveryLocation,
  )
  const VaccineDefaultDurationOfImmunity = useSelector(
    getVaccineDefaultDurationOfImmunity,
  )
  const RabiesTagDefaultExpiration = useSelector(getRabiesTagDefaultExpiration)
  const VaccineType = useSelector(getVaccineType)

  const isRabies = getIsRabiesVaccine(vaccine)

  const { vaccinationDetails } = vaccine
  const {
    rabiesTagExpires: rabiesTagExpiresValue,
    rabiesTagExpiration,
    rabiesTagExpirationOptionId,
    rabiesTagExpirationUnitId,
  } = vaccinationDetails || {}

  const dueDateDefaultValue = useManualTimeDurationValue(
    vaccinationDetails?.vaccineDurationOfImmunityOptionId,
    vaccinationDetails?.vaccineDurationOfImmunityUnitId,
    vaccinationDetails?.vaccineDurationOfImmunity,
    VaccineDefaultDurationOfImmunity,
    'duration',
  )
  const rabiesTagExpiresDefaultValue = useManualTimeDurationValue(
    rabiesTagExpirationOptionId,
    rabiesTagExpirationUnitId,
    rabiesTagExpiration,
    RabiesTagDefaultExpiration,
    'expiration',
  )

  const {
    fields: {
      amountId,
      amount,
      dueDate,
      locationId,
      location,
      lotNumber,
      serialNumber,
      lotExpiration,
      manufacturedBy,
      doseTypeId,
      deliveryMethodId,
      typeId,
      rabiesTag,
      rabiesTagExpires,
    },
    reset,
    validate,
  } = useFields(
    [
      {
        name: 'manufacturedBy',
        label: t('Common:MANUFACTURED_BY'),
        initialValue: vaccinationDetails?.manufacturedBy || '',
      },
      {
        name: 'serialNumber',
        label: t('Common:SERIAL_NUMBER'),
        initialValue: vaccinationDetails?.serialNumber || '',
      },
      {
        name: 'location',
        label: t('Common:LOCATION'),
        initialValue:
          vaccinationDetails?.location ||
          vaccinationDetails?.vaccineDefaultDeliveryLocation ||
          '',
      },
      {
        name: 'amount',
        label: t('Common:AMOUNT'),
        initialValue:
          vaccinationDetails?.amount ||
          vaccinationDetails?.vaccineDefaultAmount ||
          '',
      },
      {
        name: 'lotNumber',
        label: t('Common:LOT_NUMBER'),
        initialValue: vaccinationDetails?.lotNumber || '',
      },
      {
        name: 'lotExpiration',
        label: t('Common:LOT_EXPIRATION'),
        initialValue: vaccinationDetails?.lotExpiration || '',
      },
      {
        name: 'dueDate',
        label: t('Common:EXPIRATION_DATE'),
        initialValue:
          vaccinationDetails?.dueDate ||
          defaultDueDateOverride ||
          dueDateDefaultValue ||
          '',
      },
      {
        name: 'doseTypeId',
        label: t('Soap:ADDITIONAL_VACCINE_DETAILS.VACCINE_DOSE_TYPE'),
        type: 'select',
        initialValue:
          vaccinationDetails?.doseTypeId ||
          vaccinationDetails?.vaccineDefaultDoseTypeId ||
          '',
      },
      {
        name: 'deliveryMethodId',
        label: t('Common:VACCINE_DELIVERY_METHOD'),
        type: 'select',
        initialValue:
          vaccinationDetails?.deliveryMethodId ||
          vaccinationDetails?.vaccineDeliveryMethodId ||
          '',
      },
      {
        name: 'amountId',
        type: 'select',
        initialValue:
          vaccinationDetails?.amountId ||
          vaccinationDetails?.vaccineDefaultAmountId ||
          '',
      },
      {
        name: 'locationId',
        type: 'select',
        initialValue:
          vaccinationDetails?.locationId ||
          vaccinationDetails?.vaccineDefaultDeliveryLocationId ||
          '',
      },
      {
        name: 'typeId',
        label: t('Common:VACCINE_TYPE'),
        type: 'select',
        initialValue:
          vaccinationDetails?.typeId || vaccinationDetails?.vaccineTypeId || '',
      },
      {
        name: 'rabiesTag',
        initialValue: vaccinationDetails?.rabiesTag || '',
      },
      {
        name: 'rabiesTagExpires',
        initialValue:
          rabiesTagExpiresValue ||
          defaultDueDateOverride ||
          rabiesTagExpiresDefaultValue ||
          '',
      },
    ],
    false,
  )

  const isExpirationDateCalculatedByReminderProtocol = useSelector(
    getFeatureToggle(
      FeatureToggle.VACCINATION_DURATION_OF_IMMUNITY_BASED_ON_REMINDER_PROTOCOL,
    ),
  )

  const getDueDateLabel = (label: string | undefined, fieldHasValue: boolean) =>
    isExpirationDateCalculatedByReminderProtocol && fieldHasValue ? (
      <TextWithTooltip
        tooltipText={t('Tooltips:VACCINE_EXPIRATION_DATE')}
        variant="body"
      >
        {label}
      </TextWithTooltip>
    ) : (
      label
    )

  useEffect(() => {
    reset()
  }, [vaccinationDetails])

  useImperativeHandle(ref, () => ({
    validate,
    get: () => ({
      manufacturedBy: manufacturedBy.value || null,
      serialNumber: serialNumber.value || null,
      location: location.value || null,
      locationId: locationId.value || null,
      amount: amount.value || null,
      amountId: amountId.value || null,
      lotNumber: lotNumber.value || null,
      lotExpiration: lotExpiration.value || null,
      dueDate: dueDate.value || null,
      doseTypeId: doseTypeId.value || null,
      deliveryMethodId: deliveryMethodId.value || null,
      typeId: typeId.value || null,
      ...(isRabies
        ? {
            rabiesTag: rabiesTag.value || null,
            rabiesTagExpires: rabiesTagExpires.value || null,
          }
        : {}),
    }),
  }))

  return (
    <Grid
      container
      item
      alignItems="flex-start"
      className={className}
      spacing={2}
    >
      {isRabies && (
        <RabiesVaccineDetails
          fields={{ rabiesTag, rabiesTagExpires }}
          patientId={patientId}
        />
      )}
      <Grid item xs={3}>
        <FormControl fullWidth margin="none">
          <InputLabel htmlFor="dose-type-select">{doseTypeId.label}</InputLabel>
          <PuiSelect
            fullWidth
            field={doseTypeId}
            input={<Input id="dose-type-select" />}
            items={VaccineDefaultDoseType}
          />
        </FormControl>
      </Grid>
      <Grid item xs={4}>
        <Calendar
          fullWidth
          label={getDueDateLabel(dueDate.label, Boolean(dueDate.value))}
          margin="none"
          value={dueDate.value || null}
          onChange={(newDate: string | Nil) => {
            dueDate.setValue(newDate || null)
          }}
        />
      </Grid>
      <Grid item xs={5}>
        <FormControl fullWidth margin="none">
          <InputLabel htmlFor="delivery-method-select">
            {deliveryMethodId.label}
          </InputLabel>
          <PuiSelect
            fullWidth
            field={deliveryMethodId}
            input={<Input id="delivery-method-select" />}
            items={VaccineDeliveryMethod}
          />
        </FormControl>
      </Grid>
      <Grid container item spacing={2}>
        <ManualInputSelect
          fields={{
            unitId: amountId,
            customUnit: amount,
          }}
          label={amount.label}
          options={VaccineDefaultAmount}
        />
        <ManualInputSelect
          fields={{
            unitId: locationId,
            customUnit: location,
          }}
          label={location.label}
          options={VaccineDefaultDeliveryLocation}
          unitGridWidth={5}
        />
      </Grid>
      <Grid item xs={4}>
        <FormControl fullWidth margin="none">
          <InputLabel htmlFor="vaccine-type-select">{typeId.label}</InputLabel>
          <PuiSelect
            fullWidth
            field={typeId}
            input={<Input id="vaccine-type-select" />}
            items={VaccineType}
          />
        </FormControl>
      </Grid>
      <Grid item xs={4}>
        <PuiTextField
          field={manufacturedBy}
          label={manufacturedBy.label}
          margin="none"
        />
      </Grid>
      <Grid item xs={4}>
        <PuiTextField
          field={serialNumber}
          label={serialNumber.label}
          margin="none"
        />
      </Grid>
      <Grid item xs={6}>
        <PuiTextField field={lotNumber} label={lotNumber.label} margin="none" />
      </Grid>
      <Grid item xs={6}>
        <Calendar
          fullWidth
          label={lotExpiration.label}
          margin="none"
          value={lotExpiration.value || null}
          onChange={(newDate: string | Nil) => {
            lotExpiration.setValue(newDate || null)
          }}
        />
      </Grid>
    </Grid>
  )
})

export default AdditionalVaccineDetails
