import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { Grid } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import {
  AlertIconType,
  BusinessBrandingConfiguration,
  ButtonWithLoader,
  Nil,
  PuiTextField,
  Text,
  useFields,
} from '@pbt/pbt-ui-components'
import {
  MembershipSignUpSuccessBackground,
  SuccessAlert,
} from '@pbt/pbt-ui-components/src/icons'
import { BoopScreens } from '@pbt/pbt-ui-components/src/img'

import DialogNames from '~/constants/DialogNames'
import FeatureToggle from '~/constants/featureToggle'
import { sendDefaultWelcomeEmail } from '~/store/actions/wellnessPlans'
import { getCurrentBusiness } from '~/store/reducers/auth'
import { getFeatureToggle } from '~/store/reducers/constants'
import { getUser } from '~/store/reducers/users'
import { getWellnessPlansIsLoading } from '~/store/reducers/wellnessPlans'
import useCloseAfterCreation from '~/utils/useCloseAfterCreation'
import useDialog from '~/utils/useDialog'

const useStyles = makeStyles(
  (theme) => ({
    root: {},
    success: {
      maxWidth: 450,
    },
    successWithNames: {
      maxWidth: 450,
      paddingLeft: theme.spacing(4),
      paddingRight: theme.spacing(4),
    },
    successIcon: {
      width: 38,
      height: 38,
      color: theme.colors.alertSuccess,
    },
    paragraphWithNames: {
      fontSize: 20,
      fontWeight: 500,
      marginBottom: theme.spacing(1),
    },
    header: {
      position: 'relative',
    },
    content: {
      paddingBottom: theme.spacing(3),
    },
    background: {
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      position: 'absolute',
      fill: (props: BusinessBrandingConfiguration) => props.backgroundColor,
    },
    title: {
      zIndex: theme.utils.modifyZIndex(theme.zIndex.base, 'above'),
      color: theme.colors.profileText,
    },
    boopScreens: {
      marginTop: theme.spacing(5),
      marginBottom: theme.spacing(6.25),
      maxWidth: 455,
    },
    button: {
      width: 175,
      height: 56,
      marginTop: theme.spacing(3),
      fontSize: '1.8rem',
      borderRadius: 28,
      color: (props: BusinessBrandingConfiguration) =>
        props.buttonsColor ? theme.colors.profileText : undefined,
      backgroundColor: (props: BusinessBrandingConfiguration) =>
        props.buttonsColor,
      '&&&&:hover': {
        backgroundColor: (props: BusinessBrandingConfiguration) =>
          props.buttonsColor,
      },
    },
    emailInput: {
      marginTop: theme.spacing(2),
      maxWidth: 285,
    },
  }),
  { name: 'MembershipSignUpSuccessSection' },
)

interface MembershipSignUpSuccessSectionProps {
  clientId: string | Nil
  onClose?: () => void
  patientNames: string[]
  selection: { patientId: string; planId: string }[]
}

const MembershipSignUpSuccessSection = ({
  clientId,
  onClose,
  patientNames,
  selection,
}: MembershipSignUpSuccessSectionProps) => {
  const dispatch = useDispatch()
  const { t } = useTranslation(['Common', 'Memberships'])

  const business = useSelector(getCurrentBusiness)
  const client = useSelector(getUser(clientId))
  const isLoading = useSelector(getWellnessPlansIsLoading)
  const isBoopDisablementEnabled = useSelector(
    getFeatureToggle(FeatureToggle.BOOP_DISABLEMENT),
  )

  const isIpoM2BBrainTreeSignupEnabled = useSelector(
    getFeatureToggle(FeatureToggle.IPO_M2B_BRAINTREE_SIGNUP),
  )

  const [openEmailSentAlert, closeEmailSentAlert] = useDialog(
    DialogNames.DISMISSIBLE_ALERT,
  )

  const classes = useStyles({
    buttonsColor: business?.buttonsColor,
    backgroundColor: business?.backgroundColor,
  })

  const {
    fields: { email },
    validate,
  } = useFields([
    {
      name: 'email',
      label: t('Common:EMAIL'),
      validators: ['email', 'required'],
      initialValue: client?.email || '',
    },
  ])

  useEffect(() => {
    if (isBoopDisablementEnabled && client?.id && client?.email) {
      dispatch(sendDefaultWelcomeEmail(client.id, client.email, selection))
    }
  }, [isBoopDisablementEnabled, client, selection])

  const onSentInviteSuccess = () => {
    openEmailSentAlert({
      iconType: AlertIconType.SUCCESS,
      message: t('Memberships:MEMBERSHIP_SIGN_UP_SUCCESS_SECTION.INVITE_SENT'),
      okButtonText: t('Common:CLOSE_ACTION'),
      onOk: () => {
        closeEmailSentAlert()
        if (onClose) {
          onClose()
        }
      },
    })
  }

  const showSuccessAfterSendInvite = useCloseAfterCreation(
    onSentInviteSuccess,
    getWellnessPlansIsLoading,
  )

  const send = () => {
    if (validate()) {
      showSuccessAfterSendInvite()
      if (client?.id) {
        dispatch(sendDefaultWelcomeEmail(client.id, email.value, selection))
      }
    }
  }

  const composePatientNames = (names: string[]) => {
    if (names.length >= 2) {
      return `${names.slice(0, -1).join(', ')}${
        names.length > 2 ? ', and' : ' and'
      } ${names.at(-1)}`
    }
    return names[0]
  }

  return (
    <Grid
      container
      item
      alignItems="center"
      className={classes.root}
      direction="column"
    >
      {isBoopDisablementEnabled ? (
        <Grid
          container
          alignItems="center"
          className={
            isIpoM2BBrainTreeSignupEnabled
              ? classes.successWithNames
              : classes.success
          }
          direction="column"
          px={8}
          py={4}
        >
          <SuccessAlert className={classes.successIcon} />
          {isIpoM2BBrainTreeSignupEnabled ? (
            <Grid>
              <Text
                align="center"
                className={classes.paragraphWithNames}
                my={3}
              >
                {t(
                  'Memberships:MEMBERSHIP_SIGN_UP_SUCCESS_SECTION.CONGRATS_ON_ENROLLING_WITH_NAME',
                  {
                    petNames: composePatientNames(patientNames),
                  },
                )}
              </Text>
              <Text align="center">
                {t(
                  'Memberships:MEMBERSHIP_SIGN_UP_SUCCESS_SECTION.ENROLLING_REMINDER',
                )}
              </Text>
            </Grid>
          ) : (
            <Grid>
              <Text align="center" my={3}>
                {t(
                  'Memberships:MEMBERSHIP_SIGN_UP_SUCCESS_SECTION.CONGRATS_ON_ENROLLING',
                )}
              </Text>
            </Grid>
          )}
          {!isIpoM2BBrainTreeSignupEnabled && (
            <ButtonWithLoader onClick={onClose}>
              {t('Common:CLOSE_ACTION')}
            </ButtonWithLoader>
          )}
        </Grid>
      ) : (
        <>
          <Grid
            container
            item
            alignItems="center"
            className={classes.header}
            direction="column"
            pb={6}
            pt={5.75}
            px={2}
          >
            <MembershipSignUpSuccessBackground className={classes.background} />
            <Text align="center" className={classes.title} variant="hero">
              {t('Memberships:MEMBERSHIP_SIGN_UP_SUCCESS_SECTION.CONGRATS')}
            </Text>
            <Text align="center" className={classes.title} variant="hero">
              {t(
                'Memberships:MEMBERSHIP_SIGN_UP_SUCCESS_SECTION.GREAT_JOB_ENROLLING',
              )}
            </Text>
          </Grid>
          <Grid
            container
            item
            alignItems="center"
            className={classes.content}
            direction="column"
          >
            <img
              alt="boop-screens"
              className={classes.boopScreens}
              src={BoopScreens}
            />
            <Text>
              {t(
                'Memberships:MEMBERSHIP_SIGN_UP_SUCCESS_SECTION.SEND_INVITE_BOOP_SYSTEM',
              )}
            </Text>
            <PuiTextField
              className={classes.emailInput}
              field={email}
              inputProps={{ maxLength: 100 }}
              label={email.label}
            />
            <ButtonWithLoader
              className={classes.button}
              loading={isLoading}
              onClick={send}
            >
              {t('Common:SEND_INVITE')}
            </ButtonWithLoader>
          </Grid>
        </>
      )}
    </Grid>
  )
}

export default MembershipSignUpSuccessSection
