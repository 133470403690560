import React, { useState } from 'react'
import { DraggableProvidedDragHandleProps } from 'react-beautiful-dnd'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { DragIndicator as DragIndicatorIcon } from '@mui/icons-material'
import { Grid, TableCell, TableRow } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { PuiCheckbox, Text } from '@pbt/pbt-ui-components'

import AlertLabel from '~/components/common/labels/AlertLabel'
import { AlertColorMap, AlertColors } from '~/constants/alertColors'
import FeatureToggle from '~/constants/featureToggle'
import {
  FINANCE_TABLE_CELL_HEIGHT,
  FINANCE_TABLE_PADDING_X_SPACING_VALUE,
} from '~/constants/financeTable'
import {
  getChargeSheetClientBalance,
  getChargeSheetSubItemById,
} from '~/store/duck/clientFinanceData'
import { useGetChargeSheetItemHeader } from '~/store/hooks/chargeSheet'
import { getFeatureToggle } from '~/store/reducers/constants'

import { formatMoneyRange } from '../invoices/invoiceUtils'

const useStyles = makeStyles(
  (theme) => ({
    alertLabelText: {
      lineHeight: '1.7rem',
      padding: theme.spacing(0.5, 0),
    },
    alertLabelBadgeRoot: {
      justifyContent: 'flex-start',
      backgroundColor: AlertColorMap[AlertColors.GREEN],
    },
    alertLabelToolTip: {
      backgroundColor: theme.colors.selectedItem,
      color: theme.colors.badgeColor,
      '&::before, &::after': {
        display: 'none',
      },
      '&::before': {
        display: 'none',
      },
      '&::after': {
        display: 'none',
      },
    },
    icon: {
      color: theme.colors.lowAccentText,
    },
    tableTitle: {
      color: theme.colors.tabLabel,
      padding: theme.spacing(1, FINANCE_TABLE_PADDING_X_SPACING_VALUE),
      height: FINANCE_TABLE_CELL_HEIGHT,
    },
    checkBox: {
      margin: 0,
    },
    spacingDiv: {
      paddingLeft: 66,
    },
  }),
  { name: 'ChargeSheetTableSubHeader' },
)

export type ChargeSheetTableSubHeaderProps = {
  currentItemId?: string
  dragHandleProps?: DraggableProvidedDragHandleProps
  onClick?: (currentItemId?: string) => void
  showOnlySubHeader?: boolean
}

const ChargeSheetTableSubHeader = ({
  currentItemId,
  dragHandleProps,
  onClick,
}: ChargeSheetTableSubHeaderProps) => {
  const { t } = useTranslation('Common')
  const classes = useStyles()
  const title = useGetChargeSheetItemHeader(currentItemId)
  const chargeSheetItemSection = useSelector(
    getChargeSheetSubItemById(currentItemId),
  )
  const clientBalance = useSelector(getChargeSheetClientBalance)
  const navigate = useNavigate()
  const isIpoM0RevampClientBillingEnabled = useSelector(
    getFeatureToggle(FeatureToggle.IPO_M0_REVAMP_CLIENT_BILLING_PAGE),
  )

  const [isSelected, setIsSelected] = useState(false)

  const finalized =
    chargeSheetItemSection && chargeSheetItemSection.soap
      ? chargeSheetItemSection.soap.finalized
      : false
  const soapId = chargeSheetItemSection?.soapId
  const showClientBalance = !chargeSheetItemSection
  const showSelectCheckBox =
    Boolean(chargeSheetItemSection) ||
    (showClientBalance && !isIpoM0RevampClientBillingEnabled)

  const onCheckBoxClick = onClick
    ? () => {
        setIsSelected(!isSelected)
        onClick(currentItemId)
      }
    : undefined

  const onClickSoapName = () => {
    if (soapId) {
      navigate(`/soap/${soapId}`)
    }
  }

  return (
    <TableRow {...dragHandleProps}>
      <TableCell className={classes.tableTitle} colSpan={6}>
        <Grid container alignItems="center">
          {showSelectCheckBox && <DragIndicatorIcon className={classes.icon} />}
          {showSelectCheckBox && (
            <PuiCheckbox
              hidden
              checked={isSelected}
              classes={{ labelRoot: classes.checkBox }}
              onClick={onCheckBoxClick}
            />
          )}
          <Text
            className={showSelectCheckBox ? undefined : classes.spacingDiv}
            component="span"
            link={Boolean(soapId)}
            variant="h4"
            onClick={onClickSoapName}
          >
            {showClientBalance ? `${t('Common:CLIENT_BALANCE')}` : title}
          </Text>
          {isIpoM0RevampClientBillingEnabled && showClientBalance && (
            <Text component="span" variant="h5">
              {t('Common:COLON')}
              {t('Common:CAN_BE_APPLIED_DURING_INVOICE_PAYMENT')}
            </Text>
          )}
          {finalized ? (
            <Grid item padding={{ paddingLeft: '16px' }}>
              <AlertLabel
                showTooltip
                classes={{
                  root: classes.alertLabelBadgeRoot,
                  text: classes.alertLabelText,
                  tooltip: classes.alertLabelToolTip,
                }}
                message={t('Common:FINALIZED')}
                sx={{ mr: 0.5, mb: 0.5 }}
                tooltipMessage={t('Common:CHARGES_NOT_EDITABLE')}
                variant="normal"
              />
            </Grid>
          ) : null}
        </Grid>
      </TableCell>
      {showClientBalance && (
        <TableCell className={classes.tableTitle}>
          <Text strong sx={{ textAlign: 'end' }} variant="body2">
            {formatMoneyRange(Number(clientBalance))}
          </Text>
        </TableCell>
      )}
    </TableRow>
  )
}

export default ChargeSheetTableSubHeader
