import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Nil, Text, useFields } from '@pbt/pbt-ui-components'

import FeatureToggle from '~/constants/featureToggle'
import { getFeatureToggle } from '~/store/reducers/constants'

import NotesTemplateInput from '../../template-inputs/NotesTemplateInput'
import SoapWidget from '../SoapWidget'

export interface ReasonForVisitWidgetLockedProps {
  appointmentNotes: string | Nil
}

const ReasonForVisitWidgetLocked = ({
  appointmentNotes,
}: ReasonForVisitWidgetLockedProps) => {
  const { t } = useTranslation('Common')
  const isRichTextNotesEnabled = useSelector(
    getFeatureToggle(FeatureToggle.APPOINTMENT_DIALOG_ADDITIONAL_FIELDS),
  )

  const { fields } = useFields([
    {
      name: 'notes',
      label: t('Common:REASON_FOR_VISIT'),
      initialValue: appointmentNotes || '',
    },
  ])

  if (!appointmentNotes) {
    return null
  }

  return (
    <SoapWidget ContentBoxProps={{ p: 2 }} title={t('Common:REASON_FOR_VISIT')}>
      {isRichTextNotesEnabled ? (
        <NotesTemplateInput
          disabled
          singleLine
          field={fields.notes}
          maxHeight={150}
          minHeight={30}
        />
      ) : (
        <Text>{appointmentNotes}</Text>
      )}
    </SoapWidget>
  )
}

export default ReasonForVisitWidgetLocked
