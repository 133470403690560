import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { Grid, Theme, useMediaQuery } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import classNames from 'classnames'
import { useFields, Utils } from '@pbt/pbt-ui-components'

import BusinessShareIcon from '~/components/common/icons/BusinessShareIcon'
import UserSelect, {
  UserSelectFilterScope,
} from '~/components/common/inputs/UserSelect'
import { fetchClient } from '~/store/actions/clients'
import {
  editConversation,
  setAssigneeForConversation,
} from '~/store/actions/conversations'
import { fetchMember } from '~/store/actions/members'
import { getClientIsLoading } from '~/store/reducers/clients'
import {
  getConversationById,
  getConversationsIsNotAccessibleFromBusinessErrorType,
} from '~/store/reducers/conversations'
import { getUser } from '~/store/reducers/users'
import useIsCurrentContextItem from '~/utils/useIsCurrentContextItem'

import DetailsBackButton from '../../clients/DetailsBackButton'
import EditableTitle from '../common/EditableTitle'
import TransportIcon from '../TransportIcon'
import Chat from './Chat'
import MessageFormattingArea from './MessageFormattingArea'
import ChatRightRail from './right-rail/ChatRightRail'

const useStyles = makeStyles(
  (theme) => ({
    root: {
      backgroundColor: theme.colors.contentBackground,
      height: `calc(100vh - ${theme.mixins.toolbar.minHeight}px - ${theme.constants.progressBarHeight}px)`,
      width: '100%',
      [theme.breakpoints.up('lg')]: {
        width: `calc(100% - ${theme.constants.rightRailCollapsedWidth}px)`,
      },
    },
    rootWide: {
      width: '100%',
    },
    chat: {
      flex: 1,
      overflow: 'auto',
    },
    backPanel: {
      backgroundColor: theme.colors.soapStatusBar,
      paddingLeft: theme.spacing(3),
    },
    chatHeader: {
      height: 73,
      padding: theme.spacing(1, 3),
      borderRadius: 2,
      borderBottom: theme.constants.tableBorder,
      backgroundColor: theme.colors.tableBackground,
      boxShadow: theme.constants.listItemShadow,
    },
    transportIcon: {
      color: theme.colors.signIconBackground,
      fontSize: '3.6rem',
    },
  }),
  { name: 'ChatContainer' },
)

const ChatContainer = () => {
  const classes = useStyles()
  const navigate = useNavigate()
  const location = useLocation()
  const dispatch = useDispatch()
  const { conversationId } = useParams() as { conversationId: string }
  const isMedium = useMediaQuery<Theme>((theme) => theme.breakpoints.down('lg'))
  const [rightRailVisibilityStatus, setRightRailVisibilityStatus] = useState(
    !isMedium,
  )
  const { t } = useTranslation(['Common', 'Communications'])

  const conversation = useSelector(getConversationById(conversationId))

  const { title, transport, client: conversationClientId } = conversation || {}
  const locationState = location.state as { clientId: string }
  const clientId = locationState?.clientId

  const client = useSelector(getUser(clientId))
  const assignee = useSelector(getUser(conversation?.assigneeId))
  const conversationClient = useSelector(getUser(conversationClientId))
  const clientIsLoading = useSelector(getClientIsLoading)
  const isConversationNotAccessibleFromBusinessErrorType = useSelector(
    getConversationsIsNotAccessibleFromBusinessErrorType,
  )

  const isContextItem = useIsCurrentContextItem(conversation)

  useEffect(() => {
    setRightRailVisibilityStatus(!isMedium)
  }, [isMedium])

  const {
    fields: { assigneeId },
    reset,
  } = useFields(
    [
      {
        name: 'assigneeId',
        label: t('Common:ASSIGN_TEAM_MEMBER'),
        initialValue: conversation?.assigneeId,
      },
    ],
    false,
  )

  const setTitle = (newTitle: string) => {
    dispatch(editConversation(conversationId, { title: newTitle }))
  }

  const backAction = () => {
    navigate(
      clientId ? `/communications/client/${clientId}/` : '/communications',
    )
  }

  useEffect(() => {
    if (conversation?.assigneeId && !assignee) {
      dispatch(fetchMember(conversation?.assigneeId, true))
    }
  }, [conversation?.assigneeId])

  useEffect(() => {
    reset()
  }, [conversation])

  const isFullConversationClient = Boolean(conversationClient?.patients)

  useEffect(() => {
    if (conversationClientId && !clientIsLoading && !isFullConversationClient) {
      dispatch(fetchClient({ clientId: conversationClientId }))
    }
  }, [conversationClientId, clientIsLoading])

  useEffect(() => {
    if (isConversationNotAccessibleFromBusinessErrorType) {
      navigate('/communications')
    }
  }, [isConversationNotAccessibleFromBusinessErrorType])

  const withRightRail = rightRailVisibilityStatus || isMedium

  return (
    <Grid
      container
      className={classNames({
        [classes.root]: withRightRail,
        [classes.rootWide]: !withRightRail,
      })}
      direction="column"
      wrap="nowrap"
    >
      <ChatRightRail
        conversationId={conversationId}
        visible={rightRailVisibilityStatus}
        onVisibilityChange={setRightRailVisibilityStatus}
      />

      <Grid container item className={classes.backPanel}>
        <DetailsBackButton fullWidth onClick={backAction}>
          {client
            ? t(
                'Communications:CHAT_CONTAINER.BACK_TO_COMMUNICATIONS_WITH_CLIENT_NAME',
                {
                  clientName: Utils.getPersonString(client),
                },
              )
            : t('Communications:CHAT_CONTAINER.BACK_TO_INBOX')}
        </DetailsBackButton>
      </Grid>

      <Grid container item alignItems="center" className={classes.chatHeader}>
        <Grid item>
          <TransportIcon
            classes={{ transportIcon: classes.transportIcon }}
            type={transport}
          />
        </Grid>

        <EditableTitle
          endAdornment={
            <BusinessShareIcon
              businessIds={
                conversation?.businessId ? [conversation.businessId] : []
              }
            />
          }
          readOnly={!isContextItem}
          setTitle={setTitle}
          title={title}
        />

        <Grid item>
          <UserSelect
            field={{
              ...assigneeId,
              setValue: (value) => {
                assigneeId.setValue(value)
                dispatch(setAssigneeForConversation(conversationId, value))
              },
            }}
            filterScope={UserSelectFilterScope.Staff}
            label={assigneeId.label}
            readOnly={!isContextItem}
          />
        </Grid>
      </Grid>

      <Grid container item className={classes.chat} direction="column">
        <Chat conversationId={conversationId} />
      </Grid>

      {isContextItem && (
        <Grid container item>
          <MessageFormattingArea conversationId={conversationId} />
        </Grid>
      )}
    </Grid>
  )
}

export default ChatContainer
