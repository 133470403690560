import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { Grid, TableCell, TableRow } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import * as R from 'ramda'
import { Text } from '@pbt/pbt-ui-components'

import AlertLabel from '~/components/common/labels/AlertLabel'
import { AlertColorMap, AlertColors } from '~/constants/alertColors'
import {
  FINANCE_TABLE_CELL_HEIGHT,
  FINANCE_TABLE_PADDING_X_SPACING_VALUE,
} from '~/constants/financeTable'
import { useGetInvoiceV3ItemHeader } from '~/store/hooks/invoiceV3'
import { getInvoiceV3SubItemById } from '~/store/reducers/invoiceV3'

const useStyles = makeStyles(
  (theme) => ({
    tableTitle: {
      color: theme.colors.tabLabel,
      padding: theme.spacing(1, FINANCE_TABLE_PADDING_X_SPACING_VALUE),
      height: FINANCE_TABLE_CELL_HEIGHT,
    },
    alertLabelText: {
      lineHeight: '1.7rem',
      padding: theme.spacing(0.5, 0),
    },
    alertLabelBadgeRoot: {
      justifyContent: 'flex-start',
      backgroundColor: AlertColorMap[AlertColors.GREEN],
    },
    alertLabelToolTip: {
      backgroundColor: theme.colors.selectedItem,
      color: theme.colors.badgeColor,
      '&::before, &::after': {
        display: 'none',
      },
      '&::before': {
        display: 'none',
      },
      '&::after': {
        display: 'none',
      },
    },
  }),
  { name: 'InvoiceTableSubHeader' },
)

export type InvoiceTableSubHeaderProps = {
  currentItemId?: string
}

const InvoiceTableSubHeader = ({
  currentItemId,
}: InvoiceTableSubHeaderProps) => {
  const { t } = useTranslation('Common')
  const classes = useStyles()
  const title = useGetInvoiceV3ItemHeader(currentItemId)
  const itemSection = useSelector(getInvoiceV3SubItemById(currentItemId))
  const navigate = useNavigate()
  const soapId = itemSection?.soapId

  const finalized = R.path(['soap', 'finalized'], itemSection)

  if (R.isNil(currentItemId) || R.isEmpty(title)) {
    return null
  }

  const onClickSoapName = () => {
    if (soapId) {
      navigate(`/soap/${soapId}`)
    }
  }

  return (
    <TableRow>
      <TableCell className={classes.tableTitle} colSpan={6}>
        <Grid container alignItems="center">
          <Text
            component="span"
            link={Boolean(soapId)}
            variant="h4"
            onClick={onClickSoapName}
          >
            {title}
          </Text>
          {finalized ? (
            <Grid item padding={{ paddingLeft: '16px' }}>
              <AlertLabel
                showTooltip
                classes={{
                  root: classes.alertLabelBadgeRoot,
                  text: classes.alertLabelText,
                  tooltip: classes.alertLabelToolTip,
                }}
                message={t('Common:FINALIZED')}
                sx={{ mr: 0.5, mb: 0.5 }}
                tooltipMessage={t('Common:CHARGES_NOT_EDITABLE')}
                variant="normal"
              />
            </Grid>
          ) : null}
        </Grid>
      </TableCell>
    </TableRow>
  )
}

export default InvoiceTableSubHeader
