import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { FormControlLabel, Grid, Radio, RadioGroup } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import * as R from 'ramda'
import {
  Calendar,
  LanguageUtils,
  moment,
  Nil,
  Text,
  Utils,
} from '@pbt/pbt-ui-components'

import { getDateString } from '~/utils/time'

import BaseFilter, { FilterOption } from './BaseFilter'
import { DueDate } from './CommonDueDates'

const useStyles = makeStyles(
  (theme) => ({
    group: {
      padding: theme.spacing(1),
    },
    radioLabel: {
      fontSize: '1.6rem',
    },
    labelRoot: {
      margin: 0,
    },
    dateRangeContainer: {
      borderTop: theme.constants.tabBorder,
    },
  }),
  { name: 'DateRangeFilter' },
)

export interface DateRangeFilterProps {
  DueDates?: Array<DueDate>
  anchorEl: any
  includeSearch: boolean
  isLoading: false
  onChange: (filterOption: FilterOption) => void
  onClose: () => void
  open: boolean
  searchTerm?: string
  setSearchTerm: (term: string) => void
}

const DateRangeFilter = ({
  includeSearch = false,
  open = false,
  isLoading = false,
  onChange,
  onClose,
  DueDates: DueDatesProp,
  ...rest
}: DateRangeFilterProps) => {
  const DueDates = DueDatesProp || []
  const classes = useStyles()
  const { t } = useTranslation(['Common', 'Time'])

  const [selectedOption, setSelectedOption] = useState<string>('')
  const [fromDate, setFromDate] = useState<string>()
  const [toDate, setToDate] = useState<string>()

  const apply = () => {
    const dueDate = Utils.findById(selectedOption, DueDates)
    const value = selectedOption
      ? R.map((date) => date && date.toISOString(), dueDate.getDateRange())
      : fromDate || toDate
      ? [
          moment(fromDate).startOf('day').toISOString(),
          moment(toDate).endOf('day').toISOString(),
        ]
      : undefined

    const humanReadable = selectedOption
      ? dueDate.name
      : fromDate || toDate
      ? getDateString(moment(fromDate), moment(toDate))
      : null

    onChange({ value, humanReadable })
    onClose()
  }

  const clear = () => {
    setSelectedOption('')
    setFromDate(undefined)
    setToDate(undefined)
  }

  return (
    <BaseFilter
      hasSelectedOption={Boolean(selectedOption || fromDate || toDate)}
      includeSearch={includeSearch}
      isLoading={isLoading}
      open={open}
      onApply={apply}
      onClear={clear}
      onClose={onClose}
      {...rest}
    >
      <RadioGroup
        aria-label="dueDates"
        className={classes.group}
        name="dueDates1"
        value={selectedOption}
        onChange={(_, value) => {
          setSelectedOption(value)
          setFromDate(undefined)
          setToDate(undefined)
        }}
      >
        {DueDates.map((dueDate) => (
          <FormControlLabel
            classes={{
              root: classes.labelRoot,
              label: classes.radioLabel,
            }}
            control={<Radio />}
            key={dueDate.id}
            label={LanguageUtils.getTranslatedFieldName(dueDate)}
            value={dueDate.id}
          />
        ))}
      </RadioGroup>
      <Grid
        container
        item
        className={classes.dateRangeContainer}
        direction="column"
        p={1.5}
      >
        <Text variant="lowAccent2">{t('Common:DATE_RANGE')}</Text>
        <Grid container item columnSpacing={3}>
          <Grid item xs>
            <Calendar
              fullWidth
              label={t('Time:LABEL.FROM_DATE')}
              value={moment(fromDate)}
              onChange={(newFromDate: string | Nil) => {
                setSelectedOption('')
                setFromDate(newFromDate || undefined)
              }}
            />
          </Grid>
          <Grid item xs>
            <Calendar
              fullWidth
              label={t('Time:LABEL.TO_DATE')}
              value={moment(toDate)}
              onChange={(newToDate: string | Nil) => {
                setSelectedOption('')
                setToDate(newToDate || undefined)
              }}
            />
          </Grid>
        </Grid>
      </Grid>
    </BaseFilter>
  )
}

export default DateRangeFilter
