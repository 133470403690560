import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { ChevronRight } from '@mui/icons-material'
import MoreHorizIcon from '@mui/icons-material/MoreHoriz'
import { Alert, Button, Divider, Fab, Grid } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import classNames from 'classnames'
import { last } from 'ramda'
import {
  LinkButton,
  PermissionArea,
  PuiPopper,
  Text,
  useFields,
} from '@pbt/pbt-ui-components'
import {
  Calendar,
  Estimates,
  List,
  Print,
} from '@pbt/pbt-ui-components/src/icons'

import MenuDropdown, {
  MenuDropdownItem,
} from '~/components/common/inputs/MenuDropdown'
import ItemHistoryLabel from '~/components/common/labels/ItemHistoryLabel'
import PuiIconButton from '~/components/common/PuiIconButton'
import DialogNames from '~/constants/DialogNames'
import FeatureToggle from '~/constants/featureToggle'
import { SOAPStep } from '~/constants/SOAPStates'
import { fetchCheckoutInvoices } from '~/store/actions/finance'
import { addSOAP, finalizeSoap, reOpenSoap } from '~/store/actions/soap'
import { patchAppointment } from '~/store/actions/timetable'
import {
  fetchSoapCharges,
  getCharges,
  getChargesLoading,
} from '~/store/duck/charges'
import { useOpenInvoice } from '~/store/hooks/finance'
import {
  getCRUDByArea,
  getCurrentBusiness,
  getIsPracticeAdministrator,
} from '~/store/reducers/auth'
import { getFeatureToggle } from '~/store/reducers/constants'
import { getSelectedOrders } from '~/store/reducers/orders'
import {
  getAppointmentId,
  getClientId,
  getInvoiceId,
  getIsFinalized,
  getPatientId,
  getSoapBusinessId,
  getSoapId,
  getSOAPisFetching,
} from '~/store/reducers/soap'
import { getTimetableEvent } from '~/store/reducers/timetable'
import { addOriginalBusinessId } from '~/utils'
import { isChargesInvoiceType } from '~/utils/finance'
import useDialog from '~/utils/useDialog'

import {
  createMenuItem,
  NewMenuItemType,
  onSelectMenuItem,
} from '../header/top-menu/topMenuUtils'
import ClientAndPatientLabelMenuDropDown, {
  LabelMenuOptions,
} from '../labels/ClientAndPatientLabelMenuDropDown'
import { useCanUpdateSignature } from '../soapV2/utils/useCanUpdateSignature'
import { useIsSoapCustomizationEnabled } from '../soapV2/utils/useIsSoapCustomizationEnabled'
import { useMissingVaccineInfo } from '../soapV2/utils/useMissingVaccineInfo'
import { getAppointmentWarningLabel } from '../timetable/timetableUtils'
import AppointmentStatusSelectDropdown from './appointment/AppointmentStatusSelectDropdown'
import useConfirmLeaveSoapForAssignmentAlert from './useConfirmLeaveSoapForAssignment'
import useConfirmLockSoapAlert from './useConfirmLockSoapAlert'

const useStyles = makeStyles(
  (theme) => ({
    paper: {
      width: 250,
      overflow: 'auto',
      boxShadow: theme.constants.blockShadow,
      zIndex: theme.utils.modifyZIndex(theme.zIndex.rightRail, 'above'),
    },
    icon: {
      borderRadius: '50%',
      backgroundColor: 'unset',
      border: '1px solid',
      borderColor: theme.colors.lowAccentText,
    },
    alertMessage: {
      '&.MuiAlert-root > .MuiAlert-message': {
        overflow: 'visible',
      },
    },
    addNowButton: {
      fontSize: theme.typography.pxToRem(12),
      padding: 12,
      marginLeft: -12,
      minWidth: 0,
      color: 'inherit',
    },
    alertText: {
      fontSize: theme.typography.pxToRem(12),
    },
    secondaryAlertText: {
      paddingTop: 12,
    },
    svg: {
      color: theme.colors.lowAccentText,
    },
    finalizeButton: {
      minWidth: 160,
      height: 40,
      width: '100%',
    },
    menuButton: {
      justifyContent: 'space-between',
      color: theme.colors.lowAccentText,
      textTransform: 'none',
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1),
    },
    chevronIcon: {
      color: theme.colors.lowAccentText,
      fontSize: '2.6rem !important',
    },
    endAdornment: {
      right: 0,
      color: theme.colors.toggleIcon,
      position: 'absolute',
      pointerEvents: 'none',
    },
    warningMessage: {
      paddingTop: 1,
      paddingBottom: 1,
      paddingLeft: theme.spacing(0.75),
      paddingRight: theme.spacing(0.75),
      backgroundColor: theme.colors.important,
      borderRadius: 2,
      color: theme.colors.profileText,
      textAlign: 'center',
      width: '100%',
      height: '100%',
    },
    reopenButton: {
      padding: theme.spacing(2),
    },
  }),
  { name: 'SoapHeaderMenu' },
)

const SoapHeaderMenu = () => {
  const classes = useStyles()
  const { t } = useTranslation(['Common', 'TimeTable'])

  const ref = useRef<HTMLButtonElement>(null)
  const anchorEl = ref.current

  const [isMenuOpen, setIsMenuOpen] = useState(false)
  const [selectedItem, setSelectedItem] = useState<string>()

  const { step = SOAPStep.APPOINTMENT } = useParams()
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const business = useSelector(getCurrentBusiness)
  const clientId = useSelector(getClientId)
  const patientId = useSelector(getPatientId)
  const appointmentId = useSelector(getAppointmentId)
  const invoiceId = useSelector(getInvoiceId)
  const soapId = useSelector(getSoapId)
  const soapBusinessId = useSelector(getSoapBusinessId)
  const soapIsFetching = useSelector(getSOAPisFetching)
  const appointment = useSelector(getTimetableEvent(appointmentId))
  const SOAPPermissions = useSelector(getCRUDByArea(PermissionArea.SOAP))
  const invoicePermissions = useSelector(getCRUDByArea(PermissionArea.INVOICE))
  const taskPermissions = useSelector(getCRUDByArea(PermissionArea.TASK))
  const appointmentPermissions = useSelector(
    getCRUDByArea(PermissionArea.EVENT_APPOINTMENT),
  )
  const communicationPermissions = useSelector(
    getCRUDByArea(PermissionArea.COMMUNICATION),
  )
  const patientPermissions = useSelector(getCRUDByArea(PermissionArea.PATIENT))
  const isPracticeAdmin = useSelector(getIsPracticeAdministrator(business))
  const isFinalized = useSelector(getIsFinalized)
  const isPatientSharingEnabled = useSelector(
    getFeatureToggle(FeatureToggle.PATIENT_SHARING),
  )
  const isCvcRolesEnabled = useSelector(
    getFeatureToggle(FeatureToggle.CVC_ROLES),
  )
  const isChargeSheetEnabled = useSelector(
    getFeatureToggle(FeatureToggle.CHARGE_SHEET),
  )
  const clientCharges = useSelector(getCharges)
  const chargesLoading = useSelector(getChargesLoading)
  const isSoapCustomizationEnabled = useIsSoapCustomizationEnabled()
  const missingVaccineInfo = useMissingVaccineInfo()
  const isMissingVaccineInfo = missingVaccineInfo.length > 0
  const isOnlyMissingSignature =
    missingVaccineInfo.filter(
      (vaccineDetails) =>
        'signature' in vaccineDetails &&
        Object.keys(vaccineDetails).length === 1,
    ).length > 0
  const canUpdateSignature = useCanUpdateSignature()

  const isInvoice = isChargesInvoiceType(clientCharges)

  const appointmentSoaps = appointment?.soaps
  const updateSOAPDisabled =
    !SOAPPermissions.update ||
    (isMissingVaccineInfo && business?.autoGenerateVaccineCertificates)
  const createInvoiceDisabled = !invoicePermissions.create
  const isCheckOutStep = step.toUpperCase() === SOAPStep.CHECK_OUT

  const onInvoiceDialogClose = (redirectTo?: string) => {
    if (redirectTo) {
      navigate(redirectTo)
    }
    if (isCheckOutStep && appointmentId) {
      dispatch(fetchCheckoutInvoices(appointmentId))
    }
  }

  const [openAppointmentDialog] = useDialog(DialogNames.EVENT)
  const [openSoapAttachmentDialog] = useDialog(
    DialogNames.SOAP_MULTI_ATTACHMENT,
  )
  const [openNewConversationDialog] = useDialog(
    DialogNames.NEW_CONVERSATION_WITH_STEPS,
  )
  const [openDiagnosisHistoryDialog] = useDialog(DialogNames.DIAGNOSIS_HISTORY)
  const [openNoteHistoryDialog] = useDialog(DialogNames.NOTE_HISTORY)
  const [openAddReminderDialog] = useDialog(DialogNames.ADD_REMINDER)
  const [openVaccineHistoryDialog] = useDialog(DialogNames.VACCINE_HISTORY)
  const [openVitalsDialog] = useDialog(DialogNames.VITALS)
  const [openEstimatesListDialog] = useDialog(DialogNames.ESTIMATES_LIST)
  const [openAppointmentReportCardDialog] = useDialog(
    DialogNames.APPOINTMENT_REPORT_CARD,
    null,
    { soapId },
  )
  const [openPrintCageLabelDialog] = useDialog(DialogNames.PRINT_CAGE_LABEL)
  const [openPrintFolderLabelDialog] = useDialog(DialogNames.PRINT_FOLDER_LABEL)
  const [openPrintPracticeAddressLabelDialog] = useDialog(
    DialogNames.PRINT_PRACTICE_ADDRESS_LABEL,
  )
  const [openTaskDialog] = useDialog(DialogNames.TASK)
  const [openInvoiceDialog] = useDialog(
    DialogNames.INVOICE,
    onInvoiceDialogClose,
    {
      clientId,
      eventId: appointmentId,
      invoiceId,
      patientId,
      soapId,
      soapBusinessId,
    },
  )
  const [openAddendumDialog] = useDialog(DialogNames.ADDENDUM)

  const [navigateToNewSoap, setNavigateToNewSoap] = useState(false)

  const [openConfirmLockSoapAlert] = useConfirmLockSoapAlert()
  const [openConfirmLeaveSoapForAssignmentAlert] =
    useConfirmLeaveSoapForAssignmentAlert()
  const [openVaccineCertificateValidationDialog] = useDialog(
    DialogNames.VACCINE_CERTIFICATE_VALIDATION,
  )

  const handleFinalizeSoap = () =>
    openConfirmLockSoapAlert(() => dispatch(finalizeSoap()))
  const handleReOpenSoap = () => dispatch(reOpenSoap())
  const openInvoice = useOpenInvoice(
    clientId,
    isChargeSheetEnabled ? undefined : openInvoiceDialog,
  )
  const selectedOrders = useSelector(getSelectedOrders)

  useEffect(() => {
    if (navigateToNewSoap && appointmentSoaps?.length) {
      const lastSoapId = last(appointmentSoaps)?.id
      const url = isSoapCustomizationEnabled
        ? `/soap/${lastSoapId}`
        : `/soap/${lastSoapId}/appointment`
      navigate(
        addOriginalBusinessId(
          url,
          isPatientSharingEnabled ? appointment?.businessId : null,
        ),
      )
      setNavigateToNewSoap(false)
    }
  }, [appointmentSoaps?.length])

  useEffect(() => {
    if (clientId && soapId) {
      fetchSoapCharges({ id: clientId, soapId })
    }
  }, [clientId, soapId])

  const handleAddSOAP = () => {
    dispatch(
      addSOAP(appointmentId, isPatientSharingEnabled ? soapBusinessId : null),
    )
    setNavigateToNewSoap(true)
    setIsMenuOpen(false)
  }

  const handleOpenInvoice = () => {
    openInvoice({
      clientId,
      eventId: appointmentId,
      invoiceId,
      patientId,
      soapId,
      soapBusinessId,
      id: invoiceId,
    })
    setIsMenuOpen(false)
  }

  const toggleMenu = () =>
    setIsMenuOpen((prevIsMenuOpen) =>
      chargesLoading && !prevIsMenuOpen ? false : !prevIsMenuOpen,
    )

  const ADD_MENU_ITEMS = [
    createMenuItem(
      t('Menu:SOAP_TOP_MENU.APPOINTMENT_NEW'),
      NewMenuItemType.APPOINTMENT_SOAP,
      appointmentPermissions.create,
    ),
    isFinalized &&
      createMenuItem(
        t('Common:ADDENDUM'),
        NewMenuItemType.ADDENDUM,
        SOAPPermissions.update,
      ),
    createMenuItem(
      t('Common:ATTACHMENT'),
      NewMenuItemType.ATTACHMENT,
      SOAPPermissions.update,
    ),
    createMenuItem(
      t('Common:CONVERSATION'),
      NewMenuItemType.CONVERSATION,
      communicationPermissions.create,
    ),
    createMenuItem(
      t('Common:DIAGNOSIS_HISTORY'),
      NewMenuItemType.DIAGNOSIS_HISTORY,
      !isCvcRolesEnabled || patientPermissions.update,
    ),
    createMenuItem(
      t('Menu:SOAP_TOP_MENU.NOTE_TIMELINE'),
      NewMenuItemType.NOTE,
      !isCvcRolesEnabled || patientPermissions.update,
    ),
    createMenuItem(
      t('Common:REMINDER'),
      NewMenuItemType.REMINDER,
      SOAPPermissions.update,
    ),
    createMenuItem(
      t('Menu:SOAP_TOP_MENU.SOAP_TO_CURRENT_APPOINTMENT'),
      NewMenuItemType.SOAP,
      SOAPPermissions.create,
    ),
    createMenuItem(
      t('Common:TASK'),
      NewMenuItemType.TASK,
      taskPermissions.create,
    ),
    createMenuItem(
      t('Common:VACCINE_HISTORY'),
      NewMenuItemType.VACCINE_HISTORY,
      !isCvcRolesEnabled || patientPermissions.update,
    ),
    createMenuItem(
      t('Common:VITALS'),
      NewMenuItemType.VITALS,
      !isCvcRolesEnabled || patientPermissions.update,
    ),
  ] as MenuDropdownItem<string>[]

  const NewMenuActionsMap = {
    [NewMenuItemType.APPOINTMENT_SOAP]: openConfirmLeaveSoapForAssignmentAlert,
    [NewMenuItemType.ATTACHMENT]: () =>
      openSoapAttachmentDialog({ clientId, patientId, soapId, soapBusinessId }),
    [NewMenuItemType.CONVERSATION]: () =>
      openNewConversationDialog({ clientId, patientId }),
    [NewMenuItemType.DIAGNOSIS_HISTORY]: () =>
      openDiagnosisHistoryDialog({ clientId, patientId }),
    [NewMenuItemType.NOTE]: () =>
      openNoteHistoryDialog({ clientId, patientId }),
    [NewMenuItemType.REMINDER]: () => openAddReminderDialog({ patientId }),
    [NewMenuItemType.SOAP]: () => handleAddSOAP(),
    [NewMenuItemType.TASK]: () =>
      openTaskDialog({
        clientId,
        patientId,
        appointmentId,
        soapId,
        dueDate: appointment?.scheduledEndDatetime,
      }),
    [NewMenuItemType.VACCINE_HISTORY]: () =>
      openVaccineHistoryDialog({ clientId, patientId }),
    [NewMenuItemType.VITALS]: () => openVitalsDialog({ clientId, patientId }),
    [NewMenuItemType.ADDENDUM]: () => openAddendumDialog({ soapId, patientId }),
  }

  const LabelMenuActionsMap = {
    [LabelMenuOptions.CAGE]: () =>
      openPrintCageLabelDialog({ clientId, patientId }),
    [LabelMenuOptions.FOLDER]: () =>
      openPrintFolderLabelDialog({ clientId, patientId }),
    [LabelMenuOptions.PRACTICE_ADDRESS]: () =>
      openPrintPracticeAddressLabelDialog(),
  }

  const {
    fields: { appointmentStatus },
  } = useFields(
    [
      {
        name: 'appointmentStatus',
        initialValue: appointment?.state?.id || '',
      },
    ],
    false,
  )

  useEffect(() => {
    appointmentStatus.setValue(appointmentStatus.initialValue)
  }, [appointmentStatus.initialValue])

  const onHandleStatusChange = (state: string) => {
    if (appointment?.id) {
      dispatch(
        patchAppointment({
          id: appointment?.id,
          state,
        }),
      )
    }
  }

  useEffect(() => {
    if (
      appointmentStatus.value &&
      appointmentStatus.value !== appointment?.state?.id
    ) {
      onHandleStatusChange(appointmentStatus.value)
    }
  }, [appointmentStatus.value])

  const warningMessage = getAppointmentWarningLabel({
    from: appointment?.scheduledStartDatetime,
    to: appointment?.scheduledEndDatetime,
    stateName: appointment?.state?.name,
  })

  const ChevronRightIcon = useCallback(
    () => <ChevronRight className={classes.chevronIcon} />,
    [],
  )

  const onMenuItemHovered = (target: string) => {
    setSelectedItem(target)
  }

  const handleAddMissingRabiesInfo = () => {
    openVaccineCertificateValidationDialog({
      clientId,
      patientId,
      vaccines: selectedOrders,
    })
  }

  return (
    <>
      <PuiIconButton
        Icon={MoreHorizIcon}
        className={classes.icon}
        classes={{ icon: classes.svg }}
        id="soap-header-menu-open-btn"
        isLoading={chargesLoading}
        ref={ref}
        onClick={toggleMenu}
      />
      <PuiPopper
        hideCloseButton
        anchorEl={anchorEl}
        classes={{
          paper: classes.paper,
        }}
        open={isMenuOpen}
        placement="bottom-end"
        onClose={() => setIsMenuOpen(false)}
      >
        <Grid container flexDirection="column">
          {!isFinalized && (
            <Grid
              item
              mb={1}
              mt={2}
              mx={3}
              onMouseEnter={() => {
                onMenuItemHovered('finalize')
              }}
            >
              <Fab
                className={classes.finalizeButton}
                color="inherit"
                disabled={updateSOAPDisabled}
                variant="extended"
                onClick={handleFinalizeSoap}
              >
                {t('Common:FINALIZE_ACTION')}
              </Fab>
            </Grid>
          )}
          {business?.autoGenerateVaccineCertificates &&
            !isFinalized &&
            isMissingVaccineInfo && (
              <Grid item mb={2} mx={1} width={230}>
                <Alert className={classes.alertMessage} severity="warning">
                  <p className={classes.alertText}>
                    {t('Common:SOAP_VACCINE_ERROR')}
                  </p>
                  {isOnlyMissingSignature && !canUpdateSignature ? (
                    <p
                      className={classNames(
                        classes.alertText,
                        classes.secondaryAlertText,
                      )}
                    >
                      {t('Common:SOAP_VACCINE_SIGNATURE_ERROR')}
                    </p>
                  ) : (
                    <LinkButton
                      className={classes.addNowButton}
                      onClick={handleAddMissingRabiesInfo}
                    >
                      {t('Common:ADD_NOW')}
                    </LinkButton>
                  )}
                </Alert>
              </Grid>
            )}
          {isFinalized && isPracticeAdmin && (
            <Grid
              item
              onMouseEnter={() => {
                onMenuItemHovered('reopen')
              }}
            >
              <Button
                fullWidth
                className={classNames(classes.menuButton, classes.reopenButton)}
                variant="text"
                onClick={handleReOpenSoap}
              >
                {`${t('Common:RE-OPEN_ACTION')} SOAP`}
              </Button>
            </Grid>
          )}
          <Divider />
          <MenuDropdown
            linkButton
            openOnHover
            Button={
              <Button
                className={classes.menuButton}
                endIcon={<ChevronRightIcon />}
              >
                <Grid
                  container
                  alignItems="end"
                  onMouseEnter={() => {
                    onMenuItemHovered('add_items')
                  }}
                >
                  <Text
                    fontSize={30}
                    fontWeight={100}
                    lineHeight={0.85}
                    ml="3px"
                    variant="lowAccent"
                  >
                    +
                  </Text>
                  <Grid item lineHeight={1.2} ml="3px">
                    <Text variant="body">{t('Common:ADD_ACTION')}</Text>
                  </Grid>
                </Grid>
              </Button>
            }
            disabled={soapIsFetching}
            forceClosed={selectedItem !== 'add_items'}
            items={ADD_MENU_ITEMS}
            menuProps={{
              anchorOrigin: { vertical: 'top', horizontal: 'left' },
              transformOrigin: { vertical: 'top', horizontal: 'right' },
            }}
            title={t('Common:ADD_ACTION')}
            onSelected={onSelectMenuItem(NewMenuActionsMap)}
          />
          <Button
            className={classes.menuButton}
            disabled={createInvoiceDisabled || soapIsFetching}
            onClick={() => {
              openAppointmentDialog({
                clientId,
                appointmentId,
                patientId,
              })
            }}
            onMouseEnter={() => {
              onMenuItemHovered('open_appointment')
            }}
          >
            <Grid container>
              <Calendar />
              <Grid item>
                <Text variant="body">
                  {t('TimeTable:ADD_APPOINTMENT_COMPONENT.EDIT_APPOINTMENT')}
                </Text>
              </Grid>
            </Grid>
          </Button>
          {!isFinalized && (
            <Button
              className={classes.menuButton}
              disabled={updateSOAPDisabled || createInvoiceDisabled}
              onClick={() => {
                openEstimatesListDialog({
                  patientId,
                })
              }}
              onMouseEnter={() => {
                onMenuItemHovered('open_estimates')
              }}
            >
              <Grid container>
                <Estimates />
                <Grid item>
                  <Text variant="body">{t('Common:ESTIMATE_BUDGET')}</Text>
                </Grid>
              </Grid>
            </Button>
          )}
          <Button
            className={classes.menuButton}
            disabled={createInvoiceDisabled || soapIsFetching}
            onClick={handleOpenInvoice}
            onMouseEnter={() => {
              onMenuItemHovered('open_invoice')
            }}
          >
            <Grid container>
              <Estimates />
              <Grid item>
                <Text variant="body">
                  {isChargeSheetEnabled
                    ? isInvoice
                      ? t('Common:INVOICE')
                      : t('Common:CHARGE_SHEET')
                    : t('Common:INVOICE')}
                </Text>
              </Grid>
            </Grid>
          </Button>

          <ClientAndPatientLabelMenuDropDown
            linkButton
            openOnHover
            Button={
              <Button
                className={classes.menuButton}
                endIcon={<ChevronRightIcon />}
              >
                <Grid
                  container
                  alignItems="end"
                  onMouseEnter={() => {
                    onMenuItemHovered('label_menu')
                  }}
                >
                  <Print />
                  <Grid item lineHeight={1.2}>
                    <Text variant="body">{t('Common:LABEL')}</Text>
                  </Grid>
                </Grid>
              </Button>
            }
            forceClosed={selectedItem !== 'label_menu'}
            menuProps={{
              anchorOrigin: { vertical: 'top', horizontal: 'left' },
              transformOrigin: { vertical: 'top', horizontal: 'right' },
            }}
            onChange={onSelectMenuItem(LabelMenuActionsMap)}
          />
          <Button
            className={classes.menuButton}
            onClick={() => openAppointmentReportCardDialog({ soapId })}
            onMouseEnter={() => {
              onMenuItemHovered('open_appointment_report_card')
            }}
          >
            <Grid container>
              <List />
              <Grid item>
                <Text variant="body">
                  {t('Menu:SOAP_TOP_MENU.REPORT_CARD')}
                </Text>
              </Grid>
            </Grid>
          </Button>

          <Divider />
          <Grid container item flexDirection="column" my={1}>
            <Grid
              item
              ml={1}
              mr={0.5}
              onMouseEnter={() => {
                onMenuItemHovered('appointment_status')
              }}
            >
              <AppointmentStatusSelectDropdown
                openOnHover
                field={appointmentStatus}
                forceClosed={selectedItem !== 'appointment_status'}
                menuProps={{
                  anchorOrigin: { vertical: 'top', horizontal: 'left' },
                  transformOrigin: { vertical: 'top', horizontal: 'right' },
                }}
              />
            </Grid>
            {warningMessage && (
              <Grid item height={25} mt={1} mx={1} width={100}>
                <Text
                  strong
                  className={classes.warningMessage}
                  lineHeight={2}
                  variant="body3"
                >
                  {warningMessage}
                </Text>
              </Grid>
            )}
          </Grid>
          <Divider />
          <Grid item m={1}>
            <ItemHistoryLabel
              createdOnly
              item={appointment}
              textVariant="lowAccent4"
            />
          </Grid>
        </Grid>
      </PuiPopper>
    </>
  )
}

export default SoapHeaderMenu
