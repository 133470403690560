import React, { useRef } from 'react'
import { useSelector } from 'react-redux'
import makeStyles from '@mui/styles/makeStyles'
import { BasePuiDialogProps, Nil, PuiDialog } from '@pbt/pbt-ui-components'

import {
  getInventory,
  getInventoryVariation,
} from '~/store/reducers/inventories'
import { DataHandle, Variation as VariationType } from '~/types'

import Variation from './Variation'

const useStyles = makeStyles(
  (theme) => ({
    paper: {
      width: 1024,
      maxWidth: 1024,
      [theme.breakpoints.down('md')]: {
        maxWidth: 'calc(100% - 32px) !important',
        margin: '48px auto !important',
      },
      [theme.breakpoints.down('sm')]: {
        margin: '16px auto !important',
      },
    },
  }),
  { name: 'VariationDialog' },
)

interface VariationHandle extends DataHandle {
  getIsTouched: () => boolean
  save: () => void
}

interface VariationDialogProps extends BasePuiDialogProps {
  duplicate: VariationType | Nil
  inventoryId: string | Nil
  variationId: string | Nil
}

const VariationDialog = ({
  open,
  inventoryId,
  variationId,
  duplicate,
  onClose,
}: VariationDialogProps) => {
  const classes = useStyles()
  const variationRef = useRef<VariationHandle>(null)

  const inventory = useSelector(getInventory(inventoryId))
  const variation =
    useSelector(getInventoryVariation(inventoryId, variationId)) || duplicate

  const hasUnsavedChanges = () => Boolean(variationRef.current?.getIsTouched())

  const onConfirmSave = () => variationRef.current?.save()

  return (
    <PuiDialog
      confirmSaveOnClose
      ConfirmCloseDialogProps={{
        onOk: onConfirmSave,
      }}
      aria-labelledby="variation-dialog"
      classes={{
        paper: classes.paper,
      }}
      hasUnsavedChanges={hasUnsavedChanges}
      open={open}
      onClose={onClose}
    >
      <Variation
        inventory={inventory}
        ref={variationRef}
        variation={variation}
        onOk={onClose}
      />
    </PuiDialog>
  )
}

export default VariationDialog
