/* eslint-disable max-lines */
import React, {
  forwardRef,
  useContext,
  useEffect,
  useImperativeHandle,
} from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import {
  Checkbox,
  FormControl,
  Grid,
  IconButton,
  OutlinedInputProps,
  SelectChangeEvent,
  Table,
  TableBody,
  TableCell,
  TableRow,
} from '@mui/material'
import { makeStyles } from '@mui/styles'
import classNames from 'classnames'
import * as R from 'ramda'
import {
  CustomFieldValidatorState,
  NamedEntity,
  NumberUtils,
  PuiSelect,
  PuiTextField,
  PuiTooltip,
  Text,
  useFields,
} from '@pbt/pbt-ui-components'
import { AddNote } from '@pbt/pbt-ui-components/src/icons'
import { getConstantTranslatedName } from '@pbt/pbt-ui-components/src/utils/languageUtils'

import {
  GroupedRefundItem,
  GroupedRefundItemCalculation,
  RefundInvoiceLineItem,
  RefundInvoiceLineItemCalculation,
} from '~/api/graphql/generated/types'
import {
  canRestockInvoiceItem,
  formatMoneyRange,
  isGroupedInvoiceItem,
  isUnrefundable,
} from '~/components/dashboard/invoices/invoiceUtils'
import FinanceItemNameCellBody from '~/components/dashboard/soapV2/charges/table/body/FinanceItemNameCellBody'
import DialogNames from '~/constants/DialogNames'
import { FINANCE_TABLE_CELL_HEIGHT } from '~/constants/financeTable'
import {
  InvoiceViewStates,
  REFUND_AMOUNT_PAID_CELL_WIDTH,
  REFUND_DISCOUNT_CELL_WIDTH,
  REFUND_LANDING_COLUMNS,
  REFUND_PATIENT_CELL_WIDTH,
  REFUND_REASON_CELL_WIDTH,
  REFUND_REASON_SUMMARY_CELL_WIDTH,
  REFUND_REFUND_AMOUNT_CELL_WIDTH,
  REFUND_REFUND_QTY_CELL_WIDTH,
  REFUND_REFUND_QTY_SUMMARY_CELL_WIDTH,
  REFUND_RESTOCK_QTY_CELL_WIDTH,
  REFUND_RESTOCK_QTY_SUMMARY_CELL_WIDTH,
  REFUND_SELECT_CELL_WIDTH,
  REFUND_SUMMARY_COLUMNS,
  REFUND_TAX_CELL_WIDTH,
} from '~/constants/refund'
import { getRefundCalculation } from '~/store/duck/refunds'
import { getRefundReason } from '~/store/reducers/constants'
import { getPatientName } from '~/store/reducers/patients'
import { InvoiceLineItem } from '~/types'
import { getConstantIdStringByNameString } from '~/utils'
import { useGetRefundAndRestock } from '~/utils/refundUtils'
import useDialog from '~/utils/useDialog'

import { PatientPreferencesChip } from '../../clients/patient/PatientPreferencesChip'
import {
  RefundContext,
  RefundTableRowHandle,
  RefundTableRowValue,
} from './RefundContext'

const useStyles = makeStyles(
  (theme) => ({
    creationDate: {
      opacity: '50%',
    },
    groupedCell: {
      padding: 0,
    },
    isSelectedRow: {
      backgroundColor: '#F5F9FB',
      borderTop: '0.5px solid #5A98B1',
      borderBottom: '0.5px solid #5A98B1',
      '&:first-of-type': {
        borderLeft: '0.5px solid #5A98B1',
      },
      '&:last-of-type': {
        borderRight: '0.5px solid #5A98B1',
      },
    },
    addNoteIcon: {
      '& path': {
        fill: theme.colors.title,
      },
      paddingLeft: 8,
      paddingRight: 8,
    },
    refundQtyCellTooltip: {
      color: '#ffffff',
      backgroundColor: '#3c3838',
      // PuiBaseTooltip has hardcoded tooltip arrow in styles.
      '&::before': {
        display: 'none',
      },
      '&::after': {
        display: 'none',
      },
    },
    tableCellWithBorder: {
      borderRight: theme.constants.tableBorder,
      borderBottom: 'none',
      paddingTop: 0,
      paddingBottom: 0,
      alignSelf: 'center',
    },
    selectCell: {
      minWidth: REFUND_SELECT_CELL_WIDTH,
      width: REFUND_SELECT_CELL_WIDTH,
      borderBottom: 'none',
    },
    patientCell: {
      minWidth: REFUND_PATIENT_CELL_WIDTH,
      width: REFUND_PATIENT_CELL_WIDTH,
    },
    reasonCell: {
      maxWidth: REFUND_REASON_CELL_WIDTH,
      minWidth: REFUND_REASON_CELL_WIDTH,
      width: REFUND_REASON_CELL_WIDTH,
      paddingRight: 4,
      '& div': {
        fontSize: '1.4rem',
      },
    },
    reasonSummaryCell: {
      maxWidth: REFUND_REASON_SUMMARY_CELL_WIDTH,
      minWidth: REFUND_REASON_SUMMARY_CELL_WIDTH,
      width: REFUND_REASON_SUMMARY_CELL_WIDTH,
    },
    refundQtyCell: {
      minWidth: REFUND_REFUND_QTY_CELL_WIDTH,
      width: REFUND_REFUND_QTY_CELL_WIDTH,
      '& input, & p': {
        fontSize: '1.4rem',
      },
    },
    refundQtySummaryCell: {
      minWidth: REFUND_REFUND_QTY_SUMMARY_CELL_WIDTH,
      width: REFUND_REFUND_QTY_SUMMARY_CELL_WIDTH,
    },
    chip: {
      width: 24,
      height: 20,
      marginLeft: 8,
      marginRight: 8,
    },
    restockQtyCell: {
      minWidth: REFUND_RESTOCK_QTY_CELL_WIDTH,
      width: REFUND_RESTOCK_QTY_CELL_WIDTH,
      '& div, & input': {
        fontSize: '1.4rem',
      },
    },
    restockQtySummaryCell: {
      minWidth: REFUND_RESTOCK_QTY_SUMMARY_CELL_WIDTH,
      width: REFUND_RESTOCK_QTY_SUMMARY_CELL_WIDTH,
    },
    amountPaidCell: {
      minWidth: REFUND_AMOUNT_PAID_CELL_WIDTH,
      width: REFUND_AMOUNT_PAID_CELL_WIDTH,
    },
    taxCell: {
      minWidth: REFUND_TAX_CELL_WIDTH,
      width: REFUND_TAX_CELL_WIDTH,
    },
    discountCell: {
      minWidth: REFUND_DISCOUNT_CELL_WIDTH,
      width: REFUND_DISCOUNT_CELL_WIDTH,
    },
    refundAmountCell: {
      minWidth: REFUND_REFUND_AMOUNT_CELL_WIDTH,
      width: REFUND_REFUND_AMOUNT_CELL_WIDTH,
    },
    financeItemBundleCell: {
      height: FINANCE_TABLE_CELL_HEIGHT,
    },
    itemNameSummaryCell: {
      paddingLeft: theme.spacing(3),
    },
  }),
  { name: 'RefundTableRow' },
)

export interface RefundTableRowProps {
  groupItem?: InvoiceLineItem | GroupedRefundItem
  isExpandedSelector?: any
  isNested?: boolean
  item: InvoiceLineItem | RefundInvoiceLineItem
  tableCellClassName: string
  toggleExpand?: (item: InvoiceLineItem) => void
}

// TODO Break this up into smaller reusable components
export const RefundTableRow = forwardRef<
  RefundTableRowHandle,
  RefundTableRowProps
>(
  (
    {
      isExpandedSelector,
      groupItem,
      isNested = false,
      item: itemProp,
      tableCellClassName,
      toggleExpand,
    }: RefundTableRowProps,
    ref,
  ) => {
    const classes = useStyles()
    const { t } = useTranslation(['Common', 'Invoices'])
    const item = itemProp as InvoiceLineItem
    const refundItem = itemProp as RefundInvoiceLineItem
    const { clientId, patientId } = item

    const [openNoteHistoryDialog] = useDialog(DialogNames.NOTE_HISTORY)
    const isExpanded = useSelector(
      isExpandedSelector ? isExpandedSelector(item) : R.F,
    ) as boolean
    const patientName = useSelector(getPatientName(item.patientId))
    const refundReasonItems = useSelector(getRefundReason)

    const patientAversionId = getConstantIdStringByNameString(
      'Patient aversion',
      refundReasonItems,
    )

    const medicalAversionId = getConstantIdStringByNameString(
      'Medical reason',
      refundReasonItems,
    )

    const {
      getRowRefs,
      invoice,
      itemsMarkedForRefund,
      onMarkForRefund,
      onRemoveFromRefund,
      onResetRefundCandidate,
      onSelectItem,
      onUpdateRefundCandidate,
      refundCandidate,
      selectedItem,
      updateRefundAmounts,
      viewState,
    } = useContext(RefundContext)

    const landingView = viewState === InvoiceViewStates.REFUND_LANDING
    const summaryView = viewState === InvoiceViewStates.REFUND_SUMMARY
    const invoiceView = viewState === InvoiceViewStates.REFUND_INVOICE

    const columnsLength =
      summaryView || invoiceView
        ? REFUND_SUMMARY_COLUMNS.length
        : REFUND_LANDING_COLUMNS.length

    const {
      variationPackageTypeId,
      variationPerPackageUnitsId,
      variationPerPackageAmount = 1,
      quantity,
      totalRefundEligibleQty,
      totalRestockEligibleQty = 0,
      prepaid,
      id: itemId,
      groupSnapshotId,
      subTotal: itemSubtotal,
    } = item

    const {
      restockedAsPackage,
      price: invoicePrice,
      discount: invoiceDiscount,
      taxAmount: invoiceTaxAmount,
    } = refundItem

    const refundCalculation = useSelector(getRefundCalculation(invoice?.id))

    const {
      packageName,
      perPackageUnitName,
      purchasedAsPackage,
      refundPackageName,
    } = useGetRefundAndRestock({
      originalLineItem: item,
      restockedAsPackage,
    })

    const maxRefundQty = prepaid
      ? Math.min(totalRefundEligibleQty ?? quantity, quantity)
      : totalRefundEligibleQty ?? quantity

    const isGrouped = isGroupedInvoiceItem(item)

    /*
     Prepaids invoiceLineItems can be refunded if and only it was purchased on this invoice
     or if additional quantities were purhcased on this invoice
     */
    const isItemPrepaid = (it: InvoiceLineItem) =>
      it.prepaid && it.quantity === 0
    const isWellnessPlanItem = Boolean(item.wplanLogId)

    const markedForRefund = itemsMarkedForRefund.has(itemId)
    const itemIsFree = itemSubtotal === 0

    const canRestock = !isGrouped && canRestockInvoiceItem(item)

    const itemIsFullyRefunded = (itemToCheck: InvoiceLineItem) =>
      isUnrefundable(itemToCheck)

    const isFullyRefunded = isGrouped
      ? item.items?.every(itemIsFullyRefunded)
      : itemIsFullyRefunded(item)

    const disableSingleCheckbox = (singleItem: InvoiceLineItem) =>
      isItemPrepaid(singleItem) ||
      Boolean(singleItem.wplanLogId) ||
      itemIsFullyRefunded(singleItem) ||
      // Free items can't be refunded, and if also can't restock, not selectable.
      (singleItem.subTotal === 0 && !canRestockInvoiceItem(singleItem))

    const enableGroupCheckBox = () =>
      (item.items || []).some((it) => !disableSingleCheckbox(it))

    const isSingleCheckBoxDisabled = disableSingleCheckbox(item)

    const isRestockAsPackage = (restockAsPackage: string) =>
      restockAsPackage.split('-')[0] === 'package'
    const validateRefundMaxQty = (refundQuantity: number) =>
      refundQuantity <= maxRefundQty
    const refundMaxValidator = ({
      state: { refundQuantity },
    }: CustomFieldValidatorState) => validateRefundMaxQty(refundQuantity)

    const restockPackageMaxValidator = ({
      state: { restockAsPackage, restockQuantity },
    }: CustomFieldValidatorState) => {
      if (isRestockAsPackage(restockAsPackage) || !purchasedAsPackage) {
        return restockQuantity <= totalRestockEligibleQty
      }
      return true
    }

    const restockPackageUnitMaxValidator = ({
      state: { restockQuantity },
    }: CustomFieldValidatorState) =>
      restockQuantity <= totalRestockEligibleQty * variationPerPackageAmount

    const { fields, validate } = useFields(
      [
        {
          name: 'refundReason',
          label: t('Invoices:REFUND_REASON_LABEL'),
          type: 'select',
          validators: markedForRefund ? ['required'] : [],
          initialValue: groupItem
            ? getRowRefs()
                .get((groupItem as InvoiceLineItem).id)
                ?.getValue().refundReasonId
            : undefined,
        },
        {
          name: 'refundQuantity',
          type: 'number',
          validators:
            markedForRefund && !isGrouped && !itemIsFree
              ? [
                  'required',
                  'greaterThanZero',
                  {
                    validator: refundMaxValidator,
                    validatorName: 'refundMaxValidator',
                  },
                ]
              : [],
          messages: {
            refundMaxValidator: t('Validations:MAXIMUM_QUANTITY', {
              max: maxRefundQty,
            }),
          },
        },
        {
          name: 'restockQuantity',
          type: 'number',
          validators:
            markedForRefund && canRestock
              ? [
                  'required',
                  {
                    validator: restockPackageMaxValidator,
                    validatorName: 'restockPackageMaxValidator',
                  },
                  {
                    validator: restockPackageUnitMaxValidator,
                    validatorName: 'restockPackageUnitMaxValidator',
                  },
                ]
              : [],
          messages: {
            restockPackageMaxValidator: t('Validations:MAXIMUM_QUANTITY', {
              max: totalRestockEligibleQty,
            }),
            restockPackageUnitMaxValidator: t('Validations:MAXIMUM_QUANTITY', {
              max: totalRestockEligibleQty * variationPerPackageAmount,
            }),
          },
        },
        {
          name: 'restockAsPackage',
          type: 'select',
          validators: markedForRefund && canRestock ? ['required'] : [],
          initialValue: purchasedAsPackage
            ? `package-${variationPackageTypeId}`
            : `packageUnit-${variationPerPackageUnitsId}`,
        },
      ],
      false,
    )

    const restockItems = [
      {
        id: `packageUnit-${variationPerPackageUnitsId}`,
        name: perPackageUnitName,
        nameTranslation: `${
          purchasedAsPackage
            ? totalRestockEligibleQty * variationPerPackageAmount
            : totalRestockEligibleQty
        } ${perPackageUnitName}`,
      },
      ...(purchasedAsPackage
        ? [
            {
              id: `package-${variationPackageTypeId}`,
              name: packageName,
              nameTranslation: `${totalRestockEligibleQty} ${packageName}`,
            },
          ]
        : []),
    ] as NamedEntity[]

    const { refundReason, refundQuantity, restockQuantity, restockAsPackage } =
      fields

    const reasonTypeToSelected = {
      patientAversion:
        refundReason.value === patientAversionId &&
        !R.isEmpty(refundReason.value),
      medicalAversion:
        refundReason.value === medicalAversionId &&
        !R.isEmpty(refundReason.value),
    }

    const keepExpanded =
      markedForRefund ||
      R.any((i) => itemsMarkedForRefund.has(i.id), item.items || [])

    const toggleExpandInner = (i: InvoiceLineItem) => {
      if (isGrouped && toggleExpand) {
        toggleExpand(i)
      }
    }

    const CommonNameProps = {
      isGrouped,
      item,
      nested: isNested,
      isReadOnly: !landingView,
      tableCellClassName: classNames(
        tableCellClassName,
        classes.tableCellWithBorder,
      ),
    }

    const groupIdWithItemIds = [
      item.id,
      ...(item.items || [])
        .filter((childItem) => {
          if (
            isItemPrepaid(childItem) ||
            Boolean(childItem.wplanLogId) ||
            itemIsFullyRefunded(childItem) ||
            // Free items can't be refunded, and if also can't restock, not selectable.
            (childItem.subTotal === 0 && !canRestockInvoiceItem(childItem))
          ) {
            return false
          }

          return true
        })
        .map((i) => i.id),
    ]

    const handleMarkGroupItem = (
      currentItem: InvoiceLineItem,
      isCurrentlyExpanded: boolean,
    ) => {
      if (!isCurrentlyExpanded) {
        toggleExpandInner(currentItem)
      }

      onMarkForRefund(groupIdWithItemIds)
    }

    const handleMarkSingleItem = (currentItemId: string) => {
      const idsToMark = [currentItemId]
      if (groupItem) {
        const itemsToCheck = R.without(
          [currentItemId],
          (groupItem.items || []).map((i) => i.id),
        )
        const allOthersMarked = R.all(
          (i: string) => itemsMarkedForRefund.has(i),
          itemsToCheck,
        )

        if (allOthersMarked) {
          idsToMark.push((groupItem as InvoiceLineItem).id)
        }
      }
      onMarkForRefund(idsToMark)
    }

    const onSetGroupReason = (event: SelectChangeEvent<unknown>) => {
      const newValue = event.target.value
      refundReason.setValue(newValue)
      const children = item.items || []
      children.forEach((c) => {
        const childRef = getRowRefs().get(c.id)
        childRef?.setReason(newValue as string)
      })
    }

    const calcItemsGrouped = R.map(
      (section) =>
        R.map(
          (groupedItem) =>
            'items' in groupedItem
              ? [groupedItem, ...groupedItem.items]
              : groupedItem,
          section.groupedItems,
        ),
      refundCalculation?.sections || [],
    )

    const calcItems = R.flatten(calcItemsGrouped)

    const refundAmountResult = R.find((calcItem) => {
      if (R.has('groupSnapshot', calcItem) && isGrouped) {
        return (
          (calcItem as GroupedRefundItemCalculation).groupSnapshot!.id ===
          groupSnapshotId
        )
      }
      return (
        (calcItem as RefundInvoiceLineItemCalculation)?.originalLineItem?.id ===
        itemId
      )
    }, calcItems)

    const refundAmount = refundAmountResult
      ? isGrouped
        ? (refundAmountResult as GroupedRefundItemCalculation).subTotal
        : (refundAmountResult as RefundInvoiceLineItemCalculation).refundAmount
      : 0

    const discount = invoiceView
      ? invoiceDiscount
      : refundAmountResult?.discount
    const taxAmount = invoiceView
      ? invoiceTaxAmount
      : refundAmountResult?.taxAmount
    const showRefundColumns = markedForRefund || invoiceView
    const isCurrentItemRefundCandidate =
      refundCandidate && refundCandidate.id === item.id

    useImperativeHandle(
      ref,
      () => ({
        validate,
        getValue: () => {
          const restockedAsPackageValue = isRestockAsPackage(
            restockAsPackage.value,
          )
          const restockQtyItem = !restockedAsPackageValue
            ? restockQuantity.value / variationPerPackageAmount
            : restockQuantity.value

          return isGrouped
            ? ({} as RefundTableRowValue)
            : {
                originalLineItemId: itemId,
                refundReasonId: refundReason.value,
                refundQty: refundQuantity.value,
                restockQty: restockQtyItem,
                restockedAsPackage: restockedAsPackageValue,
              }
        },
        setReason: (reason: string) => refundReason.setValue(reason),
      }),
      [item.id, refundReason],
    )

    const handleChangeRefundQuantity: OutlinedInputProps['onChange'] = (
      event,
    ) => {
      const newValue = Number(event.target.value)
      // Only update when within range
      if (validateRefundMaxQty(newValue)) {
        refundQuantity.setValue(newValue)

        if (isCurrentItemRefundCandidate) {
          onUpdateRefundCandidate({ refundQuantity: newValue })
        }

        // Update value immediately so that refund amounts is calculated with correct value
        refundQuantity.value = newValue
        updateRefundAmounts()
      }
    }

    const handleChangeRestockQuantity: OutlinedInputProps['onChange'] = (
      event,
    ) => {
      const newValue = Number(event.target.value)
      restockQuantity.setValue(newValue)

      if (isCurrentItemRefundCandidate) {
        onUpdateRefundCandidate({ restockQuantity: newValue })
      }
    }

    const handleChangeRefundReason = (event: SelectChangeEvent<unknown>) => {
      const newValue = event.target.value as string
      refundReason.setValue(newValue)

      if (isCurrentItemRefundCandidate) {
        onUpdateRefundCandidate({ refundReason: newValue })
      }
    }

    const handleSetInitialRefundCandidate = (id: string) => {
      onUpdateRefundCandidate({
        id,
        refundAmount,
        refundReason: refundReason.value,
        refundQuantity: refundQuantity.value,
        restockQuantity: restockQuantity.value,
        hidden: isSingleCheckBoxDisabled,
        onChangeRefundReason: handleChangeRefundReason,
      })
    }

    useEffect(() => {
      if (
        isCurrentItemRefundCandidate &&
        refundReason.value !== refundCandidate?.refundReason
      ) {
        onUpdateRefundCandidate({ refundReason: refundReason.value })
      }
    }, [refundReason.value])

    useEffect(() => {
      if (
        isCurrentItemRefundCandidate &&
        refundAmount !== refundCandidate?.refundAmount
      ) {
        onUpdateRefundCandidate({ refundAmount })
      }
    }, [refundAmount])

    if (summaryView && !isGrouped && !markedForRefund) {
      return <></>
    }

    return (
      <>
        {isGrouped && item.items ? (
          <TableCell
            className={classes.groupedCell}
            colSpan={columnsLength}
            id={item.group}
            scope="row"
          >
            <Grid
              container
              className={classNames(classes.financeItemBundleCell, {
                [classes.isSelectedRow]: landingView && markedForRefund,
              })}
              wrap="nowrap"
            >
              {!(summaryView || invoiceView) && (
                <Grid
                  container
                  item
                  className={classes.selectCell}
                  justifyContent="center"
                >
                  <PuiTooltip
                    tooltipText={
                      !enableGroupCheckBox()
                        ? t('Invoices:PREPAID_DISABLED_TOOLTIP')
                        : ''
                    }
                  >
                    <div>
                      <Checkbox
                        checked={markedForRefund}
                        disabled={!enableGroupCheckBox()}
                        onChange={(_, checked) =>
                          checked
                            ? handleMarkGroupItem(item, isExpanded)
                            : onRemoveFromRefund(groupIdWithItemIds)
                        }
                      />
                    </div>
                  </PuiTooltip>
                </Grid>
              )}
              <Grid container pl={2}>
                <FinanceItemNameCellBody
                  {...CommonNameProps}
                  handleSelectItem={(selectedGroupItem) => {
                    onResetRefundCandidate()
                    onSelectItem(selectedGroupItem)
                  }}
                  isExpanded={isExpanded}
                  selectedItem={selectedItem}
                  showAsDisabled={!enableGroupCheckBox()}
                  toggleExpand={keepExpanded ? () => {} : toggleExpandInner}
                />
              </Grid>
              <Grid
                container
                item
                className={classNames(
                  classes.tableCellWithBorder,
                  classes.patientCell,
                )}
                justifyContent="flex-start"
                pl={2}
              >
                <Text variant={!enableGroupCheckBox() ? 'lowAccent2' : 'body2'}>
                  {patientName}
                </Text>
              </Grid>
              <Grid
                className={classNames(
                  classes.tableCellWithBorder,
                  summaryView ? classes.reasonSummaryCell : classes.reasonCell,
                )}
                pl={showRefundColumns ? 2 : 0}
                pr={showRefundColumns ? 2 : 0}
              >
                {showRefundColumns &&
                  (summaryView ? (
                    <Text variant="body2">
                      {getConstantTranslatedName(
                        refundReason.value,
                        refundReasonItems,
                      )}
                    </Text>
                  ) : (
                    <Grid alignItems="center" display="flex">
                      <FormControl fullWidth error={!refundReason.valid}>
                        <PuiSelect
                          field={refundReason}
                          items={refundReasonItems}
                          placeholder={refundReason.label}
                          onChange={onSetGroupReason}
                        />
                      </FormControl>
                      {reasonTypeToSelected.medicalAversion && (
                        <IconButton
                          className={classes.addNoteIcon}
                          size="large"
                          onClick={() =>
                            openNoteHistoryDialog({ clientId, patientId })
                          }
                        >
                          <AddNote filled={Boolean(item.notes)} />
                        </IconButton>
                      )}
                      {reasonTypeToSelected.patientAversion && (
                        <PatientPreferencesChip
                          className={classes.chip}
                          patientId={item.patientId}
                        />
                      )}
                    </Grid>
                  ))}
              </Grid>
              <Grid
                className={classNames(
                  classes.tableCellWithBorder,
                  summaryView
                    ? classes.refundQtySummaryCell
                    : classes.refundQtyCell,
                )}
              />
              <Grid
                className={classNames(
                  classes.tableCellWithBorder,
                  summaryView
                    ? classes.restockQtySummaryCell
                    : classes.restockQtyCell,
                )}
              />
              <Grid
                container
                item
                className={classNames(
                  classes.tableCellWithBorder,
                  classes.amountPaidCell,
                )}
                justifyContent="flex-start"
                pl={2}
              >
                {showRefundColumns && (
                  <Text
                    variant={!enableGroupCheckBox() ? 'lowAccent2' : 'body2'}
                  >
                    {NumberUtils.formatMoney(itemSubtotal)}
                  </Text>
                )}
              </Grid>
              {(summaryView || invoiceView) && (
                <>
                  <Grid
                    container
                    item
                    className={classNames(
                      classes.tableCellWithBorder,
                      classes.taxCell,
                    )}
                    justifyContent="flex-start"
                    pl={2}
                  >
                    {showRefundColumns && (
                      <Text variant="body2">
                        {NumberUtils.formatMoney(taxAmount)}
                      </Text>
                    )}
                  </Grid>
                  <Grid
                    container
                    item
                    className={classNames(
                      classes.tableCellWithBorder,
                      classes.discountCell,
                    )}
                    justifyContent="flex-start"
                    pl={2}
                  >
                    {showRefundColumns && (
                      <Text variant="body2">
                        {NumberUtils.formatMoney(discount)}
                      </Text>
                    )}
                  </Grid>
                </>
              )}
              <Grid
                container
                item
                className={classNames(
                  classes.tableCellWithBorder,
                  classes.refundAmountCell,
                )}
                justifyContent="flex-end"
                pr={2}
              >
                <Text variant={!enableGroupCheckBox() ? 'lowAccent2' : 'body2'}>
                  {showRefundColumns &&
                    formatMoneyRange(invoiceView ? invoicePrice : refundAmount)}
                </Text>
              </Grid>
            </Grid>
            {isExpanded &&
              item.items.map((childItem) =>
                landingView ||
                (summaryView && itemsMarkedForRefund.has(childItem.id)) ? (
                  <>
                    <Table key={`${childItem.bundleId}_${childItem.id}`}>
                      <TableBody>
                        <TableRow
                          className={classNames(tableCellClassName, {
                            [classes.isSelectedRow]:
                              landingView &&
                              itemsMarkedForRefund.has(childItem.id),
                          })}
                          id={childItem.logId}
                        >
                          <RefundTableRow
                            isNested
                            groupItem={item}
                            item={childItem}
                            ref={(node) => {
                              if (node) {
                                getRowRefs().set(childItem.id, node)
                              }
                            }}
                            tableCellClassName={tableCellClassName}
                          />
                        </TableRow>
                      </TableBody>
                    </Table>
                  </>
                ) : (
                  <></>
                ),
              )}
          </TableCell>
        ) : (
          <Table>
            <TableBody>
              <TableRow
                className={classNames(tableCellClassName, {
                  [classes.isSelectedRow]:
                    landingView && itemsMarkedForRefund.has(item.id),
                })}
                id={item.logId}
              >
                {!(summaryView || invoiceView) && (
                  <TableCell
                    align="center"
                    className={classNames(
                      tableCellClassName,
                      classes.selectCell,
                    )}
                  >
                    <PuiTooltip
                      tooltipText={
                        isWellnessPlanItem
                          ? t('Invoices:ITEM_REFUND_DISABLED_TOOLTIP')
                          : isItemPrepaid(item)
                          ? t('Invoices:PREPAID_DISABLED_TOOLTIP')
                          : itemIsFree && !canRestock
                          ? t('Invoices:ITEM_NOT_SELECTABLE')
                          : isFullyRefunded
                          ? t('Invoices:ITEM_IS_FULLY_REFUNDED')
                          : ''
                      }
                    >
                      <div>
                        <Checkbox
                          checked={markedForRefund}
                          disabled={isSingleCheckBoxDisabled}
                          onChange={(_, checked) => {
                            if (checked) {
                              handleMarkSingleItem(item.id)
                              if (selectedItem && item.id === selectedItem.id) {
                                handleSetInitialRefundCandidate(selectedItem.id)
                              }
                            } else {
                              onRemoveFromRefund(
                                groupItem
                                  ? [item.id, (groupItem as InvoiceLineItem).id]
                                  : [item.id],
                              )
                              if (selectedItem && item.id === selectedItem.id) {
                                onResetRefundCandidate()
                              }
                            }
                          }}
                        />
                      </div>
                    </PuiTooltip>
                  </TableCell>
                )}
                <FinanceItemNameCellBody
                  {...CommonNameProps}
                  handleSelectItem={(singleSelectedItem) => {
                    if (itemsMarkedForRefund.has(singleSelectedItem.id)) {
                      handleSetInitialRefundCandidate(singleSelectedItem.id)
                    } else {
                      onResetRefundCandidate()
                    }
                    onSelectItem(singleSelectedItem)
                  }}
                  isExpanded={false}
                  selectedItem={selectedItem}
                  showAsDisabled={isSingleCheckBoxDisabled}
                  tableCellClassName={classNames(classes.tableCellWithBorder, {
                    [classes.itemNameSummaryCell]: summaryView,
                  })}
                  toggleExpand={toggleExpandInner}
                />
                <TableCell
                  className={classNames(
                    tableCellClassName,
                    classes.tableCellWithBorder,
                    classes.patientCell,
                  )}
                >
                  <Text
                    variant={isSingleCheckBoxDisabled ? 'lowAccent2' : 'body2'}
                  >
                    {patientName}
                  </Text>
                </TableCell>
                <TableCell
                  className={classNames(
                    tableCellClassName,
                    classes.tableCellWithBorder,
                    summaryView
                      ? classes.reasonSummaryCell
                      : classes.reasonCell,
                  )}
                >
                  {showRefundColumns &&
                    (invoiceView ? (
                      <Text
                        variant={
                          isSingleCheckBoxDisabled ? 'lowAccent2' : 'body2'
                        }
                      >
                        {getConstantTranslatedName(
                          refundItem.refundReason.id,
                          refundReasonItems,
                        )}
                      </Text>
                    ) : summaryView ? (
                      <Text
                        variant={
                          isSingleCheckBoxDisabled ? 'lowAccent2' : 'body2'
                        }
                      >
                        {getConstantTranslatedName(
                          refundReason.value,
                          refundReasonItems,
                        )}
                      </Text>
                    ) : (
                      <Grid
                        alignContent="space-between"
                        alignItems="center"
                        display="flex"
                      >
                        <FormControl fullWidth error={!refundReason.valid}>
                          <PuiSelect
                            field={refundReason}
                            items={refundReasonItems}
                            placeholder={refundReason.label}
                            onChange={handleChangeRefundReason}
                          />
                        </FormControl>
                        {reasonTypeToSelected.medicalAversion && (
                          <IconButton
                            className={classes.addNoteIcon}
                            size="large"
                            onClick={() =>
                              openNoteHistoryDialog({ clientId, patientId })
                            }
                          >
                            <AddNote filled={Boolean(item.notes)} />
                          </IconButton>
                        )}
                        {reasonTypeToSelected.patientAversion && (
                          <PatientPreferencesChip
                            className={classes.chip}
                            patientId={item.patientId}
                          />
                        )}
                      </Grid>
                    ))}
                </TableCell>
                <PuiTooltip
                  classes={{
                    tooltip: classes.refundQtyCellTooltip,
                  }}
                  slotProps={{
                    popper: {
                      modifiers: [
                        {
                          name: 'offset',
                          options: {
                            offset: [0, -REFUND_REFUND_QTY_CELL_WIDTH],
                          },
                        },
                      ],
                    },
                  }}
                  tooltipPlacement="right-start"
                  tooltipText={
                    itemIsFree
                      ? t('Invoices:UNPAID_ITEM_NO_REFUND_TOOLTIP')
                      : ''
                  }
                >
                  <TableCell
                    className={classNames(
                      tableCellClassName,
                      classes.tableCellWithBorder,
                      summaryView
                        ? classes.refundQtySummaryCell
                        : classes.refundQtyCell,
                    )}
                  >
                    {showRefundColumns &&
                      !itemIsFree &&
                      (summaryView || invoiceView ? (
                        <Text variant="body2">
                          {refundQuantity.value} {refundPackageName}
                        </Text>
                      ) : (
                        <Grid container direction="row">
                          <Grid container item justifyContent="center" xs={5}>
                            <PuiTextField
                              field={refundQuantity}
                              inputProps={{
                                min: 0,
                                max: maxRefundQty,
                                step: canRestock ? '0.1' : '1',
                              }}
                              type="number"
                              onChange={handleChangeRefundQuantity}
                            />
                          </Grid>
                          <Grid
                            container
                            item
                            alignContent="center"
                            justifyContent="flex-start"
                            xs={7}
                          >
                            <Text>
                              {`/${maxRefundQty} ${refundPackageName}`}
                            </Text>
                          </Grid>
                        </Grid>
                      ))}
                  </TableCell>
                </PuiTooltip>

                <TableCell
                  className={classNames(
                    tableCellClassName,
                    classes.tableCellWithBorder,
                    summaryView
                      ? classes.restockQtySummaryCell
                      : classes.restockQtyCell,
                  )}
                >
                  {showRefundColumns &&
                    canRestock &&
                    (summaryView ? (
                      <Text
                        variant={
                          isSingleCheckBoxDisabled ? 'lowAccent2' : 'body2'
                        }
                      >
                        {`${restockQuantity.value} ${
                          isRestockAsPackage(restockAsPackage.value)
                            ? packageName
                            : perPackageUnitName
                        }`}
                      </Text>
                    ) : (
                      <Grid container direction="row">
                        <Grid
                          container
                          item
                          justifyContent="center"
                          pr={0.5}
                          xs={4}
                        >
                          <PuiTextField
                            field={restockQuantity}
                            inputProps={{
                              min: 0,
                              max:
                                isRestockAsPackage(restockAsPackage.value) ||
                                !purchasedAsPackage
                                  ? totalRestockEligibleQty
                                  : totalRestockEligibleQty *
                                    variationPerPackageAmount,
                              step: '0.1',
                            }}
                            type="number"
                            onChange={handleChangeRestockQuantity}
                          />
                        </Grid>
                        <Grid
                          container
                          item
                          alignContent="center"
                          justifyContent="center"
                          xs={0.5}
                        >
                          <span>/</span>
                        </Grid>
                        <Grid
                          container
                          item
                          alignContent="center"
                          justifyContent="flex-end"
                          xs={7.5}
                        >
                          <FormControl fullWidth error={!refundReason.valid}>
                            <PuiSelect
                              field={restockAsPackage}
                              items={restockItems}
                              valueProp="name"
                            />
                          </FormControl>
                        </Grid>
                      </Grid>
                    ))}
                </TableCell>
                <TableCell
                  className={classNames(
                    classes.tableCellWithBorder,
                    classes.amountPaidCell,
                  )}
                >
                  <Text
                    variant={isSingleCheckBoxDisabled ? 'lowAccent2' : 'body2'}
                  >
                    {NumberUtils.formatMoney(
                      invoiceView
                        ? refundItem.originalLineItem.subTotal
                        : itemSubtotal,
                    )}
                  </Text>
                </TableCell>
                {summaryView && (
                  <>
                    <TableCell
                      className={classNames(
                        classes.tableCellWithBorder,
                        classes.taxCell,
                      )}
                    >
                      <Text variant="body2">
                        {NumberUtils.formatMoney(taxAmount)}
                      </Text>
                    </TableCell>
                    <TableCell
                      className={classNames(
                        classes.tableCellWithBorder,
                        classes.discountCell,
                      )}
                    >
                      <Text variant="body2">
                        {NumberUtils.formatMoney(discount)}
                      </Text>
                    </TableCell>
                  </>
                )}
                <TableCell
                  align="right"
                  className={classNames(
                    classes.tableCellWithBorder,
                    classes.refundAmountCell,
                  )}
                >
                  <Text
                    variant={isSingleCheckBoxDisabled ? 'lowAccent2' : 'body2'}
                  >
                    {showRefundColumns &&
                      formatMoneyRange(
                        invoiceView ? invoicePrice : refundAmount,
                      )}
                  </Text>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        )}
      </>
    )
  },
)
