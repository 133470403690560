import { gql } from '@apollo/client'

import {
  CHARGE_SHEET_EVENTS,
  CHARGE_SHEET_SECTIONS_FIELDS,
  CLIENT_FIELDS,
} from './chargeSheet'
import { PATIENT_FIELDS } from './clientAndPatient'
import { PAYMENT_FIELDS } from './payments'

// TODO check wether we need all of the patients fields
export const INVOICE_FIELDS = gql`
  ${PAYMENT_FIELDS}
  ${CHARGE_SHEET_SECTIONS_FIELDS}
  ${CHARGE_SHEET_EVENTS}
  ${PATIENT_FIELDS}
  ${CLIENT_FIELDS}
  fragment InvoiceFields on Invoice {
    id
    invoiceNo
    internalNote
    clientId
    balanceAfterPaid
    businessId
    type
    subtotal
    totalTax
    additionalDiscount
    discountReasonId
    discountReasonName
    amount
    amountNoFee
    state
    stateId
    dueToPay
    dueToPayNoFee
    paid
    paidAmount
    paidDate
    serviceFeeAmount
    showBalance
    prevBalance
    totalDiscount
    modificationDate
    innerModificationDate
    refunds {
      id
      state {
        id
        name
      }
      payments {
        ...PaymentFields
      }
    }
    pendingRefundInvoice {
      id
    }
    refundDisabledReason {
      id
      name
    }
    client {
      ...ClientFields
    }
    events {
      ...ChargeSheetEvents
    }
    groups: sections {
      ...ChargeSheetSectionFields
    }
    patients {
      ...PatientFields
    }
    persons {
      id
      firstName
      lastName
    }
    payments {
      ...PaymentFields
    }
  }
`
